/*jshint esversion: 11 */
import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Alert,
  Container,
  Label,
  FormGroup
} from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter, Link, useHistory } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import validator from 'validator';
import { checkStaffLogin, apiError } from '../../store/actions';
import myAppLogo from '../../assets/images/app-logo.png';
import ForgotPasswordModal from './ForgotPasswordModal';

const Login = ({ loginError, checkStaffLogin, apiError, isAuthenticated }) => {
  const [phone_number] = useState('');
  const [password] = useState('');
  const [forgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false);
  const history = useHistory();

  const handleSubmit = (event, values) => {
    apiError('');
    checkStaffLogin(values, history);
  };

  useEffect(() => {
    document.body.classList.add('auth-body-bg');
    if (isAuthenticated) {
      history.push('/dashboard');
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const validatePhoneNumber = async (value, ctx, input, cb) => {
    if (!validator.isMobilePhone(value, 'en-IN')) {
      return cb('Please enter a valid Indian Mobile Number');
    }
    return cb(true);
  };

  return (
    <React.Fragment>
      <ForgotPasswordModal
        modalOpenHandler={setForgotPasswordModalOpen}
        modalOpenState={forgotPasswordModalOpen}
        type="staff"
      />
      <Container fluid className="p-0">
        <Row className="no-gutters">
          <Col lg={12}>
            <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div className="w-100">
                <Row className="justify-content-center">
                  <Col lg={4}>
                    <div>
                      <div className="text-center">
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ height: '150px' }}
                        >
                          <Link
                            to="/"
                            className="logo"
                            style={{ height: '100%' }}
                          >
                            <img
                              src={myAppLogo}
                              alt="logo"
                              style={{ height: '100%', width: '100%' }}
                            />
                          </Link>
                        </div>

                        <h6 className="text-muted mt-4">
                          Sign in to continue to Staff Portal
                        </h6>
                      </div>

                      {loginError && loginError ? (
                        <Alert color="danger">{loginError}</Alert>
                      ) : null}

                      <div className="p-2 mt-3">
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={handleSubmit}
                        >
                          <FormGroup className="auth-form-group-custom mb-4">
                            <i className="ri-phone-line auti-custom-input-icon"></i>
                            <Label htmlFor="phone_number">Phone Number</Label>
                            <AvField
                              name="phone_number"
                              value={phone_number}
                              type="text"
                              className="form-control"
                              id="phone_number"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: 'Phone Number is required'
                                },
                                minLength: {
                                  value: 10,
                                  errorMessage: 'Phone Number must be 10 digits'
                                },
                                maxLength: {
                                  value: 10,
                                  errorMessage: 'Phone Number must be 10 digits'
                                },
                                async: validatePhoneNumber
                              }}
                              placeholder="Enter 10-digit phone number"
                            />
                          </FormGroup>

                          <FormGroup className="auth-form-group-custom mb-4">
                            <i className="ri-lock-2-line auti-custom-input-icon"></i>
                            <Label htmlFor="userpassword">Password</Label>
                            <AvField
                              name="password"
                              value={password}
                              type="password"
                              className="form-control"
                              id="userpassword"
                              validate={{ required: true }}
                              placeholder="Enter password"
                            />
                          </FormGroup>

                          <div className="mt-4 text-center">
                            <Button
                              color="primary"
                              className="w-md waves-effect waves-light"
                              type="submit"
                            >
                              Log In
                            </Button>
                          </div>

                          <Row>
                            <Col md="6">
                              <div
                                className="mt-4 text-center"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  setForgotPasswordModalOpen(true);
                                }}
                              >
                                <i className="mdi mdi-lock mr-1"></i> Forgot
                                your password?
                              </div>
                            </Col>
                            <Col md="6">
                              <div
                                className="mt-4 text-center"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  history.push('/login');
                                }}
                              >
                                <i className="mdi mdi-account-key align-middle mr-1"></i>{' '}
                                Sign in as Admin
                              </div>
                            </Col>
                          </Row>
                        </AvForm>
                      </div>

                      <div className="mt-5 text-center">
                        <p>© {new Date().getFullYear()} Raising Right</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { loginError, isAuthenticated } = state.Login;
  return { loginError, isAuthenticated };
};

export default withRouter(
  connect(mapStateToProps, { checkStaffLogin, apiError })(Login)
);
