/*jshint esversion: 11 */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  ModalFooter,
  Alert,
  CardTitle,
  Container,
  Card,
  CardBody,
  Badge,
  Table,
  Input,
  CardHeader
} from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { assignActivities, getBatchActivityList } from '../../api/batch';
import { getActivityList } from '../../api/activity';
import moment from 'moment';
import PageDataLoader from '../Utility/PageDataLoader';
import { useHistory } from 'react-router-dom';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import validator from 'validator';
import { getTagList } from '../../api/tag';
import { parse as QParse } from 'qs';
import ActivityFilterModal from './ActivityFilterModal';
import Paginator from '../Utility/Paginator';

const AddActivity = (props) => {
  const history = useHistory();
  let { batchStartDate, batchEndDate, batchStartTime, authUser } =
    props.location.state;
  let batchId = props.match.params.batchId;
  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });
  const [breadcrumbItems] = useState([
    { title: 'Batch', link: '/batches' },
    { title: 'Assign Activities', link: '#' }
  ]);
  const [assignActivityRequestLoading, setAssignActivityRequestLoading] =
    useState(false);
  const [successAlert, setSuccessAlert] = useState({
    message: '',
    show_alert: false
  });
  const [date, setDate] = useState(batchStartDate);
  const [type, setType] = useState('add');
  const [activityInfoLoading, setActivityInfoLoading] = useState(false);
  const [activityArray, setActivityArray] = useState([]);
  const [sortingRule, setSortingRule] = useState('created_at$desc');
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [currentFilterCount, setCurrentFilterCount] = useState(0);
  const [createdAtFilter, setCreatedAtFilter] = useState({
    startDate: null,
    endDate: null
  });
  const [updatedAtFilter, setUpdatedAtFilter] = useState({
    startDate: null,
    endDate: null
  });
  const [filterName, setFilterName] = useState('');
  const [filterDuration, setFilterDuration] = useState({
    min: '',
    max: ''
  });

  const [filterError, setFilterError] = useState(false);
  const [activityListLoading, setActivityListLoading] = useState(true);
  const [activityList, setActivityList] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageLimit: 10,
    totalEntries: 0,
    totalPages: 0
  });
  const [tagQueryId, setTagQueryId] = useState([]);
  const [filterTagList, setFilterTagList] = useState([]);
  const [selectedFilterTagOption, setSelectedFilterTagOption] = useState([]);
  const [isDeleted, setIsDeleted] = useState(false);
  const filterTagOptions = useMemo(
    () => buildTagOptions(filterTagList),
    // eslint-disable-next-line
    [filterTagList]
  );

  async function handleAssignActivity(event) {
    let activities = [];
    resetErrorAlert();
    if (activityArray.length > 0) {
      activities = activityArray.map((p) => ({
        activity: p.activity.value,
        start_time: p.start_time
      }));
    }

    if (activities.length === 0 && type === 'add') {
      showErrorAlert('Atleast one activity should be associated');
      return;
    }
    setAssignActivityRequestLoading(true);
    const { response, error } = await assignActivities(
      batchId,
      date,
      activities,
      type
    );
    setAssignActivityRequestLoading(false);
    if (error) {
      showErrorAlert(error);
      return;
    }
    setActivityArray([]);
    setDate(new Date());

    showSuccessAlert(response.message);
  }

  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }

  function showSuccessAlert(message) {
    setSuccessAlert({ message: message, show_alert: true });
  }

  function resetSuccessAlert() {
    setSuccessAlert({ message: '', show_alert: false });
  }

  function resetState() {
    resetSuccessAlert();
    resetErrorAlert();
  }

  let listElem = document.getElementById('batchList');
  let linkElem = document.getElementById('batchLink');

  useEffect(() => {
    resetState();
    if (listElem) listElem.classList.add('mm-active');
    if (linkElem) linkElem.classList.add('active');
    if (authUser && batchId) {
      fetchActivityInfo();
      fetchTagOptions();
      populateSearchOptions(history?.location?.search);
    }
    // eslint-disable-next-line
  }, [authUser, listElem, linkElem]);

  const handlePagination = (page) => {
    fetchData(
      page,
      pagination.pageLimit,
      createQueryOptions(),
      sortingRule,
      date
    );
  };

  const fetchData = async (
    current_page = 1,
    page_limit = 10,
    queryOptions = {},
    sortingOption = sortingRule,
    selectedDate = date
  ) => {
    setActivityListLoading(true);
    const response = await getActivityList(
      current_page,
      page_limit,
      queryOptions,
      sortingOption,
      props.match.params.batchId,
      selectedDate
    );

    if (response) {
      setPagination({
        currentPage: response.metadata.page,
        pageLimit: response.metadata.limit,
        totalPages: response.metadata.total_pages,
        totalEntries: response.metadata.total_results
      });
      setActivityList(response.data);
      setActivityListLoading(false);
    }
  };

  /**
   *
   * @param {string} str
   */
  function getValidDateInput(str) {
    return validator.isDate(str) ? new Date(str).toISOString() : '';
  }

  async function fetchTagOptions() {
    const response = await getTagList(1, 100, {});
    if (response) {
      setFilterTagList(response?.data);
    }
  }

  /**
   *
   * @param {array<object>} tagArray
   * @returns
   */
  function buildTagOptions(tagArray) {
    const tagOptions = tagArray.map((i) => {
      return {
        label: i.name,
        value: i
      };
    });
    const selectedTagOption = tagOptions.filter((i) =>
      tagQueryId.includes(i.value._id)
    );
    setSelectedFilterTagOption(selectedTagOption);
    return tagOptions;
  }

  function createQueryOptions(
    createdStart = createdAtFilter.startDate,
    createdEnd = createdAtFilter.endDate,
    updatedStart = updatedAtFilter.startDate,
    updatedEnd = updatedAtFilter.endDate,
    name = filterName,
    minDuration = filterDuration.min,
    maxDuration = filterDuration.max,
    deletedStatus = isDeleted,
    tags = selectedFilterTagOption
  ) {
    setFilterError(false);
    if (
      (!createdStart && createdEnd) ||
      (createdStart && !createdEnd) ||
      (!updatedStart && updatedEnd) ||
      (updatedStart && !updatedEnd)
    ) {
      setFilterError('Enter valid date range');
      return;
    }

    if (
      (!minDuration && maxDuration) ||
      (minDuration && !maxDuration) ||
      parseInt(minDuration) > parseInt(maxDuration)
    ) {
      setFilterError('Enter valid duration range');
      return;
    }
    const selectedTag = Array.isArray(tags)
      ? tags.map((i) => i.value._id).join(',')
      : '';
    const startDateISOStringCreatedAt = getValidDateInput(createdStart);
    const endDateISOStringCreatedAt = getValidDateInput(createdEnd);
    const startDateISOStringUpdatedAt = getValidDateInput(updatedStart);
    const endDateISOStringUpdatedAt = getValidDateInput(updatedEnd);
    const queryOptions = {
      filter_created_at_start: startDateISOStringCreatedAt,
      filter_created_at_end: endDateISOStringCreatedAt,
      filter_updated_at_start: startDateISOStringUpdatedAt,
      filter_updated_at_end: endDateISOStringUpdatedAt,
      filter_name: name,
      filter_tag_id: selectedTag,
      filter_duration_min: minDuration,
      filter_duration_max: maxDuration,
      include_deleted: deletedStatus,
      sorting_option: sortingRule
    };
    updateUrlPath(queryOptions);
    return queryOptions;
  }

  function updateFilterCount(option) {
    let count = 0;
    Object.keys(option).forEach((i) => {
      if (option[i]?.toString()?.trim()?.length > 0 && option[i] !== false) {
        count += 1;
      }
    });
    setCurrentFilterCount(count - 1);
  }

  /**
   *
   * @param {string} searchQuery
   */
  function populateSearchOptions(searchQuery) {
    const parsedQuery = QParse(searchQuery.substring(1));
    if (parsedQuery.filter_tag_id) {
      const tagId = parsedQuery.filter_tag_id.split(',').map((i) => i.trim());
      setTagQueryId(tagId);
    }
    if (
      parsedQuery.filter_created_at_start &&
      parsedQuery.filter_created_at_end
    ) {
      updateDateFilterInput(
        parsedQuery.filter_created_at_start,
        parsedQuery.filter_created_at_end,
        setCreatedAtFilter
      );
    }
    if (
      parsedQuery.filter_updated_at_start &&
      parsedQuery.filter_updated_at_end
    ) {
      updateDateFilterInput(
        parsedQuery.filter_updated_at_start,
        parsedQuery.filter_updated_at_end,
        setUpdatedAtFilter
      );
    }
    setFilterName(parsedQuery.filter_name);
    setFilterDuration({
      min: parsedQuery.filter_duration_min,
      max: parsedQuery.filter_duration_max
    });
    const sortingOption = parsedQuery.sorting_option || sortingRule;
    setSortingRule(sortingOption);
    updateFilterCount(parsedQuery);
    fetchData(1, null, parsedQuery, sortingOption);
  }

  /**
   *
   * @param {string} str
   * @param {function} setHook
   * @returns
   */
  function updateDateFilterInput(startStr, endStr, setHook) {
    setHook({
      startDate: new Date(startStr),
      endDate: new Date(endStr)
    });
  }

  function updateUrlPath(queryOptions) {
    // const queryString = QStringify(queryOptions);
    // history.replace(
    //   `/batches/activity/${props.match.params.batchId}?${queryString}`
    // );
  }

  function openFilterModal() {
    setFilterModalOpen(true);
  }

  function closeFilterModal() {
    setFilterModalOpen(false);
  }
  const fetchActivityInfo = async (
    date = batchStartDate,
    current_page = 1,
    page_limit = 1000
  ) => {
    setActivityInfoLoading(true);
    const response = await getBatchActivityList(
      batchId,
      date,
      '',
      current_page,
      page_limit
    );

    if (response) {
      if (response.data.length > 0) {
        setType('edit');
        let activityData = response.data.map((p) => {
          return {
            activity: { label: p.activity.name, value: p.activity._id },
            start_time: p.start_time
          };
        });
        setActivityArray(activityData);
      } else {
        setType('add');
        setActivityArray([]);
      }
      setActivityInfoLoading(false);
    } else {
      setActivityInfoLoading(false);
      showErrorAlert(
        'Given date does not lie between batch start and end date'
      );
      return;
    }
  };

  function handleStartTimeSelection(index, selectedItem) {
    resetErrorAlert();
    let value = selectedItem.target.value;
    let newActivityArray = [...activityArray];
    newActivityArray[index]['start_time'] = value;
    setActivityArray(newActivityArray);
  }

  let handleActivityModalClose = () => {
    resetErrorAlert();
    setActivityArray([
      {
        activity: '',
        start_time: batchStartTime
      }
    ]);
    setDate(batchStartDate);
  };

  const handleDateChange = async (selectedDate) => {
    setDate(selectedDate);
  };

  const fetchActivityDetailDate = async () => {
    await fetchData(
      pagination.currentPage,
      pagination.pageLimit,
      createQueryOptions(),
      sortingRule,
      date
    );
    await fetchActivityInfo(date);
  };

  return (
    <React.Fragment>
      <ActivityFilterModal
        filterError={filterError}
        setFilterError={setFilterError}
        modalOpenState={filterModalOpen}
        closeModalHandler={closeFilterModal}
        fetchList={fetchData}
        updateFilterCount={updateFilterCount}
        updateUrlPath={updateUrlPath}
        name={filterName}
        setName={setFilterName}
        createdAtFilter={createdAtFilter}
        setCreatedAtFilter={setCreatedAtFilter}
        updatedAtFilter={updatedAtFilter}
        setUpdatedAtFilter={setUpdatedAtFilter}
        createQueryOptions={createQueryOptions}
        duration={filterDuration}
        setDuration={setFilterDuration}
        authUser={authUser}
        isDeleted={isDeleted}
        setIsDeleted={setIsDeleted}
        tagOptions={filterTagOptions}
        selectedTagOption={selectedFilterTagOption}
        setSelectedTagOption={setSelectedFilterTagOption}
      />
      <div className="page-content">
        {successAlert.show_alert && (
          <SweetAlert
            success
            title="Assigned"
            onConfirm={() => history.goBack()}
          >
            {successAlert.message}
          </SweetAlert>
        )}

        {activityInfoLoading ? (
          <div className="d-flex justify-content-center">
            <PageDataLoader />
          </div>
        ) : (
          <Container fluid>
            <Breadcrumbs
              title="Add/Remove Activities"
              breadcrumbItems={breadcrumbItems}
            />
            <Card>
              <CardBody>
                <AvForm
                  className="needs-validation"
                  onValidSubmit={handleAssignActivity}
                >
                  {requestError ? (
                    <Alert
                      color="danger"
                      role="alert"
                      isOpen={requestError.show_alert}
                      toggle={resetErrorAlert}
                    >
                      <i className="mdi mdi-block-helper mr-2"></i>
                      {requestError.message}
                    </Alert>
                  ) : null}
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label>Date</Label>
                        <AvField
                          name="date"
                          placeholder="Date ..."
                          type="date"
                          min={batchStartDate && batchStartDate.split('T')[0]}
                          max={batchEndDate && batchEndDate.split('T')[0]}
                          className="form-control"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: 'Date is required'
                            }
                          }}
                          onChange={async (e) => {
                            handleDateChange(e.target.value);
                          }}
                          defaultValue={moment(new Date(date)).format(
                            'YYYY-MM-DD'
                          )}
                        />
                        <Button
                          type="button"
                          color="success"
                          onClick={fetchActivityDetailDate}
                        >
                          Get Activity for the date
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row className="mt-2 mb-2">
                    <Col md="5">
                      <CardTitle tag="h5">Activity Details</CardTitle>
                    </Col>
                    {/* <Col className="d-flex justify-content-end align-items-center">
                      <Button
                        size="sm"
                        color="success"
                        className=" waves-effect waves-light"
                        disabled={
                          (activityArray.length > 0 &&
                            !activityArray[activityArray.length - 1]
                              .activity) ||
                          (activityArray.length > 0 &&
                            !activityArray[activityArray.length - 1].start_time)
                        }
                        onClick={() => addActivityFields()}
                      >
                        <i className="ri-add-line align-middle mr-2"></i>
                        Activity
                      </Button>
                    </Col> */}
                  </Row>
                  {activityArray && activityArray.length === 0 && (
                    <div style={{ textAlign: 'center' }}>
                      <CardHeader>No Activity Assigned</CardHeader>
                    </div>
                  )}
                  {activityArray &&
                    activityArray.map((element, index) => (
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label>Activity</Label>
                            <AvField
                              name="activity"
                              placeholder="Enter Activity ..."
                              type="text"
                              className="form-control"
                              value={element.activity.label}
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label>Start Time</Label>
                            <AvField
                              name="start_time"
                              placeholder="Enter Start time ..."
                              type="time"
                              className="form-control"
                              value={element.start_time}
                              onChange={(e) =>
                                handleStartTimeSelection(index, e)
                              }
                            />
                          </FormGroup>
                        </Col>
                        {/* <Col md="2" className="d-flex mt-1 align-items-center">
                          <Button
                            size="sm"
                            color="danger"
                            disabled={index === 0 && type === 'add'}
                            className=" waves-effect waves-light"
                            onClick={() => removeActivityFields(index)}
                          >
                            <i className="ri-delete-bin-6-line align-middle"></i>
                          </Button>
                        </Col> */}
                      </Row>
                    ))}

                  <ModalFooter>
                    <Button
                      disabled={assignActivityRequestLoading}
                      type="button"
                      color="light"
                      onClick={handleActivityModalClose}
                    >
                      Close
                    </Button>
                    <Button type="submit" color="success">
                      {assignActivityRequestLoading
                        ? 'Assigning ...'
                        : 'Add/Remove Activity'}
                    </Button>
                  </ModalFooter>
                </AvForm>
                <hr />
                <Row className="mt-2 mb-2">
                  <Col md="5">
                    <CardTitle tag="h5">Select Activity</CardTitle>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md="2">
                    <FormGroup>
                      <Col md="12">
                        <Label className="col-form-label">Show Entries</Label>
                        <select
                          className="form-control"
                          onChange={(e) => {
                            fetchData(1, e.target.value);
                            setPagination({
                              ...pagination,
                              pageLimit: e.target.value
                            });
                          }}
                          value={pagination.pageLimit}
                          disabled={activityListLoading}
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </Col>
                    </FormGroup>
                  </Col>

                  <Col className="d-flex justify-content-end align-items-center">
                    <Button
                      size="sm"
                      color="primary"
                      className=" waves-effect waves-light mr-3"
                      onClick={openFilterModal}
                    >
                      <i className="ri-filter-fill align-middle mr-2"></i>
                      <span>Filter Activity</span>
                      {currentFilterCount > 0 && (
                        <Badge color="light" pill className="ml-2">
                          {currentFilterCount}
                        </Badge>
                      )}
                    </Button>
                  </Col>
                </Row>
                {activityListLoading ? (
                  <div className="text-center">
                    <PageDataLoader />
                  </div>
                ) : (
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table id="tech-companies-1" striped bordered responsive>
                        <thead>
                          <tr>
                            <th>Activity</th>
                            <th>Name</th>
                            <th>Summary</th>
                            <th>Details</th>
                            <th>Tags</th>
                          </tr>
                        </thead>
                        <tbody>
                          {activityList?.map((item, index) => (
                            <tr key={index}>
                              <th style={{ textAlign: 'center' }}>
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    defaultChecked={
                                      activityArray.findIndex(
                                        (a) => a.activity.value === item._id
                                      ) !== -1
                                    }
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setActivityArray([
                                          ...activityArray,
                                          {
                                            activity: {
                                              label: item.name,
                                              value: item._id
                                            },
                                            start_time: batchStartTime
                                          }
                                        ]);
                                      } else {
                                        let index = activityArray.findIndex(
                                          (a) => a.activity.value === item._id
                                        );
                                        let newActivityArray = [
                                          ...activityArray
                                        ];
                                        newActivityArray.splice(index, 1);
                                        setActivityArray(newActivityArray);
                                      }
                                    }}
                                  />
                                </FormGroup>
                              </th>
                              <td>{item.name}</td>
                              <td>{item.summary}</td>
                              <td>
                                {item.detail}
                                {/* {moment(new Date(item.createdAt)).format(
                                  'DD-MM-YYYY'
                                )} */}
                              </td>
                              <td>
                                {item.tags &&
                                  item.tags.map((t) => (
                                    <Badge
                                      color="primary"
                                      className="mr-2 mb-2"
                                      style={{
                                        padding: '8px 15px',
                                        fontSize: '12px'
                                      }}
                                      pill
                                    >
                                      {t.name}
                                    </Badge>
                                  ))}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                )}
                <Row className="mt-2">
                  <Col md="5">
                    <p>
                      <strong>
                        {' '}
                        Showing{' '}
                        {(pagination.currentPage - 1) * pagination.pageLimit +
                          1}{' '}
                        to{' '}
                        {Math.min(
                          pagination.currentPage * pagination.pageLimit,
                          pagination.totalEntries
                        )}{' '}
                        of {pagination.totalEntries} entries
                      </strong>
                    </p>
                  </Col>
                  {!activityListLoading && activityList.length > 0 && (
                    <Col md="7">
                      <Paginator
                        totalPages={pagination.totalPages}
                        currentPage={pagination.currentPage}
                        changePage={handlePagination}
                      />
                    </Col>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Container>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(AddActivity);
