/*jshint esversion: 11 */
import React, { useState } from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Alert
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { cloneBatch } from '../../api/batch';

const CloneBatchModal = ({ modalOpenState, closeModalHandler, batch }) => {
  const history = useHistory();
  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });
  const [batchCloneRequestLoading, setBatchCloneRequestLoading] =
    useState(false);
  const [successAlert, setSuccessAlert] = useState({
    message: '',
    show_alert: false
  });

  async function handleCloneBatch(event, values) {
    resetErrorAlert();
    setBatchCloneRequestLoading(true);
    const { response, error } = await cloneBatch(
      {
        start_date: values.start_date,
        end_date: values.end_date,
        start_time: values.start_time,
        end_time: values.end_time,
        batch_name: values.name,
        offset_days: values.offset_days,
        color: values.color
      },
      batch._id
    );
    setBatchCloneRequestLoading(false);
    if (error) {
      showErrorAlert(error);
      return;
    }
    closeModalHandler();
    showSuccessAlert(response.message);
  }

  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }

  function showSuccessAlert(message) {
    setSuccessAlert({ message: message, show_alert: true });
  }

  function resetSuccessAlert() {
    setSuccessAlert({ message: '', show_alert: false });
  }

  function resetState() {
    resetSuccessAlert();
    resetErrorAlert();
    history.push('/batches');
  }

  return (
    <React.Fragment>
      {successAlert.show_alert && (
        <SweetAlert success title="Created" onConfirm={resetState}>
          {successAlert.message}
        </SweetAlert>
      )}
      <Modal isOpen={modalOpenState} backdrop="static" size="lg">
        <ModalHeader toggle={closeModalHandler}>
          Clone Current Batch
        </ModalHeader>
        <ModalBody>
          {requestError ? (
            <Alert
              color="danger"
              role="alert"
              isOpen={requestError.show_alert}
              toggle={resetErrorAlert}
            >
              <i className="mdi mdi-block-helper mr-2"></i>
              {requestError.message}
            </Alert>
          ) : null}
          <AvForm className="needs-validation" onValidSubmit={handleCloneBatch}>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label>Batch Name</Label>
                  <AvField
                    name="name"
                    placeholder="Enter New Batch Name ..."
                    type="text"
                    className="form-control"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Batch name is required'
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Start Date</Label>
                  <AvField
                    name="start_date"
                    placeholder="Enter Start Date"
                    type="date"
                    className="form-control"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Start Date is required'
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>End Date</Label>
                  <AvField
                    name="end_date"
                    placeholder="Enter End Date"
                    type="date"
                    className="form-control"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'End Date is required'
                      }
                    }}
                  />
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup>
                  <Label>Start Time</Label>
                  <AvField
                    name="start_time"
                    placeholder="Enter Start Time"
                    type="time"
                    className="form-control"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Start Time is required'
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>End Time</Label>
                  <AvField
                    name="end_time"
                    placeholder="Enter End Time"
                    type="time"
                    className="form-control"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'End Time is required'
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Enter Days Offset</Label>
                  <AvField
                    name="offset_days"
                    placeholder="Enter offset days"
                    type="text"
                    className="form-control"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Offset Days are required'
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Color</Label>
                  <AvField
                    name="color"
                    placeholder="Enter Batch Color ..."
                    type="color"
                    className="form-control"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Batch Color is required'
                      }
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <ModalFooter>
              <Button
                disabled={batchCloneRequestLoading}
                type="button"
                color="light"
                onClick={closeModalHandler}
              >
                Close
              </Button>
              <Button type="submit" color="success">
                {batchCloneRequestLoading ? 'Creating ...' : 'Clone Batch'}
              </Button>
            </ModalFooter>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(CloneBatchModal);
