/*jshint esversion: 11 */
import React from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Alert,
  Input
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const BatchFilterModal = ({
  filterError,
  setFilterError,
  modalOpenState,
  closeModalHandler,
  fetchList,
  updateFilterCount,
  name,
  setName,
  dateFilter,
  setDateFilter,
  createQueryOptions,
  offeringOptions,
  selectedOfferingOption,
  setOfferingOption,
  schedule,
  setSchedule,
  time,
  setTime,
  authUser,
  isDeleted,
  setIsDeleted
}) => {
  function dateRangeHandler(setHook) {
    return function (dates) {
      const [start, end] = dates;
      setHook({
        startDate: start,
        endDate: end
      });
    };
  }

  function applyFilter() {
    const queryOptions = createQueryOptions();
    if (queryOptions) {
      updateFilterCount(queryOptions);
      fetchList(1, null, queryOptions);
      closeModalHandler();
    }
  }

  function clearFilter() {
    setDateFilter({
      startDate: null,
      endDate: null
    });
    setOfferingOption(null);
    setName('');
    setSchedule(null);
    setTime({
      start: null,
      end: null
    });
    setIsDeleted(false);
    const queryOptions = createQueryOptions(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    if (queryOptions) {
      updateFilterCount(queryOptions);
      fetchList(1, null, queryOptions);
      closeModalHandler();
    }
  }

  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100
    })
  };

  return (
    <React.Fragment>
      <Modal isOpen={modalOpenState} backdrop="static" size="md">
        <ModalHeader toggle={closeModalHandler}>Filter Batch</ModalHeader>
        <ModalBody>
          {filterError ? (
            <Alert
              color="danger"
              role="alert"
              isOpen={filterError ? true : false}
              toggle={() => setFilterError(false)}
            >
              <i className="mdi mdi-block-helper mr-2"></i>
              {filterError}
            </Alert>
          ) : null}
          <AvForm className="needs-validation" onValidSubmit={applyFilter}>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label>Batch Name</Label>
                  <AvField
                    name="batch_name"
                    placeholder="Enter Batch Name ..."
                    type="text"
                    className="form-control"
                    onChange={(e) => setName(e.target?.value)}
                    value={name}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label>Offering</Label>
                  <Select
                    options={offeringOptions}
                    onChange={(e) => setOfferingOption(e)}
                    value={selectedOfferingOption}
                    components={animatedComponents}
                    styles={selectStyles}
                    isMulti
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>Schedule</Label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                      setSchedule(e.target?.value);
                    }}
                    value={schedule}
                  >
                    <option value="">All</option>
                    <option value="Daily">Daily</option>
                    <option value="Weekday">Weekday</option>
                    <option value="Weekend">Weekend</option>
                    <option value="Random">Random</option>
                  </select>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>Start Time</Label>
                  <AvField
                    name="start_time"
                    placeholder="Start Time ..."
                    className="form-control"
                    type="time"
                    onChange={(e) =>
                      setTime((pv) => {
                        return {
                          ...pv,
                          start: e.target?.value
                        };
                      })
                    }
                    value={time.start}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>End Time</Label>
                  <AvField
                    name="end_time"
                    placeholder="End Time ..."
                    type="time"
                    className="form-control"
                    onChange={(e) =>
                      setTime((pv) => {
                        return {
                          ...pv,
                          end: e.target?.value
                        };
                      })
                    }
                    value={time.end}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label>Batch Date</Label>
                  <DatePicker
                    selected={dateFilter.startDate}
                    selectsStart
                    className="form-control"
                    startDate={dateFilter.startDate}
                    endDate={dateFilter.endDate}
                    selectsRange={true}
                    dateFormat="dd-MM-yyyy"
                    onChange={dateRangeHandler(setDateFilter)}
                    isClearable={true}
                  />
                </FormGroup>
              </Col>
              {authUser && authUser.user_type === 'super_admin' && (
                <Col md="12">
                  <FormGroup>
                    <Label>Is Deleted</Label>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr"
                    >
                      <Input
                        id="customSwitch"
                        name="isComplted"
                        type="checkbox"
                        className="custom-control-input p-3"
                        defaultChecked={isDeleted}
                      />
                      <Label
                        htmlFor="customSwitch"
                        className="custom-control-label"
                        onClick={() => setIsDeleted(!isDeleted)}
                      >
                        Deleted Batches
                      </Label>
                    </div>
                  </FormGroup>
                </Col>
              )}
            </Row>
            <Row className="d-flex px-3" style={{ height: '100%' }}></Row>
            <ModalFooter>
              <Button size="sm" type="submit" color="primary">
                Apply Filter
              </Button>
              <Button
                size="sm"
                type="button"
                color="success"
                onClick={() => clearFilter()}
              >
                Reset
              </Button>
              <Button
                size="sm"
                type="button"
                color="light"
                onClick={closeModalHandler}
              >
                Close
              </Button>
            </ModalFooter>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(BatchFilterModal);
