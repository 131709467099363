/*jshint esversion: 11 */
import sendApiRequest from '../helpers/axios-setup';

/**
 *
 * @returns {Promise<Object>}
 */
export async function addBatchImage(data) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.post('/batch_image', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @returns
 */
export async function getBatchImage(batch, date) {
  try {
    const response = await sendApiRequest.get(`/batch_image`, {
      params: {
        batch,
        date
      }
    });
    return response.data?.data;
  } catch (e) {
    return null;
  }
}

/**
 *
 * @returns
 */
export async function removeBatchImage(batch, date, imageId) {
  try {
    const response = await sendApiRequest.delete(`/batch_image`, {
      params: {
        batch,
        date,
        imageId
      }
    });
    return response;
  } catch (e) {
    return null;
  }
}
