/*jshint esversion: 11 */
import sendApiRequest from '../helpers/axios-setup';

/**
 *
 * @returns {Promise}
 */
export async function getNoticeList(
  page,
  limit,
  {
    filter_start_date = '',
    filter_end_date = '',
    batch = '',
    include_deleted = ''
  } = {},
  sorting_option = ''
) {
  try {
    const response = await sendApiRequest.get('/notice', {
      params: {
        page: page,
        limit: limit,
        filter_start_date,
        filter_end_date,
        batch,
        include_deleted,
        sorting_option
      }
    });
    return response.data?.data[0];
  } catch (e) {
    return null;
  }
}

/**
 *
 * @param {string} batch
 * @param {string} subject
 * @param {string} description
 * @param {date} date
 * @returns {Promise<Object>}
 */
export async function addNewNotice(data) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.post('/notice', data);
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @param {String} noticeId
 * @returns
 */
export async function getNoticeInfo(noticeId) {
  try {
    const response = await sendApiRequest.get(`/notice/${noticeId}`);
    return response.data?.data;
  } catch (e) {
    return null;
  }
}

/**
 * @returns {Promise<Object>}
 */
export async function updateNotice(noticeId, data) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.put(`/notice/${noticeId}`, data);
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @param {String} noticeId
 * @returns
 */
export async function deleteRestoreNotice(noticeId, type) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.delete(`/notice/${noticeId}`, {
      params: {
        type: type
      }
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}
