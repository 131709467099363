/*jshint esversion: 11 */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  ModalFooter,
  Alert,
  Input,
  Container,
  CardBody,
  Card,
  InputGroup, InputGroupAddon, InputGroupText
} from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getBatchExecutionChildrenList } from '../../api/batch';
import PageDataLoader from '../Utility/PageDataLoader';
import { addNewBatchExecution } from '../../api/execution';
import FormUpload from '../../components/FormUpload';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { isValidYouTubeLink } from '../../helpers/expression-checker';

const AddBatchExecution = (props) => {
  const history = useHistory();
  const [date, setDate] = useState(
    new Date() >= new Date(props.location.state.batchStartDate) &&
      new Date() <= new Date(props.location.state.batchEndDate)
      ? new Date()
      : new Date(props.location.state.batchStartDate)
  );
  const [images, setImages] = useState([]);
  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });
  const [addExecutionRequestLoading, setAddExecutionRequestLoading] =
    useState(false);
  const [successAlert, setSuccessAlert] = useState({
    message: '',
    show_alert: false
  });




  const [childOptions, setChildOptions] = useState([]);
  const [childOptionsLoading, setChildOptionsLoading] = useState(false);

  const [executionArray, setExecutionArray] = useState([
    {
      batch: props.match.params.batchId,
      child: '',
      note: '',
      isPresent: null,
      images: [],
      links: []
    }
  ]);

  const [youtubeLink, setYoutubeLink] = useState('');
  const [ytLinks, setYTLinks] = useState('');

  const handleInputChange = (e) => {
    setYoutubeLink(e.target.value);
  };


  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }

  const handleButtonClick = (index, link) => {
    resetErrorAlert();
    if (!isValidYouTubeLink(link)) {
      setRequestError({ show_alert: true, message: "Enter valid youtube link!" })
      return;
    }

    const trimmedLink = link.trim();
    if (trimmedLink !== '') {
      setExecutionArray(prevExecutionArray => {
        const newExecutionArray = [...prevExecutionArray];
        newExecutionArray[index]['links'] = [...newExecutionArray[index]['links'], link]
        return newExecutionArray;
      });
      setYoutubeLink('')
    }
  };


  const handleRemoveLink = (index, linkToRemove) => {
    setExecutionArray(prevExecutionArray => {
      const newExecutionArray = [...prevExecutionArray];

      // Filter out the link that matches the linkToRemove
      newExecutionArray[index]['links'] = newExecutionArray[index]['links'].filter(link => link !== linkToRemove);

      return newExecutionArray;
    });
  };



  const [breadcrumbItems] = useState([
    { title: 'Batch', link: '/batches' },
    { title: 'Add Batch Execution', link: '#' }
  ]);

  async function handleAddExecution(event, values) {

    resetErrorAlert();

    if (images && images.length > 0) {
      let newExecutionArray = [...executionArray];
      newExecutionArray[newExecutionArray.length - 1].images = images;
      setExecutionArray(newExecutionArray);
    }

    let nonMarked = executionArray.filter((k) => k.isPresent === null)
    if (nonMarked.length > 0) {
      showErrorAlert("Please mark attendance for child " + nonMarked[0].child.label)
      return
    }

    let success = true

    executionArray &&
      executionArray.map(async (e, i) => {
        let form = new FormData();
        form.set('batch', e.batch);
        form.set('date', date);
        form.set('child', e.child.value);
        form.set('note', e.note);
        form.set('isPresent', e.isPresent);
        form.set('links', e.links)
        if (e.images.length > 0)
          e.images.map((g, index) => form.append(`images`, g));
        let response = await addNewBatchExecution(form);
        if (response.error) {
          setRequestError({ message: 'Error in adding execution', show_alert: true });
          success = false
          return
        }

      });

    if (success) {
      setAddExecutionRequestLoading(false);
      setExecutionArray([
        {
          batch: props.match.params.batchId,
          child: '',
          note: '',
          isPresent: null,
          images: [],
          links: []
        }
      ]);
      showSuccessAlert('Execution added successfully');

    }
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }

  function showSuccessAlert(message) {
    setSuccessAlert({ message: message, show_alert: true });
  }
  let listElem = document.getElementById('batchList');
  let linkElem = document.getElementById('batchLink');

  useEffect(() => {
    if (listElem) listElem.classList.add('mm-active');
    if (linkElem) linkElem.classList.add('active');

    // eslint-disable-next-line
  }, [props.authUser, listElem, linkElem]);

  const fetchChildOptions = async (
    selectedDate,
    current_page = 1,
    page_limit = 100
  ) => {
    setChildOptionsLoading(true);
    const response = await getBatchExecutionChildrenList(
      props.match.params.batchId,
      selectedDate,
      current_page,
      page_limit
    );
    if (response) {
      setChildOptions(buildOptions(response?.data || []));
      setChildOptionsLoading(false);
    } else {
      setChildOptionsLoading(false);

      return;
    }
  };

  function buildOptions(list) {
    return list.map((item) => ({
      value: item._id,
      label: item.name
    }));
  }

  function handleChildSelection(index, selectedItem) {
    let newChildOptions = childOptions.filter((c) => c !== selectedItem);
    setChildOptions(newChildOptions);
    let newExecutionArray = [...executionArray];
    newExecutionArray[index]['child'] = selectedItem;
    setExecutionArray(newExecutionArray);
  }

  function handleNote(index, e) {
    resetErrorAlert();
    let value = e.target.value;
    let newExecutionArray = [...executionArray];
    newExecutionArray[index]['note'] = value;
    setExecutionArray(newExecutionArray);
  }

  function handleIsPresent(index, value) {
    resetErrorAlert();
    let newExecutionArray = [...executionArray];
    newExecutionArray[index]['isPresent'] =
      value;
    setExecutionArray(newExecutionArray);
  }

  const addExecutionFields = () => {
    resetErrorAlert();
    let newExecutionArray = [...executionArray];
    if (images && images.length > 0) {
      newExecutionArray[newExecutionArray.length - 1].images = images;
    }
    setImages([]);
    newExecutionArray.push({
      batch: props.match.params.batchId,
      date,
      child: '',
      note: '',
      isPresent: null,
      images: [],
      links: []
    });
    setExecutionArray(newExecutionArray);
  };

  let removeExecutionFields = (i) => {
    let newExecutionArray = [...executionArray];
    if (newExecutionArray[i]['child']) {
      let newChildOptions = childOptions;
      newChildOptions.push(newExecutionArray[i]['child']);
      setChildOptions(newChildOptions);
    }
    newExecutionArray.splice(i, 1);

    setExecutionArray(newExecutionArray);
  };

  useEffect(() => {
    if (date) fetchChildOptions(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        {successAlert.show_alert && (
          <SweetAlert
            success
            title="Created"
            onConfirm={() => history.goBack()}
          >
            {successAlert.message}
          </SweetAlert>
        )}
        {requestError ? (
          <Alert
            color="danger"
            role="alert"
            isOpen={requestError.show_alert}
            toggle={resetErrorAlert}
          >
            <i className="mdi mdi-block-helper mr-2"></i>
            {requestError.message}
          </Alert>
        ) : null}
        {childOptionsLoading ? (
          <div className="d-flex justify-content-center">
            <PageDataLoader />
          </div>
        ) : (
          <Container fluid>
            <Breadcrumbs
              title="Add Batch Execution"
              breadcrumbItems={breadcrumbItems}
            />
            <Card>
              <CardBody>
                <AvForm
                  className="needs-validation"
                  onValidSubmit={handleAddExecution}
                >
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label>Date</Label>
                        <AvField
                          name="date"
                          placeholder="Date ..."
                          type="date"
                          min={
                            props.location.state.batchStartDate &&
                            props.location.state.batchStartDate.split('T')[0]
                          }
                          max={
                            props.location.state.batchEndDate &&
                            props.location.state.batchEndDate.split('T')[0]
                          }
                          className="form-control"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: 'Date is required'
                            }
                          }}
                          onChange={async (e) => {
                            setDate(e.target.value);
                            await fetchChildOptions(e.target.value);
                          }}
                          defaultValue={moment(new Date(date)).format(
                            'YYYY-MM-DD'
                          )}
                          helpMessage="Date should lie between batch start and end date"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  {date &&
                    executionArray.map((element, index) => (
                      <>
                        <Row>
                          <Col md="5">
                            <Row>
                              <Col md="12">
                                <FormGroup>
                                  <Label>Child</Label>
                                  <Select
                                    options={childOptions}
                                    disabled={childOptionsLoading}
                                    onChange={(e) =>
                                      handleChildSelection(index, e)
                                    }
                                    value={element.child}
                                  />
                                </FormGroup>
                              </Col>

                              <Col md="12">
                                <FormGroup>
                                  <Label>Note</Label>
                                  <AvField
                                    name="note"
                                    placeholder="Enter Note ..."
                                    type="textarea"
                                    rows="10"
                                    className="form-control"
                                    onChange={(e) => handleNote(index, e)}
                                    value={element.note}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="4">
                                <FormGroup>
                                  <Label>Attendance</Label>
                                  <Select
                                    options={[{ "value": false, label: "Absent" }, { "value": true, label: "Present" }]}
                                    onChange={(e) => {
                                      handleIsPresent(index, e.value)
                                    }}
                                    defaultValue={{ "value": true, label: "Present" }}
                                  />

                                  {/* <div
                                    className="custom-control custom-switch mb-2"
                                    dir="ltr"
                                  >
                                    <Input
                                      id={'customSwitch' + index}
                                      type="checkbox"
                                      className="custom-control-input p-3"
                                    />
                                    <Label
                                      htmlFor={'customSwitch' + index}
                                      className="custom-control-label"
                                      onClick={(e) => handleIsPresent(index, e)}
                                    >
                                      Attendance Status
                                    </Label>
                                  </div> */}
                                </FormGroup>
                              </Col>
                            </Row>
                          </Col>
                          <Col md="6">
                            <FormUpload
                              setImages={setImages}
                              title="Execution Images"
                              video={true}
                            />
                            <InputGroup style={{ marginTop: '5px' }}>
                              <InputGroupAddon addonType='append'>
                                <InputGroupText>
                                  <i className="fab fa-youtube"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                type="text"
                                placeholder="Enter YouTube link"
                                value={youtubeLink}
                                onChange={handleInputChange}
                              />
                              <InputGroupAddon addonType="append">
                                <Button color="primary" onClick={() => handleButtonClick(index, youtubeLink)}>
                                  +
                                </Button>
                              </InputGroupAddon>
                            </InputGroup>
                            {executionArray[index]['links']?.length > 0 && (
                              <div className="mt-3">
                                {executionArray[index]['links'].map(link => (
                                  <p key={link} className="link-item" style={{ display: 'flex', alignItems: 'center' }}>
                                    &#128279; {/* Unicode for link icon */}
                                    <a href={link} target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px' }}>{link}</a>
                                    <span
                                      onClick={() => handleRemoveLink(index, link)}
                                      style={{ cursor: 'pointer', color: 'red', marginLeft: '10px' }}
                                    >
                                      &#10060; {/* Unicode for cross icon */}
                                    </span>
                                  </p>
                                ))}
                              </div>
                            )}

                          </Col>
                          <Col
                            md="1"
                            className="d-flex mt-1  justify-content-end align-items-center"
                          >
                            <Button
                              size="sm"
                              color="danger"
                              disabled={index === 0}
                              className=" waves-effect waves-light"
                              onClick={() => removeExecutionFields(index)}
                            >
                              <i className="ri-delete-bin-6-line align-middle"></i>
                            </Button>
                          </Col>
                        </Row>
                        <hr />
                      </>
                    ))}
                  {date && (
                    <>
                      <Row className="mt-2 mb-2">
                        <Col className="d-flex justify-content-end align-items-center">
                          <Button
                            size="sm"
                            color="info"
                            className=" waves-effect waves-light"
                            disabled={
                              !executionArray[executionArray.length - 1]
                                .child ||
                              executionArray[executionArray.length - 1].note ===
                              ''
                            }
                            onClick={() => addExecutionFields()}
                          >
                            <i className="ri-add-line align-middle mr-2"></i>
                            Add
                          </Button>
                        </Col>
                      </Row>
                      <ModalFooter>
                        <Button
                          disabled={addExecutionRequestLoading}
                          type="button"
                          color="light"
                          onClick={() => history.goBack()}
                        >
                          Close
                        </Button>
                        <Button
                          type="submit"
                          color="success"
                          disabled={
                            !executionArray[executionArray.length - 1].child ||
                            executionArray[executionArray.length - 1].note ===
                            ''
                          }
                        >
                          {addExecutionRequestLoading
                            ? 'Adding ...'
                            : 'Add Execution'}
                        </Button>
                      </ModalFooter>
                    </>
                  )}
                </AvForm>
              </CardBody>
            </Card>
          </Container>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(AddBatchExecution);
