/*jshint esversion: 11 */
import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Table,
  Label,
  FormGroup,
  Button,
  Badge
} from 'reactstrap';
import { connect } from 'react-redux';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import PageDataLoader from '../Utility/PageDataLoader';
import Paginator from '../Utility/Paginator';
import { getBookInfo, getBookList } from '../../api/book';
import CreateBookModal from './CreateBookModal';
import ViewBookModal from './ViewBookModal';
import UpdateBookModal from './UpdateBookModal';
import { validatePermissionKey } from '../../helpers/permission';
import {
  CREATE_BOOK,
  DELETE_BOOK,
  UPDATE_BOOK
} from '../Utility/PermissionKeys';
import { stringify as QStringify, parse as QParse } from 'qs';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { getTagList } from '../../api/tag';
import BookFilterModal from './BookFilterModal';
import DeleteBookModal from './DeleteBookModal';

const BookListPage = ({ authUser }) => {
  const history = useHistory();

  const [filterError, setFilterError] = useState(false);
  const [breadcrumbItems] = useState([
    { title: 'Books', link: '#' },
    { title: 'View List', link: '#' }
  ]);
  const [bookListLoading, setBookListLoading] = useState(true);
  const [bookList, setBookList] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageLimit: 10,
    totalEntries: 0,
    totalPages: 0
  });
  const [addBookModalOpen, setAddBookModalOpen] = useState(false);
  const [bookInfo, setBookInfo] = useState({});
  const [bookInfoLoading, setBookInfoLoading] = useState(false);
  const [bookInfoModalOpen, setBookInfoModalOpen] = useState(false);
  const [updateBookModalOpen, setUpdateBookModalOpen] = useState(false);
  const [sortingRule, setSortingRule] = useState('name$asc');
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [currentFilterCount, setCurrentFilterCount] = useState(0);
  const [filterBookName, setFilterBookName] = useState('');
  const [filterAvailabilityStatus, setFilterAvailabilityStatus] = useState('');
  const [filterAge, setFilterAge] = useState({
    upper: '',
    lower: ''
  });
  const [tagQueryId, setTagQueryId] = useState([]);
  const [filterTagList, setFilterTagList] = useState([]);
  const [selectedFilterTagOption, setSelectedFilterTagOption] = useState([]);
  const [deleteBookModalOpen, setDeleteBookModalOpen] = useState(false);
  const [bookId, setBookId] = useState('');
  const [isDeleted, setIsDeleted] = useState(false);

  const filterTagOptions = useMemo(
    () => buildTagOptions(filterTagList),
    // eslint-disable-next-line
    [filterTagList]
  );

  let listElem = document.getElementById('bookList');
  let linkElem = document.getElementById('bookLink');

  useEffect(() => {
    if (listElem) listElem.classList.add('mm-active');
    if (linkElem) linkElem.classList.add('active');
    if (authUser) {
      fetchTagOptions();
      populateSearchOptions(history?.location?.search);
    }
    // eslint-disable-next-line
  }, [authUser, listElem, linkElem]);

  const handlePagination = (page) => {
    fetchData(page, pagination.pageLimit, createQueryOptions(), sortingRule);
  };

  const fetchData = async (
    current_page = 1,
    page_limit = 10,
    queryOptions = {},
    sortingOption = sortingRule
  ) => {
    setBookListLoading(true);
    const response = await getBookList(
      current_page,
      page_limit,
      queryOptions,
      sortingOption
    );

    if (response) {
      setPagination({
        currentPage: response.metadata.page,
        pageLimit: response.metadata.limit,
        totalPages: response.metadata.total_pages,
        totalEntries: response.metadata.total_results
      });
      setBookList(response.data);
      setBookListLoading(false);
    }
  };

  async function fetchTagOptions() {
    const response = await getTagList(1, 100, {});
    if (response) {
      setFilterTagList(response?.data);
    }
  }

  /**
   *
   * @param {array<object>} tagArray
   * @returns
   */
  function buildTagOptions(tagArray) {
    const tagOptions = tagArray.map((i) => {
      return {
        label: i.name,
        value: i
      };
    });
    const selectedTagOption = tagOptions.filter((i) =>
      tagQueryId.includes(i.value._id)
    );
    setSelectedFilterTagOption(selectedTagOption);
    return tagOptions;
  }

  function createQueryOptions(
    name = filterBookName,
    tags = selectedFilterTagOption,
    startAge = filterAge.lower,
    endAge = filterAge.upper,
    isAvailable = filterAvailabilityStatus,
    deletedStatus = isDeleted
  ) {
    setFilterError(false);
    if (
      (startAge && !endAge) ||
      (!startAge && endAge) ||
      parseInt(startAge) > parseInt(endAge)
    ) {
      setFilterError('Enter valid age range');
      return;
    }
    const selectedTag = Array.isArray(tags)
      ? tags.map((i) => i.value._id).join(',')
      : '';
    const queryOptions = {
      filter_name: name,
      filter_tag_id: selectedTag,
      filter_start_age: startAge,
      filter_end_age: endAge,
      filter_is_available: isAvailable,
      include_deleted: deletedStatus,
      sorting_option: sortingRule
    };
    updateUrlPath(queryOptions);
    return queryOptions;
  }

  async function fetchSortedList(sortingOption) {
    setSortingRule(sortingOption);
    const queryOptions = createQueryOptions();
    queryOptions.sorting_option = sortingOption;
    updateUrlPath(queryOptions);
    fetchData(1, null, queryOptions, sortingOption);
  }

  /**
   *
   * @param {string} searchQuery
   */
  function populateSearchOptions(searchQuery) {
    const parsedQuery = QParse(searchQuery.substring(1));
    if (parsedQuery.filter_tag_id) {
      const tagId = parsedQuery.filter_tag_id.split(',').map((i) => i.trim());
      setTagQueryId(tagId);
    }
    setFilterBookName(parsedQuery.filter_name);
    setFilterAvailabilityStatus(parsedQuery.filter_is_available || '');
    setFilterAge({
      lower: parsedQuery.filter_start_age,
      upper: parsedQuery.filter_end_age
    });
    const sortingOption = parsedQuery.sorting_option || sortingRule;
    setSortingRule(sortingOption);
    updateFilterCount(parsedQuery);
    fetchData(1, null, parsedQuery, sortingOption);
  }

  function updateUrlPath(queryOptions) {
    const queryString = QStringify(queryOptions);
    history.replace(`/books?${queryString}`);
  }

  function updateFilterCount(option) {
    let count = 0;
    Object.keys(option).forEach((i) => {
      if (option[i]?.toString()?.trim()?.length > 0 && option[i] !== false) {
        count += 1;
      }
    });
    setCurrentFilterCount(count - 1);
  }

  function openFilterModal() {
    setFilterModalOpen(true);
  }

  function closeFilterModal() {
    setFilterModalOpen(false);
  }

  function openAddBookModal() {
    setAddBookModalOpen(true);
  }

  function closeAddBookModal() {
    setAddBookModalOpen(false);
  }

  function openBookInfoModal(roleId) {
    fetchBookInfo(roleId);
    setBookInfoModalOpen(true);
  }

  function closeBookInfoModal() {
    setBookInfoModalOpen(false);
  }

  async function openUpdateBookModal(bookId) {
    fetchBookInfo(bookId);
    setUpdateBookModalOpen(true);
  }

  function closeUpdateBookModal() {
    setUpdateBookModalOpen(false);
  }

  async function openDeleteBookModal(bookId, requestType) {
    setBookId({
      bookId,
      requestType
    });
    setDeleteBookModalOpen(true);
  }

  function closeDeleteBookModal() {
    setDeleteBookModalOpen(false);
  }

  async function fetchBookInfo(bookId) {
    setBookInfoLoading(true);
    const response = await getBookInfo(bookId);
    if (response) {
      setBookInfo(response);
      setBookInfoLoading(false);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <BookFilterModal
          filterError={filterError}
          setFilterError={setFilterError}
          modalOpenState={filterModalOpen}
          closeModalHandler={closeFilterModal}
          fetchList={fetchData}
          updateFilterCount={updateFilterCount}
          updateUrlPath={updateUrlPath}
          bookName={filterBookName}
          setBookName={setFilterBookName}
          tagOptions={filterTagOptions}
          selectedTagOption={selectedFilterTagOption}
          setSelectedTagOption={setSelectedFilterTagOption}
          createQueryOptions={createQueryOptions}
          bookAge={filterAge}
          setBookAge={setFilterAge}
          availableStatus={filterAvailabilityStatus}
          setAvailableStatus={setFilterAvailabilityStatus}
          authUser={authUser}
          isDeleted={isDeleted}
          setIsDeleted={setIsDeleted}
        />
        <CreateBookModal
          modalOpenState={addBookModalOpen}
          closeModalHandler={closeAddBookModal}
          fetchNewBookList={async () => {
            let options = await createQueryOptions();
            if (options) {
              await fetchData(1, null, options);
            }
          }}
          authUser={authUser}
        />
        <ViewBookModal
          modalOpenState={bookInfoModalOpen}
          closeModalHandler={closeBookInfoModal}
          bookInfo={bookInfo}
          bookInfoLoading={bookInfoLoading}
        />
        <UpdateBookModal
          modalOpenState={updateBookModalOpen}
          closeModalHandler={closeUpdateBookModal}
          bookInfo={bookInfo}
          bookInfoLoading={bookInfoLoading}
          fetchNewBookList={async () => {
            let options = await createQueryOptions();
            if (options) {
              await fetchData(1, null, options);
            }
          }}
        />
        {bookId && (
          <DeleteBookModal
            modalOpenState={deleteBookModalOpen}
            closeModalHandler={closeDeleteBookModal}
            bookId={bookId.bookId}
            type={bookId.requestType}
            fetchNewData={async () => {
              let options = await createQueryOptions();
              if (options) {
                await fetchData(1, null, options);
              }
            }}
          />
        )}
        <Container fluid>
          <Breadcrumbs title="View Books" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <>
                    <Row className="mt-2">
                      <Col md="2">
                        <FormGroup>
                          <Col md="12">
                            <Label className="col-form-label">
                              Show Entries
                            </Label>
                            <select
                              className="form-control"
                              onChange={(e) => {
                                fetchData(
                                  1,
                                  e.target.value,
                                  createQueryOptions(),
                                  sortingRule
                                );
                                setPagination({
                                  ...pagination,
                                  pageLimit: e.target.value
                                });
                              }}
                              value={pagination.pageLimit}
                              disabled={bookListLoading}
                            >
                              <option value={10}>10</option>
                              <option value={25}>25</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <Label className="col-form-label">Sort Books</Label>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              fetchSortedList(e.target.value);
                            }}
                            value={sortingRule}
                            disabled={bookListLoading}
                          >
                            <option value="name$asc">Book Name (A-Z)</option>
                            <option value="name$desc">Book Name (Z-A)</option>
                            <option value="age$asc">Age (MIN - MAX)</option>
                            <option value="age$desc">Age (MAX - MIN)</option>
                            <option value="updated_at$desc">
                              Update Time (Newest First)
                            </option>
                            <option value="updated_at$asc">
                              Update Time (Oldest First)
                            </option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col className="d-flex justify-content-end align-items-center">
                        <Button
                          size="sm"
                          color="primary"
                          className=" waves-effect waves-light mr-3"
                          onClick={openFilterModal}
                        >
                          <i className="ri-filter-fill align-middle mr-2"></i>
                          <span>Filter Books</span>
                          {currentFilterCount > 0 && (
                            <Badge color="light" pill className="ml-2">
                              {currentFilterCount}
                            </Badge>
                          )}
                        </Button>
                        {validatePermissionKey(authUser, CREATE_BOOK) && (
                          <Button
                            size="sm"
                            color="success"
                            className=" waves-effect waves-light"
                            onClick={openAddBookModal}
                          >
                            <i className="ri-add-line align-middle mr-2"></i>
                            Add Book
                          </Button>
                        )}
                      </Col>
                    </Row>
                    {bookListLoading ? (
                      <div className="text-center">
                        <PageDataLoader />
                      </div>
                    ) : (
                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <Table
                            id="tech-companies-1"
                            striped
                            bordered
                            responsive
                          >
                            <thead>
                              <tr>
                                <th>SL. No</th>
                                <th>Name</th>
                                <th>Age</th>
                                <th>Available</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {bookList?.map((item, index) => (
                                <tr key={index}>
                                  <th>{index + 1}</th>
                                  <td>{item.name}</td>
                                  <td>{item.age}</td>
                                  <td>{item.isAvailable ? 'YES' : 'NO'}</td>
                                  <td>
                                    <Button
                                      size="sm"
                                      color="primary"
                                      className=" waves-effect waves-light mr-2 mb-2"
                                      onClick={() => {
                                        openBookInfoModal(item._id);
                                      }}
                                    >
                                      <i className="fas fa-eye mr-2"></i>
                                      View
                                    </Button>
                                    {validatePermissionKey(
                                      authUser,
                                      UPDATE_BOOK
                                    ) && (
                                      <Button
                                        size="sm"
                                        color="warning"
                                        className=" waves-effect waves-light mr-2 mb-2"
                                        onClick={() => {
                                          openUpdateBookModal(item._id);
                                        }}
                                      >
                                        <i className="fas fa-edit mr-2"></i>
                                        Edit
                                      </Button>
                                    )}
                                    {validatePermissionKey(
                                      authUser,
                                      DELETE_BOOK
                                    ) && (
                                      <Button
                                        size="sm"
                                        color="danger"
                                        className=" waves-effect waves-light mb-2"
                                        onClick={() => {
                                          openDeleteBookModal(
                                            item._id,
                                            item.isDeleted
                                              ? 'restore'
                                              : 'delete'
                                          );
                                        }}
                                      >
                                        {item.isDeleted ? (
                                          <i className="fas fa-trash-restore mr-2"></i>
                                        ) : (
                                          <i className="fas fa-trash mr-2"></i>
                                        )}
                                        {item.isDeleted ? 'Restore' : 'Delete'}
                                      </Button>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    )}
                    <Row className="mt-2">
                      <Col md="5">
                        <p>
                          <strong>
                            {' '}
                            Showing{' '}
                            {(pagination.currentPage - 1) *
                              pagination.pageLimit +
                              1}{' '}
                            to{' '}
                            {Math.min(
                              pagination.currentPage * pagination.pageLimit,
                              pagination.totalEntries
                            )}{' '}
                            of {pagination.totalEntries} entries
                          </strong>
                        </p>
                      </Col>
                      {!bookListLoading && bookList.length > 0 && (
                        <Col md="7">
                          <Paginator
                            totalPages={pagination.totalPages}
                            currentPage={pagination.currentPage}
                            changePage={handlePagination}
                          />
                        </Col>
                      )}
                    </Row>
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(BookListPage);
