import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Col,
  Alert,
  Row,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { updateQuestion } from '../../api/question';
import SweetAlert from 'react-bootstrap-sweetalert';
import PageDataLoader from '../Utility/PageDataLoader';

const UpdateQuestionModal = ({ modalOpenState, closeModalHandler, questionInfo, questionInfoLoading, fetchNewQuestionList }) => {
  const [title, setTitle] = useState('');
  const [optionList, setOptionList] = useState([{ option_name: '', is_correct: false }]);
  const [questionRequestLoading, setQuestionRequestLoading] = useState(false);
  const [successAlert, setSuccessAlert] = useState({
    message: '',
    show_alert: false
  });
  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });

  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }

  function showSuccessAlert(message) {
    setSuccessAlert({ message: message, show_alert: true });
  }

  function resetSuccessAlert() {
    setSuccessAlert({ message: '', show_alert: false });
  }

  function resetState() {
    resetSuccessAlert();
    resetErrorAlert();
  }

  useEffect(() => {
    if (questionInfo && !questionInfoLoading) {
      setTitle(questionInfo.title);
      setOptionList(questionInfo.option_list || [{ option_name: '', is_correct: false, weightage: 1 }]);
    }
  }, [questionInfo, questionInfoLoading]);

  const handleOptionChange = (index, field, value) => {
    const updatedOptions = [...optionList];
    if (field === "is_correct") {
      updatedOptions.forEach((item, i) => {
        item.is_correct = i === index ? true : false;
      });
    } else {
      updatedOptions[index][field] = value;
    }

    setOptionList(updatedOptions);
  };

  const addOption = () => {
    setOptionList([...optionList, { option_name: '', is_correct: false }]);
  };

  const removeOption = (index) => {
    const updatedOptions = optionList.filter((_, i) => i !== index);
    setOptionList(updatedOptions);
  };

  const handleSubmit = async () => {
    resetErrorAlert();
    let option_empty = optionList.filter((opt) => opt.option_name === "");
    if (option_empty.length > 0) {
      showErrorAlert('Option Name cannot be empty');
      return;
    }
    let correct = optionList.filter((opt) => opt.is_correct === true);
    if (correct.length === 0) {
      showErrorAlert('At least one option should be marked correct');
      return;
    }

    setQuestionRequestLoading(true);
    const { response, error } = await updateQuestion(questionInfo._id, title, optionList);
    setQuestionRequestLoading(false);
    if (error) {
      showErrorAlert(error.message || 'Error in updating question');
      setQuestionRequestLoading(false);
      return;
    }
    setOptionList([]);
    fetchNewQuestionList();
    closeModalHandler();
    showSuccessAlert(response.message);
  };

  return (
    <React.Fragment>
      {successAlert.show_alert && (
        <SweetAlert success title="Updated" onConfirm={resetState}>
          {successAlert.message}
        </SweetAlert>
      )}
      <Modal isOpen={modalOpenState} backdrop="static" size='xl'>
        <ModalHeader toggle={closeModalHandler}>Update Question</ModalHeader>
        {!title ? <div className="text-center"><PageDataLoader /></div> : <ModalBody>
          <React.Fragment>
            {requestError ? (
              <Alert
                color="danger"
                role="alert"
                isOpen={requestError.show_alert}
                toggle={resetErrorAlert}
              >
                <i className="mdi mdi-block-helper mr-2"></i>
                {requestError.message}
              </Alert>
            ) : null}
            <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
              <ModalBody>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label for="title">Title</Label>
                      <AvField
                        name="title"
                        id="title"
                        type="text"
                        value={title}
                        className="form-control"
                        onChange={(e) => setTitle(e.target.value)}
                        validate={{
                          required: { value: true, errorMessage: 'Please enter a title' },
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {optionList.map((option, index) => (
                  <Row key={index}>
                    <Col md="4" className='mt-2'>
                      <FormGroup>
                        <Label>Option</Label>
                        <AvField
                          name="option"
                          placeholder="Enter Option ..."
                          type="text"
                          className="form-control"
                          value={option.option_name}
                          onChange={(e) => handleOptionChange(index, "option_name", e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4" className='mt-2'>
                      <FormGroup>
                        <Label>Weightage</Label>
                        <AvField
                          name="option"
                          placeholder="Enter Weightage ..."
                          type="number"
                          min-={0}
                          className="form-control"
                          value={option.weightage ? option.weightage : 1}
                          onChange={(e) => handleOptionChange(index, "weightage", e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3" className='mt-5'>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="radio"
                            checked={option.is_correct}
                            onChange={(e) => handleOptionChange(index, 'is_correct', e.target.checked)}
                          />
                          Correct Answer
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col md="1">
                      <Button
                        size="sm"
                        color="danger"
                        disabled={index === 0}
                        className="waves-effect waves-light mt-5"
                        onClick={() => removeOption(index)}
                      >
                        <i className="ri-delete-bin-6-line align-middle"></i>
                      </Button>
                    </Col>
                  </Row>
                ))}
                <Row>
                  <Col className="d-flex justify-content align-items-center mt-2">
                    <Button
                      size="sm"
                      color="primary"
                      className="waves-effect waves-light"
                      onClick={addOption}
                    >
                      <i className="ri-add-line align-middle mr-2"></i>
                      Add
                    </Button>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button
                  disabled={questionRequestLoading}
                  type="button"
                  color="light"
                  onClick={closeModalHandler}
                >
                  Close
                </Button>
                <Button type="submit" color="success">
                  {questionRequestLoading ? 'Editing ...' : 'Edit Question'}
                </Button>
              </ModalFooter>
            </AvForm>
          </React.Fragment>
        </ModalBody>}
      </Modal>
    </React.Fragment>
  );
};

export default UpdateQuestionModal;
