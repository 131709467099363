/*jshint esversion: 11 */
import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Alert
} from 'reactstrap';
import Select from 'react-select';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { updateTodo } from '../../api/todo';
import PageDataLoader from '../Utility/PageDataLoader';
import moment from 'moment';
import { taskStatus } from '../Utility/Enumerator';
import { getUsersList } from '../../api/staff';

const UpdateTodoModal = ({
  modalOpenState,
  closeModalHandler,
  authUser,
  fetchNewTodoList,
  todoInfoLoading,
  todoInfo
}) => {
  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });
  const [todoUpdateRequestLoading, setTodoUpdateRequestLoading] =
    useState(false);
  const [successAlert, setSuccessAlert] = useState({
    message: '',
    show_alert: false
  });

  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [userOptions, setUserOptions] = useState([]);
  const [userOptionsLoading, setUserOptionsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  async function handleTodoUpdate(event, values) {
    resetErrorAlert();
    if (!selectedStatus) {
      showErrorAlert('Please select status of task');
      return;
    }
    if (!selectedStatus) {
      showErrorAlert('Please select status of task');
      return;
    }
    setTodoUpdateRequestLoading(true);
    const { response, error } = await updateTodo(
      todoInfo._id,
      values.title,
      values.detail,
      todoInfo.assigned_by._id,
      todoInfo.assigned_by_role,
      selectedUser.value,
      selectedUser.role,
      values.expected_date,
      selectedStatus.value
    );
    setTodoUpdateRequestLoading(false);
    if (error) {
      showErrorAlert(error);
      return;
    }
    closeModalHandler();
    showSuccessAlert(response.message);
    fetchNewTodoList();
  }

  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }

  function showSuccessAlert(message) {
    setSuccessAlert({ message: message, show_alert: true });
  }

  function resetSuccessAlert() {
    setSuccessAlert({ message: '', show_alert: false });
  }

  function resetState() {
    resetSuccessAlert();
    resetErrorAlert();
  }

  const handleStatusSelection = (selectedItem) => {
    setSelectedStatus(selectedItem);
  };

  const handleUserSelection = (selectedItem) => {
    setSelectedUser(selectedItem);
  };

  useEffect(() => {
    resetState();
    if (authUser) {
      setStatusOptions(buildListOptions(taskStatus));
      fetchUserOptions();
    }
    // eslint-disable-next-line
  }, [authUser, modalOpenState]);

  useEffect(() => {
    if (todoInfo && statusOptions.length > 0 && userOptions.length > 0) {
      setSelectedStatus(
        statusOptions.find((k) => k.value === todoInfo?.status)
      );
      setSelectedUser(
        userOptions.find((k) => k.value === todoInfo.assigned_to?._id)
      );
    }
    // eslint-disable-next-line
  }, [authUser, todoInfo, statusOptions]);

  function buildListOptions(list) {
    return list.map((item) => ({
      value: item,
      label: item
    }));
  }
  const fetchUserOptions = async () => {
    setUserOptionsLoading(true);
    const response = await getUsersList();
    if (response) {
      setUserOptions(buildOptions(response || []));
      setUserOptionsLoading(false);
    }
  };

  function buildOptions(list) {
    return list.map((item) => {
      let role_name = item.role ? item.role.role_name : 'SuperAdmin';
      let user_type = item.role ? 'Staff' : 'super_admin';
      return {
        value: item._id,
        label: item.name + ' (' + role_name + ')',
        role: user_type
      };
    });
  }

  return (
    <React.Fragment>
      {successAlert.show_alert && (
        <SweetAlert success title="Updated" onConfirm={resetState}>
          {successAlert.message}
        </SweetAlert>
      )}
      <Modal isOpen={modalOpenState} backdrop="static" size="xl">
        <ModalHeader toggle={closeModalHandler}>
          {todoInfoLoading ? 'Loading ...' : 'Edit [' + todoInfo.title + ']'}
        </ModalHeader>
        <ModalBody>
          {todoInfoLoading ? (
            <div className="d-flex justify-content-center">
              <PageDataLoader />
            </div>
          ) : (
            <React.Fragment>
              {requestError ? (
                <Alert
                  color="danger"
                  role="alert"
                  isOpen={requestError.show_alert}
                  toggle={resetErrorAlert}
                >
                  <i className="mdi mdi-block-helper mr-2"></i>
                  {requestError.message}
                </Alert>
              ) : null}
              <AvForm
                className="needs-validation"
                onValidSubmit={handleTodoUpdate}
              >
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>Title</Label>
                      <AvField
                        name="title"
                        placeholder="Enter Title ..."
                        type="text"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Title is required'
                          }
                        }}
                        defaultValue={todoInfo?.title}
                        readOnly={
                          !(
                            todoInfo.assigned_by?._id === authUser?._id ||
                            authUser?.user_type === 'super_admin'
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Expected Date</Label>
                      <AvField
                        name="expected_date"
                        placeholder="Enter Expected Date ..."
                        type="date"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Expected date is required'
                          }
                        }}
                        defaultValue={
                          todoInfo &&
                          moment(new Date(todoInfo.expected_date)).format(
                            'YYYY-MM-DD'
                          )
                        }
                        readOnly={
                          !(
                            todoInfo.assigned_by?._id === authUser?._id ||
                            authUser?.user_type === 'super_admin'
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <Label>Detail</Label>
                      <AvField
                        name="detail"
                        placeholder="Enter Details"
                        type="textarea"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Details is required'
                          }
                        }}
                        defaultValue={todoInfo?.detail}
                        readOnly={
                          !(
                            todoInfo.assigned_by?._id === authUser?._id ||
                            authUser?.user_type === 'super_admin'
                          )
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <Label>Assign To</Label>
                      <Select
                        options={userOptions}
                        disabled={userOptionsLoading}
                        onChange={handleUserSelection}
                        value={selectedUser}
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <Label>Status</Label>
                      <Select
                        options={statusOptions}
                        onChange={handleStatusSelection}
                        value={selectedStatus}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <ModalFooter>
                  <Button
                    disabled={todoUpdateRequestLoading}
                    type="button"
                    color="light"
                    onClick={closeModalHandler}
                  >
                    Close
                  </Button>
                  <Button type="submit" color="success">
                    {todoUpdateRequestLoading ? 'Updating ...' : 'Update Todo'}
                  </Button>
                </ModalFooter>
              </AvForm>
            </React.Fragment>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(UpdateTodoModal);
