/*jshint esversion: 11 */
import React, { useState, useEffect, useMemo } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Table,
  Label,
  FormGroup,
  Button,
  Badge,
  Nav,
  NavItem,
  NavLink,
  ModalHeader,
  ModalBody,
  Modal,
  ModalFooter,
  Alert,
  Input
} from 'reactstrap';

import { connect } from 'react-redux';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import PageDataLoader from '../Utility/PageDataLoader';
import Paginator from '../Utility/Paginator';
import { getFeedbackInfo, getFeedbackList } from '../../api/feedback';
import moment from 'moment';
import CreateFeedbackModal from './CreateFeedbackModal';
import ViewFeedbackModal from './ViewFeedbackModal';
import UpdateFeedbackModal from './UpdateFeedbackModal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import validator from 'validator';
import { stringify as QStringify, parse as QParse } from 'qs';
import { useHistory } from 'react-router-dom';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { getChildList } from '../../api/child';
import { getActivityList } from '../../api/activity';
import {
  CREATE_FEEDBACK,
  DELETE_FEEDBACK,
  UPDATE_FEEDBACK
} from '../Utility/PermissionKeys';
import { validatePermissionKey } from '../../helpers/permission';
import DeleteFeedbackModal from './DeleteFeedbackModal';

const animatedComponents = makeAnimated();

const FeedbackListPage = ({ authUser }) => {
  const history = useHistory();

  const [breadcrumbItems] = useState([
    { title: 'Feedbacks', link: '#' },
    { title: 'View List', link: '#' }
  ]);
  const [activeTab, setActiveTab] = useState('Child');
  const [feedbackListLoading, setFeedbackListLoading] = useState(true);
  const [feedbackList, setFeedbackList] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageLimit: 10,
    totalEntries: 0,
    totalPages: 0
  });
  const [name, setName] = useState('');
  const [filterError, setFilterError] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [currentFilterCount, setCurrentFilterCount] = useState(0);
  const [addFeedbackModalOpen, setAddFeedbackModalOpen] = useState(false);
  const [feedbackInfo, setFeedbackInfo] = useState({});
  const [feedbackInfoLoading, setFeedbackInfoLoading] = useState(false);
  const [feedbackInfoModalOpen, setFeedbackInfoModalOpen] = useState(false);
  const [updateFeedbackModalOpen, setUpdateFeedbackModalOpen] = useState(false);
  const [expectedDateFilter, setExpectedDateFilter] = useState({
    startDate: null,
    endDate: null
  });
  const [referenceQueryId, setReferenceQueryId] = useState([]);
  const [referenceList, setReferenceList] = useState([]);
  const [selectedReferenceOption, setSelectedReferenceOption] = useState([]);
  const [deleteFeedbackModalOpen, setDeleteFeedbackModalOpen] = useState(false);
  const [feedbackId, setFeedbackId] = useState('');
  const [isDeleted, setIsDeleted] = useState(false);

  const referenceOptions = useMemo(
    () => buildReferenceOptions(referenceList),
    // eslint-disable-next-line
    [referenceList]
  );

  let listElem = document.getElementById('feedbackList');
  let linkElem = document.getElementById('feedbackLink');

  useEffect(() => {
    if (listElem) listElem.classList.add('mm-active');
    if (linkElem) linkElem.classList.add('active');
    if (authUser) {
      fetchData();
      populateSearchOptions(history?.location?.search);
      fetchReferenceList();
    }
    // eslint-disable-next-line
  }, [authUser, listElem, linkElem]);

  const handlePagination = (page) => {
    fetchData(page, pagination.pageLimit, activeTab, createQueryOptions());
  };

  const fetchData = async (
    current_page = 1,
    page_limit = 10,
    type = activeTab,
    queryOptions = {}
  ) => {
    setFeedbackListLoading(true);
    const response = await getFeedbackList(
      current_page,
      page_limit,
      type,
      queryOptions
    );

    if (response) {
      setPagination({
        currentPage: response.metadata.page,
        pageLimit: response.metadata.limit,
        totalPages: response.metadata.total_pages,
        totalEntries: response.metadata.total_results
      });
      setFeedbackList(response.data);
      setFeedbackListLoading(false);
    }
  };

  /**
   *
   * @param {string} searchQuery
   */
  function populateSearchOptions(searchQuery) {
    const parsedQuery = QParse(searchQuery.substring(1));
    if (parsedQuery.filter_start_date) {
      setExpectedDateFilter((pv) => {
        return {
          ...pv,
          startDate: new Date(parsedQuery.filter_start_date)
        };
      });
    }
    if (parsedQuery.filter_end_date) {
      setExpectedDateFilter((pv) => {
        return {
          ...pv,
          endDate: new Date(parsedQuery.filter_end_date)
        };
      });
    }

    if (parsedQuery.reference) {
      const referenceId = parsedQuery.reference.split(',').map((i) => i.trim());
      setReferenceQueryId(referenceId);
    }
    setName(parsedQuery.filter_name);
    fetchData(1, null, activeTab, parsedQuery);
    updateFilterCount(parsedQuery);
  }

  function createQueryOptions(
    startDate = expectedDateFilter.startDate,
    endDate = expectedDateFilter.endDate,
    reference = selectedReferenceOption,
    filterName = name,
    deletedStatus = isDeleted
  ) {
    setFilterError(false);
    if ((startDate && !endDate) || (!startDate && endDate)) {
      setFilterError('Select valid date range');
      return;
    }
    const startDateISOString = validator.isDate(startDate)
      ? new Date(startDate).toISOString()
      : '';
    const endDateISOString = validator.isDate(endDate)
      ? new Date(endDate).toISOString()
      : '';
    const referenceReference = Array.isArray(reference)
      ? reference.map((i) => i.value._id).join(',')
      : '';

    const queryOptions = {
      filter_name: filterName,
      filter_reference: referenceReference,
      filter_start_date: startDateISOString,
      filter_end_date: endDateISOString,
      include_deleted: deletedStatus
    };
    updateUrlPath(queryOptions);
    updateFilterCount(queryOptions);
    fetchData(1, null, activeTab, queryOptions);
    return queryOptions;
  }

  function updateUrlPath(queryOptions) {
    const queryString = QStringify(queryOptions);
    history.replace(`/feedbacks?${queryString}`);
  }

  async function fetchReferenceList(tab = activeTab) {
    let response = [];
    if (tab === 'Child') response = await getChildList(1, 500);
    else response = await getActivityList(1, 100);
    if (response) {
      setReferenceList(response.data);
    }
  }

  function openAddFeedbackModal() {
    setAddFeedbackModalOpen(true);
  }

  function closeAddFeedbackModal() {
    setAddFeedbackModalOpen(false);
  }

  function openFeedbackInfoModal(roleId) {
    fetchFeedbackInfo(roleId);
    setFeedbackInfoModalOpen(true);
  }

  function closeFeedbackInfoModal() {
    setFeedbackInfoModalOpen(false);
  }

  async function openUpdateFeedbackModal(feedbackId) {
    fetchFeedbackInfo(feedbackId);
    setUpdateFeedbackModalOpen(true);
  }

  function closeUpdateFeedbackModal() {
    setUpdateFeedbackModalOpen(false);
  }
  async function openDeleteFeedbackModal(feedbackId, requestType) {
    setFeedbackId({
      feedbackId,
      requestType
    });
    setDeleteFeedbackModalOpen(true);
  }

  function closeDeleteFeedbackModal() {
    setDeleteFeedbackModalOpen(false);
  }

  function resetFields() {
    setName('');
    setSelectedReferenceOption(null);
    setExpectedDateFilter({
      startDate: null,
      endDate: null
    });
    setIsDeleted(false);
  }

  async function fetchFeedbackInfo(feedbackId) {
    setFeedbackInfoLoading(true);
    const response = await getFeedbackInfo(feedbackId);
    if (response) {
      setFeedbackInfo(response);
      setFeedbackInfoLoading(false);
    }
  }

  const toggle = (tab) => {
    if (activeTab !== tab) {
      fetchReferenceList(tab);
      setActiveTab(tab);
    }
  };

  function openFilterModal() {
    setFilterModalOpen(true);
  }

  function closeFilterModal() {
    setFilterModalOpen(false);
  }

  function updateFilterCount(option) {
    let count = 0;
    Object.keys(option).forEach((i) => {
      if (option[i]?.toString()?.trim()?.length > 0 && option[i] !== false) {
        count += 1;
      }
    });
    setCurrentFilterCount(count);
  }

  /**
   *
   * @param {array<date>} dates
   */
  function dateRangeHandler(dates) {
    const [start, end] = dates;
    setExpectedDateFilter({
      startDate: start,
      endDate: end
    });
  }

  /**
   *
   * @param {array<object>} referenceArray
   * @returns
   */
  function buildReferenceOptions(referenceArray) {
    const referenceOptions = referenceArray.map((i) => {
      return {
        label: i.name,
        value: i
      };
    });
    const selectedReference = referenceOptions.filter((i) =>
      referenceQueryId.includes(i.value._id)
    );
    setSelectedReferenceOption(selectedReference);
    return referenceOptions;
  }

  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100
    })
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Modal isOpen={filterModalOpen} backdrop="static" size="md">
          <ModalHeader toggle={closeFilterModal}>Filter Feedback</ModalHeader>
          <AvForm
            className="needs-validation"
            onValidSubmit={async () => {
              let options = await createQueryOptions();
              if (options) {
                await fetchData(1, null, activeTab, options);
                closeFilterModal();
              }
            }}
          >
            <ModalBody>
              {filterError ? (
                <Alert
                  color="danger"
                  role="alert"
                  isOpen={filterError ? true : false}
                  toggle={() => setFilterError(false)}
                >
                  <i className="mdi mdi-block-helper mr-2"></i>
                  {filterError}
                </Alert>
              ) : null}
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label>Name</Label>
                    <AvField
                      name="name"
                      placeholder="Enter Name ..."
                      type="text"
                      className="form-control"
                      onChange={(e) => setName(e.target?.value)}
                      value={name}
                    />
                  </FormGroup>
                </Col>

                <Col md="12">
                  <FormGroup className="mt-3">
                    <Label>{activeTab}</Label>
                    <Select
                      options={referenceOptions}
                      onChange={(e) => setSelectedReferenceOption(e)}
                      value={selectedReferenceOption}
                      components={animatedComponents}
                      styles={selectStyles}
                      isMulti
                    />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup className="mb-0">
                    <Label>Created At</Label>
                    <div className="d-flex justify-content-center">
                      <DatePicker
                        selected={expectedDateFilter.startDate}
                        selectsStart
                        className="form-control"
                        startDate={expectedDateFilter.startDate}
                        endDate={expectedDateFilter.endDate}
                        selectsRange={true}
                        dateFormat="dd-MM-yyyy"
                        onChange={dateRangeHandler}
                        isClearable={true}
                      />
                    </div>
                  </FormGroup>
                </Col>
                {authUser && authUser.user_type === 'super_admin' && (
                  <Col md="12">
                    <FormGroup>
                      <Label>Is Deleted</Label>
                      <div
                        className="custom-control custom-switch mb-2"
                        dir="ltr"
                      >
                        <Input
                          id="customSwitch"
                          name="isComplted"
                          type="checkbox"
                          className="custom-control-input p-3"
                          defaultChecked={isDeleted}
                        />
                        <Label
                          htmlFor="customSwitch"
                          className="custom-control-label"
                          onClick={() => setIsDeleted(!isDeleted)}
                        >
                          Deleted Feedbacks
                        </Label>
                      </div>
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <Row className="d-flex px-3" style={{ height: '100%' }}></Row>
            </ModalBody>
            <ModalFooter>
              <Button size="sm" type="submit" color="primary">
                Apply Filter
              </Button>
              <Button
                size="sm"
                type="button"
                color="success"
                onClick={async () => {
                  await resetFields();
                  let options = await createQueryOptions(
                    null,
                    null,
                    null,
                    null,
                    null
                  );
                  if (options) {
                    await fetchData(1, null, activeTab, options);
                    closeFilterModal();
                  }
                }}
              >
                Reset
              </Button>
              <Button
                size="sm"
                type="button"
                color="light"
                onClick={closeFilterModal}
              >
                Close
              </Button>
            </ModalFooter>
          </AvForm>
        </Modal>
        <CreateFeedbackModal
          modalOpenState={addFeedbackModalOpen}
          closeModalHandler={closeAddFeedbackModal}
          fetchNewFeedbackList={async () => {
            let options = await createQueryOptions();
            if (options) {
              await fetchData(1, null, activeTab, options);
            }
          }}
          authUser={authUser}
          feedbackType={activeTab}
        />
        <ViewFeedbackModal
          modalOpenState={feedbackInfoModalOpen}
          closeModalHandler={closeFeedbackInfoModal}
          feedbackInfo={feedbackInfo}
          feedbackInfoLoading={feedbackInfoLoading}
          feedbackType={activeTab}
        />
        <UpdateFeedbackModal
          modalOpenState={updateFeedbackModalOpen}
          closeModalHandler={closeUpdateFeedbackModal}
          feedbackInfo={feedbackInfo}
          feedbackInfoLoading={feedbackInfoLoading}
          fetchNewFeedbackList={async () => {
            let options = await createQueryOptions();
            if (options) {
              await fetchData(1, null, activeTab, options);
            }
          }}
          feedbackType={activeTab}
        />
        {feedbackId && (
          <DeleteFeedbackModal
            modalOpenState={deleteFeedbackModalOpen}
            closeModalHandler={closeDeleteFeedbackModal}
            feedbackId={feedbackId.feedbackId}
            type={feedbackId.requestType}
            fetchNewData={async () => {
              let options = await createQueryOptions();
              if (options) {
                await fetchData(1, null, activeTab, options);
              }
            }}
          />
        )}
        <Container fluid>
          <Breadcrumbs
            title="View Feedbacks"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <>
                    <Row className="mt-2">
                      <Col>
                        <Nav tabs className="nav-tabs-custom nav-justified">
                          <NavItem>
                            <NavLink
                              className={activeTab === 'Child' && 'active'}
                              onClick={() => {
                                toggle('Child');
                                fetchData(
                                  1,
                                  null,
                                  'Child',
                                  createQueryOptions()
                                );
                              }}
                            >
                              General
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={activeTab === 'Activity' && 'active'}
                              onClick={() => {
                                toggle('Activity');
                                fetchData(
                                  1,
                                  null,
                                  'Activity',
                                  createQueryOptions()
                                );
                              }}
                            >
                              Activity
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col className="d-flex justify-content-end align-items-center">
                        <Button
                          size="sm"
                          color="primary"
                          className=" waves-effect waves-light mr-3"
                          onClick={openFilterModal}
                        >
                          <i className="ri-filter-fill align-middle mr-2"></i>
                          <span>Filter Feedback</span>
                          {currentFilterCount > 0 && (
                            <Badge color="light" pill className="ml-2">
                              {currentFilterCount}
                            </Badge>
                          )}
                        </Button>
                        {validatePermissionKey(authUser, CREATE_FEEDBACK) && (
                          <Button
                            size="sm"
                            color="success"
                            className=" waves-effect waves-light"
                            onClick={openAddFeedbackModal}
                          >
                            <i className="ri-add-line align-middle mr-2"></i>
                            Add Feedback
                          </Button>
                        )}
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md="2">
                        <FormGroup>
                          <Label>Show Entries</Label>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              fetchData(1, e.target.value);
                              setPagination({
                                ...pagination,
                                pageLimit: e.target.value
                              });
                            }}
                            value={pagination.pageLimit}
                            disabled={feedbackListLoading}
                          >
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>
                    {feedbackListLoading ? (
                      <div className="text-center">
                        <PageDataLoader />
                      </div>
                    ) : (
                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <Table
                            id="tech-companies-1"
                            striped
                            bordered
                            responsive
                          >
                            <thead>
                              <tr>
                                <th>SL. No</th>
                                <th>Name</th>

                                <th>Created At</th>
                                <th>Last Updated At</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {feedbackList?.map((item, index) => (
                                <tr key={index}>
                                  <th>{index + 1}</th>
                                  <td>{item.name}</td>

                                  <td>
                                    {moment(new Date(item.createdAt)).format(
                                      'DD-MM-YYYY'
                                    )}
                                  </td>
                                  <td>
                                    {moment(new Date(item.updatedAt)).format(
                                      'DD-MM-YYYY'
                                    )}
                                  </td>

                                  <td>
                                    <Button
                                      size="sm"
                                      color="primary"
                                      className=" waves-effect waves-light mr-2 mb-2"
                                      onClick={() => {
                                        openFeedbackInfoModal(item._id);
                                      }}
                                    >
                                      <i className="fas fa-eye mr-2"></i>
                                      View
                                    </Button>
                                    {validatePermissionKey(
                                      authUser,
                                      UPDATE_FEEDBACK
                                    ) && (
                                      <Button
                                        size="sm"
                                        color="warning"
                                        className=" waves-effect waves-light mr-2 mb-2"
                                        onClick={() => {
                                          openUpdateFeedbackModal(item._id);
                                        }}
                                      >
                                        <i className="fas fa-edit mr-2"></i>
                                        Edit
                                      </Button>
                                    )}
                                    {validatePermissionKey(
                                      authUser,
                                      DELETE_FEEDBACK
                                    ) && (
                                      <Button
                                        size="sm"
                                        color="danger"
                                        className=" waves-effect waves-light mb-2"
                                        onClick={() => {
                                          openDeleteFeedbackModal(
                                            item._id,
                                            item.isDeleted
                                              ? 'restore'
                                              : 'delete'
                                          );
                                        }}
                                      >
                                        {item.isDeleted ? (
                                          <i className="fas fa-trash-restore mr-2"></i>
                                        ) : (
                                          <i className="fas fa-trash mr-2"></i>
                                        )}
                                        {item.isDeleted ? 'Restore' : 'Delete'}
                                      </Button>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    )}
                    <Row className="mt-2">
                      <Col md="5">
                        <p>
                          <strong>
                            {' '}
                            Showing{' '}
                            {(pagination.currentPage - 1) *
                              pagination.pageLimit +
                              1}{' '}
                            to{' '}
                            {Math.min(
                              pagination.currentPage * pagination.pageLimit,
                              pagination.totalEntries
                            )}{' '}
                            of {pagination.totalEntries} entries
                          </strong>
                        </p>
                      </Col>
                      {!feedbackListLoading && feedbackList.length > 0 && (
                        <Col md="7">
                          <Paginator
                            totalPages={pagination.totalPages}
                            currentPage={pagination.currentPage}
                            changePage={handlePagination}
                          />
                        </Col>
                      )}
                    </Row>
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(FeedbackListPage);