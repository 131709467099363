/*jshint esversion: 11 */
import React from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Input
} from 'reactstrap';
import { connect } from 'react-redux';
import PageDataLoader from '../Utility/PageDataLoader';
import moment from 'moment';
import ExecutionCarousel from './ExecutionCarousel';

const ViewAssignmentModal = ({
  modalOpenState,
  closeModalHandler,
  assignmentInfoLoading,
  assignmentInfo
}) => {
  return (
    <React.Fragment>
      <Modal isOpen={modalOpenState} backdrop="static" size="lg">
        <ModalHeader toggle={closeModalHandler}>
          {assignmentInfoLoading
            ? 'Loading ...'
            : assignmentInfo.assignment.name}
        </ModalHeader>
        <ModalBody>
          {assignmentInfoLoading ? (
            <div className="d-flex justify-content-center">
              <PageDataLoader />
            </div>
          ) : (
            <React.Fragment>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label>Name</Label>
                    <Input
                      name="name"
                      type="text"
                      className="form-control"
                      defaultValue={assignmentInfo.assignment?.name}
                      readOnly
                    />
                  </FormGroup>
                </Col>

                <Col md="12">
                  <FormGroup>
                    <Label>Summary</Label>
                    <Input
                      name="summary"
                      type="textarea"
                      className="form-control"
                      defaultValue={assignmentInfo.assignment?.summary}
                      readOnly
                    />
                  </FormGroup>
                </Col>

                <Col md="8">
                  <FormGroup>
                    <Label>Date</Label>
                    <Input
                      name="date"
                      type="date"
                      className="form-control"
                      defaultValue={
                        assignmentInfo.assignment &&
                        moment(new Date(assignmentInfo.assignment.date)).format(
                          'YYYY-MM-DD'
                        )
                      }
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Completed</Label>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr"
                    >
                      <Input
                        type="checkbox"
                        className="custom-control-input p-3"
                        id="customSwitch1"
                        defaultChecked={assignmentInfo?.isCompleted}
                        readOnly
                      />
                      <Label className="custom-control-label">
                        Completed Status
                      </Label>
                    </div>
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label>Video URL</Label>
                    <Input
                      name="video_url"
                      type="text"
                      className="form-control"
                      defaultValue={assignmentInfo.assignment?.video_url}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                {assignmentInfo.assignment.images &&
                  assignmentInfo.assignment.images.length > 0 && (
                    <Col md="12">
                      <ExecutionCarousel
                        images={assignmentInfo.assignment.images}
                      />
                    </Col>
                  )}
              </Row>
              <ModalFooter>
                <Button type="button" color="light" onClick={closeModalHandler}>
                  Close
                </Button>
              </ModalFooter>
            </React.Fragment>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(ViewAssignmentModal);
