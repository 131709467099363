import React from 'react';
import { Redirect } from 'react-router-dom';
/**
 *
 * Authentication
 */
import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
import StaffLogin from '../pages/Authentication/StaffLogin';
/**
 *
 * Dashboard
 */
import Dashboard from '../pages/Dashboard/index';
/**
 *
 * Resource: Roles
 */
import ViewRoleListPage from '../pages/Roles';
import ViewStaffListPage from '../pages/Staffs';
import ViewOfferingListPage from '../pages/Offerings';
import ViewBatchListPage from '../pages/Batch';
import ViewSingleBatch from '../pages/Batch/ViewSingleBatch';
import ViewParentListPage from '../pages/Parents';
import ViewChildListPage from '../pages/Child';
import ViewSingleChild from '../pages/Child/ViewSingleChild';
import CreateChildPage from '../pages/Child/CreateChildPage';
import UpdateChildPage from '../pages/Child/UpdateChildPage';
import ViewActivityListPage from '../pages/Activities';
import ViewAssignmentListPage from '../pages/Assignments';
import ViewTagListPage from '../pages/Tags';
import ViewToyListPage from '../pages/Toys';
import ViewBookListPage from '../pages/Books';
import ViewTodoListPage from '../pages/Todos';
import ViewFeedbackListPage from '../pages/Feedback';
import QuestionListPage from '../pages/Questions';
import AddExecution from '../pages/Batch/AddExecution';
import AddBatchExecution from '../pages/Batch/AddBatchExecution';
import AddActivity from '../pages/Batch/AddActivity';
import BadgeListPage from '../pages/Badges';
import SectionPage from '../pages/Section';
import FormListPage from '../pages/Form'

const authProtectedRoutes = [
  // Dashboard
  { path: '/dashboard', component: Dashboard },
  // Resource: Roles
  { path: '/roles', component: ViewRoleListPage },
  { path: '/staff', component: ViewStaffListPage },
  { path: '/offerings', component: ViewOfferingListPage },
  { path: '/batches', component: ViewBatchListPage },
  { path: '/batches/view/:batchId', component: ViewSingleBatch },
  { path: '/children', component: ViewChildListPage },
  { path: '/children/view/:childId', component: ViewSingleChild },
  { path: '/children/create', component: CreateChildPage },
  { path: '/children/update/:childId', component: UpdateChildPage },
  { path: '/activities', component: ViewActivityListPage },
  { path: '/assignments', component: ViewAssignmentListPage },
  { path: '/tags', component: ViewTagListPage },
  { path: '/badges', component: BadgeListPage },
  { path: '/toys', component: ViewToyListPage },
  { path: '/books', component: ViewBookListPage },
  { path: '/todos', component: ViewTodoListPage },
  { path: '/parents', component: ViewParentListPage },
  { path: '/feedbacks', component: ViewFeedbackListPage },
  { path: '/questions', component: QuestionListPage },
  { path: '/section', component: SectionPage },
  { path: '/form', component: FormListPage },

  { path: '/executions/create/:batchId', component: AddBatchExecution },
  { path: '/executions/create/:batchId/:activityId', component: AddExecution },
  { path: '/batches/activity/:batchId', component: AddActivity },
  { path: '/', exact: true, component: () => <Redirect to="/dashboard" /> }
];

const publicRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/login/staff', component: StaffLogin }
];

export { authProtectedRoutes, publicRoutes };
