/*jshint esversion: 11 */
import React from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Input,
  Badge
} from 'reactstrap';
import { connect } from 'react-redux';
import PageDataLoader from '../Utility/PageDataLoader';
import ExecutionCarousel from '../Child/ExecutionCarousel';

const ViewToyModal = ({
  modalOpenState,
  closeModalHandler,
  toyInfoLoading,
  toyInfo
}) => {
  return (
    <React.Fragment>
      <Modal isOpen={modalOpenState} backdrop="static" size="lg">
        <ModalHeader toggle={closeModalHandler}>
          {toyInfoLoading ? 'Loading ...' : toyInfo.name}
        </ModalHeader>
        <ModalBody>
          {toyInfoLoading ? (
            <div className="d-flex justify-content-center">
              <PageDataLoader />
            </div>
          ) : (
            <React.Fragment>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label>Name</Label>
                    <Input
                      name="name"
                      type="text"
                      className="form-control"
                      defaultValue={toyInfo?.name}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>Age</Label>
                    <Input
                      name="age"
                      type="number"
                      className="form-control"
                      defaultValue={toyInfo?.age}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label>Summary</Label>
                    <Input
                      name="summary"
                      type="text"
                      className="form-control"
                      defaultValue={toyInfo?.summary}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label>Overview</Label>
                    <Input
                      name="overview"
                      type="textarea"
                      className="form-control"
                      defaultValue={toyInfo?.overview}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="8">
                  <FormGroup>
                    <Label>Tags</Label>
                    <div>
                      {toyInfo.tags &&
                        toyInfo.tags.map((t) => (
                          <Badge
                            color="primary"
                            className="mr-2 mb-2"
                            style={{
                              padding: '10px 20px',
                              fontSize: '14px'
                            }}
                            pill
                          >
                            {t.name}
                          </Badge>
                        ))}
                    </div>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Available</Label>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr"
                    >
                      <Input
                        type="checkbox"
                        className="custom-control-input p-3"
                        id="customSwitch1"
                        defaultChecked={toyInfo?.isAvailable}
                        readOnly
                      />
                      <Label className="custom-control-label">
                        Availability Status
                      </Label>
                    </div>
                  </FormGroup>
                </Col>
                {toyInfo.pictures && toyInfo.pictures.length > 0 && (
                  <Col md="12">
                    <ExecutionCarousel images={toyInfo.pictures} />
                  </Col>
                )}
              </Row>
              <ModalFooter>
                <Button type="button" color="light" onClick={closeModalHandler}>
                  Close
                </Button>
              </ModalFooter>
            </React.Fragment>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(ViewToyModal);
