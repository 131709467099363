import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input, Col, Alert } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { AvForm } from 'availity-reactstrap-validation';

const FilterSectionModal = ({ isOpen, closeFilterModal, sectionName, setSectionName, fetchSections, authUser, fetchSectionsReset, filterError, setFilterError, createQueryOptions, updateFilterCount, isDeleted, setIsDeleted, createdAtFilter, setCreatedAtFilter }) => {

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // setFilters({ ...filters, [name]: value });
  };

  const handleSwitchChange = (e) => {
    const { checked } = e.target;
    // setFilters({ ...filters, isDeleted: checked });
  };

  function dateRangeHandler(setHook) {
    return function (dates) {
      const [start, end] = dates;
      setHook({
        startDate: start,
        endDate: end
      });
    };
  }


  const resetFilters = () => {
    setCreatedAtFilter({
      startDate: null,
      endDate: null
    });

    setIsDeleted(false);

    setSectionName('')
    const queryOptions = createQueryOptions(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    if (queryOptions) {
      updateFilterCount(queryOptions);
      fetchSections(1, null, queryOptions);
      closeFilterModal()
    }
  };


  function applyFilter() {
    const queryOptions = createQueryOptions();
    if (queryOptions) {
      updateFilterCount(queryOptions);
      fetchSections(1, null, queryOptions);
      closeFilterModal();
    }
  }

  return (
    <Modal isOpen={isOpen} backdrop="static" size="md">
      <ModalHeader toggle={closeFilterModal}>Filter Attributes</ModalHeader>
      <AvForm className='needs-validation' onValidSubmit={applyFilter}
      >
        <ModalBody>
          {filterError ? (
            <Alert
              color="danger"
              role="alert"
              isOpen={filterError ? true : false}
              toggle={() => setFilterError(false)}
            >
              <i className="mdi mdi-block-helper mr-2"></i>
              {filterError}
            </Alert>
          ) : null}
          <Col md="12">
            <FormGroup>
              <Label for="filter_name">Name</Label>
              <Input
                type="text"
                id="filter_name"
                name="name"
                value={sectionName || ''}
                onChange={(e) => setSectionName(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup className="mb-0">
              <Label>Created At</Label>
              <div className="d-flex justify-content-center">
                <DatePicker
                  selected={createdAtFilter.startDate}
                  selectsStart
                  className="form-control"
                  startDate={createdAtFilter.startDate}
                  endDate={createdAtFilter.endDate}
                  selectsRange={true}
                  dateFormat="dd-MM-yyyy"
                  onChange={dateRangeHandler(setCreatedAtFilter)}
                  isClearable={true}

                />
              </div>
            </FormGroup>
          </Col>
          {authUser && authUser.user_type === 'super_admin' && (
            <Col md="12">
              <FormGroup>
                <Label>Is Deleted</Label>
                <div className="custom-control custom-switch mb-2" dir="ltr">
                  <Input
                    id="customSwitch"
                    name="isDeleted"
                    type="checkbox"
                    className="custom-control-input p-3"
                    checked={isDeleted}
                    onChange={() => setIsDeleted(!isDeleted)}
                  />
                  <Label htmlFor="customSwitch" className="custom-control-label">
                    Deleted Attribute
                  </Label>
                </div>
              </FormGroup>
            </Col>
          )}
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="primary" type='submit'>
            Apply Filter
          </Button>
          <Button size="sm" type="button" color="success" onClick={resetFilters}>
            Reset
          </Button>
          <Button size="sm" type="button" color="light" onClick={closeFilterModal}>
            Close
          </Button>
        </ModalFooter>
      </AvForm>

    </Modal>
  );
};

export default FilterSectionModal;
