/*jshint esversion: 11 */
import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Input
} from 'reactstrap';
import Select from 'react-select';
import { connect } from 'react-redux';
import PageDataLoader from '../Utility/PageDataLoader';

const ViewTodoModal = ({
  modalOpenState,
  closeModalHandler,
  questionInfo,
  questionInfoLoading
}) => {
  const [selectedReference, setSelectedReference] = useState(null);

  useEffect(() => {
    if (questionInfo) {
      setSelectedReference(buildOptions(questionInfo?.reference_id || []));
    }
  }, [questionInfo]);

  function buildOptions(list) {
    return list.map((item) => ({
      value: item._id,
      label: item.name
    }));
  }

  return (
    <React.Fragment>
      <Modal isOpen={modalOpenState} backdrop="static" size="lg">
        <ModalHeader toggle={closeModalHandler}>
          {questionInfoLoading ? 'Loading ...' : questionInfo.title}
        </ModalHeader>
        <ModalBody>
          {questionInfoLoading ? (
            <div className="d-flex justify-content-center">
              <PageDataLoader />
            </div>
          ) : (
            <React.Fragment>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label>Title</Label>
                    <Input
                      name="title"
                      type="text"
                      className="form-control"
                      defaultValue={questionInfo?.title}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label>Options & Weightage</Label>
                    {questionInfo.option_list &&
                      questionInfo.option_list.map((element, index) => (
                        <Row>

                          <Col md="6">
                            <Input
                              name="question"
                              type="text"
                              className="form-control mb-3"
                              value={element.option_name}
                              style={{ backgroundColor: element.is_correct && "pink" }}
                              readOnly
                            />
                          </Col>
                          <Col md="6">
                            <Input
                              name="question"
                              type="text"
                              className="form-control mb-3"
                              value={element.weightage ? element.weightage : 1}
                              style={{ backgroundColor: element.is_correct && "pink" }}
                              readOnly
                            />
                          </Col>
                        </Row>

                      ))}
                  </FormGroup>
                </Col>
              </Row>
              <ModalFooter>
                <Button type="button" color="light" onClick={closeModalHandler}>
                  Close
                </Button>
              </ModalFooter>
            </React.Fragment>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(ViewTodoModal);
