import React, { useState } from 'react';

const Paginator = ({
  totalPages,
  currentPage,
  changePage,
  showOnlyIndicators
}) => {
  const [visibleArray] = useState([1, 2, 3, 4, 5, 6, 7, 8]);
  const [totalParts] = useState(new Array(Math.ceil(totalPages / 8)));
  const [currentPartIndex] = useState(
    totalParts.findIndex((it, idx) => currentPage <= (idx + 1) * 8)
  );
  const [currentVisiblePages] = useState(
    currentPartIndex === totalParts.length - 1
      ? visibleArray
          .splice(0, Math.min(totalPages - currentPartIndex * 8, 8))
          .map((it) => it + currentPartIndex * 8)
      : visibleArray.map((it) => it + currentPartIndex * 8)
  );

  return (
    <div>
      <div data-test="datatable-pagination">
        <div className="dataTables_paginate">
          <ul data-test="pagination" className="pagination">
            <li
              data-test="page-item"
              className={`${currentPage === 1 ? 'disabled' : ''} page-item`}
              onClick={() => {
                changePage(currentPage - 1);
              }}
            >
              <a
                href="#prev"
                data-test="page-link"
                aria-label="Previous"
                className="page-link page-link"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <span>Previous</span>
              </a>
            </li>
            {!showOnlyIndicators &&
              currentVisiblePages.map((it, idx) => (
                <li
                  data-test="page-item"
                  className={`${it === currentPage ? 'active' : ''} page-item`}
                  key={idx}
                  onClick={() => {
                    changePage(it);
                  }}
                >
                  <a
                    href={`#${it}`}
                    data-test="page-link"
                    className="page-link page-link"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    {it}
                  </a>
                </li>
              ))}
            <li
              data-test="page-item"
              className={`${
                currentPage === totalPages ? 'disabled' : ''
              } page-item`}
              onClick={() => {
                changePage(currentPage + 1);
              }}
            >
              <a
                href="#next"
                data-test="page-link"
                aria-label="Next"
                className="page-link page-link"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <span>Next</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Paginator;
