import React, { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { deleteRestoreStaff } from '../../api/staff';

const DeleteStaffModal = ({
  modalOpenState,
  closeModalHandler,
  setStaffId,
  staffId,
  type,
  fetchNewData
}) => {
  const [successAlert, setSuccessAlert] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });

  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }
  const deleteStaff = async () => {
    setRequestLoading(true);

    const { response, error } = await deleteRestoreStaff(staffId, type);
    if (response) {
      setRequestLoading(false);
      setSuccessAlert(
        `Staff ${
          type === 'restore'
            ? 'restored'
            : type === 'delete'
            ? 'deleted'
            : 'terminated'
        } successfully`
      );
      closeModalHandler();
      fetchNewData();
    }
    if (error) {
      setRequestLoading(false);
      showErrorAlert(error);
      setStaffId({ staffId, requestType: 'terminate' });
      return;
    }
  };

  useEffect(() => {
    resetErrorAlert();
  }, [modalOpenState]);
  return (
    <React.Fragment>
      {successAlert && (
        <SweetAlert
          success
          title={
            type === 'restore'
              ? 'Restored'
              : type === 'delete'
              ? 'Deleted'
              : 'Terminated'
          }
          onConfirm={() => setSuccessAlert(false)}
        >
          {successAlert}
        </SweetAlert>
      )}

      <Modal isOpen={modalOpenState} backdrop="static" size="md">
        <ModalHeader toggle={closeModalHandler}></ModalHeader>
        <ModalBody>
          {/* {requestError ? (
            <Alert
              color="danger"
              role="alert"
              isOpen={requestError.show_alert}
              toggle={resetErrorAlert}
            >
              <i className="mdi mdi-block-helper mr-2"></i>
              {requestError.message}
            </Alert>
          ) : null} */}
          <h4>
            {requestError?.show_alert
              ? requestError.message
              : `Are you sure you want to ${type} this staff?`}
          </h4>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={requestLoading}
            type="button"
            color="light"
            onClick={closeModalHandler}
          >
            Close
          </Button>
          <Button type="submit" color="danger" onClick={() => deleteStaff()}>
            {requestLoading
              ? type === 'restore'
                ? 'Restoring ...'
                : type === 'delete'
                ? 'Deleting'
                : 'Terminating'
              : type === 'restore'
              ? 'Restore'
              : type === 'delete'
              ? 'Delete'
              : 'Terminate'}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default DeleteStaffModal;
