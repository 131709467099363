/*jshint esversion: 11 */
import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Table,
  Label,
  FormGroup,
  Button,
  Badge
} from 'reactstrap';
import { connect } from 'react-redux';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import PageDataLoader from '../Utility/PageDataLoader';
import Paginator from '../Utility/Paginator';
import { getBatchInfo, getBatchList } from '../../api/batch';
import moment from 'moment';
import CreateBatchModal from './CreateBatchModal';
import UpdateBatchModal from './UpdateBatchModal';
import { validatePermissionKey } from '../../helpers/permission';
import {
  CREATE_BATCH,
  DELETE_BATCH,
  UPDATE_BATCH
} from '../Utility/PermissionKeys';
import { useHistory } from 'react-router-dom';
import { stringify as QStringify, parse as QParse } from 'qs';
import { useMemo } from 'react';
import validator from 'validator';
import { getOfferingList } from '../../api/offering';
import BatchFilterModal from './BatchFilterModal';
import DeleteBatchModal from './DeleteBatchModal';

const BatchListPage = ({ authUser }) => {
  const history = useHistory();
  const [breadcrumbItems] = useState([
    { title: 'Batches', link: '#' },
    { title: 'View List', link: '#' }
  ]);
  const [batchListLoading, setBatchListLoading] = useState(true);
  const [batchList, setBatchList] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageLimit: 10,
    totalEntries: 0,
    totalPages: 0
  });
  const [filterError, setFilterError] = useState(false);
  const [addBatchModalOpen, setAddBatchModalOpen] = useState(false);
  const [batchInfo, setBatchInfo] = useState({});
  const [batchInfoLoading, setBatchInfoLoading] = useState(false);
  const [updateBatchModalOpen, setUpdateBatchModalOpen] = useState(false);
  const [sortingRule, setSortingRule] = useState('updated_at$desc');
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [currentFilterCount, setCurrentFilterCount] = useState(0);
  const [dateFilter, setDateFilter] = useState({
    startDate: null,
    endDate: null
  });
  const [filterBatchName, setFilterBatchName] = useState('');
  const [filterSchedule, setFilterSchedule] = useState('');
  const [filterTime, setFilterTime] = useState({
    start: '',
    end: ''
  });
  const [offeringQueryId, setOfferingQueryId] = useState([]);
  const [filterOfferingList, setFilterOfferingList] = useState([]);
  const [selectedFilterOfferingOption, setSelectedFilterOfferingOption] =
    useState([]);
  const [deleteBatchModalOpen, setDeleteBatchModalOpen] = useState(false);
  const [batchId, setBatchId] = useState('');
  const [isDeleted, setIsDeleted] = useState(false);

  const filterOfferingOptions = useMemo(
    () => buildOfferingOptions(filterOfferingList),
    // eslint-disable-next-line
    [filterOfferingList]
  );
  let listElem = document.getElementById('batchList');
  let linkElem = document.getElementById('batchLink');
  useEffect(() => {
    if (listElem) listElem.classList.add('mm-active');
    if (linkElem) linkElem.classList.add('active');
    if (authUser) {
      fetchOfferingOption();
      populateSearchOptions(history?.location?.search);
    }
    // eslint-disable-next-line
  }, [authUser, listElem, linkElem]);

  const handlePagination = (page) => {
    fetchData(page, pagination.pageLimit, createQueryOptions(), sortingRule);
  };

  const fetchData = async (
    current_page = 1,
    page_limit = 10,
    queryOptions = {},
    sortingOption = sortingRule
  ) => {
    setBatchListLoading(true);
    const response = await getBatchList(
      current_page,
      page_limit,
      queryOptions,
      sortingOption
    );

    if (response) {
      setPagination({
        currentPage: response.metadata.page,
        pageLimit: response.metadata.limit,
        totalPages: response.metadata.total_pages,
        totalEntries: response.metadata.total_results
      });
      setBatchList(response.data);
      setBatchListLoading(false);
    }
  };

  async function fetchOfferingOption() {
    const response = await getOfferingList(1, 100);
    if (response) {
      setFilterOfferingList(response?.data);
    }
  }

  /**
   *
   * @param {array<object>} roleArray
   * @returns
   */
  function buildOfferingOptions(roleArray) {
    const offeringOptions = roleArray.map((i) => {
      return {
        label: i.name,
        value: i
      };
    });
    const selectedOfferingOption = offeringOptions.filter((i) =>
      offeringQueryId.includes(i.value._id)
    );
    setSelectedFilterOfferingOption(selectedOfferingOption);
    return offeringOptions;
  }

  /**
   *
   * @param {string} str
   */
  function getValidDateInput(str) {
    return validator.isDate(str) ? new Date(str).toISOString() : '';
  }

  function createQueryOptions(
    startDate = dateFilter.startDate,
    endDate = dateFilter.endDate,
    offering = selectedFilterOfferingOption,
    name = filterBatchName,
    schedule = filterSchedule,
    startTime = filterTime.start,
    endTime = filterTime.end,
    deletedStatus = isDeleted
  ) {
    setFilterError(false);
    if ((startDate && !endDate) || (!startDate && endDate)) {
      setFilterError('Enter valid date range');
      return;
    }

    if (startDate && endDate && new Date(startDate) > new Date(endDate)) {
      setFilterError('Enter valid start date and end date');
      return;
    }

    if (
      (startTime && !endTime) ||
      (!startTime && endTime) ||
      startTime > endTime
    ) {
      setFilterError('Enter valid time range');
      return;
    }
    const startDateISOString = getValidDateInput(startDate);
    const endDateISOString = getValidDateInput(endDate);
    const selectedOffering = Array.isArray(offering)
      ? offering.map((i) => i.value._id).join(',')
      : '';
    const queryOptions = {
      start_date: startDateISOString,
      end_date: endDateISOString,
      name,
      offering: selectedOffering,
      schedule,
      start_time: startTime,
      end_time: endTime,
      include_deleted: deletedStatus,
      sorting_option: sortingRule
    };
    updateUrlPath(queryOptions);
    return queryOptions;
  }

  function updateFilterCount(option) {
    let count = 0;
    Object.keys(option).forEach((i) => {
      if (option[i]?.toString()?.trim()?.length > 0 && option[i] !== false) {
        count += 1;
      }
    });
    setCurrentFilterCount(count - 1);
  }

  /**
   *
   * @param {string} searchQuery
   */
  function populateSearchOptions(searchQuery) {
    const parsedQuery = QParse(searchQuery.substring(1));
    if (parsedQuery.start_date && parsedQuery.end_date) {
      updateDateFilterInput(
        parsedQuery.start_date,
        parsedQuery.end_date,
        setDateFilter
      );
    }
    if (parsedQuery.offering) {
      const offeringId = parsedQuery.offering.split(',').map((i) => i.trim());
      setOfferingQueryId(offeringId);
    }
    setFilterBatchName(parsedQuery.name);
    setFilterSchedule(parsedQuery.schedule);
    setFilterTime({
      start: parsedQuery.start_time,
      end: parsedQuery.end_time
    });
    const sortingOption = parsedQuery.sorting_option || sortingRule;
    setSortingRule(sortingOption);
    updateFilterCount(parsedQuery);
    fetchData(1, null, parsedQuery, sortingOption);
  }

  /**
   *
   * @param {string} str
   * @param {function} setHook
   * @returns
   */
  function updateDateFilterInput(startStr, endStr, setHook) {
    setHook({
      startDate: new Date(startStr),
      endDate: new Date(endStr)
    });
  }

  function updateUrlPath(queryOptions) {
    const queryString = QStringify(queryOptions);
    history.replace(`/batches?${queryString}`);
  }

  async function fetchSortedList(sortingOption) {
    setSortingRule(sortingOption);
    const queryOptions = createQueryOptions();
    queryOptions.sorting_option = sortingOption;
    updateUrlPath(queryOptions);
    fetchData(1, null, queryOptions, sortingOption);
  }

  function openFilterModal() {
    setFilterModalOpen(true);
  }

  function closeFilterModal() {
    setFilterModalOpen(false);
  }

  function openAddBatchModal() {
    setAddBatchModalOpen(true);
  }

  function closeAddBatchModal() {
    setAddBatchModalOpen(false);
  }

  async function openUpdateBatchModal(batchId) {
    fetchBatchInfo(batchId);
    setUpdateBatchModalOpen(true);
  }

  function closeUpdateBatchModal() {
    setUpdateBatchModalOpen(false);
  }

  async function openDeleteBatchModal(batchId, requestType) {
    setBatchId({
      batchId,
      requestType
    });
    setDeleteBatchModalOpen(true);
  }

  function closeDeleteBatchModal() {
    setDeleteBatchModalOpen(false);
  }

  async function fetchBatchInfo(batchId) {
    setBatchInfoLoading(true);
    const response = await getBatchInfo(batchId);
    if (response) {
      setBatchInfo(response);
      setBatchInfoLoading(false);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <BatchFilterModal
          filterError={filterError}
          setFilterError={setFilterError}
          modalOpenState={filterModalOpen}
          closeModalHandler={closeFilterModal}
          fetchList={fetchData}
          updateFilterCount={updateFilterCount}
          updateUrlPath={updateUrlPath}
          name={filterBatchName}
          setName={setFilterBatchName}
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
          offeringOptions={filterOfferingOptions}
          selectedOfferingOption={selectedFilterOfferingOption}
          setOfferingOption={setSelectedFilterOfferingOption}
          createQueryOptions={createQueryOptions}
          schedule={filterSchedule}
          setSchedule={setFilterSchedule}
          time={filterTime}
          setTime={setFilterTime}
          authUser={authUser}
          isDeleted={isDeleted}
          setIsDeleted={setIsDeleted}
        />
        <CreateBatchModal
          modalOpenState={addBatchModalOpen}
          closeModalHandler={closeAddBatchModal}
          fetchNewBatchList={async () => {
            let options = await createQueryOptions();
            if (options) {
              await fetchData(1, null, options);
            }
          }}
          authUser={authUser}
        />
        <UpdateBatchModal
          modalOpenState={updateBatchModalOpen}
          closeModalHandler={closeUpdateBatchModal}
          batchInfo={batchInfo}
          batchInfoLoading={batchInfoLoading}
          fetchNewBatchList={async () => {
            let options = await createQueryOptions();
            if (options) {
              await fetchData(1, null, options);
            }
          }}
        />
        {batchId && (
          <DeleteBatchModal
            modalOpenState={deleteBatchModalOpen}
            closeModalHandler={closeDeleteBatchModal}
            batchId={batchId.batchId}
            type={batchId.requestType}
            fetchNewData={async () => {
              let options = await createQueryOptions();
              if (options) {
                await fetchData(1, null, options);
              }
            }}
          />
        )}
        <Container fluid>
          <Breadcrumbs title="View Batches" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <>
                    <Row className="mt-2">
                      <Col md="2">
                        <FormGroup>
                          <Col md="12">
                            <Label className="col-form-label">
                              Show Entries
                            </Label>
                            <select
                              className="form-control"
                              onChange={(e) => {
                                fetchData(1, e.target.value);
                                setPagination({
                                  ...pagination,
                                  pageLimit: e.target.value
                                });
                              }}
                              value={pagination.pageLimit}
                              disabled={batchListLoading}
                            >
                              <option value={10}>10</option>
                              <option value={25}>25</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <Label className="col-form-label">Sort Batch</Label>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              fetchSortedList(e.target.value);
                            }}
                            value={sortingRule}
                            disabled={batchListLoading}
                          >
                            <option value="start_date$desc">
                              Start Date (Newest First)
                            </option>
                            <option value="start_date$asc">
                              Start Date (Oldest First)
                            </option>
                            <option value="end_date$desc">
                              End Date (Newest First)
                            </option>
                            <option value="end_date$asc">
                              End Date (Oldest First)
                            </option>
                            <option value="offering$asc">Offering (A-Z)</option>
                            <option value="offering$desc">
                              Offering (Z-A)
                            </option>
                            <option value="time$asc">
                              Time (Earliest First)
                            </option>
                            <option value="time$desc">
                              Time (Earliest Last)
                            </option>
                            <option value="schedule$asc">Schedule (A-Z)</option>
                            <option value="schedule$desc">
                              Schedule (Z-A)
                            </option>
                            <option value="updated_at$desc">
                              Update Time (Newest First)
                            </option>
                            <option value="updated_at$asc">
                              Update Time (Oldest First)
                            </option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col className="d-flex justify-content-end align-items-center">
                        <Button
                          size="sm"
                          color="primary"
                          className=" waves-effect waves-light mr-3"
                          onClick={openFilterModal}
                        >
                          <i className="ri-filter-fill align-middle mr-2"></i>
                          <span>Filter Batch</span>
                          {currentFilterCount > 0 && (
                            <Badge color="light" pill className="ml-2">
                              {currentFilterCount}
                            </Badge>
                          )}
                        </Button>
                        {validatePermissionKey(authUser, CREATE_BATCH) && (
                          <Button
                            size="sm"
                            color="success"
                            className=" waves-effect waves-light"
                            onClick={openAddBatchModal}
                          >
                            <i className="ri-add-line align-middle mr-2"></i>
                            Add Batch
                          </Button>
                        )}
                      </Col>
                    </Row>
                    {batchListLoading ? (
                      <div className="text-center">
                        <PageDataLoader />
                      </div>
                    ) : (
                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <Table
                            id="tech-companies-1"
                            striped
                            bordered
                            responsive
                          >
                            <thead>
                              <tr>
                                <th>SL. No</th>
                                <th>Name</th>
                                <th>Offering</th>
                                <th>Schedule</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Time</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {batchList?.map((item, index) => (
                                <tr key={index}>
                                  <th>{index + 1}</th>
                                  <td style={{ color: item.color }}>{item.name}</td>
                                  <td>{item.offering.name}</td>
                                  <td>{item.schedule}</td>
                                  <td>
                                    {moment(new Date(item.start_date)).format(
                                      'DD-MM-YYYY'
                                    )}
                                  </td>
                                  <td>
                                    {moment(new Date(item.end_date)).format(
                                      'DD-MM-YYYY'
                                    )}
                                  </td>
                                  <td>
                                    {item.start_time + '-' + item.end_time}
                                  </td>
                                  <td>
                                    <Button
                                      size="sm"
                                      color="primary"
                                      className=" waves-effect waves-light mr-2 mb-2"
                                      onClick={() => {
                                        history.push({
                                          pathname: `/batches/view/${item._id}`
                                        });
                                      }}
                                    >
                                      <i className="fas fa-eye mr-2"></i>
                                      View
                                    </Button>
                                    {validatePermissionKey(
                                      authUser,
                                      UPDATE_BATCH
                                    ) && (
                                        <Button
                                          size="sm"
                                          color="warning"
                                          className=" waves-effect waves-light mr-2 mb-2"
                                          onClick={() => {
                                            openUpdateBatchModal(item._id);
                                          }}
                                        >
                                          <i className="fas fa-edit mr-2"></i>
                                          Edit
                                        </Button>
                                      )}
                                    {validatePermissionKey(
                                      authUser,
                                      DELETE_BATCH
                                    ) && (
                                        <Button
                                          size="sm"
                                          color="danger"
                                          className=" waves-effect waves-light mb-2"
                                          onClick={() => {
                                            openDeleteBatchModal(
                                              item._id,
                                              item.isDeleted
                                                ? 'restore'
                                                : 'delete'
                                            );
                                          }}
                                        >
                                          {item.isDeleted ? (
                                            <i className="fas fa-trash-restore mr-2"></i>
                                          ) : (
                                            <i className="fas fa-trash mr-2"></i>
                                          )}
                                          {item.isDeleted ? 'Restore' : 'Delete'}
                                        </Button>
                                      )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    )}
                    <Row className="mt-2">
                      <Col md="5">
                        <p>
                          <strong>
                            {' '}
                            Showing{' '}
                            {(pagination.currentPage - 1) *
                              pagination.pageLimit +
                              1}{' '}
                            to{' '}
                            {Math.min(
                              pagination.currentPage * pagination.pageLimit,
                              pagination.totalEntries
                            )}{' '}
                            of {pagination.totalEntries} entries
                          </strong>
                        </p>
                      </Col>
                      {!batchListLoading && batchList.length > 0 && (
                        <Col md="7">
                          <Paginator
                            totalPages={pagination.totalPages}
                            currentPage={pagination.currentPage}
                            changePage={handlePagination}
                          />
                        </Col>
                      )}
                    </Row>
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(BatchListPage);
