/*jshint esversion: 11 */
import sendApiRequest from '../helpers/axios-setup';

/**
 *
 * @returns {Promise}
 */
export async function getOfferingList(
  page,
  limit,
  {
    filter_start_created_at = '',
    filter_end_created_at = '',
    filter_start_last_updated_at = '',
    filter_end_last_updated_at = '',
    name = '',
    include_deleted = ''
  } = {},
  sorting_option = ''
) {
  try {
    const response = await sendApiRequest.get('/offering', {
      params: {
        page: page,
        limit: limit,
        filter_start_created_at,
        filter_end_created_at,
        filter_start_last_updated_at,
        filter_end_last_updated_at,
        filter_name: name,
        include_deleted,
        sorting_option
      }
    });
    return response.data?.data[0];
  } catch (e) {
    return null;
  }
}

/**
 *
 * @param {string} name
 * @param {string} summary
 * @param {string} detail
 * @returns {Promise<Object>}
 */
export async function addNewOffering(name, summary, detail, color) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.post('/offering', {
      name,
      summary,
      detail,
      color
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @param {String} offeringId
 * @returns
 */
export async function getOfferingInfo(offeringId) {
  try {
    const response = await sendApiRequest.get(`/offering/${offeringId}`);
    return response.data?.data;
  } catch (e) {
    return null;
  }
}

/**
 *
 * @param {string} name
 * @param {string} summary
 * @param {string} detail
 * @returns {Promise<Object>}
 */
export async function updateOffering(offeringId, name, summary, detail, color) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.put(`/offering/${offeringId}`, {
      name,
      summary,
      detail,
      color
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @param {String} offeringId
 * @returns
 */
export async function deleteRestoreOffering(offeringId, type) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.delete(`/offering/${offeringId}`, {
      params: {
        type: type
      }
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}
