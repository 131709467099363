import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { getAuthenticatedUser } from '../helpers/auth-requests';

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  loginState,
  ...rest
}) => {
  const { isAuthenticated, loading } = loginState;
  const location = useLocation()

  console.log(location)

  const history = useHistory()
  useEffect(() => {
    const checkLoggedin = async () => {
      try {

        let token = localStorage.getItem("ACCESS_TOKEN");
        if (token === null || token === "") {
          // localStorage.setItem("ACCESS_TOKEN", "");
          localStorage.clear()
          history.push('/login')
        }
        else {
          const res = await getAuthenticatedUser('/auth/verify')
          if (res.error) {
            history.push('/login')
          }
        }
        // console.log(res);
      } catch (e) {
        console.log(e, 'error')
        localStorage.clear()
        window.location.reload()
        // history.push('/login')
      }
    };

    if (!loading && location.pathname !== "/login/staff") {
      checkLoggedin();

    }
    // Clean up the event listener when the component unmounts
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthProtected && !isAuthenticated && !loading) {
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          );
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loginState: state.Login
  };
};

export default connect(mapStateToProps)(AppRoute);
