export const VIEW_ROLE = 'view_roles';
export const EDIT_ROLE = 'update_role';
export const CREATE_ROLE = 'create_role';
export const DELETE_ROLE = 'delete_role';

export const VIEW_STAFF = 'view_users';
export const CREATE_STAFF = 'create_user';
export const EDIT_STAFF = 'update_user';
export const DELETE_STAFF = 'delete_user';

export const VIEW_OFFERINGS = 'view_offerings';
export const CREATE_OFFERING = 'create_offering';
export const UPDATE_OFFERING = 'update_offering';
export const DELETE_OFFERING = 'delete_offering';

export const VIEW_BATCH = 'view_batch';
export const CREATE_BATCH = 'create_batch';
export const UPDATE_BATCH = 'update_batch';
export const DELETE_BATCH = 'delete_batch';
export const ASSIGN_CHILDREN = 'assign_children';
export const ASSIGN_ACTIVITIES = 'assign_activities';

export const VIEW_PARENTS = 'view_parents';
export const CREATE_PARENT = 'create_parent';
export const UPDATE_PARENT = 'update_parent';
export const DELETE_PARENT = 'delete_parent';

export const VIEW_CHILDREN = 'view_children';
export const CREATE_CHILD = 'create_child';
export const UPDATE_CHILD = 'update_child';
export const DELETE_CHILD = 'delete_child';

export const VIEW_TAGS = 'view_tags';
export const CREATE_TAG = 'create_tag';
export const UPDATE_TAG = 'update_tag';
export const DELETE_TAG = 'delete_tag';

export const VIEW_BADGES = 'view_badges';
export const CREATE_BADGE = 'create_badge';
export const UPDATE_BADGE = 'update_badge';
export const DELETE_BADGE = 'delete_badge';

export const VIEW_TOYS = 'view_toys';
export const CREATE_TOY = 'create_toy';
export const UPDATE_TOY = 'update_toy';
export const DELETE_TOY = 'delete_toy';

export const VIEW_BOOKS = 'view_books';
export const CREATE_BOOK = 'create_book';
export const UPDATE_BOOK = 'update_book';
export const DELETE_BOOK = 'delete_book';

export const VIEW_ACTIVITIES = 'view_activities';
export const CREATE_ACTIVITY = 'create_activity';
export const UPDATE_ACTIVITY = 'update_activity';
export const DELETE_ACTIVITY = 'delete_activity';

export const VIEW_EXECUTIONS = 'view_executions';
export const ADD_EXECUTION = 'add_execution';
export const UPDATE_EXECUTION = 'update_execution';
export const DELETE_EXECUTION = 'delete_execution';

export const VIEW_ASSIGNMENTS = 'view_assignments';
export const CREATE_ASSIGNMENT = 'create_assignment';
export const UPDATE_ASSIGNMENT = 'update_assignment';
export const DELETE_ASSIGNMENT = 'delete_assignment';

export const VIEW_FEEDBACK_RESPONSE = 'view_feedback_response';
export const UPDATE_FEEDBACK = 'update_feedback';
export const CREATE_FEEDBACK = 'create_feedback';
export const VIEW_FEEDBACKS = 'view_feedbacks';
export const DELETE_FEEDBACK = 'delete_feedback';

// export const VIEW_QUESTION_RESPONSE = 'view_question_response';
export const UPDATE_QUESTION = 'update_question';
export const CREATE_QUESTION = 'create_question';
export const VIEW_QUESTIONS = 'view_questions';
export const DELETE_QUESTION = 'delete_question';

export const UPDATE_ATTRIBUTE = 'update_attribute';
export const CREATE_ATTRIBUTE = 'create_attribute';
export const VIEW_ATTRIBUTES = 'view_attributes';
export const DELETE_ATTRIBUTE = 'delete_attribute';

export const UPDATE_FORM = 'update_form';
export const CREATE_FORM = 'create_form';
export const VIEW_FORMS = 'view_forms';
export const DELETE_FORM = 'delete_form';

export const VIEW_NOTICES = 'view_notices';
export const CREATE_NOTICE = 'create_notice';
export const UPDATE_NOTICE = 'update_notice';
export const DELETE_NOTICE = 'delete_notice';

export const VIEW_BATCH_IMAGE = 'view_batch_image';
export const CREATE_BATCH_IMAGE = 'create_batch_image';
export const DELETE_BATCH_IMAGE = 'delete_batch_image';

export const VIEW_TRANSACTION = 'view_transaction';
export const CREATE_TRANSACTION = 'create_transaction';
export const CREATE_FORM_RESPONSE = 'create_form_response';