/*jshint esversion: 11 */
import {
  CHECK_LOGIN,
  LOGIN_USER_SUCCESSFUL,
  API_ERROR,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOAD_USER,
  LOAD_USER_SUCCESSFUL,
  CHECK_ADMIN_LOGIN,
  CHECK_STAFF_LOGIN
} from './actionTypes';

export const checkAdminLogin = (user, history) => {
  return {
    type: CHECK_ADMIN_LOGIN,
    payload: { user, history }
  };
};

export const checkStaffLogin = (user, history) => {
  return {
    type: CHECK_STAFF_LOGIN,
    payload: { user, history }
  };
};

export const checkLogin = (user, history) => {
  return {
    type: CHECK_LOGIN,
    payload: { user, history }
  };
};

export const loadUser = (history) => {
  return {
    type: LOAD_USER,
    payload: { history }
  };
};

export const loadUserSuccessful = (user) => {
  return {
    type: LOAD_USER_SUCCESSFUL,
    payload: user
  };
};

export const loginUserSuccessful = (user) => {
  return {
    type: LOGIN_USER_SUCCESSFUL,
    payload: user
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error
  };
};

export const logoutUser = (history) => {
  return {
    type: LOGOUT_USER,
    payload: { history }
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {}
  };
};
