/*jshint esversion: 11 */
import sendApiRequest from '../helpers/axios-setup';
import { stringify as QStringify } from 'qs';

/**
 *
 * @returns {Promise}
 */
export async function getStaffList(
  page,
  limit,
  {
    start_created_at = '',
    end_created_at = '',
    name = '',
    email_address = '',
    phone_number = '',
    role = '',
    gender = '',
    start_age = '',
    end_age = '',
    include_deleted = ''
  } = {},
  sorting_option = ''
) {
  try {
    const response = await sendApiRequest.get('/user', {
      params: {
        page: page,
        limit: limit,
        filter_query: QStringify({
          start_created_at,
          end_created_at,
          name,
          email_address,
          phone_number,
          role,
          gender,
          start_age,
          end_age,
          include_deleted
        }),
        sorting_option: sorting_option
      }
    });
    return response.data?.data[0];
  } catch (e) {
    return null;
  }
}
/**
 *
 * @param {string} staffName
 * @param {string} emailAddress
 * @param {string} phoneNumber
 * @param {string} password
 * @param {string} roleId
 * @param {string} dob
 * @param {string} gender
 * @param {string} experience
 * @returns {Promise<Object>}
 */
export async function addNewStaff(data) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.post('/user', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @param {String} staffId
 * @returns
 */
export async function getStaffInfo(staffId) {
  try {
    const response = await sendApiRequest.get(`/user/${staffId}`);
    return response.data?.data;
  } catch (e) {
    return null;
  }
}

/**
 *
 * @param {string} staffName
 * @param {string} emailAddress
 * @param {string} phoneNumber
 * @param {string} roleId
 * @param {string} dob
 * @param {string} gender
 * @param {string} experience
 * @returns {Promise<Object>}
 */
export async function updateStaff(staffId, data) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.put(`/user/${staffId}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @returns
 */
export async function getUsersList() {
  try {
    const response = await sendApiRequest.get(`/user/all`);
    return response.data?.data;
  } catch (e) {
    return null;
  }
}

/**
 *
 * @param {String} userId
 * @returns
 */
export async function deleteRestoreStaff(userId, type) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.delete(`/user/${userId}`, {
      params: {
        type: type
      }
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}
