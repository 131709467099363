import React from 'react';
import { Row, Col, Container } from 'reactstrap';

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid>
          <Row>
            <Col sm={6}>{new Date().getFullYear()} © Raising Right.</Col>
            <Col sm={6}>
              <div className="text-sm-right d-none d-sm-block">
                Powered by{' '}
                <a
                  href="https://thehelioweb.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  HelioWeb
                </a>
              </div>{' '}
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
