import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

// Carousel images
import img1 from '../../assets/images/execution_absence.jpeg';
import { fetchImage } from '../../api/image';
import PageDataLoader from '../Utility/PageDataLoader';
import DeleteImageModal from './DeleteImageModal';
import { validatePermissionKey } from '../../helpers/permission';
import { UPDATE_CHILD } from '../Utility/PermissionKeys';

const ThumbnailGallery = ({
  images,
  canDelete,
  childId,
  fetchChildInfo,
  indexOfFirstRecord,
  gallery,
  authUser
}) => {
  const [deleteImageModal, setDeleteImageModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isGalleryZoom, setIsGalleryZoom] = useState(false);
  const [imagesLoading, setImageLoading] = useState(true);
  const [items, setItems] = useState([
    {
      src: img1,
      altText: 'Slide 1',
      caption: 'Slide 1'
    }
  ]);

  const fetchUrls = async () => {
    setImageLoading(true);
    if (images.length > 0) {
      let data = images.map(async (i) => {
        let imgUrl = await fetchImage(i.documentKey);
        if (imgUrl !== null) {
          return {
            src: URL.createObjectURL(imgUrl),
            altText: 'Execution img',
            caption: 'Execution img'
          };
        }
        else {
          return {}
        }

      });
      let resp = await Promise.all(data);
      var newRes = resp.filter(value => Object.keys(value).length !== 0);
      setItems(newRes);
    }
    setImageLoading(false);
  };

  function openDeleteImageModal() {
    setDeleteImageModal(true);
  }

  function closeDeleteImageModal() {
    setDeleteImageModal(false);
  }

  useEffect(() => {
    fetchUrls();
    // eslint-disable-next-line
  }, [images]);

  const ImagesLightbox = () => {
    return (
      <>
        {isGalleryZoom && (
          <Lightbox
            mainSrc={items[photoIndex].src}
            nextSrc={items[(photoIndex + 1) % items.length].src}
            prevSrc={items[(photoIndex + items.length - 1) % items.length].src}
            onCloseRequest={() => setIsGalleryZoom(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + items.length - 1) % items.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % items.length)
            }
          />
        )}
      </>
    );
  };

  const slides = items.map((item, index) => {
    if (item !== undefined) {
      return (
        <Col md="4" className="mb-2">
          <div className="zoom-gallery thumbnail">
            <img
              src={item.src}
              alt={item.altText}
              style={{
                width: '100%',
                height: 250
              }}
              onClick={() => {
                setIsGalleryZoom(true);
                setPhotoIndex(index);
              }}
            />
            {validatePermissionKey(authUser, UPDATE_CHILD) && canDelete && (
              <Button
                size="sm"
                color="danger"
                className="mt-1 waves-effect waves-light deleteBtn"
                onClick={() => {
                  setActiveIndex(index);
                  openDeleteImageModal();
                }}
              >
                <i className="ri-delete-bin-6-line align-middle"></i>
              </Button>
            )}
          </div>
        </Col>
      );
    }

  });

  return (
    <React.Fragment>
      {imagesLoading ? (
        <div className="text-center">
          <PageDataLoader />
        </div>
      ) : (
        <>
          <DeleteImageModal
            images={gallery}
            closeModalHandler={closeDeleteImageModal}
            modalOpenState={deleteImageModal}
            childId={childId}
            fetchChildInfo={fetchChildInfo}
            activeIndex={indexOfFirstRecord + activeIndex}
          />
          <ImagesLightbox />
          <Row>{slides}</Row>
        </>
      )}
    </React.Fragment>
  );
};

export default ThumbnailGallery;
