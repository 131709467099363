/*jshint esversion: 11 */
import sendApiRequest from '../helpers/axios-setup';
import { stringify as QStringify } from 'qs';

/**
 *
 * @returns {Promise}
 */
export async function getBatchList(
  page,
  limit,
  {
    start_date = '',
    end_date = '',
    name = '',
    offering = '',
    schedule = '',
    start_time = '',
    end_time = '',
    include_deleted = ''
  } = {},
  sorting_option = ''
) {
  try {
    const response = await sendApiRequest.get('/batch', {
      params: {
        page: page,
        limit: limit,
        filter_query: QStringify({
          start_date,
          end_date,
          name,
          offering,
          schedule,
          start_time,
          end_time,
          include_deleted
        }),
        sorting_option: sorting_option
      }
    });
    return response.data?.data[0];
  } catch (e) {
    return null;
  }
}

/**
 *
 * @param {string} name
 * @param {string} summary
 * @param {string} offering
 * @param {date} start_date
 * @param {date} end_date
 * @param {time} start_time
 * @param {time} end_time
 * @param {string} schedule
 * @param {string} primary_teacher
 * @param {string} secondary_teacher
 * @returns {Promise<Object>}
 */
export async function addNewBatch(data) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.post('/batch', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @param {string} batch_name
 * @param {date} start_date
 * @param {date} end_date
 * @param {time} start_time
 * @param {time} end_time
 * @param {string} offset_days
 * @returns {Promise<Object>}
 */
export async function cloneBatch(data, batchId) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.post(`/batch/clone/${batchId}`, data);
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @param {String} batchId
 * @returns
 */
export async function getBatchInfo(batchId) {
  try {
    const response = await sendApiRequest.get(`/batch/${batchId}`);
    return response.data?.data;
  } catch (e) {
    return null;
  }
}

/**
 * @param {string} name
 * @param {string} summary
 * @param {string} offering
 * @param {date} start_date
 * @param {date} end_date
 * @param {time} start_time
 * @param {time} end_time
 * @param {string} schedule
 * @param {string} primary_teacher
 * @param {string} secondary_teacher
 * @returns {Promise<Object>}
 */
export async function updateBatch(batchId, data) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.put(`/batch/${batchId}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 * @param {string} name
 * @param {array} children
 * @returns {Promise<Object>}
 */
export async function assignChildren(batchId, children) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.put(`/batch/children/${batchId}`, {
      children
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 * @returns {Promise<Object>}
 */
export async function assignActivities(batchId, date, activities, type) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.put(`/batch/activity/${batchId}`, {
      date,
      activities,
      type
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 * @returns {Promise<Object>}
 */
export async function removeActivities(batchId) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.delete(`/batch/activity/${batchId}`);
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @returns {Promise}
 */
export async function getBatchChildrenList(batch_id, page, limit) {
  try {
    const response = await sendApiRequest.get(`/batch/children/${batch_id}`, {
      params: {
        page: page,
        limit: limit
      }
    });
    return response.data?.data[0];
  } catch (e) {
    return null;
  }
}

/**
 *
 * @returns {Promise}
 */
export async function getBatchActivityList(
  batch_id,
  date,
  name,
  page,
  limit,
  from_date,
  to_date
) {
  try {
    const response = await sendApiRequest.get(`/batch/activity/${batch_id}`, {
      params: {
        date: date,
        name: name,
        page: page,
        limit: limit,
        from_date: from_date,
        to_date: to_date
      }
    });
    return response.data?.data[0];
  } catch (e) {
    return null;
  }
}

/**
 *
 * @returns {Promise}
 */
export async function getBatchExecutionList(batch_id, page, limit) {
  try {
    const response = await sendApiRequest.get(`/batch/execution/${batch_id}`, {
      params: {
        page: page,
        limit: limit
      }
    });

    return response.data?.data[0];
  } catch (e) {
    return null;
  }
}

/**
 *
 * @returns {Promise}
 */
export async function getBatchExecutionChildrenList(batch, date, page, limit) {
  try {
    const response = await sendApiRequest.get(`/batch/batch-execution`, {
      params: {
        page: page,
        limit: limit,
        batch,
        date
      }
    });

    return response.data?.data[0];
  } catch (e) {
    return null;
  }
}

/**
 *
 * @returns {Promise}
 */
export async function getCalendarDetails(start_date, end_date) {
  try {
    const response = await sendApiRequest.get(`/batch/calendar`, {
      params: {
        start_date,
        end_date
      }
    });

    return response.data;
  } catch (e) {
    return null;
  }
}

/**
 *
 * @param {String} batchId
 * @returns
 */
export async function deleteRestoreBatch(batchId, type) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.delete(`/batch/${batchId}`, {
      params: {
        type: type
      }
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}
