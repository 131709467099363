/*jshint esversion: 11 */
import sendApiRequest from '../helpers/axios-setup';

/**
 *
 * @returns {Promise}
 */
export async function getTagList(
  page,
  limit,
  {
    filter_start_created_at = '',
    filter_end_created_at = '',
    filter_start_last_updated_at = '',
    filter_end_last_updated_at = '',
    name = '',
    include_deleted = ''
  } = {},
  sorting_option = ''
) {
  try {
    const response = await sendApiRequest.get('/tag', {
      params: {
        page: page,
        limit: limit,
        filter_start_created_at,
        filter_end_created_at,
        filter_start_last_updated_at,
        filter_end_last_updated_at,
        filter_name: name,
        include_deleted,
        sorting_option
      }
    });
    return response.data?.data[0];
  } catch (e) {
    return null;
  }
}

/**
 *
 * @param {string} name
 * @returns {Promise<Object>}
 */
export async function addNewTag(name) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.post('/tag', {
      name
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @param {String} tagId
 * @returns
 */
export async function getTagInfo(tagId) {
  try {
    const response = await sendApiRequest.get(`/tag/${tagId}`);
    return response.data?.data;
  } catch (e) {
    return null;
  }
}

/**
 *
 * @param {string} name
 * @returns {Promise<Object>}
 */
export async function updateTag(tagId, name) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.put(`/tag/${tagId}`, {
      name
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @param {String} tagId
 * @returns
 */
export async function deleteRestoreTag(tagId, type) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.delete(`/tag/${tagId}`, {
      params: {
        type: type
      }
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}
