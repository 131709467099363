import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const DeleteSectionModal = ({ isOpen, toggle, section, deleteSection}) => {
  const handleDelete = () => {
    deleteSection();
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{!section?.isDeleted?"Delete Section":"Restore Section"}</ModalHeader>
      <ModalBody>
        Are you sure you want to {section?.isDeleted?'restore':'delete'} the attribute "{section?.name}"?
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={handleDelete}>{!section?.isDeleted?'Delete':'Restore'}</Button>{' '}
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteSectionModal;