/*jshint esversion: 11 */
import sendApiRequest from '../helpers/axios-setup';

/**
 *
 * @returns {Promise}
 */
export async function getExecutionList(
  child,
  page,
  limit,
  include_deleted = '',
  filter_batch = '',
  filter_start_date = '',
  filter_end_date = '',
  sorting_option = ''
) {
  try {
    const response = await sendApiRequest.get('/execution', {
      params: {
        child: child,
        page: page,
        limit: limit,
        include_deleted,
        filter_batch,
        filter_start_date,
        filter_end_date,
        sorting_option
      }
    });
    return response.data?.data[0];
  } catch (e) {
    return null;
  }
}

/**
 *
 * @param {string} activity
 * @param {string} child
 * @param {string} note
 * @param {boolean} isPresent
 * @returns {Promise<Object>}
 */
export async function addNewExecution(data) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.post('/execution', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @param {string} activity
 * @param {string} child
 * @param {string} note
 * @param {boolean} isPresent
 * @returns {Promise<Object>}
 */
export async function addNewBatchExecution(data) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.post('/execution/batch', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @param {String} executionId
 * @returns
 */
export async function getExecutionInfo(executionId) {
  try {
    const response = await sendApiRequest.get(`/execution/${executionId}`);
    return response.data?.data;
  } catch (e) {
    return null;
  }
}

/**
 *
 * @param {string} note
 * @param {boolean} isPresent
 * @returns {Promise<Object>}
 */
export async function updateExecution(executionId, data) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.put(
      `/execution/${executionId}`,
      data,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    );
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @param {string} note
 * @param {boolean} isPresent
 * @returns {Promise<Object>}
 */
export async function updateBatchExecution(executionId, data) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.put(
      `/execution/batch/${executionId}`,
      data,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    );
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @param {String} executionId
 * @returns
 */
export async function deleteRestoreExecution(executionId, type) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.delete(`/execution/${executionId}`, {
      params: {
        type: type
      }
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}
