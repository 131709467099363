/*jshint esversion: 11 */
import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Input
} from 'reactstrap';
import Select from 'react-select';
import { connect } from 'react-redux';
import PageDataLoader from '../Utility/PageDataLoader';

const ViewTodoModal = ({
  modalOpenState,
  closeModalHandler,
  feedbackInfoLoading,
  feedbackInfo,
  feedbackType
}) => {
  const [selectedReference, setSelectedReference] = useState(null);

  useEffect(() => {
    if (feedbackInfo) {
      setSelectedReference(buildOptions(feedbackInfo?.reference_id || []));
    }
  }, [modalOpenState, feedbackInfo]);

  function buildOptions(list) {
    return list.map((item) => ({
      value: item._id,
      label: item.name
    }));
  }
  return (
    <React.Fragment>
      <Modal isOpen={modalOpenState} backdrop="static" size="lg">
        <ModalHeader toggle={closeModalHandler}>
          {feedbackInfoLoading ? 'Loading ...' : feedbackInfo.name}
        </ModalHeader>
        <ModalBody>
          {feedbackInfoLoading ? (
            <div className="d-flex justify-content-center">
              <PageDataLoader />
            </div>
          ) : (
            <React.Fragment>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label>Name</Label>
                    <Input
                      name="name"
                      type="text"
                      className="form-control"
                      defaultValue={feedbackInfo?.name}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label>{feedbackType}</Label>
                    <Select
                      isDisabled={true}
                      isMulti
                      defaultValue={selectedReference}
                    />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label>Questions</Label>
                    {feedbackInfo.questions &&
                      feedbackInfo.questions.map((element, index) => (
                        <Input
                          name="question"
                          type="text"
                          className="form-control mb-3"
                          value={element}
                          readOnly
                        />
                      ))}
                  </FormGroup>
                </Col>
              </Row>
              <ModalFooter>
                <Button type="button" color="light" onClick={closeModalHandler}>
                  Close
                </Button>
              </ModalFooter>
            </React.Fragment>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(ViewTodoModal);