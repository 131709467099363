/*jshint esversion: 11 */
import React, { useState } from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Input,
  Collapse
} from 'reactstrap';
import { connect } from 'react-redux';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { addCommentExecution } from '../../api/feedback';
import moment from 'moment';

const ViewFeedbackModal = ({
  modalOpenState,
  closeModalHandler,
  feedbackInfo,
  executionId,
  fetchNewData
}) => {
  const [collapse, setCollapse] = useState([]);

  const toggleCollapse = (index) => {
    let collapseCopy = [...collapse];
    collapseCopy[index] = !collapseCopy[index];
    setCollapse(collapseCopy);
  };
  const [comment, setComment] = useState('');
  const [commentRequestLoading, setCommentRequestLoading] = useState(false);
  const handleComment = async (responseId) => {
    setCommentRequestLoading(true);
    let { response } = await addCommentExecution(
      executionId,
      responseId,
      comment
    );
    if (response) {
      setComment('');
      fetchNewData();
      closeModalHandler();
    }
    setCommentRequestLoading(false);
  };
  return (
    <React.Fragment>
      <Modal isOpen={modalOpenState} backdrop="static" size="lg">
        <ModalHeader toggle={closeModalHandler}>
          View Feedback Response
        </ModalHeader>
        <ModalBody>
          <React.Fragment>
            {feedbackInfo && (
              <Row className="mb-1">
                <Col className="d-flex justify-content-end align-items-center">
                  <h5>
                    {moment(new Date(feedbackInfo.updatedAt)).format(
                      'DD-MM-YYYY'
                    )}
                  </h5>
                </Col>
              </Row>
            )}
            <Row>
              {feedbackInfo &&
                feedbackInfo.feedback_response.map((f, index) => (
                  <Col md="12">
                    <FormGroup>
                      <Label>{f.question}</Label>
                      <Input
                        type="text"
                        className="form-control"
                        defaultValue={f.answer}
                        readOnly
                      />
                    </FormGroup>
                    {f.comment ? (
                      <>
                        <h6 className="mb-0" style={{ color: '#ed8f8f' }}>
                          Comment By Teacher
                        </h6>
                        <p>{f.comment}</p>
                      </>
                    ) : (
                      <>
                        {f.answer && (
                          <>
                            <Button
                              type="button"
                              color="primary"
                              size="sm"
                              className="mb-1"
                              onClick={() => toggleCollapse(f._id)}
                            >
                              <i className="ri-add-line align-middle mr-2"></i>
                              Comment
                            </Button>
                            <Collapse isOpen={collapse[f._id]} key={f._id}>
                              <AvForm
                                className="needs-validation"
                                onValidSubmit={() => handleComment(f._id)}
                              >
                                <Row>
                                  <Col md="12">
                                    <FormGroup>
                                      <Label>Comment</Label>
                                      <AvField
                                        name="comment"
                                        placeholder="Enter Comment ..."
                                        type="text"
                                        className="form-control"
                                        onChange={(e) =>
                                          setComment(e.target.value)
                                        }
                                        validate={{
                                          required: {
                                            value: true,
                                            errorMessage: 'Comment is required'
                                          }
                                        }}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="12">
                                    <Button
                                      id={index}
                                      type="submit"
                                      color="success"
                                      size="sm"
                                    >
                                      {commentRequestLoading
                                        ? 'Adding...'
                                        : 'Submit'}
                                    </Button>
                                  </Col>
                                </Row>
                              </AvForm>
                            </Collapse>
                          </>
                        )}
                      </>
                    )}
                    <hr />
                  </Col>
                ))}
            </Row>
            <ModalFooter>
              <Button type="button" color="light" onClick={closeModalHandler}>
                Close
              </Button>
            </ModalFooter>
          </React.Fragment>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(ViewFeedbackModal);
