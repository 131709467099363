import React, { useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { removeBatchImage } from '../../api/batchImage';

const DeleteImageModal = ({
  modalOpenState,
  closeModalHandler,
  batch,
  date,
  images,
  fetchNewData,
  activeIndex
}) => {
  const [successAlert, setSuccessAlert] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const deleteImage = async () => {
    setRequestLoading(true);
    let imageId = images[activeIndex]._id;
    const response = await removeBatchImage(batch, date, imageId);
    if (response) {
      setRequestLoading(false);
      setSuccessAlert('Image deleted successfully');
      closeModalHandler();
      fetchNewData();
    }
  };
  return (
    <React.Fragment>
      {successAlert && (
        <SweetAlert
          success
          title="Deleted"
          onConfirm={() => setSuccessAlert(false)}
        >
          {successAlert}
        </SweetAlert>
      )}

      <Modal isOpen={modalOpenState} backdrop="static" size="md">
        <ModalHeader toggle={closeModalHandler}></ModalHeader>
        <ModalBody>
          <h4>Are you sure you want to delete this image?</h4>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={requestLoading}
            type="button"
            color="light"
            onClick={closeModalHandler}
          >
            Close
          </Button>
          <Button type="submit" color="danger" onClick={() => deleteImage()}>
            {requestLoading ? 'Deleting ...' : 'Delete'}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default DeleteImageModal;
