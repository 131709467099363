import React, { Fragment } from 'react';
import Loader from 'react-loader-spinner';

function PageDataLoader(props) {
  return (
    <Fragment>
      <Loader
        type="TailSpin"
        color="#5664d2"
        height={props.height || 80}
        width={props.width || 80}
      />
    </Fragment>
  );
}

export default PageDataLoader;
