const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;

export const isValidYouTubeLink = (url) => {
  return youtubeRegex.test(url);
};

export const extractYouTubeVideoId = (url) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

const regexLink = /\.(mp4|mov|mkv)$/;
export const isVideoExtension=(link) =>{
  return regexLink.test(link)
  ;}