/*jshint esversion: 11 */
import sendApiRequest from '../helpers/axios-setup';

/**
 *
 * @param {string} feedback_type
 * @param {string} reference_id
 * @param {array} questions
 * @returns {Promise<Object>}
 */
export async function addNewFeedback(
  feedback_type,
  name,
  reference_id,
  questions
) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.post('/feedback', {
      feedback_type,
      name,
      reference_id,
      questions
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @param {String} referenceId
 * @returns
 */
export async function getFeedbackInfo(referenceId) {
  try {
    const response = await sendApiRequest.get(`/feedback/${referenceId}`);
    return response.data?.data;
  } catch (e) {
    return null;
  }
}

/**
 *
 * @param {array} questions
 * @returns {Promise<Object>}
 */
export async function updateFeedback(
  feedbackId,
  feedback_type,
  name,
  reference_id,
  questions
) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.put(
      `/feedback/reference/${feedbackId}`,
      {
        feedback_type,
        name,
        reference_id,
        questions
      }
    );
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @returns {Promise}
 */
export async function getFeedbackResponseList(child, page, limit) {
  try {
    const response = await sendApiRequest.get('/feedback/response', {
      params: {
        child: child,
        page: page,
        limit: limit
      }
    });
    return response.data?.data[0];
  } catch (e) {
    return null;
  }
}

/**
 *
 * @returns {Promise}
 */
export async function addCommentExecution(execution_id, response_id, comment) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.put(
      `/execution/feedback/${execution_id}/${response_id}`,
      {
        comment
      }
    );
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @returns {Promise}
 */
export async function addCommentGeneral(feedback_id, response_id, comment) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.put(
      `/feedback/response/${feedback_id}/${response_id}`,
      {
        comment
      }
    );
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @returns {Promise}
 */
export async function getFeedbackList(
  page,
  limit,
  type,
  {
    filter_name = '',
    filter_reference = '',
    filter_start_date = '',
    filter_end_date = '',
    include_deleted = ''
  } = {}
) {
  try {
    const response = await sendApiRequest.get('/feedback', {
      params: {
        page: page,
        limit: limit,
        type,
        filter_name,
        filter_reference,
        filter_start_date,
        filter_end_date,
        include_deleted
      }
    });
    return response.data?.data[0];
  } catch (e) {
    return null;
  }
}

/**
 *
 * @returns {Promise}
 */
export async function getReferenceList(type) {
  try {
    const response = await sendApiRequest.get('/feedback/reference/options', {
      params: {
        type
      }
    });

    return response.data;
  } catch (e) {
    return null;
  }
}

/**
 *
 * @param {String} feedbackId
 * @returns
 */
export async function deleteRestoreFeedback(feedbackId, type) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.delete(`/feedback/${feedbackId}`, {
      params: {
        type: type
      }
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}
