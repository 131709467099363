/*jshint esversion: 11 */
import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Table,
  Label,
  FormGroup,
  Button,
  Badge
} from 'reactstrap';
import { connect } from 'react-redux';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import PageDataLoader from '../Utility/PageDataLoader';
import Paginator from '../Utility/Paginator';
import { getRoleList, getRoleInfo } from '../../api/role';
import moment from 'moment';
import _ from 'lodash';
import CreateRoleModal from './CreateRoleModal';
import ViewRoleModal from './ViewRoleModal';
import UpdateRoleModal from './UpdateRoleModal';
import RoleListFilterModal from './RoleListFilterModal';
import { validatePermissionKey } from '../../helpers/permission';
import { CREATE_ROLE, DELETE_ROLE, EDIT_ROLE } from '../Utility/PermissionKeys';
import { stringify as QStringify, parse as QParse } from 'qs';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { getStaffList } from '../../api/staff';
import validator from 'validator';
import DeleteRoleModal from './DeleteRoleModal';

const RoleListPage = ({ authUser }) => {
  const history = useHistory();

  const [breadcrumbItems] = useState([
    { title: 'Roles', link: '#' },
    { title: 'View List', link: '#' }
  ]);
  const [roleListLoading, setRoleListLoading] = useState(true);
  const [roleList, setRoleList] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageLimit: 10,
    totalEntries: 0,
    totalPages: 0
  });
  const [createRoleModalOpen, setCreateRoleModalOpen] = useState(false);
  const [roleInfo, setRoleInfo] = useState({});
  const [roleInfoLoading, setRoleInfoLoading] = useState(false);
  const [roleInfoModalOpen, setRoleInfoModalOpen] = useState(false);
  const [updateRoleModalOpen, setUpdateRoleModalOpen] = useState(false);
  const [deleteRoleModalOpen, setDeleteRoleModalOpen] = useState(false);
  const [roleId, setRoleId] = useState('');
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [currentFilterCount, setCurrentFilterCount] = useState(0);
  const [sortingRule, setSortingRule] = useState('role_name$asc');
  const [filterRoleName, setFilterRoleName] = useState('');
  const [filterError, setFilterError] = useState(false);
  const [createdAtFilter, setCreatedAtFilter] = useState({
    startDate: null,
    endDate: null
  });
  const [updatedAtFilter, setUpdatedAtFilter] = useState({
    startDate: null,
    endDate: null
  });
  const [createdByQueryId, setCreatedByQueryId] = useState([]);
  const [updatedByQueryId, setUpdatedByQueryId] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [selectedCreatedByOption, setSelectedCreatedByOption] = useState([]);
  const [selectedUpdatedByOption, setSelectedUpdatedByOption] = useState([]);
  const [isDeleted, setIsDeleted] = useState(false);
  const staffOptions = useMemo(
    () => buildStaffOptions(staffList),
    // eslint-disable-next-line
    [staffList]
  );
  let listElem = document.getElementById('roleList');
  let linkElem = document.getElementById('roleLink');

  useEffect(() => {
    if (listElem) listElem.classList.add('mm-active');
    if (linkElem) linkElem.classList.add('active');
    if (authUser) {
      fetchStaffList();
      populateSearchOptions(history?.location?.search);
    }
    // eslint-disable-next-line
  }, [authUser, listElem, linkElem]);

  const handlePagination = (page) => {
    fetchData(page, pagination.pageLimit, createQueryOptions(), sortingRule);
  };

  const fetchData = async (
    current_page = 1,
    page_limit = 10,
    queryOptions = {},
    sortingOption = sortingRule
  ) => {
    setRoleListLoading(true);
    const response = await getRoleList(
      current_page,
      page_limit,
      queryOptions,
      sortingOption
    );

    if (response) {
      setPagination({
        currentPage: response.metadata.page,
        pageLimit: response.metadata.limit,
        totalPages: response.metadata.total_pages,
        totalEntries: response.metadata.total_results
      });
      setRoleList(response.data);
      setRoleListLoading(false);
    }
  };

  async function fetchStaffList() {
    const response = await getStaffList(1, 100);
    if (response) {
      setStaffList(response.data);
    }
  }

  /**
   *
   * @param {array<object>} staffArray
   * @returns
   */
  function buildStaffOptions(staffArray) {
    const staffOptions = staffArray.map((i) => {
      return {
        label: `${i.name} - ${i.role?.role_name}`,
        value: i
      };
    });
    const createdByStaff = staffOptions.filter((i) =>
      createdByQueryId.includes(i.value._id)
    );
    const updatedByStaff = staffOptions.filter((i) =>
      updatedByQueryId.includes(i.value._id)
    );
    setSelectedCreatedByOption(createdByStaff);
    setSelectedUpdatedByOption(updatedByStaff);
    return staffOptions;
  }

  

  /**
   *
   * @param {string} str
   */
  function getValidDateInput(str) {
    return validator.isDate(str) ? new Date(str).toISOString() : '';
  }

  function createQueryOptions(
    startCreate = createdAtFilter.startDate,
    endCreate = createdAtFilter.endDate,
    startUpdate = updatedAtFilter.startDate,
    endUpdate = updatedAtFilter.endDate,
    createdFilter = selectedCreatedByOption,
    updatedFilter = selectedUpdatedByOption,
    roleName = filterRoleName,
    isDeletedFilter = isDeleted
  ) {
    setFilterError(false);
    if ((startCreate && !endCreate) || (!startCreate && endCreate)) {
      setFilterError('Enter valid date range');
      return;
    }
    if ((startUpdate && !endUpdate) || (!startUpdate && endUpdate)) {
      setFilterError('Enter valid date range');
      return;
    }
    const startDateISOStringCreatedAt = getValidDateInput(startCreate);
    const endDateISOStringCreatedAt = getValidDateInput(endCreate);
    const startDateISOStringUpdatedAt = getValidDateInput(startUpdate);
    const endDateISOStringUpdatedAt = getValidDateInput(endUpdate);
    const createdBy = Array.isArray(createdFilter)
      ? createdFilter.map((i) => i.value._id).join(',')
      : '';
    const updatedBy = Array.isArray(updatedFilter)
      ? updatedFilter.map((i) => i.value._id).join(',')
      : '';
    const queryOptions = {
      filter_start_created_at: startDateISOStringCreatedAt,
      filter_end_created_at: endDateISOStringCreatedAt,
      filter_start_last_updated_at: startDateISOStringUpdatedAt,
      filter_end_last_updated_at: endDateISOStringUpdatedAt,
      filter_created_by: createdBy,
      filter_last_updated_by: updatedBy,
      role_name: roleName,
      include_deleted: isDeletedFilter,
      sorting_option: sortingRule
    };
    updateUrlPath(queryOptions);
    updateFilterCount(queryOptions);
    return queryOptions;
  }

  function updateFilterCount(option) {
    let count = 0;
    Object.keys(option).forEach((i) => {
      if (option[i]?.toString()?.trim()?.length > 0 && option[i] !== false) {
        count += 1;
      }
    });
    setCurrentFilterCount(count - 1);
  }

  async function fetchSortedList(sortingOption) {
    setSortingRule(sortingOption);
    const queryOptions = createQueryOptions();
    queryOptions.sorting_option = sortingOption;
    updateUrlPath(queryOptions);
    fetchData(1, null, queryOptions, sortingOption);
  }

  function updateUrlPath(queryOptions) {
    const queryString = QStringify(queryOptions);
    history.replace(`/roles?${queryString}`);
  }

  /**
   *
   * @param {string} searchQuery
   */
  function populateSearchOptions(searchQuery) {
    const parsedQuery = QParse(searchQuery.substring(1));
    if (
      parsedQuery.filter_start_created_at &&
      parsedQuery.filter_end_created_at
    ) {
      updateDateFilterInput(
        parsedQuery.filter_start_created_at,
        parsedQuery.filter_end_created_at,
        setCreatedAtFilter
      );
    }
    if (
      parsedQuery.filter_start_last_updated_at &&
      parsedQuery.filter_end_last_updated_at
    ) {
      updateDateFilterInput(
        parsedQuery.filter_start_last_updated_at,
        parsedQuery.filter_end_last_updated_at,
        setUpdatedAtFilter
      );
    }
    if (parsedQuery.filter_created_by) {
      const userId = parsedQuery.filter_created_by
        .split(',')
        .map((i) => i.trim());
      setCreatedByQueryId(userId);
    }
    if (parsedQuery.filter_last_updated_by) {
      const userId = parsedQuery.filter_last_updated_by
        .split(',')
        .map((i) => i.trim());
      setUpdatedByQueryId(userId);
    }
    const sortingOption = parsedQuery.sorting_option || sortingRule;
    setSortingRule(sortingOption);
    setFilterRoleName(parsedQuery.role_name);
    // setIsDeleted(parsedQuery.include_deleted);
    updateFilterCount(parsedQuery);
    fetchData(1, null, parsedQuery, sortingOption);
  }

  /**
   *
   * @param {string} str
   * @param {function} setHook
   * @returns
   */
  function updateDateFilterInput(startStr, endStr, setHook) {
    setHook({
      startDate: new Date(startStr),
      endDate: new Date(endStr)
    });
  }

  function openFilterModal() {
    setFilterModalOpen(true);
  }

  function closeFilterModal() {
    setFilterModalOpen(false);
  }

  function openCreateRoleModal() {
    setCreateRoleModalOpen(true);
  }

  function closeCreateRoleModal() {
    setCreateRoleModalOpen(false);
  }

  function openRoleInfoModal(roleId) {
    fetchRoleInfo(roleId);
    setRoleInfoModalOpen(true);
  }

  function closeRoleInfoModal() {
    setRoleInfoModalOpen(false);
  }

  async function openUpdateRoleModal(roleId) {
    fetchRoleInfo(roleId);
    setUpdateRoleModalOpen(true);
  }

  function closeUpdateRoleModal() {
    setUpdateRoleModalOpen(false);
  }

  async function fetchRoleInfo(roleId) {
    setRoleInfoLoading(true);
    const response = await getRoleInfo(roleId);
    if (response) {
      setRoleInfo(response);
      setRoleInfoLoading(false);
    }
  }

  async function openDeleteRoleModal(roleId, requestType) {
    setRoleId({
      roleId,
      requestType
    });
    setDeleteRoleModalOpen(true);
  }

  function closeDeleteRoleModal() {
    setDeleteRoleModalOpen(false);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <RoleListFilterModal
          setFilterError={setFilterError}
          filterError={filterError}
          modalOpenState={filterModalOpen}
          closeModalHandler={closeFilterModal}
          fetchNewRoleList={fetchData}
          updateFilterCount={updateFilterCount}
          updateUrlPath={updateUrlPath}
          roleName={filterRoleName}
          setRoleName={setFilterRoleName}
          createdAtFilter={createdAtFilter}
          setCreatedAtFilter={setCreatedAtFilter}
          updatedAtFilter={updatedAtFilter}
          setUpdatedFilter={setUpdatedAtFilter}
          staffOptions={staffOptions}
          selectedCreatedByOption={selectedCreatedByOption}
          selectedUpdatedByOption={selectedUpdatedByOption}
          setSelectedCreatedByOption={setSelectedCreatedByOption}
          setSelectedUpdatedByOption={setSelectedUpdatedByOption}
          createQueryOptions={createQueryOptions}
          isDeleted={isDeleted}
          setIsDeleted={setIsDeleted}
          authUser={authUser}
        />
        <CreateRoleModal
          modalOpenState={createRoleModalOpen}
          closeModalHandler={closeCreateRoleModal}
          fetchNewRoleList={async () => {
            let options = await createQueryOptions();
            if (options) {
              await fetchData(1, null, options, sortingRule);
            }
          }}
        />
        <ViewRoleModal
          modalOpenState={roleInfoModalOpen}
          closeModalHandler={closeRoleInfoModal}
          roleInfo={roleInfo}
          roleInfoLoading={roleInfoLoading}
        />
        <UpdateRoleModal
          modalOpenState={updateRoleModalOpen}
          closeModalHandler={closeUpdateRoleModal}
          roleInfo={roleInfo}
          roleInfoLoading={roleInfoLoading}
          fetchNewRoleList={async () => {
            let options = await createQueryOptions();
            if (options) {
              await fetchData(1, null, options, sortingRule);
            }
          }}
        />
        {roleId && (
          <DeleteRoleModal
            modalOpenState={deleteRoleModalOpen}
            closeModalHandler={closeDeleteRoleModal}
            roleId={roleId.roleId}
            type={roleId.requestType}
            fetchNewData={async () => {
              let options = await createQueryOptions();
              if (options) {
                await fetchData(1, null, options, sortingRule);
              }
            }}
          />
        )}
        <Container fluid>
          <Breadcrumbs title="View Roles" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <>
                    <Row className="mt-2">
                      <Col md="2">
                        <FormGroup>
                          <Label className="col-form-label">Show Entries</Label>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              fetchData(
                                1,
                                e.target.value,
                                createQueryOptions(),
                                sortingRule
                              );
                              setPagination({
                                ...pagination,
                                pageLimit: e.target.value
                              });
                            }}
                            value={pagination.pageLimit}
                            disabled={roleListLoading}
                          >
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <Label className="col-form-label">Sort Role</Label>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              fetchSortedList(e.target.value);
                            }}
                            value={sortingRule}
                            disabled={roleListLoading}
                          >
                            <option value="role_name$asc">
                              Role Name (A-Z)
                            </option>
                            <option value="role_name$desc">
                              Role Name (Z-A)
                            </option>
                            <option value="created_at$desc">
                              Create Time (Newest First)
                            </option>
                            <option value="created_at$asc">
                              Create Time (Oldest First)
                            </option>
                            <option value="updated_at$desc">
                              Update Time (Newest First)
                            </option>
                            <option value="updated_at$asc">
                              Update Time (Oldest First)
                            </option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col className="d-flex justify-content-end align-items-center">
                        <Button
                          size="sm"
                          color="primary"
                          className=" waves-effect waves-light mr-3"
                          onClick={openFilterModal}
                        >
                          <i className="ri-filter-fill align-middle mr-2"></i>
                          <span>Filter Role</span>
                          {currentFilterCount > 0 && (
                            <Badge color="light" pill className="ml-2">
                              {currentFilterCount}
                            </Badge>
                          )}
                        </Button>
                        {validatePermissionKey(authUser, CREATE_ROLE) && (
                          <Button
                            size="sm"
                            color="success"
                            className=" waves-effect waves-light"
                            onClick={openCreateRoleModal}
                          >
                            <i className="ri-add-line align-middle mr-2"></i>
                            Create Role
                          </Button>
                        )}
                      </Col>
                    </Row>
                    {roleListLoading ? (
                      <div className="text-center">
                        <PageDataLoader />
                      </div>
                    ) : (
                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <Table
                            id="tech-companies-1"
                            striped
                            bordered
                            responsive
                          >
                            <thead>
                              <tr>
                                <th>SL. No</th>
                                <th>Role Name</th>
                                <th>Created By</th>
                                <th>Last Updated By</th>
                                <th>Created At</th>
                                <th>Last Updated At</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {roleList?.map((item, index) => (
                                <tr key={index}>
                                  <th>{index + 1}</th>
                                  <td>{item.role_name}</td>
                                  <td>
                                    {item.created_by?.name} [
                                    {_.upperCase(item.created_by?.user_type)}]
                                  </td>
                                  <td>
                                    {item.last_updated_by?.name} [
                                    {_.upperCase(
                                      item.last_updated_by?.user_type
                                    )}
                                    ]
                                  </td>
                                  <td>
                                    {moment(new Date(item.created_at)).format(
                                      'DD-MM-YYYY'
                                    )}
                                  </td>
                                  <td>
                                    {moment(new Date(item.updated_at)).format(
                                      'DD-MM-YYYY'
                                    )}
                                  </td>
                                  <td>
                                    <Button
                                      size="sm"
                                      color="primary"
                                      className=" waves-effect waves-light mr-2 mb-2"
                                      onClick={() => {
                                        openRoleInfoModal(item._id);
                                      }}
                                    >
                                      <i className="fas fa-eye mr-2"></i>
                                      View
                                    </Button>
                                    {validatePermissionKey(
                                      authUser,
                                      EDIT_ROLE
                                    ) && (
                                      <Button
                                        size="sm"
                                        color="warning"
                                        className=" waves-effect waves-light mr-2 mb-2"
                                        onClick={() => {
                                          openUpdateRoleModal(item._id);
                                        }}
                                      >
                                        <i className="fas fa-edit mr-2"></i>
                                        Edit
                                      </Button>
                                    )}
                                    {validatePermissionKey(
                                      authUser,
                                      DELETE_ROLE
                                    ) && (
                                      <Button
                                        size="sm"
                                        color="danger"
                                        className=" waves-effect waves-light mb-2"
                                        onClick={() => {
                                          openDeleteRoleModal(
                                            item._id,
                                            item.is_deleted
                                              ? 'restore'
                                              : 'delete'
                                          );
                                        }}
                                      >
                                        {item.is_deleted ? (
                                          <i className="fas fa-trash-restore mr-2"></i>
                                        ) : (
                                          <i className="fas fa-trash mr-2"></i>
                                        )}
                                        {item.is_deleted ? 'Restore' : 'Delete'}
                                      </Button>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    )}
                    <Row className="mt-2">
                      <Col md="5">
                        <p>
                          <strong>
                            {' '}
                            Showing{' '}
                            {(pagination.currentPage - 1) *
                              pagination.pageLimit +
                              1}{' '}
                            to{' '}
                            {Math.min(
                              pagination.currentPage * pagination.pageLimit,
                              pagination.totalEntries
                            )}{' '}
                            of {pagination.totalEntries} entries
                          </strong>
                        </p>
                      </Col>
                      {!roleListLoading && roleList.length > 0 && (
                        <Col md="7">
                          <Paginator
                            totalPages={pagination.totalPages}
                            currentPage={pagination.currentPage}
                            changePage={handlePagination}
                          />
                        </Col>
                      )}
                    </Row>
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(RoleListPage);
