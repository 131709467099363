/*jshint esversion: 11 */
import sendApiRequest from '../helpers/axios-setup';

/**
 *
 * @returns {Promise}
 */
export async function getTodoList(
  page,
  limit,
  filter,
  { assignedTo = '', endDate = '', startDate = '', status = '', isDeleted = '' }
) {
  try {
    const response = await sendApiRequest.get('/todo', {
      params: {
        page: page,
        limit: limit,
        filter: filter,
        filter_assigned_to_user_id: assignedTo,
        expected_start_date: startDate,
        expected_end_date: endDate,
        filter_status: status,
        filter_deleted: isDeleted
      }
    });
    return response.data?.data[0];
  } catch (e) {
    return null;
  }
}

/**
 *
 * @param {string} title
 * @param {string} detail
 * @param {string} assigned_by
 * @param {string} assigned_to
 * @param {date} expected_date
 * @returns {Promise<Object>}
 */
export async function addNewTodo(
  title,
  detail,
  assigned_by,
  assigned_by_role,
  assigned_to,
  assigned_to_role,
  expected_date
) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.post('/todo', {
      title,
      detail,
      assigned_by,
      assigned_by_role,
      assigned_to,
      assigned_to_role,
      expected_date
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @param {String} todoId
 * @returns
 */
export async function getTodoInfo(todoId) {
  try {
    const response = await sendApiRequest.get(`/todo/${todoId}`);
    return response.data?.data;
  } catch (e) {
    return null;
  }
}

/**
 *
 * @param {string} name
 * @param {string} summary
 * @param {string} detail
 * @returns {Promise<Object>}
 */
export async function updateTodo(
  todoId,
  title,
  detail,
  assigned_by,
  assigned_by_role,
  assigned_to,
  assigned_to_role,
  expected_date,
  status
) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.put(`/todo/${todoId}`, {
      title,
      detail,
      assigned_by,
      assigned_by_role,
      assigned_to,
      assigned_to_role,
      expected_date,
      status
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @param {String} todoId
 * @returns
 */
export async function deleteRestoreTask(todoId, type) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.delete(`/todo/${todoId}`, {
      params: {
        type: type
      }
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}
