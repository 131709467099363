/*jshint esversion: 11 */
import React, { Component } from 'react';
import MetisMenu from 'metismenujs';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader
} from '../../store/actions';
import {
  VIEW_ACTIVITIES,
  VIEW_ASSIGNMENTS,
  VIEW_BADGES,
  VIEW_BATCH,
  VIEW_BOOKS,
  VIEW_CHILDREN,
  VIEW_FEEDBACKS,
  VIEW_FORMS,
  VIEW_OFFERINGS,
  VIEW_PARENTS,
  VIEW_QUESTIONS,
  VIEW_ROLE,
  VIEW_ATTRIBUTES,
  VIEW_STAFF,
  VIEW_TAGS,
  VIEW_TOYS
} from '../../pages/Utility/PermissionKeys';
import { validatePermissionKey } from '../../helpers/permission';

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.type !== prevProps.type) {
        this.initMenu();
      }
    }
  }

  initMenu() {
    new MetisMenu('#side-menu');

    var matchingMenuItem = null;
    var ul = document.getElementById('side-menu');
    var items = ul.getElementsByTagName('a');
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add('active');
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show');

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add('mm-active');
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">

            <li id="homeList">
              <Link to="/dashboard" id="homeLink" className="waves-effect">
                <i className="ri-dashboard-line"></i>
                <span className="ml-1">Home</span>
              </Link>
            </li>
            <li id="todoList">
              <Link to="/todos" id="todoLink" className="waves-effect">
                <i className="ri-todo-fill "></i>
                <span className="ml-1">Todo</span>
              </Link>
            </li>
            {validatePermissionKey(this.props.authUser, VIEW_ROLE) && (
              <li id="roleList">
                <Link to="/roles" id="roleLink" className="waves-effect">
                  <i className="fas fa-user-shield"></i>
                  <span className="ml-1">Roles</span>
                </Link>
              </li>
            )}
            {validatePermissionKey(this.props.authUser, VIEW_STAFF) && (
              <li id="staffList">
                <Link to="/staff" id="staffLink" className="waves-effect">
                  <i className="ri-team-fill"></i>
                  <span className="ml-1">Staffs</span>
                </Link>
              </li>
            )}
            {validatePermissionKey(this.props.authUser, VIEW_OFFERINGS) && (
              <li id="offeringList">
                <Link
                  id="offeringLink"
                  to="/offerings"
                  className="waves-effect"
                >
                  <i className="ri-bubble-chart-fill "></i>
                  <span className="ml-1">Offerings</span>
                </Link>
              </li>
            )}

            {validatePermissionKey(this.props.authUser, VIEW_BATCH) && (
              <li id="batchList">
                <Link to="/batches" id="batchLink" className="waves-effect">
                  <i className="ri-hard-drive-fill "></i>
                  <span className="ml-1">Batch</span>
                </Link>
              </li>
            )}

            {validatePermissionKey(this.props.authUser, VIEW_PARENTS) && (
              <li id="parentList">
                <Link id="parentLink" to="/parents" className="waves-effect">
                  <i className="ri-parent-fill "></i>
                  <span className="ml-1">Parents</span>
                </Link>
              </li>
            )}

            {validatePermissionKey(this.props.authUser, VIEW_CHILDREN) && (
              <li id="childList">
                <Link to="/children" id="childLink" className="waves-effect">
                  <i className="ri-account-box-fill "></i>
                  <span className="ml-1">Children</span>
                </Link>
              </li>
            )}
            {validatePermissionKey(this.props.authUser, VIEW_ACTIVITIES) && (
              <li id="activityList">
                <Link
                  id="activityLink"
                  to="/activities"
                  className="waves-effect"
                >
                  <i className="ri-palette-fill "></i>
                  <span className="ml-1">Activities</span>
                </Link>
              </li>
            )}
            {validatePermissionKey(this.props.authUser, VIEW_ASSIGNMENTS) && (
              <li id="assignmentList">
                <Link
                  id="assignmentLink"
                  to="/assignments"
                  className="waves-effect"
                >
                  <i className="ri-survey-fill "></i>
                  <span className="ml-1">Assignments</span>
                </Link>
              </li>
            )}
            {validatePermissionKey(this.props.authUser, VIEW_TAGS) && (
              <li id="tagList">
                <Link id="tagLink" to="/tags" className="waves-effect">
                  <i className="ri-price-tag-3-fill  "></i>
                  <span className="ml-1">Tags</span>
                </Link>
              </li>
            )}

            {validatePermissionKey(this.props.authUser, VIEW_TOYS) && (
              <li id="toyList">
                <Link id="toyLink" to="/toys" className="waves-effect">
                  <i className="ri-mickey-fill "></i>
                  <span className="ml-1">Toys</span>
                </Link>
              </li>
            )}
            {validatePermissionKey(this.props.authUser, VIEW_BOOKS) && (
              <li id="bookList">
                <Link id="bookLink" to="/books" className="waves-effect">
                  <i className="ri-book-3-fill "></i>
                  <span className="ml-1">Books</span>
                </Link>
              </li>
            )}
            {validatePermissionKey(this.props.authUser, VIEW_FEEDBACKS) && (
              <li id="feedbackList">
                <Link
                  id="feedbackLink"
                  to="/feedbacks"
                  className="waves-effect"
                >
                  <i className="ri-numbers-fill "></i>
                  <span className="ml-1">Feedback</span>
                </Link>
              </li>
            )}
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="fas fa-award"></i>
                <span className="ml-1">Learner's Profile</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  {validatePermissionKey(this.props.authUser, VIEW_BADGES) && (
                    <Link id="badgeLink" to="/badges" className="waves-effect">
                      <span className="ml-1">Badges</span>
                    </Link>
                  )}
                </li>
                <li>
                  {validatePermissionKey(this.props.authUser, VIEW_QUESTIONS) && (
                    <Link
                      id="questionLink"
                      to="/questions"
                      className="waves-effect"
                    >
                      <span className="ml-1">Question</span>
                    </Link>
                  )}
                </li>
                <li>

                  {validatePermissionKey(this.props.authUser, VIEW_ATTRIBUTES) && (
                    <Link
                      id="sectionLink"
                      to="/section"
                      className="waves-effect"
                    >
                      <span className="ml-1">Attributes</span>
                    </Link>
                  )}
                </li>
                {/* <li>
                  {validatePermissionKey(this.props.authUser, VIEW_FORMS) && (
                    <Link
                      id="formLink"
                      to="/form"
                      className="waves-effect"
                    >
                      <span className="ml-1">Forms</span>
                    </Link>
                  )}
                </li> */}
              </ul>
            </li>



          </ul>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state.Layout, authUser: state.Login?.user };
};

export default withRouter(
  connect(mapStateToProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader
  })(SidebarContent)
);
