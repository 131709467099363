/*jshint esversion: 11 */
import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Table,
  Label,
  FormGroup,
  Button,
  Badge
} from 'reactstrap';
import { connect } from 'react-redux';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import PageDataLoader from '../Utility/PageDataLoader';
import Paginator from '../Utility/Paginator';
import { getAssignmentInfo, getAssignmentList } from '../../api/assignment';
import moment from 'moment';
import CreateAssignmentModal from './CreateAssignmentModal';
import ViewAssignmentModal from './ViewAssignmentModal';
import UpdateAssignmentModal from './UpdateAssignmentModal';
import { validatePermissionKey } from '../../helpers/permission';
import {
  CREATE_ASSIGNMENT,
  DELETE_ASSIGNMENT,
  UPDATE_ASSIGNMENT
} from '../Utility/PermissionKeys';
import validator from 'validator';
import { stringify as QStringify, parse as QParse } from 'qs';
import { useHistory } from 'react-router-dom';
import AssignmentFilterModal from './AssignmentFilterModal';
import DeleteAssignmentModal from './DeleteAssignmentModal';

const AssignmentListPage = ({ authUser }) => {
  const history = useHistory();

  const [breadcrumbItems] = useState([
    { title: 'Assignments', link: '#' },
    { title: 'View List', link: '#' }
  ]);
  const [assignmentListLoading, setAssignmentListLoading] = useState(true);
  const [assignmentList, setAssignmentList] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageLimit: 10,
    totalEntries: 0,
    totalPages: 0
  });
  const [filterError, setFilterError] = useState(false);
  const [addAssignmentModalOpen, setAddAssignmentModalOpen] = useState(false);
  const [assignmentInfo, setAssignmentInfo] = useState({});
  const [assignmentInfoLoading, setAssignmentInfoLoading] = useState(false);
  const [assignmentInfoModalOpen, setAssignmentInfoModalOpen] = useState(false);
  const [updateAssignmentModalOpen, setUpdateAssignmentModalOpen] =
    useState(false);
  const [sortingRule, setSortingRule] = useState('name$asc');
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [currentFilterCount, setCurrentFilterCount] = useState(0);
  const [dateFilter, setDateFilter] = useState({
    startDate: null,
    endDate: null
  });
  const [filterAssignmentName, setFilterAssignmentName] = useState('');
  const [deleteAssignmentModalOpen, setDeleteAssignmentModalOpen] =
    useState(false);
  const [assignmentId, setAssignmentId] = useState('');
  const [isDeleted, setIsDeleted] = useState(false);

  let listElem = document.getElementById('assignmentList');
  let linkElem = document.getElementById('assignmentLink');

  useEffect(() => {
    if (listElem) listElem.classList.add('mm-active');
    if (linkElem) linkElem.classList.add('active');
    if (authUser) {
      populateSearchOptions(history?.location?.search);
    }
    // eslint-disable-next-line
  }, [authUser, linkElem, listElem]);

  const handlePagination = (page) => {
    fetchData(page, pagination.pageLimit, createQueryOptions(), sortingRule);
  };

  const fetchData = async (
    current_page = 1,
    page_limit = 10,
    queryOptions = {},
    sortingOption = sortingRule
  ) => {
    setAssignmentListLoading(true);
    const response = await getAssignmentList(
      current_page,
      page_limit,
      queryOptions,
      sortingOption
    );

    if (response) {
      setPagination({
        currentPage: response.metadata.page,
        pageLimit: response.metadata.limit,
        totalPages: response.metadata.total_pages,
        totalEntries: response.metadata.total_results
      });
      setAssignmentList(response.data);
      setAssignmentListLoading(false);
    }
  };

  /**
   *
   * @param {string} str
   */
  function getValidDateInput(str) {
    return validator.isDate(str) ? new Date(str).toISOString() : '';
  }

  function createQueryOptions(
    dateStart = dateFilter.startDate,
    dateEnd = dateFilter.endDate,
    name = filterAssignmentName,
    deletedStatus = isDeleted
  ) {
    setFilterError(false);

    if ((dateStart && !dateEnd) || (dateEnd && !dateStart)) {
      setFilterError('Enter valid date range');
      return;
    }
    const startDateISOStringCreatedAt = getValidDateInput(dateStart);
    const endDateISOStringCreatedAt = getValidDateInput(dateEnd);
    const queryOptions = {
      filter_start_date: startDateISOStringCreatedAt,
      filter_end_date: endDateISOStringCreatedAt,
      name,
      include_deleted: deletedStatus,
      sorting_option: sortingRule
    };
    updateUrlPath(queryOptions);
    return queryOptions;
  }

  function updateFilterCount(option) {
    let count = 0;
    Object.keys(option).forEach((i) => {
      if (option[i]?.toString()?.trim()?.length > 0 && option[i] !== false) {
        count += 1;
      }
    });
    setCurrentFilterCount(count - 1);
  }

  /**
   *
   * @param {string} searchQuery
   */
  function populateSearchOptions(searchQuery) {
    const parsedQuery = QParse(searchQuery.substring(1));
    if (parsedQuery.filter_start_date && parsedQuery.filter_end_date) {
      updateDateFilterInput(
        parsedQuery.filter_start_date,
        parsedQuery.filter_end_date,
        setDateFilter
      );
    }
    setFilterAssignmentName(parsedQuery.name);
    const sortingOption = parsedQuery.sorting_option || sortingRule;
    setSortingRule(sortingOption);
    updateFilterCount(parsedQuery);
    fetchData(1, null, parsedQuery, sortingOption);
  }

  /**
   *
   * @param {string} str
   * @param {function} setHook
   * @returns
   */
  function updateDateFilterInput(startStr, endStr, setHook) {
    setHook({
      startDate: new Date(startStr),
      endDate: new Date(endStr)
    });
  }

  function updateUrlPath(queryOptions) {
    const queryString = QStringify(queryOptions);
    history.replace(`/assignments?${queryString}`);
  }

  async function fetchSortedList(sortingOption) {
    setSortingRule(sortingOption);
    const queryOptions = createQueryOptions();
    queryOptions.sorting_option = sortingOption;
    updateUrlPath(queryOptions);
    fetchData(1, null, queryOptions, sortingOption);
  }

  function openFilterModal() {
    setFilterModalOpen(true);
  }

  function closeFilterModal() {
    setFilterModalOpen(false);
  }

  function openAddAssignmentModal() {
    setAddAssignmentModalOpen(true);
  }

  function closeAddAssignmentModal() {
    setAddAssignmentModalOpen(false);
  }

  function openAssignmentInfoModal(assignmentId) {
    fetchAssignmentInfo(assignmentId);
    setAssignmentInfoModalOpen(true);
  }

  function closeAssignmentInfoModal() {
    setAssignmentInfoModalOpen(false);
  }

  async function openUpdateAssignmentModal(assignmentId) {
    fetchAssignmentInfo(assignmentId);
    setUpdateAssignmentModalOpen(true);
  }

  function closeUpdateAssignmentModal() {
    setUpdateAssignmentModalOpen(false);
  }

  async function openDeleteAssignmentModal(assignmentId, requestType) {
    setAssignmentId({
      assignmentId,
      requestType
    });
    setDeleteAssignmentModalOpen(true);
  }

  function closeDeleteAssignmentModal() {
    setDeleteAssignmentModalOpen(false);
  }

  async function fetchAssignmentInfo(assignmentId) {
    setAssignmentInfoLoading(true);
    const response = await getAssignmentInfo(assignmentId);
    if (response) {
      setAssignmentInfo(response);
      setAssignmentInfoLoading(false);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <AssignmentFilterModal
          filterError={filterError}
          setFilterError={setFilterError}
          modalOpenState={filterModalOpen}
          closeModalHandler={closeFilterModal}
          fetchList={fetchData}
          updateFilterCount={updateFilterCount}
          updateUrlPath={updateUrlPath}
          assignmentName={filterAssignmentName}
          setAssignmentName={setFilterAssignmentName}
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
          createQueryOptions={createQueryOptions}
          authUser={authUser}
          isDeleted={isDeleted}
          setIsDeleted={setIsDeleted}
        />
        <CreateAssignmentModal
          modalOpenState={addAssignmentModalOpen}
          closeModalHandler={closeAddAssignmentModal}
          fetchNewAssignmentList={async () => {
            let options = await createQueryOptions();
            if (options) {
              await fetchData(1, null, options);
            }
          }}
        />
        <ViewAssignmentModal
          modalOpenState={assignmentInfoModalOpen}
          closeModalHandler={closeAssignmentInfoModal}
          assignmentInfo={assignmentInfo}
          assignmentInfoLoading={assignmentInfoLoading}
        />
        <UpdateAssignmentModal
          modalOpenState={updateAssignmentModalOpen}
          closeModalHandler={closeUpdateAssignmentModal}
          assignmentInfo={assignmentInfo}
          assignmentInfoLoading={assignmentInfoLoading}
          fetchNewAssignmentList={async () => {
            let options = await createQueryOptions();
            if (options) {
              await fetchData(1, null, options);
            }
          }}
          authUser={authUser}
        />
        {assignmentId && (
          <DeleteAssignmentModal
            modalOpenState={deleteAssignmentModalOpen}
            closeModalHandler={closeDeleteAssignmentModal}
            assignmentId={assignmentId.assignmentId}
            type={assignmentId.requestType}
            fetchNewData={async () => {
              let options = await createQueryOptions();
              if (options) {
                await fetchData(1, null, options);
              }
            }}
          />
        )}
        <Container fluid>
          <Breadcrumbs
            title="View Assignments"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <>
                    <Row className="mt-2">
                      <Col md="2">
                        <FormGroup>
                          <Col md="12">
                            <Label className="col-form-label">
                              Show Entries
                            </Label>
                            <select
                              className="form-control"
                              onChange={(e) => {
                                fetchData(
                                  1,
                                  e.target.value,
                                  createQueryOptions(),
                                  sortingRule
                                );
                                setPagination({
                                  ...pagination,
                                  pageLimit: e.target.value
                                });
                              }}
                              value={pagination.pageLimit}
                              disabled={assignmentListLoading}
                            >
                              <option value={10}>10</option>
                              <option value={25}>25</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <Label className="col-form-label">
                            Sort Assignment
                          </Label>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              fetchSortedList(e.target.value);
                            }}
                            value={sortingRule}
                            disabled={assignmentListLoading}
                          >
                            <option value="name$asc">
                              Assignment Name (A-Z)
                            </option>
                            <option value="name$desc">
                              Assignment Name (Z-A)
                            </option>
                            <option value="created_at$desc">
                              Create Time (Newest First)
                            </option>
                            <option value="created_at$asc">
                              Create Time (Oldest First)
                            </option>
                            <option value="date$desc">
                              Expected Date (Newest First)
                            </option>
                            <option value="date$asc">
                              Expected Date (Oldest First)
                            </option>
                            <option value="updated_at$desc">
                              Update Time (Newest First)
                            </option>
                            <option value="updated_at$asc">
                              Update Time (Oldest First)
                            </option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col className="d-flex justify-content-end align-items-center">
                        <Button
                          size="sm"
                          color="primary"
                          className=" waves-effect waves-light mr-3"
                          onClick={openFilterModal}
                        >
                          <i className="ri-filter-fill align-middle mr-2"></i>
                          <span>Filter Assignment</span>
                          {currentFilterCount > 0 && (
                            <Badge color="light" pill className="ml-2">
                              {currentFilterCount}
                            </Badge>
                          )}
                        </Button>
                        {validatePermissionKey(authUser, CREATE_ASSIGNMENT) && (
                          <Button
                            size="sm"
                            color="success"
                            className=" waves-effect waves-light"
                            onClick={openAddAssignmentModal}
                          >
                            <i className="ri-add-line align-middle mr-2"></i>
                            Add Assignment
                          </Button>
                        )}
                      </Col>
                    </Row>
                    {assignmentListLoading ? (
                      <div className="text-center">
                        <PageDataLoader />
                      </div>
                    ) : (
                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <Table
                            id="tech-companies-1"
                            striped
                            bordered
                            responsive
                          >
                            <thead>
                              <tr>
                                <th>SL. No</th>
                                <th>Name</th>
                                <th>Expected Date</th>
                                <th>Created At</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {assignmentList?.map((item, index) => (
                                <tr key={index}>
                                  <th>{index + 1}</th>
                                  <td>{item.name}</td>
                                  <td>
                                    {moment(new Date(item.date)).format(
                                      'DD-MM-YYYY'
                                    )}
                                  </td>
                                  <td>
                                    {moment(new Date(item.createdAt)).format(
                                      'DD-MM-YYYY'
                                    )}
                                  </td>
                                  <td>
                                    <Button
                                      size="sm"
                                      color="primary"
                                      className=" waves-effect waves-light mr-2 mb-2"
                                      onClick={() => {
                                        openAssignmentInfoModal(item._id);
                                      }}
                                    >
                                      <i className="fas fa-eye mr-2"></i>
                                      View
                                    </Button>
                                    {validatePermissionKey(
                                      authUser,
                                      UPDATE_ASSIGNMENT
                                    ) && (
                                      <Button
                                        size="sm"
                                        color="warning"
                                        className=" waves-effect waves-light mr-2 mb-2"
                                        onClick={() => {
                                          openUpdateAssignmentModal(item._id);
                                        }}
                                      >
                                        <i className="fas fa-edit mr-2"></i>
                                        Edit
                                      </Button>
                                    )}
                                    {validatePermissionKey(
                                      authUser,
                                      DELETE_ASSIGNMENT
                                    ) && (
                                      <Button
                                        size="sm"
                                        color="danger"
                                        className=" waves-effect waves-light mb-2"
                                        onClick={() => {
                                          openDeleteAssignmentModal(
                                            item._id,
                                            item.isDeleted
                                              ? 'restore'
                                              : 'delete'
                                          );
                                        }}
                                      >
                                        {item.isDeleted ? (
                                          <i className="fas fa-trash-restore mr-2"></i>
                                        ) : (
                                          <i className="fas fa-trash mr-2"></i>
                                        )}
                                        {item.isDeleted ? 'Restore' : 'Delete'}
                                      </Button>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    )}
                    <Row className="mt-2">
                      <Col md="5">
                        <p>
                          <strong>
                            {' '}
                            Showing{' '}
                            {(pagination.currentPage - 1) *
                              pagination.pageLimit +
                              1}{' '}
                            to{' '}
                            {Math.min(
                              pagination.currentPage * pagination.pageLimit,
                              pagination.totalEntries
                            )}{' '}
                            of {pagination.totalEntries} entries
                          </strong>
                        </p>
                      </Col>
                      {!assignmentListLoading && assignmentList.length > 0 && (
                        <Col md="7">
                          <Paginator
                            totalPages={pagination.totalPages}
                            currentPage={pagination.currentPage}
                            changePage={handlePagination}
                          />
                        </Col>
                      )}
                    </Row>
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(AssignmentListPage);
