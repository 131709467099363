/*jshint esversion: 11 */
import React, { useState } from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Alert,
  Progress
} from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import FormUpload from '../../components/FormUpload';
import { addBatchImage } from '../../api/batchImage';

const AddBatchImageModal = ({
  modalOpenState,
  closeModalHandler,
  batch,
  date,
  fetchNewData
}) => {
  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });
  const [images, setImages] = useState([]);
  const [form, setForm] = useState(new FormData());
  const [addBatchImageRequestLoading, setAddBatchImageRequestLoading] =
    useState(false);
  const [successAlert, setSuccessAlert] = useState({
    message: '',
    show_alert: false
  });
  const [progress, setProgress] = useState(0)

  // async function handleAddBatchImage(event, values) {
  //   resetErrorAlert();
  //   if (!images || images.length === 0) {
  //     showErrorAlert('Add atleast one image');
  //     return;
  //   }
  //   form.set('batch', batch);
  //   form.set('date', date);
  //   if (images && images.length > 0) {
  //     images.map((g, index) => form.append(`images`, g));
  //   }
  //   setAddBatchImageRequestLoading(true);
  //   const { response, error } = await addBatchImage(form);
  //   setAddBatchImageRequestLoading(false);
  //   if (error) {
  //     setForm(new FormData());
  //     setImages([]);
  //     showErrorAlert(error);
  //     return;
  //   }
  //   setForm(new FormData());
  //   setImages([]);
  //   fetchNewData();
  //   closeModalHandler();
  //   showSuccessAlert(response.message);
  // }

  async function handleAddBatchImage(event, values) {
    resetErrorAlert();

    if (!images || images.length === 0) {
      showErrorAlert('Add atleast one image');
      return;
    }

    form.set('batch', batch);
    form.set('date', date);

    const imageChunks = [];
    for (let i = 0; i < images.length; i += 2) {
      imageChunks.push(images.slice(i, i + 2));
    }
    let pg = Math.ceil(images.length / 2)
    let slot = Math.round(100 / pg)

    setAddBatchImageRequestLoading(true);

    for (const imageChunk of imageChunks) {
      const formData = new FormData();
      formData.set('batch', batch);
      formData.set('date', date);

      imageChunk.forEach((image, index) => {
        formData.append(`images`, image);
      });


      const { response, error } = await addBatchImage(formData);

      if (error) {
        setAddBatchImageRequestLoading(false);
        setForm(new FormData());
        setImages([]);
        showErrorAlert(error);
        return;
      }
      setProgress(slot)
      slot += slot
    }

    setAddBatchImageRequestLoading(false);
    setForm(new FormData());
    setImages([]);
    fetchNewData();
    closeModalHandler();
    showSuccessAlert('Images uploaded successfully.'); // Update success message (optional)
    setProgress(0)
  }



  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }

  function showSuccessAlert(message) {
    setSuccessAlert({ message: message, show_alert: true });
  }

  function resetSuccessAlert() {
    setSuccessAlert({ message: '', show_alert: false });
  }

  function resetState() {
    resetSuccessAlert();
    resetErrorAlert();
  }


  return (
    <React.Fragment>
      {successAlert.show_alert && (
        <SweetAlert success title="Added" onConfirm={resetState}>
          {successAlert.message}
        </SweetAlert>
      )}
      <Modal isOpen={modalOpenState} backdrop="static" size="xl">
        <ModalHeader >{'Add Images'}</ModalHeader>
        <ModalBody>
          <React.Fragment>
            {requestError ? (
              <Alert
                color="danger"
                role="alert"
                isOpen={requestError.show_alert}
                toggle={resetErrorAlert}
              >
                <i className="mdi mdi-block-helper mr-2"></i>
                {requestError.message}
              </Alert>
            ) : null}



            <AvForm
              className="needs-validation"
              onValidSubmit={handleAddBatchImage}
            >
              <Row>
                <Col md="12">
                  <FormUpload title="Batch Images" setImages={setImages} />
                </Col>
              </Row>
              {addBatchImageRequestLoading && (<Progress value={progress} />)}

              <ModalFooter>

                {/* {addBatchImageRequestLoading && (
                  <Progress value={progress} />
                )} */}
                <Button
                  disabled={addBatchImageRequestLoading}
                  type="button"
                  color="light"
                  onClick={closeModalHandler}
                >
                  Close
                </Button>
                <Button type="submit" color="success">
                  {addBatchImageRequestLoading ? 'Adding ...' : 'Add Images'}
                </Button>

              </ModalFooter>
            </AvForm>
          </React.Fragment>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(AddBatchImageModal);
