/*jshint esversion: 11 */
import React, { useState } from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Alert,
  Input
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { upperCase } from 'lodash';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { addNewRole, getAvailablePermissions } from '../../api/role';
import PageDataLoader from '../Utility/PageDataLoader';
import SweetAlert from 'react-bootstrap-sweetalert';

const CreateRoleModal = ({
  modalOpenState,
  closeModalHandler,
  authUser,
  fetchNewRoleList
}) => {
  const [availablePermissions, setAvailablePermissions] = useState({});
  const [permissionListLoading, setPermissionListLoading] = useState(false);
  const [permissionSelectionInput, setPermissionSelectionInput] = useState({});
  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });
  const [roleCreateRequestLoading, setRoleCreateRequestLoading] =
    useState(false);
  const [successAlert, setSuccessAlert] = useState({
    message: '',
    show_alert: false
  });

  useEffect(() => {
    if (authUser) {
      fetchAvailablePermissions();
    }
    // eslint-disable-next-line
  }, [authUser, modalOpenState]);

  const fetchAvailablePermissions = async () => {
    setPermissionListLoading(true);
    const response = await getAvailablePermissions();
    if (response) {
      setAvailablePermissions(response);
      setPermissionSelectionInput(buildSelectionInput(response));
      setPermissionListLoading(false);
    }
  };

  function buildSelectionInput(permissionMap) {
    const permissionInputMap = new Map();
    Object.keys(permissionMap)
      .flatMap((k) => permissionMap[k])
      .forEach((t) => {
        permissionInputMap.set(t, false);
      });
    return Object.fromEntries(permissionInputMap);
  }

  async function handleRoleCreation(event, values) {
    resetErrorAlert();
    const selectedPermissionKeys = Object.keys(permissionSelectionInput).filter(
      (k) => permissionSelectionInput[k]
    );
    if (selectedPermissionKeys.length === 0) {
      showErrorAlert('Please select at least one permission');
      return;
    }
    setRoleCreateRequestLoading(true);
    const { response, error } = await addNewRole(
      values.role_name,
      selectedPermissionKeys
    );
    setRoleCreateRequestLoading(false);
    if (error) {
      showErrorAlert(error);
      return;
    }
    closeModalHandler();
    showSuccessAlert(response.message);
    fetchNewRoleList();
  }

  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }

  function showSuccessAlert(message) {
    setSuccessAlert({ message: message, show_alert: true });
  }

  function resetSuccessAlert() {
    setSuccessAlert({ message: '', show_alert: false });
  }

  function resetState() {
    resetSuccessAlert();
    resetErrorAlert();
  }

  return (
    <React.Fragment>
      {successAlert.show_alert && (
        <SweetAlert success title="Created" onConfirm={resetState}>
          {successAlert.message}
        </SweetAlert>
      )}
      <Modal isOpen={modalOpenState} backdrop="static" size="xl">
        <ModalHeader toggle={closeModalHandler}>Create New Role</ModalHeader>
        <ModalBody>
          {requestError ? (
            <Alert
              color="danger"
              role="alert"
              isOpen={requestError.show_alert}
              toggle={resetErrorAlert}
            >
              <i className="mdi mdi-block-helper mr-2"></i>
              {requestError.message}
            </Alert>
          ) : null}
          <AvForm
            className="needs-validation"
            onValidSubmit={handleRoleCreation}
          >
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label>Role Name</Label>
                  <AvField
                    name="role_name"
                    placeholder="Enter Role Name ..."
                    type="text"
                    className="form-control"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Role name is required'
                      }
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="d-flex px-3" style={{ height: '100%' }}>
              {permissionListLoading ? (
                <React.Fragment>
                  <PageDataLoader />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {Object.keys(availablePermissions).map((k, idx) => (
                    <React.Fragment key={idx}>
                      <Col
                        sm={4}
                        className="border p-2"
                        style={{ height: '100%' }}
                      >
                        <h5 className="font-size-14 mb-4">{upperCase(k)}</h5>
                        {availablePermissions[k].map((pmKey) => (
                          <div
                            className="form-check mb-3"
                            key={pmKey}
                            style={{ cursor: 'pointer' }}
                          >
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              id={pmKey}
                              checked={permissionSelectionInput[pmKey]}
                              onChange={async (e) => {
                                setPermissionSelectionInput({
                                  ...permissionSelectionInput,
                                  [pmKey]: !permissionSelectionInput[pmKey]
                                });
                              }}
                              name={pmKey}
                              style={{ cursor: 'pointer' }}
                            />
                            <Label
                              style={{ cursor: 'pointer' }}
                              className="form-check-label"
                              htmlFor={pmKey}
                            >
                              {upperCase(pmKey)}
                            </Label>
                          </div>
                        ))}
                      </Col>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              )}
            </Row>
            <ModalFooter>
              <Button
                disabled={roleCreateRequestLoading}
                type="button"
                color="light"
                onClick={closeModalHandler}
              >
                Close
              </Button>
              <Button type="submit" color="success">
                {roleCreateRequestLoading ? 'Creating ...' : 'Create Role'}
              </Button>
            </ModalFooter>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(CreateRoleModal);
