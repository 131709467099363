/*jshint esversion: 11 */
import React, { useState } from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Alert
} from 'reactstrap';
import {
  AvForm,
  AvField,
  AvInput,
  AvGroup
} from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { getRoleList } from '../../api/role';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
  validateDOB,
  validateEmailAddress,
  validatePhoneNumber
} from '../../helpers/input-validator';
import moment from 'moment';
import Select from 'react-select';
import { updateStaff } from '../../api/staff';
import PageDataLoader from '../Utility/PageDataLoader';
import FormUpload from '../../components/FormUpload';
import { getBatchList } from '../../api/batch';

const UpdateStaffModal = ({
  modalOpenState,
  closeModalHandler,
  authUser,
  fetchNewStaffList,
  staffInfoLoading,
  staffInfo
}) => {
  const [form, setForm] = useState(new FormData());
  const [image, setImage] = useState('');
  const [roleOptions, setRoleOptions] = useState([]);
  const [roleOptionsLoading, setRoleOptionsLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [viewAll, setViewAll] = useState(false);

  const [batchOptions, setBatchOptions] = useState([]);
  const [batchOptionsLoading, setBatchOptionsLoading] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState(null);

  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });
  const [staffUpdateRequestLoading, setStaffUpdateRequestLoading] =
    useState(false);
  const [successAlert, setSuccessAlert] = useState({
    message: '',
    show_alert: false
  });

  useEffect(() => {
    setForm(new FormData());
    if (authUser) {
      fetchRoleOptions();
      fetchBatchOptions();
    }
    // eslint-disable-next-line
  }, [authUser, modalOpenState]);

  useEffect(() => {
    if (staffInfo && roleOptions.length > 0) {
      setViewAll(staffInfo?.view_all_batches);
      setSelectedRole(
        roleOptions.find((k) => k.value._id === staffInfo?.role?._id)
      );
      setSelectedBatch(buildBatchOptions(staffInfo?.batches || []));
    }
    // eslint-disable-next-line
  }, [staffInfo, roleOptions]);

  const fetchRoleOptions = async () => {
    setRoleOptionsLoading(true);
    const response = await getRoleList(1, 100, {});
    if (response) {
      setRoleOptions(buildRoleOptions(response?.data || []));
      setRoleOptionsLoading(false);
    }
  };

  function buildRoleOptions(roleList) {
    return roleList.map((item) => ({
      value: item,
      label: item.role_name
    }));
  }

  const fetchBatchOptions = async () => {
    setBatchOptionsLoading(true);
    const response = await getBatchList(1, 100);
    if (response) {
      setBatchOptions(buildBatchOptions(response?.data || []));
      setBatchOptionsLoading(false);
    }
  };

  function buildBatchOptions(batchList) {
    return batchList.map((item) => ({
      value: item._id,
      label: item.name
    }));
  }

  async function handleStaffUpdate(event, values) {
    resetErrorAlert();
    if (!selectedRole) {
      showErrorAlert('Please select a Role for the Staff');
      return;
    }
    form.set('name', values.staff_name);
    form.set('email_address', values.email_address);
    form.set('phone_number', values.phone_number);
    form.set('role', selectedRole?.value?._id);
    form.set('dob', values.dob);
    form.set('gender', values.gender);
    form.set('experience', values.experience);
    if (image) form.set('profile_picture', image);
    form.set('view_all_batches', viewAll);
    if (selectedBatch && selectedBatch.length > 0) {
      selectedBatch.map((g, index) => form.append(`batches`, g.value));
    }
    setStaffUpdateRequestLoading(true);
    const { response, error } = await updateStaff(staffInfo._id, form);
    setStaffUpdateRequestLoading(false);
    if (error) {
      showErrorAlert(error);
      return;
    }
    closeModalHandler();
    showSuccessAlert(response.message);
    fetchNewStaffList();
  }

  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }

  function showSuccessAlert(message) {
    setSuccessAlert({ message: message, show_alert: true });
  }

  function resetSuccessAlert() {
    setSuccessAlert({ message: '', show_alert: false });
  }

  function resetState() {
    resetSuccessAlert();
    resetErrorAlert();
  }

  function handleRoleSelection(selectedItem) {
    setSelectedRole(selectedItem);
  }

  function handleBatchSelection(selectedItem) {
    setSelectedBatch(selectedItem);
  }

  return (
    <React.Fragment>
      {successAlert.show_alert && (
        <SweetAlert success title="Updated" onConfirm={resetState}>
          {successAlert.message}
        </SweetAlert>
      )}
      <Modal isOpen={modalOpenState} backdrop="static" size="xl">
        <ModalHeader toggle={closeModalHandler}>
          {staffInfoLoading ? 'Loading ...' : 'Edit [' + staffInfo.name + ']'}
        </ModalHeader>
        <ModalBody>
          {staffInfoLoading ? (
            <div className="d-flex justify-content-center">
              <PageDataLoader />
            </div>
          ) : (
            <React.Fragment>
              {requestError ? (
                <Alert
                  color="danger"
                  role="alert"
                  isOpen={requestError.show_alert}
                  toggle={resetErrorAlert}
                >
                  <i className="mdi mdi-block-helper mr-2"></i>
                  {requestError.message}
                </Alert>
              ) : null}
              <AvForm
                className="needs-validation"
                onValidSubmit={handleStaffUpdate}
              >
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label>Staff Name</Label>
                      <AvField
                        name="staff_name"
                        placeholder="Enter Staff Name ..."
                        type="text"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Staff name is required'
                          },
                          pattern: {
                            value: `^[A-Za-z ]+$`,
                            errorMessage:
                              'Staff name must be composed only with letter'
                          }
                        }}
                        defaultValue={staffInfo?.name}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label>Email Address</Label>
                      <AvField
                        name="email_address"
                        placeholder="Enter Email Address ..."
                        type="text"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Email Address is required'
                          },
                          async: validateEmailAddress
                        }}
                        defaultValue={staffInfo?.email_address}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label>Phone Number</Label>
                      <AvField
                        name="phone_number"
                        placeholder="Enter Phone Number ..."
                        type="text"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Phone Number is required'
                          },
                          minLength: {
                            value: 10,
                            errorMessage: 'Phone Number must be 10 digits'
                          },
                          maxLength: {
                            value: 10,
                            errorMessage: 'Phone Number must be 10 digits'
                          },
                          async: validatePhoneNumber
                        }}
                        defaultValue={staffInfo?.phone_number}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label>Date of Birth</Label>
                      <AvField
                        name="dob"
                        max={moment()
                          .subtract(22, 'years')
                          .format('YYYY-MM-DD')}
                        placeholder="Choose Date of Birth ..."
                        type="date"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'DOB is required'
                          },
                          async: validateDOB
                        }}
                        defaultValue={moment(staffInfo?.dob).format(
                          'YYYY-MM-DD'
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label>Gender</Label>
                      <AvField
                        name="gender"
                        placeholder="Choose Gender ..."
                        type="select"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Gender is required'
                          }
                        }}
                        defaultValue={staffInfo?.gender}
                      >
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </AvField>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label>Role</Label>
                      <Select
                        options={roleOptions}
                        disabled={roleOptionsLoading}
                        onChange={handleRoleSelection}
                        value={selectedRole}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup>
                      <Label>Select All Batches</Label>
                      <AvGroup check className="mt-2">
                        <AvInput
                          type="checkbox"
                          name="checkbox"
                          defaultValue={viewAll}
                          onChange={(e) => {
                            setViewAll(!viewAll);
                          }}
                        />
                        <Label check for="checkbox">
                          {' '}
                          View All Batches
                        </Label>
                      </AvGroup>
                    </FormGroup>
                  </Col>
                  {!viewAll && (
                    <Col md="10">
                      <FormGroup>
                        <Label>Batches</Label>
                        <Select
                          options={batchOptions}
                          disabled={batchOptionsLoading}
                          onChange={handleBatchSelection}
                          value={selectedBatch}
                          isMulti
                        />
                      </FormGroup>
                    </Col>
                  )}
                  <Col md="12">
                    <FormGroup>
                      <Label>Work Experience</Label>
                      <AvField
                        name="experience"
                        placeholder="Type Work Experience ..."
                        type="textarea"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Work Experience is required'
                          }
                        }}
                        defaultValue={staffInfo?.experience}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormUpload setImage={setImage} title="Profile Picture" />
                  </Col>
                </Row>
                <ModalFooter>
                  <Button
                    disabled={staffUpdateRequestLoading}
                    type="button"
                    color="light"
                    onClick={closeModalHandler}
                  >
                    Close
                  </Button>
                  <Button type="submit" color="success">
                    {staffUpdateRequestLoading
                      ? 'Updating ...'
                      : 'Update Staff'}
                  </Button>
                </ModalFooter>
              </AvForm>
            </React.Fragment>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(UpdateStaffModal);
