import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import ProfileMenu from '../Common/ProfileMenu';
import logosmdark from '../../assets/images/logo-sm-dark.png';
import logodark from '../../assets/images/logo-dark.png';
import logosmlight from '../../assets/images/logo-sm-light.png';
import logolight from '../../assets/images/logo-light.png';

const Header = ({ toggleMenuCallback }) => {
  const toggleFullScreen = () => {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  };

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/dashboard" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logosmdark} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logodark} alt="" height="20" />
                </span>
              </Link>

              <Link
                to="/dashboard"
                className="logo logo-light d-flex justify-content-center align-items-center"
              >
                <span className="logo-sm">
                  <img src={logosmlight} alt="" height="22" />
                </span>
                <span className="logo-lg" style={{ width: '100px' }}>
                  <img
                    src={logolight}
                    alt=""
                    style={{
                      height: '50px',
                      width: '90px',
                      objectFit: 'contain'
                    }}
                  />
                </span>
              </Link>
            </div>

            <Button
              size="sm"
              color="none"
              type="button"
              onClick={toggleMenuCallback}
              className="px-3 font-size-24 header-item waves-effect"
              id="vertical-menu-btn"
            >
              <i className="ri-menu-2-line align-middle"></i>
            </Button>
          </div>

          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ml-2">
              <button
                type="button"
                onClick={() => {}}
                className="btn header-item noti-icon waves-effect"
                id="page-header-search-dropdown"
              >
                <i className="ri-search-line"></i>
              </button>
            </div>

            <div className="dropdown d-none d-lg-inline-block ml-1">
              <Button
                color="none"
                type="button"
                className="header-item noti-icon waves-effect"
                onClick={toggleFullScreen}
              >
                <i className="ri-fullscreen-line"></i>
              </Button>
            </div>
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { myLoginState: state.Login };
};

export default connect(mapStateToProps)(withRouter(Header));
