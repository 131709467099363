/*jshint esversion: 11 */
import React, { useState, useEffect, useMemo } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Table,
  Label,
  FormGroup,
  Button,
  Badge,
  Nav,
  NavItem,
  NavLink,
  ModalHeader,
  ModalBody,
  Modal,
  ModalFooter,
  Alert,
  Input
} from 'reactstrap';

import { connect } from 'react-redux';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import PageDataLoader from '../Utility/PageDataLoader';
import Paginator from '../Utility/Paginator';
import moment from 'moment';
import CreateFormModal from './CreateFormModal';
import UpdateFormModal from './UpdateFormModal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import validator from 'validator';
import { stringify as QStringify, parse as QParse } from 'qs';
import { useHistory } from 'react-router-dom';

import {
  CREATE_FORM,
  DELETE_FORM,
  UPDATE_FORM
} from '../Utility/PermissionKeys';
import { validatePermissionKey } from '../../helpers/permission';
import DeleteFormModal from './DeleteFormModal';
import FilterFormsModal from './FilterFormsModal';
import { getFormInfo, getFormList } from '../../api/form';
import ViewFormModal from './ViewFormModal';
import UpdateFormModel from './UpdateFormModal';

const FormListPage = ({ authUser }) => {
  const history = useHistory();

  const [breadcrumbItems] = useState([
    { title: 'Forms', link: '#' },
    { title: 'View List', link: '#' }
  ]);
  const [activeTab, setActiveTab] = useState('Child');
  const [formListLoading, setFormListLoading] = useState(true);
  const [formList, setFormList] = useState([]);
  const [sortingRule, setSortingRule] = useState('name$asc');

  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageLimit: 10,
    totalEntries: 0,
    totalPages: 0
  });
  const [filterFormName, setFilterFormName] = useState('');
  const [filterError, setFilterError] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [currentFilterCount, setCurrentFilterCount] = useState(0);
  const [addFormModalOpen, setAddFormModalOpen] = useState(false);
  const [formInfo, setFormInfo] = useState({});
  const [formInfoLoading, setFormInfoLoading] = useState(false);
  const [formInfoModalOpen, setFormInfoModalOpen] = useState(false);
  const [updateFormFormModal, setUpdateFormModalOpen] = useState(false);
  const [expectedDateFilter, setExpectedDateFilter] = useState({
    startDate: null,
    endDate: null
  });
  const [referenceQueryId, setReferenceQueryId] = useState([]);
  const [referenceList, setReferenceList] = useState([]);
  const [selectedReferenceOption, setSelectedReferenceOption] = useState([]);
  const [deleteFormModalOpen, setDeleteFormModalOpen] = useState(false);
  const [formId, setFormId] = useState('');
  const [isDeleted, setIsDeleted] = useState(false);
  const [sortOrder, setSortOrder] = useState('name$asc');
  const [createdAtFilter, setCreatedAtFilter] = useState({
    startDate: null,
    endDate: null
  });

  let listElem = document.getElementById('formList');
  let linkElem = document.getElementById('formLink');

  useEffect(() => {
    if (listElem) listElem.classList.add('mm-active');
    if (linkElem) linkElem.classList.add('active');
    if (authUser) {
      fetchData();
      populateSearchOptions(history?.location?.search);
    }
    // eslint-disable-next-line
  }, [authUser, listElem, linkElem]);



  /**
   *
   * @param {string} str
   */
  function getValidDateInput(str) {
    return validator.isDate(str) ? new Date(str).toISOString() : '';
  }
  const toggleFilterModal = () => setFilterModalOpen(!filterModalOpen);

  const handlePagination = (page) => {
    fetchData(page, pagination.pageLimit, createQueryOptions());
  };

  const fetchData = async (
    current_page = 1,
    page_limit = 10,
    queryOptions = {},
    sortingOption = ''
  ) => {
    setFormListLoading(true);
    const response = await getFormList(
      current_page,
      page_limit,
      queryOptions,
      sortingOption
    );

    if (response) {
      setPagination({
        currentPage: response.metadata.page,
        pageLimit: response.metadata.limit,
        totalPages: response.metadata.total_pages,
        totalEntries: response.metadata.total_results
      });
      setFormList(response.data);
      setFormListLoading(false);
    }
  };

  /**
   *
   * @param {string} searchQuery
   */
  function populateSearchOptions(searchQuery) {
    const parsedQuery = QParse(searchQuery.substring(1));
    if (parsedQuery.filter_start_date) {
      setExpectedDateFilter((pv) => {
        return {
          ...pv,
          startDate: new Date(parsedQuery.filter_start_date)
        };
      });
    }
    if (parsedQuery.filter_end_date) {
      setExpectedDateFilter((pv) => {
        return {
          ...pv,
          endDate: new Date(parsedQuery.filter_end_date)
        };
      });
    }
    setFilterFormName(parsedQuery.filter_name);
    fetchData(1, null, parsedQuery);
    updateFilterCount(parsedQuery);
  }

  function createQueryOptions(
    startDate = createdAtFilter.startDate,
    endDate = createdAtFilter.endDate,
    filterName = filterFormName,
    deletedStatus = isDeleted
  ) {
    setFilterError(false);
    if ((startDate && !endDate) || (!startDate && endDate)) {
      setFilterError('Select valid date range');
      return;
    }
    const startDateISOStringCreatedAt = getValidDateInput(startDate);
    const endDateISOStringCreatedAt = getValidDateInput(endDate);

    const queryOptions = {
      filter_name: filterName,
      filter_start_date: startDateISOStringCreatedAt,
      filter_end_date: endDateISOStringCreatedAt,
      include_deleted: deletedStatus
    };
    updateUrlPath(queryOptions);
    // updateFilterCount(queryOptions);
    // fetchData(1, null, queryOptions);
    return queryOptions;
  }

  function updateUrlPath(queryOptions) {
    const queryString = QStringify(queryOptions);
    history.replace(`/form?${queryString}`);
  }


  function openAddFormModal() {
    setAddFormModalOpen(true);
  }

  function closeAddFormModal() {
    setAddFormModalOpen(false);
  }

  function openFormInfoModal(roleId) {
    fetchFormInfo(roleId);
    setFormInfoModalOpen(true);
  }

  function closeFormInfoModal() {
    setFormInfoModalOpen(false);
  }

  async function openUpdateFormModal(formId) {
    fetchFormInfo(formId);
    setUpdateFormModalOpen(true);
  }

  function closeUpdateFormForm() {
    setUpdateFormModalOpen(false);
  }
  async function openDeleteFormModal(formId, requestType) {
    setFormId({
      formId,
      requestType
    });
    setDeleteFormModalOpen(true);
  }

  async function fetchSortedList(sortingOption) {
    setSortingRule(sortingOption);
    const queryOptions = createQueryOptions();
    queryOptions.sorting_option = sortingOption;
    updateUrlPath(queryOptions);
    fetchData(1, null, queryOptions, sortingOption);
  }

  function closeDeleteFormModal() {
    setDeleteFormModalOpen(false);
  }

  function resetFields() {
    setFilterFormName('');
    setSelectedReferenceOption(null);
    setExpectedDateFilter({
      startDate: null,
      endDate: null
    });
    setIsDeleted(false);
  }

  async function fetchFormInfo(formId) {
    setFormInfoLoading(true);
    const response = await getFormInfo(formId);
    if (response) {
      setFormInfo(response?.data);
      setFormInfoLoading(false);
    }
  }



  function closeFilterModal() {
    setFilterModalOpen(false);
  }

  function updateFilterCount(option) {
    let count = 0;
    Object.keys(option).forEach((i) => {
      if (option[i]?.toString()?.trim()?.length > 0 && option[i] !== false) {
        count += 1;
      }
    });
    setCurrentFilterCount(count);
  }


  async function openUpdateFormModal(formId) {
    fetchFormInfo(formId);
    setUpdateFormModalOpen(true);
  }

  function closeUpdateFormForm() {
    setUpdateFormModalOpen(false);
  }



  return (
    <React.Fragment>
      <div className="page-content">

        <FilterFormsModal
          filterError={filterError}
          setFilterError={setFilterError}
          modalOpenState={filterModalOpen}
          closeModalHandler={closeFilterModal}
          fetchList={fetchData}
          updateFilterCount={updateFilterCount}
          updateUrlPath={updateUrlPath}
          formName={filterFormName}
          setFormName={setFilterFormName}
          createdAtFilter={createdAtFilter}
          setCreatedAtFilter={setCreatedAtFilter}
          createQueryOptions={createQueryOptions}
          authUser={authUser}
          isDeleted={isDeleted}
          setIsDeleted={setIsDeleted}
        />


        <CreateFormModal
          modalOpenState={addFormModalOpen}
          closeModalHandler={closeAddFormModal}
          formInfoLoading={formInfoLoading}
          fetchNewFormList={async () => {
            let options = await createQueryOptions();
            if (options) {
              await fetchData(1, null, options);
            }
          }}
          formType={activeTab}
        />
        <ViewFormModal
          modalOpenState={formInfoModalOpen}
          closeModalHandler={closeFormInfoModal}
          formInfo={formInfo}
          formInfoLoading={formInfoLoading}
          formType={activeTab}
        />
        <UpdateFormModel
          modalOpenState={updateFormFormModal}
          closeModalHandler={closeUpdateFormForm}
          formInfo={formInfo}
          formInfoLoading={formInfoLoading}
          fetchNewFormList={async () => {
            let options = await createQueryOptions();
            if (options) {
              await fetchData(1, null, options);
            }
          }}
          formType={activeTab}
        />
        {formId && (
          <DeleteFormModal
            modalOpenState={deleteFormModalOpen}
            closeModalHandler={closeDeleteFormModal}
            formId={formId?.formId}
            type={formId.requestType}
            fetchNewData={async () => {
              let options = await createQueryOptions();
              if (options) {
                await fetchData(1, null, options);
              }
            }}
          />
        )}
        <Container fluid>
          <Breadcrumbs
            title="View Forms"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <>
                    <Row className="mt-2">
                      <Col md="2">
                        <FormGroup>
                          <Col md="12">
                            <Label className="col-form-label">
                              Show Entries
                            </Label>
                            <select
                              className="form-control"
                              onChange={(e) => {
                                fetchData(
                                  1,
                                  e.target.value,
                                  createQueryOptions(),
                                  sortingRule
                                );
                                setPagination({
                                  ...pagination,
                                  pageLimit: e.target.value
                                });
                              }}
                              value={pagination.pageLimit}
                              disabled={formListLoading}
                            >
                              <option value={10}>10</option>
                              <option value={25}>25</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <Label className="col-form-label">
                            Sort Form
                          </Label>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              fetchSortedList(e.target.value);
                            }}
                            value={sortingRule}
                            disabled={formListLoading}
                          >
                            <option value="name$asc">Question Name (A-Z)</option>
                            <option value="name$desc">Question Name (Z-A)</option>
                            <option value="updated_at$desc">Update Time (Newest First)</option>
                            <option value="updated_at$asc">Update Time (Oldest First)</option>

                          </select>
                        </FormGroup>
                      </Col>
                      <Col className="d-flex justify-content-end align-items-center">
                        <Button
                          size="sm"
                          color="primary"
                          className=" waves-effect waves-light mr-3"
                          onClick={toggleFilterModal}
                        >
                          <i className="ri-filter-fill align-middle mr-2"></i>
                          <span>Filter Form</span>
                          {currentFilterCount > 0 && (
                            <Badge color="light" pill className="ml-2">
                              {currentFilterCount}
                            </Badge>
                          )}
                        </Button>
                        {validatePermissionKey(authUser, CREATE_FORM) && (
                          <Button
                            size="sm"
                            color="success"
                            className=" waves-effect waves-light"
                            onClick={openAddFormModal}
                          >
                            <i className="ri-add-line align-middle mr-2"></i>
                            Add Form
                          </Button>
                        )}
                      </Col>
                    </Row>


                    {formListLoading ? (
                      <div className="text-center">
                        <PageDataLoader />
                      </div>
                    ) : (
                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <Table
                            id="tech-companies-1"
                            striped
                            bordered
                            responsive
                          >
                            <thead>
                              <tr>
                                <th>SL. No</th>
                                <th>Name</th>

                                <th>Created At</th>
                                <th>Last Updated At</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {formList?.map((item, index) => (
                                <tr key={index}>
                                  <th>{index + 1}</th>
                                  <td>{item.name}</td>

                                  <td>
                                    {moment(new Date(item.createdAt)).format(
                                      'DD-MM-YYYY'
                                    )}
                                  </td>
                                  <td>
                                    {moment(new Date(item.updatedAt)).format(
                                      'DD-MM-YYYY'
                                    )}
                                  </td>

                                  <td>
                                    <Button
                                      size="sm"
                                      color="primary"
                                      className=" waves-effect waves-light mr-2 mb-2"
                                      onClick={() => {
                                        openFormInfoModal(item._id);
                                      }}
                                    >
                                      <i className="fas fa-eye mr-2"></i>
                                      View
                                    </Button>
                                    {validatePermissionKey(
                                      authUser,
                                      UPDATE_FORM
                                    ) && (
                                        <Button
                                          size="sm"
                                          color="warning"
                                          className=" waves-effect waves-light mr-2 mb-2"
                                          onClick={() => {
                                            openUpdateFormModal(item._id);
                                          }}
                                        >
                                          <i className="fas fa-edit mr-2"></i>
                                          Edit
                                        </Button>
                                      )}
                                    {validatePermissionKey(
                                      authUser,
                                      DELETE_FORM
                                    ) && (
                                        <Button
                                          size="sm"
                                          color="danger"
                                          className=" waves-effect waves-light mb-2"
                                          onClick={() => {
                                            openDeleteFormModal(
                                              item._id,
                                              item.isDeleted
                                                ? 'restore'
                                                : 'delete'
                                            );
                                          }}
                                        >
                                          {item.isDeleted ? (
                                            <i className="fas fa-trash-restore mr-2"></i>
                                          ) : (
                                            <i className="fas fa-trash mr-2"></i>
                                          )}
                                          {item.isDeleted ? 'Restore' : 'Delete'}
                                        </Button>
                                      )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    )}
                    <Row className="mt-2">
                      <Col md="5">
                        <p>
                          <strong>
                            {' '}
                            Showing{' '}
                            {(pagination.currentPage - 1) *
                              pagination.pageLimit +
                              1}{' '}
                            to{' '}
                            {Math.min(
                              pagination.currentPage * pagination.pageLimit,
                              pagination.totalEntries
                            )}{' '}
                            of {pagination.totalEntries} entries
                          </strong>
                        </p>
                      </Col>
                      {!formListLoading && formList.length > 0 && (
                        <Col md="7">
                          <Paginator
                            totalPages={pagination.totalPages}
                            currentPage={pagination.currentPage}
                            changePage={handlePagination}
                          />
                        </Col>
                      )}
                    </Row>
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(FormListPage);
