/*jshint esversion: 11 */
import sendApiRequest from '../helpers/axios-image-setup';

/**
 *
 * @returns {Promise}
 */
export async function fetchImage(key) {
  try {
    const response = await sendApiRequest.get(`/file/${key}`, {
      responseType: 'blob'
    });
    return response.data;
  } catch (e) {
    return null;
  }
}
