/*jshint esversion: 11 */
import React, { useState } from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Alert
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { updateBadge } from '../../api/badge';
import PageDataLoader from '../Utility/PageDataLoader';

const UpdateBadgeModal = ({
  modalOpenState,
  closeModalHandler,
  authUser,
  fetchNewBadgeList,
  badgeInfoLoading,
  badgeInfo
}) => {
  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });
  const [badgeUpdateRequestLoading, setBadgeUpdateRequestLoading] = useState(false);
  const [successAlert, setSuccessAlert] = useState({
    message: '',
    show_alert: false
  });

  async function handleBadgeUpdate(event, values) {
    resetErrorAlert();
    setBadgeUpdateRequestLoading(true);
    const { response, error } = await updateBadge(badgeInfo._id, values.badge_name, values.badge_summary);
    setBadgeUpdateRequestLoading(false);
    if (error) {
      showErrorAlert(error);
      return;
    }
    closeModalHandler();
    showSuccessAlert(response.message);
    fetchNewBadgeList();
  }

  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }

  function showSuccessAlert(message) {
    setSuccessAlert({ message: message, show_alert: true });
  }

  function resetSuccessAlert() {
    setSuccessAlert({ message: '', show_alert: false });
  }

  function resetState() {
    resetSuccessAlert();
    resetErrorAlert();
  }

  return (
    <React.Fragment>
      {successAlert.show_alert && (
        <SweetAlert success title="Updated" onConfirm={resetState}>
          {successAlert.message}
        </SweetAlert>
      )}
      <Modal isOpen={modalOpenState} backdrop="static" size="xl">
        <ModalHeader toggle={closeModalHandler}>
          {badgeInfoLoading ? 'Loading ...' : 'Edit [' + badgeInfo.name + ']'}
        </ModalHeader>
        <ModalBody>
          {badgeInfoLoading ? (
            <div className="d-flex justify-content-center">
              <PageDataLoader />
            </div>
          ) : (
            <React.Fragment>
              {requestError ? (
                <Alert
                  color="danger"
                  role="alert"
                  isOpen={requestError.show_alert}
                  toggle={resetErrorAlert}
                >
                  <i className="mdi mdi-block-helper mr-2"></i>
                  {requestError.message}
                </Alert>
              ) : null}
              <AvForm
                className="needs-validation"
                onValidSubmit={handleBadgeUpdate}
              >
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label>Badge Name</Label>
                      <AvField
                        name="badge_name"
                        placeholder="Enter Badge Name ..."
                        type="text"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Badge name is required'
                          }
                        }}
                        defaultValue={badgeInfo?.name}
                      />
                      <Label>Badge Summary</Label>
                      <AvField
                        name="badge_summary"
                        placeholder="Enter Badge Summary"
                        type="textarea"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Badge name is required'
                          }
                        }}
                        defaultValue={badgeInfo?.summary}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <ModalFooter>
                  <Button
                    disabled={badgeUpdateRequestLoading}
                    type="button"
                    color="light"
                    onClick={closeModalHandler}
                  >
                    Close
                  </Button>
                  <Button type="submit" color="success">
                    {badgeUpdateRequestLoading ? 'Updating ...' : 'Update Badge'}
                  </Button>
                </ModalFooter>
              </AvForm>
            </React.Fragment>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(UpdateBadgeModal);
