import { AvField, AvForm } from 'availity-reactstrap-validation';
import React, { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import {
  Alert,
  Button,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import { getReferenceList, updateFeedback } from '../../api/feedback';
import PageDataLoader from '../Utility/PageDataLoader';

const EditFeedbackModal = ({
  modalOpenState,
  closeModalHandler,
  fetchNewFeedbackList,
  feedbackType,
  feedbackInfo,
  feedbackInfoLoading
}) => {
  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });

  const [feedbackRequestLoading, setFeedbackRequestLoading] = useState(false);
  const [questionArray, setQuestionArray] = useState(['']);
  const [referenceOptions, setReferenceOptions] = useState([]);
  const [referenceOptionsLoading, setReferenceOptionsLoading] = useState(false);
  const [selectedReference, setSelectedReference] = useState(null);
  const [successAlert, setSuccessAlert] = useState({
    message: '',
    show_alert: false
  });

  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }

  function showSuccessAlert(message) {
    setSuccessAlert({ message: message, show_alert: true });
  }

  function resetSuccessAlert() {
    setSuccessAlert({ message: '', show_alert: false });
  }

  function resetState() {
    resetSuccessAlert();
    resetErrorAlert();
  }

  async function handleSubmit(event, values) {
    resetErrorAlert();
    if (!selectedReference || selectedReference.length === 0) {
      showErrorAlert('Select atleast one ' + feedbackType);
      return;
    }
    let referenceArray =
      selectedReference && selectedReference.map((c) => c.value);
    if (questionArray.length === 1 && questionArray[0] === '') {
      showErrorAlert('Atleast one question should be added');
      return;
    }
    setFeedbackRequestLoading(true);
    const { response, error } = await updateFeedback(
      feedbackInfo._id,
      feedbackType,
      values.name,
      referenceArray,
      questionArray
    );
    setFeedbackRequestLoading(false);
    if (error) {
      showErrorAlert(error);
      return;
    }
    setQuestionArray(['']);
    setSelectedReference(null);
    fetchNewFeedbackList();
    closeModalHandler();
    showSuccessAlert(response.message);
  }

  function handleQuestion(index, selectedItem) {
    resetErrorAlert();
    let value = selectedItem.target.value;
    let newQuestionArray = [...questionArray];
    newQuestionArray[index] = value;
    setQuestionArray(newQuestionArray);
  }

  const addQuestionFields = () => {
    resetErrorAlert();
    setQuestionArray([...questionArray, '']);
  };

  let removeQuestionFields = (i) => {
    let newQuestionArray = [...questionArray];
    newQuestionArray.splice(i, 1);
    setQuestionArray(newQuestionArray);
  };

  useEffect(() => {
    if (feedbackInfo) {
      setSelectedReference(buildOptions(feedbackInfo?.reference_id || []));
      setQuestionArray(feedbackInfo?.questions || ['']);
    }

    fetchReferenceOptions();

    // eslint-disable-next-line
  }, [modalOpenState, feedbackInfo]);

  const fetchReferenceOptions = async () => {
    setReferenceOptionsLoading(true);
    const response = await getReferenceList(feedbackType);
    if (response) {
      setReferenceOptions(buildOptions(response?.data || []));
      setReferenceOptionsLoading(false);
    }
  };

  function buildOptions(list) {
    return list.map((item) => ({
      value: item._id,
      label: item.name
    }));
  }

  const handleReferenceSelection = (selectedItem) => {
    setSelectedReference(selectedItem);
  };

  return (
    <React.Fragment>
      {successAlert.show_alert && (
        <SweetAlert success title="Updated" onConfirm={resetState}>
          {successAlert.message}
        </SweetAlert>
      )}
      <Modal isOpen={modalOpenState} backdrop="static" size="xl">
        <ModalHeader toggle={closeModalHandler}>Edit Feedback</ModalHeader>
        <ModalBody>
          {feedbackInfoLoading ? (
            <div className="d-flex justify-content-center">
              <PageDataLoader />
            </div>
          ) : (
            <React.Fragment>
              {requestError ? (
                <Alert
                  color="danger"
                  role="alert"
                  isOpen={requestError.show_alert}
                  toggle={resetErrorAlert}
                >
                  <i className="mdi mdi-block-helper mr-2"></i>
                  {requestError.message}
                </Alert>
              ) : null}

              <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
                <ModalBody>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <Label>Name</Label>
                        <AvField
                          name="name"
                          placeholder="Enter Name ..."
                          type="text"
                          className="form-control"
                          value={feedbackInfo?.name}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: 'Name is required'
                            }
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup>
                        <Label>{feedbackType}</Label>
                        <Select
                          options={referenceOptions}
                          disabled={referenceOptionsLoading}
                          isMulti
                          defaultValue={selectedReference}
                          onChange={handleReferenceSelection}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-2">
                    <Col md="5">
                      <CardTitle tag="h5">Questions</CardTitle>
                    </Col>
                    <Col className="d-flex justify-content-end align-items-center">
                      <Button
                        size="sm"
                        color="success"
                        className=" waves-effect waves-light"
                        disabled={!questionArray[questionArray.length - 1]}
                        onClick={() => addQuestionFields()}
                      >
                        <i className="ri-add-line align-middle mr-2"></i>
                        Add
                      </Button>
                    </Col>
                  </Row>
                  {questionArray.map((element, index) => (
                    <Row>
                      <Col md="11">
                        <FormGroup>
                          <AvField
                            name="question"
                            placeholder="Enter Question ..."
                            type="text"
                            className="form-control"
                            value={element}
                            onChange={(e) => handleQuestion(index, e)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="1" className="d-flex align-items-center">
                        <Button
                          size="sm"
                          color="danger"
                          disabled={index === 0}
                          className=" waves-effect waves-light"
                          onClick={() => removeQuestionFields(index)}
                        >
                          <i className="ri-delete-bin-6-line align-middle"></i>
                        </Button>
                      </Col>
                    </Row>
                  ))}
                </ModalBody>
                <ModalFooter>
                  <Button
                    disabled={feedbackRequestLoading}
                    type="button"
                    color="light"
                    onClick={closeModalHandler}
                  >
                    Close
                  </Button>
                  <Button type="submit" color="success">
                    {feedbackRequestLoading ? 'Editing ...' : 'Edit Feedback'}
                  </Button>
                </ModalFooter>
              </AvForm>
            </React.Fragment>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default EditFeedbackModal;