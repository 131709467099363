/*jshint esversion: 11 */
import axios from 'axios';
const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_HOST_URL,
  timeout: 1000000
});

instance.interceptors.request.use((req) => {
  const access_token = window.localStorage.getItem('ACCESS_TOKEN');

  if (access_token) {
    req.headers.Authorization = `Bearer ${access_token}`;
  }
  try {
    req.baseURL = req.baseURL + `/public/storage`;
  } catch (e) {}
  return req;
});

export default instance;
