import sendRequest from './axios-setup';

// Login Method
export const loginRequest = (url, data) => {
  return sendRequest
    .post(url, data)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      throw err.response && err.response.data ? err.response.data : err;
    });
};

export const getAuthenticatedUser = (url) => {
  if (localStorage.getItem('ACCESS_TOKEN')) {
    return sendRequest
      .get(url)
      .then((response) => {
        if (response.status === 400 || response.status === 500)
          throw response.data;
        return response.data;
      })
      .catch((err) => {
        throw err.response && err.response.data ? err.response.data : err;
      });
  }
};
