import sendApiRequest from '../helpers/axios-setup';
/**
 *
 * @returns {Promise}
 */
export async function getBadgeList(
    page,
    limit,
    {
      filter_start_created_at = '',
      filter_end_created_at = '',
      filter_start_last_updated_at = '',
      filter_end_last_updated_at = '',
      name = '',
      include_deleted = ''
    } = {},
    sorting_option = ''
  ) {
    try {
      const response = await sendApiRequest.get('/badge', {
        params: {
          page: page,
          limit: limit,
          filter_start_created_at,
          filter_end_created_at,
          filter_start_last_updated_at,
          filter_end_last_updated_at,
          filter_name: name,
          include_deleted,
          sorting_option
        }
      });
      return response.data?.data[0];
    } catch (e) {
      return null;
    }
  }




  export async function addNewBadge(name,summary) {
    const responseMap = {
      response: null,
      error: null
    };
    try {
      const response = await sendApiRequest.post('/badge', {
        name,
        summary
      });
      responseMap.response = response.data;
    } catch (e) {
      responseMap.error = e?.response?.data?.error;
    }
    return responseMap;
  }



  /**
 *
 * @param {String} badgeId
 * @returns
 */
export async function getBadgeInfo(badgeId) {
    try {
      const response = await sendApiRequest.get(`/badge/${badgeId}`);
      return response.data?.data;
    } catch (e) {
      return null;
    }
  }
  
  /**
   *
   * @param {string} name
   * @returns {Promise<Object>}
   */
  export async function updateBadge(badgeId, name, summary) {
    const responseMap = {
      response: null,
      error: null
    };
    try {
      const response = await sendApiRequest.put(`/badge/${badgeId}`, {
        name,
        summary
      });
      responseMap.response = response.data;
    } catch (e) {
      responseMap.error = e?.response?.data?.error;
    }
    return responseMap;
  }
  
  /**
   *
   * @param {String} badgeId
   * @returns
   */
  export async function deleteRestoreBadge(badgeId, type) {
    const responseMap = {
      response: null,
      error: null
    };
    try {
      const response = await sendApiRequest.delete(`/badge/${badgeId}`, {
        params: {
          type: type
        }
      });
      responseMap.response = response.data;
    } catch (e) {
      responseMap.error = e?.response?.data?.error;
    }
    return responseMap;
  }
  