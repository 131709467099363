import React, { useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
  FormGroup,
  Label,
  Button,
  Alert
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import sendRequest from '../../helpers/axios-setup';
import PropTypes from 'prop-types';
import validator from 'validator';
import SweetAlert from 'react-bootstrap-sweetalert';

function StepOne({
  goPrevState,
  progressHandler,
  stepHandler,
  errorHandler,
  type
}) {
  const [requestingOtp, setRequestingOtp] = useState(false);

  const validatePhoneNumber = async (value, ctx, input, cb) => {
    if (!validator.isMobilePhone(value, 'en-IN')) {
      return cb('Please enter a valid Indian Mobile Number');
    }
    return cb(true);
  };

  async function submitHandler(event, values) {
    setRequestingOtp(true);
    progressHandler(true);
    try {
      const { data } = await sendRequest.post(`/${type}/auth/send-otp`, {
        phone_number: values.phone_number
      });
      localStorage.setItem(
        'FORGOT_PASSWORD_OTP_VERIFICATION',
        JSON.stringify({
          phone_number: values.phone_number,
          verification_key: data.data.verification_key
        })
      );
      setRequestingOtp(false);
      stepHandler(2);
    } catch (e) {
      setRequestingOtp(false);
      progressHandler(false);
      errorHandler({
        success: false,
        error: true,
        message: e?.response?.data?.error
      });
    }
  }

  return (
    <AvForm className="needs-validation" onValidSubmit={submitHandler}>
      <Row>
        <Col md={12}>
          <FormGroup className="auth-form-group-custom mb-4">
            <i className="ri-phone-line auti-custom-input-icon"></i>
            <Label htmlFor="phone_number">Phone Number</Label>
            <AvField
              name="phone_number"
              type="text"
              className="form-control"
              id="phone_number"
              validate={{
                required: {
                  value: true,
                  errorMessage: 'Phone Number is required'
                },
                minLength: {
                  value: 10,
                  errorMessage: 'Phone Number must be 10 digits'
                },
                maxLength: {
                  value: 10,
                  errorMessage: 'Phone Number must be 10 digits'
                },
                async: validatePhoneNumber
              }}
              placeholder="Enter 10-digit phone number"
            />
          </FormGroup>
        </Col>
      </Row>

      <ModalFooter>
        <Button
          type="button"
          color="light"
          size="sm"
          onClick={goPrevState}
          disabled={requestingOtp}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          size="sm"
          color="success"
          disabled={requestingOtp}
        >
          {requestingOtp ? 'Loading ...' : 'Next'}
        </Button>
      </ModalFooter>
    </AvForm>
  );
}

function StepTwo({
  goPrevState,
  progressHandler,
  stepHandler,
  errorHandler,
  type
}) {
  const [requestingPasswordChangeToken, setRequestingPasswordChangeToken] =
    useState(false);
  const [inputOtp, setInputOtp] = useState('');

  async function submitHandler(event, values) {
    const verification_cred = localStorage.getItem(
      'FORGOT_PASSWORD_OTP_VERIFICATION'
    );

    if (!verification_cred) {
      errorHandler({
        success: false,
        error: true,
        message: 'Session Expired, please try again'
      });
      progressHandler(false);
      stepHandler(1);
    }

    setRequestingPasswordChangeToken(true);
    progressHandler(true);
    try {
      const { data } = await sendRequest.post(`/${type}/auth/verify-otp`, {
        phone_number: JSON.parse(verification_cred).phone_number,
        verification_key: JSON.parse(verification_cred).verification_key,
        otp_code: String(values.otp_code)
      });

      setRequestingPasswordChangeToken(false);
      localStorage.removeItem('FORGOT_PASSWORD_OTP_VERIFICATION');
      localStorage.setItem('PASSWORD_RESET_TOKEN', data.data.reset_token);
      stepHandler(3);
    } catch (e) {
      setRequestingPasswordChangeToken(false);
      errorHandler({
        success: false,
        error: true,
        message: e?.response?.data?.error
      });
      progressHandler(false);
      stepHandler(1);
    }
  }

  const validateOtpCode = async (value, ctx, input, cb) => {
    if (!validator.isNumeric(value)) {
      return cb('OTP should be numeric');
    }
    return cb(true);
  };

  return (
    <AvForm className="needs-validation" onValidSubmit={submitHandler}>
      <Row>
        <Col md={12}>
          <FormGroup className="auth-form-group-custom mb-4">
            <i className=" ri-lock-2-line auti-custom-input-icon"></i>
            <Label htmlFor="phone_number">OTP Code</Label>
            <AvField
              name="otp_code"
              type="text"
              className="form-control"
              id="otp_code"
              value={inputOtp}
              onChange={(e) => {
                setInputOtp(e.target.value);
              }}
              validate={{
                required: {
                  value: true,
                  errorMessage: 'OTP Code is required'
                },
                minLength: {
                  value: 5,
                  errorMessage: 'OTP Code must be 5 digits'
                },
                maxLength: {
                  value: 5,
                  errorMessage: 'OTP Code must be 5 digits'
                },
                async: validateOtpCode
              }}
              placeholder="Enter 5 digit OTP"
              style={
                inputOtp ? { fontSize: '22px', letterSpacing: '15px' } : {}
              }
            />
          </FormGroup>
        </Col>
      </Row>

      <ModalFooter>
        <Button
          type="button"
          color="light"
          size="sm"
          onClick={() => {
            goPrevState(1);
          }}
          disabled={requestingPasswordChangeToken}
        >
          Previous
        </Button>
        <Button
          type="submit"
          size="sm"
          color="success"
          disabled={requestingPasswordChangeToken}
        >
          {requestingPasswordChangeToken ? 'Loading ...' : 'Next'}
        </Button>
      </ModalFooter>
    </AvForm>
  );
}

function StepThree({
  goPrevState,
  progressHandler,
  stepHandler,
  errorHandler,
  successHandler,
  modalCloseHandler,
  type
}) {
  const [togglePassword, setTogglePassword] = useState({
    newPassword: false,
    confirmPassword: false
  });
  const [userInput, setUserInput] = useState({
    new_password: '',
    confirm_password: ''
  });
  const [changePasswordLoading, setChangePasswordLoading] = useState(false);

  async function submitHandler(event, values) {
    const password_reset_token = localStorage.getItem('PASSWORD_RESET_TOKEN');

    if (!password_reset_token) {
      errorHandler({
        success: false,
        error: true,
        message: 'Session Expired, please try again'
      });
      progressHandler(false);
      stepHandler(1);
    }

    setChangePasswordLoading(true);
    progressHandler(true);
    try {
      const { data } = await sendRequest.post(`/${type}/auth/reset-password`, {
        reset_token: password_reset_token,
        password: String(values.new_password),
        confirm_password: String(values.confirm_password)
      });

      setChangePasswordLoading(false);
      localStorage.removeItem('PASSWORD_RESET_TOKEN');
      successHandler({
        success: true,
        error: false,
        message: data?.message,
        show_alert: true
      });
      modalCloseHandler();
    } catch (e) {
      progressHandler(false);
      errorHandler({
        success: false,
        error: true,
        message: e?.response?.data?.error
      });
      stepHandler(1);
    }
  }

  function changeInput(e) {
    setUserInput({
      ...userInput,
      [e.target.name]: e.target.value
    });
  }

  const validatePasswordConfirmation = async (value, ctx, input, cb) => {
    if (value !== userInput.new_password) {
      return cb('Password did not match.');
    }
    return cb(true);
  };

  return (
    <AvForm className="needs-validation" onValidSubmit={submitHandler}>
      <Row>
        <Col md={12}>
          <FormGroup>
            <Label>
              New Password
              <i
                className={
                  togglePassword.newPassword
                    ? 'ri-eye-line ml-2'
                    : 'ri-eye-off-line ml-2'
                }
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setTogglePassword({
                    ...togglePassword,
                    newPassword: !togglePassword.newPassword
                  });
                }}
              ></i>
            </Label>
            <AvField
              name="new_password"
              type={togglePassword.newPassword ? 'text' : 'password'}
              className="form-control"
              validate={{
                required: {
                  value: true,
                  errorMessage: 'New Password is required'
                }
              }}
              disabled={changePasswordLoading}
              value={userInput.new_password}
              onChange={changeInput}
            />
          </FormGroup>
        </Col>
        <Col md={12}>
          <FormGroup>
            <Label>
              Confirm Password{' '}
              <i
                className={
                  togglePassword.confirmPassword
                    ? 'ri-eye-line ml-2'
                    : 'ri-eye-off-line ml-2'
                }
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setTogglePassword({
                    ...togglePassword,
                    confirmPassword: !togglePassword.confirmPassword
                  });
                }}
              ></i>
            </Label>
            <AvField
              name="confirm_password"
              type={togglePassword.confirmPassword ? 'text' : 'password'}
              className="form-control"
              validate={{
                required: {
                  value: true,
                  errorMessage: 'Confirm Password is required'
                },
                minLength: {
                  value: 6,
                  errorMessage: 'Password must be at least 6 characters'
                },
                async: validatePasswordConfirmation
              }}
              disabled={changePasswordLoading}
              value={userInput.confirm_password}
              onChange={changeInput}
            />
          </FormGroup>
        </Col>
      </Row>

      <ModalFooter>
        <Button
          type="button"
          color="light"
          size="sm"
          onClick={() => {
            goPrevState(2);
          }}
          disabled={changePasswordLoading}
        >
          Previous
        </Button>
        <Button
          type="submit"
          size="sm"
          color="success"
          disabled={changePasswordLoading}
        >
          {changePasswordLoading ? 'Loading ...' : 'Submit'}
        </Button>
      </ModalFooter>
    </AvForm>
  );
}

function CurrentModalBody({
  currentStep,
  resetModalHandler,
  progressHandler,
  stepHandler,
  errorHandler,
  successHandler,
  type
}) {
  switch (currentStep) {
    case 1:
      return (
        <StepOne
          goPrevState={resetModalHandler}
          progressHandler={progressHandler}
          stepHandler={stepHandler}
          errorHandler={errorHandler}
          type={type}
        />
      );
    case 2:
      return (
        <StepTwo
          goPrevState={stepHandler}
          progressHandler={progressHandler}
          stepHandler={stepHandler}
          errorHandler={errorHandler}
          type={type}
        />
      );
    case 3:
      return (
        <StepThree
          goPrevState={stepHandler}
          progressHandler={progressHandler}
          stepHandler={stepHandler}
          errorHandler={errorHandler}
          modalCloseHandler={resetModalHandler}
          successHandler={successHandler}
          type={type}
        />
      );
    default:
      return null;
  }
}

function ForgotPasswordModal({ modalOpenState, modalOpenHandler, type }) {
  const [currentStep, setCurrentStep] = useState(1);
  const [onProgress, setOnProgress] = useState(false);
  const [responseAlert, setResponseAlert] = useState({
    success: false,
    error: false,
    message: '',
    show_alert: false
  });

  function resetModalState() {
    modalOpenHandler(false);
    setOnProgress(false);
    setCurrentStep(1);
  }

  function resetResponseAlert() {
    setResponseAlert({
      success: false,
      error: false,
      message: '',
      show_alert: false
    });
  }

  return (
    <>
      {responseAlert.show_alert && (
        <SweetAlert
          error={responseAlert.error}
          success={responseAlert.success}
          title="Success"
          onConfirm={resetResponseAlert}
        >
          {responseAlert.message}
        </SweetAlert>
      )}
      <Modal isOpen={modalOpenState} backdrop="static">
        <ModalHeader
          toggle={() => {
            if (!onProgress) {
              resetModalState();
            }
          }}
        >
          Recover Password
        </ModalHeader>
        <ModalBody>
          {responseAlert?.error ? (
            <Alert
              color="danger"
              role="alert"
              isOpen={responseAlert?.error}
              toggle={resetResponseAlert}
            >
              <i className="mdi mdi-block-helper mr-2"></i>
              {responseAlert?.message}
            </Alert>
          ) : null}
          <CurrentModalBody
            currentStep={currentStep}
            resetModalHandler={resetModalState}
            progressHandler={setOnProgress}
            stepHandler={setCurrentStep}
            errorHandler={setResponseAlert}
            successHandler={setResponseAlert}
            type={type}
          />
        </ModalBody>
      </Modal>
    </>
  );
}

ForgotPasswordModal.propTypes = {
  modalOpenState: PropTypes.bool,
  modalOpenHandler: PropTypes.func,
  type: PropTypes.string
};

export default ForgotPasswordModal;
