/*jshint esversion: 11 */
import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Input,
  CardImg
} from 'reactstrap';
import { connect } from 'react-redux';
import PageDataLoader from '../Utility/PageDataLoader';
import moment from 'moment';
import { fetchImage } from '../../api/image';

const ViewStaffModal = ({
  modalOpenState,
  closeModalHandler,
  staffInfoLoading,
  staffInfo
}) => {
  const [imgUrl, setImgUrl] = useState('');
  const [imageLoading, setImageLoading] = useState(false);

  const getImage = async (key) => {
    setImageLoading(true);
    let response = await fetchImage(key);
    setImgUrl(URL.createObjectURL(response));
    setImageLoading(false);
  };

  useEffect(() => {
    let key;
    if (staffInfo.profile_picture && staffInfo.profile_picture.documentKey) {
      key = staffInfo.profile_picture.documentKey;
    } else {
      key = process.env.REACT_APP_PROFILE_IMG;
    }
    getImage(key);
  }, [staffInfo]);
  return (
    <React.Fragment>
      <Modal isOpen={modalOpenState} backdrop="static" size="lg">
        <ModalHeader toggle={closeModalHandler}>
          {staffInfoLoading ? 'Loading ...' : staffInfo.name}
        </ModalHeader>
        <ModalBody>
          {staffInfoLoading ? (
            <div className="d-flex justify-content-center">
              <PageDataLoader />
            </div>
          ) : (
            <React.Fragment>
              <Row>
                <Col
                  md="12"
                  className="d-flex justify-content-center align-items-center"
                >
                  {imageLoading ? (
                    <div className="text-center">
                      <PageDataLoader />
                    </div>
                  ) : (
                    <CardImg
                      src={imgUrl}
                      alt={staffInfo.name}
                      style={{ borderRadius: '50%', height: 200, width: 200 }}
                      onClick={() => {}}
                    />
                  )}
                </Col>
              </Row>
              <hr className="my-3" />
              <Row>
                <Col md="4">
                  <FormGroup>
                    <Label>Role</Label>
                    <Input
                      name="role_name"
                      type="text"
                      className="form-control"
                      defaultValue={staffInfo?.role?.role_name}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Email Address</Label>
                    <Input
                      name="email_address"
                      type="text"
                      className="form-control"
                      defaultValue={staffInfo?.email_address}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Phone Number</Label>
                    <Input
                      name="phone_number"
                      type="text"
                      className="form-control"
                      defaultValue={staffInfo?.phone_number}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Gender</Label>
                    <Input
                      name="gender"
                      type="text"
                      className="form-control"
                      defaultValue={staffInfo.gender}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>DOB</Label>
                    <Input
                      name="dob"
                      type="text"
                      className="form-control"
                      defaultValue={
                        moment(staffInfo.dob).format('DD-MM-YYYY') +
                        ' | ' +
                        moment(new Date()).diff(
                          new Date(staffInfo.dob),
                          'years'
                        ) +
                        ' years'
                      }
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Created At</Label>
                    <Input
                      name="created_at"
                      type="text"
                      className="form-control"
                      defaultValue={moment(staffInfo.createdAt).format(
                        'DD-MM-YYYY | hh:mm A'
                      )}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label>Work Experience</Label>
                    <Input
                      name="experience"
                      type="textarea"
                      className="form-control"
                      defaultValue={staffInfo?.experience}
                      readOnly
                    />
                  </FormGroup>
                </Col>
              </Row>
              <ModalFooter>
                <Button type="button" color="light" onClick={closeModalHandler}>
                  Close
                </Button>
              </ModalFooter>
            </React.Fragment>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(ViewStaffModal);
