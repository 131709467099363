/*jshint esversion: 11 */
import React, { useState } from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Alert,
  Input
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { getOfferingList } from '../../api/offering';
import SweetAlert from 'react-bootstrap-sweetalert';
import moment from 'moment';
import Select from 'react-select';
import { updateBatch } from '../../api/batch';
import PageDataLoader from '../Utility/PageDataLoader';
import { getStaffList } from '../../api/staff';
import { scheduleEnum } from '../Utility/Enumerator';
import FormUpload from '../../components/FormUpload';
import { viewSections } from '../../api/section';

const UpdateBatchModal = ({
  modalOpenState,
  closeModalHandler,
  authUser,
  fetchNewBatchList,
  batchInfoLoading,
  batchInfo
}) => {

  const scheduleOptions = scheduleEnum;
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [offeringOptions, setOfferingOptions] = useState([]);
  const [offeringOptionsLoading, setOfferingOptionsLoading] = useState(false);
  const [selectedOffering, setSelectedOffering] = useState(null);
  const [totalFee, setTotalFee] = useState(0);
  const [sessFee, setSessionlFee] = useState(0);
  const [noOfSessions, setNumberOfSessions] = useState(1);
  const [staffOptions, setStaffOptions] = useState([]);
  const [staffOptionsLoading, setStaffOptionsLoading] = useState(false);
  const [selectedPrimaryTeacher, setSelectedPrimaryTeacher] = useState(null);
  const [selectedSecondaryTeacher, setSelectedSecondaryTeacher] = useState(null);
  const [sectionLoading, setSectionLoading] = useState(false)
  const [sectionOptions, setSectionOptions] = useState([])
  const [attributes, setAttributes] = useState([])

  const [sessionBatch, setSessionbatch] = useState({
    value: false,
    label: "Normal Batch"
  })

  const [batchType, setbatchType] = useState([
    {
      value: false,
      label: "Normal Batch"
    },
    {
      value: true,
      label: "Session Batch"
    }
  ])



  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });
  const [batchUpdateRequestLoading, setBatchUpdateRequestLoading] =
    useState(false);
  const [successAlert, setSuccessAlert] = useState({
    message: '',
    show_alert: false
  });
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (authUser) {
      fetchOfferingOptions();
      fetchStaffOptions();
      fetchSections()
    }
    // eslint-disable-next-line
  }, [authUser, modalOpenState]);


  const fetchSections = async () => {
    setSectionLoading(true)
    const response = await viewSections(1, 100)
    if (response) {
      setSectionOptions(buildOptions(response?.data || []))
      setSectionLoading(false)
    }
  }

  useEffect(() => {
    if (batchInfo && offeringOptions.length > 0 && staffOptions.length > 0 && sectionOptions.length > 0) {
      setSelectedOffering(
        offeringOptions.find((k) => k.value === batchInfo?.offering?._id)
      );
      setSelectedPrimaryTeacher(
        staffOptions.find((k) => k.value === batchInfo?.primary_teacher?._id)
      );
      setSelectedSecondaryTeacher(
        staffOptions.find((k) => k.value === batchInfo?.secondary_teacher?._id)
      );

      setSelectedSchedule(
        scheduleOptions.find((k) => k.value === batchInfo?.schedule)
      );

      setAttributes(buildOptions(batchInfo?.attributes || []))


      setTotalFee(batchInfo.totalFee)
      setSessionbatch({
        value: batchInfo?.isSessionBatch || false,
        name: batchInfo?.isSessionBatch ? "Session Batch" : "Normal Batch"
      })
      setSessionlFee(batchInfo?.sessionFee)
    }
    // eslint-disable-next-line
  }, [batchInfo, offeringOptions, sectionOptions]);


  const fetchOfferingOptions = async () => {
    setOfferingOptionsLoading(true);
    const response = await getOfferingList(1, 100);
    if (response) {
      setOfferingOptions(buildOptions(response?.data || []));
      setOfferingOptionsLoading(false);
    }
  };



  const fetchStaffOptions = async () => {
    setStaffOptionsLoading(true);
    const response = await getStaffList(1, 100);
    if (response) {
      setStaffOptions(buildOptions(response?.data || []));
      setStaffOptionsLoading(false);
    }
  };



  function buildOptions(list) {
    return list.map((item) => ({
      value: item._id,
      label: item.name
    }));
  }


  const handleSectionToggle = (selectedData) => {
    setAttributes(selectedData)

  };

  async function handleBatchUpdate(event, values) {
    resetErrorAlert();
    if (!selectedOffering) {
      showErrorAlert('Please select a Offering for the Batch');
      return;
    }
    if (!selectedSchedule) {
      showErrorAlert('Please select a schedule')
      return
    }
    let form = new FormData();
    form.set('name', values.name);
    form.set('offering', selectedOffering.value);
    form.set('start_date', values.start_date);
    form.set('end_date', values.end_date);
    form.set('start_time', values.start_time);
    form.set('end_time', values.end_time);
    form.set('schedule', selectedSchedule.value);
    form.set('summary', values.summary);
    form.set('detail', values.detail);
    form.set('color', values.color);
    form.set('sessionFee', parseInt(sessFee) || 0);
    form.set('totalFee', parseInt(totalFee) || 0);
    form.set('totalSessions', parseInt(noOfSessions));
    form.set('isSessionBatch', sessionBatch.value);

    if (selectedPrimaryTeacher)
      form.set('primary_teacher', selectedPrimaryTeacher?.value);
    if (selectedSecondaryTeacher)
      form.set('secondary_teacher', selectedSecondaryTeacher?.value);
    if (images && images.length > 0) {
      images.map((g, index) => form.append(`pictures`, g));
    }

    if (attributes.length > 0) {
      attributes.map((attribute) => {
        console.log(attribute)
        form.append("attributes", attribute.value)
      })
    }


    setBatchUpdateRequestLoading(true);
    const { response, error } = await updateBatch(batchInfo._id, form);
    setBatchUpdateRequestLoading(false);
    if (error) {
      showErrorAlert(error);
      return;
    }
    setNumberOfSessions(1)
    setTotalFee(0)
    setSessionlFee(0)
    setSessionbatch({
      value: false,
      label: "Normal Batch"
    })
    closeModalHandler();
    showSuccessAlert(response.message);
    fetchNewBatchList();
  }

  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }

  function showSuccessAlert(message) {
    setSuccessAlert({ message: message, show_alert: true });
  }

  function resetSuccessAlert() {
    setSuccessAlert({ message: '', show_alert: false });
  }

  function resetState() {
    resetSuccessAlert();
    resetErrorAlert();
  }

  function handleOfferingSelection(selectedItem) {
    setSelectedOffering(selectedItem);
  }

  function handleScheduleSelection(selectedItem) {
    setSelectedSchedule(selectedItem);
  }

  function handlePrimaryTeacherSelection(selectedItem) {
    setSelectedPrimaryTeacher(selectedItem);
  }

  function handleSecondaryTeacherSelection(selectedItem) {
    setSelectedSecondaryTeacher(selectedItem);
  }

  function handleSessionFee(e) {
    setSessionlFee(e.target.value);
    claculateTotalFee({ sessionFee: e.target.value })
  }

  function claculateTotalFee({ sessionFee = sessFee, noOfSess = noOfSessions }) {
    let value = parseInt(sessionFee) * parseInt(noOfSess);
    setTotalFee(value);
  }

  function handleNoOfSessions(e) {
    setNumberOfSessions(e.target.value)
    claculateTotalFee({ noOfSess: e.target.value })
  }

  function handleSelectSessionType(selectedItem) {
    setTotalFee(0)
    setNumberOfSessions(1)
    setSessionlFee(0)
    claculateTotalFee({ sessionFee: 0, noOfSess: 1 })
    setSessionbatch(selectedItem);
  }



  return (
    <React.Fragment>
      {successAlert.show_alert && (
        <SweetAlert success title="Updated" onConfirm={resetState}>
          {successAlert.message}
        </SweetAlert>
      )}
      <Modal isOpen={modalOpenState} backdrop="static" size="xl">
        <ModalHeader toggle={closeModalHandler}>
          {batchInfoLoading ? 'Loading ...' : 'Edit [' + batchInfo.name + ']'}
        </ModalHeader>
        <ModalBody>
          {batchInfoLoading ? (
            <div className="d-flex justify-content-center">
              <PageDataLoader />
            </div>
          ) : (
            <React.Fragment>
              {requestError ? (
                <Alert
                  color="danger"
                  role="alert"
                  isOpen={requestError.show_alert}
                  toggle={resetErrorAlert}
                >
                  <i className="mdi mdi-block-helper mr-2"></i>
                  {requestError.message}
                </Alert>
              ) : null}
              <AvForm
                className="needs-validation"
                onValidSubmit={handleBatchUpdate}
              >
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>Batch Name</Label>
                      <AvField
                        name="name"
                        placeholder="Enter Batch Name ..."
                        type="text"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Batch name is required'
                          }
                        }}
                        defaultValue={batchInfo?.name}
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <Label>Offering</Label>
                      <Select
                        options={offeringOptions}
                        disabled={offeringOptionsLoading}
                        onChange={handleOfferingSelection}
                        value={selectedOffering}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label>Color</Label>
                      <AvField
                        name="color"
                        placeholder="Enter Batch Color ..."
                        type="color"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Batch color is required'
                          }
                        }}
                        defaultValue={batchInfo?.color}
                      />
                    </FormGroup>
                  </Col>

                  <Col md="4">
                    <FormGroup>
                      <Label>Start Date</Label>
                      <AvField
                        name="start_date"
                        placeholder="Enter Start Date ..."
                        type="date"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Start date is required'
                          }
                        }}
                        defaultValue={
                          batchInfo &&
                          moment(new Date(batchInfo.start_date)).format(
                            'YYYY-MM-DD'
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label>End Date</Label>
                      <AvField
                        name="end_date"
                        placeholder="Enter End Date ..."
                        type="date"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'End date is required'
                          }
                        }}
                        defaultValue={
                          batchInfo &&
                          moment(new Date(batchInfo.end_date)).format(
                            'YYYY-MM-DD'
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label>Start Time</Label>
                      <AvField
                        name="start_time"
                        placeholder="Enter Start time ..."
                        type="time"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Start time is required'
                          }
                        }}
                        defaultValue={batchInfo?.start_time}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label>End Time</Label>
                      <AvField
                        name="end_time"
                        placeholder="Enter End Time ..."
                        type="time"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'End time is required'
                          }
                        }}
                        defaultValue={batchInfo?.end_time}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label>Schedule</Label>
                      <Select
                        options={scheduleOptions}
                        onChange={handleScheduleSelection}
                        value={selectedSchedule}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Primary Teacher</Label>
                      <Select
                        options={staffOptions}
                        disabled={staffOptionsLoading}
                        onChange={handlePrimaryTeacherSelection}
                        value={selectedPrimaryTeacher}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Secondary Teacher</Label>
                      <Select
                        options={staffOptions}
                        disabled={staffOptionsLoading}
                        onChange={handleSecondaryTeacherSelection}
                        value={selectedSecondaryTeacher}
                      />
                    </FormGroup>
                  </Col>
                  {batchInfo && !batchInfo?.children?.length ? <Col md="4">
                    <FormGroup>
                      <Label>Batch Type</Label>
                      <Select
                        options={batchType}
                        onChange={handleSelectSessionType}
                        value={sessionBatch}

                      />
                    </FormGroup>
                  </Col> : null}
                  <Col md='2'>
                    <FormGroup>
                      <Label>{sessionBatch?.value ? "Session Fee" : "Batch Fee"}</Label>

                      <Input
                        type="number"
                        value={sessFee}
                        onChange={(e) => handleSessionFee(e)}
                        className="form-control"
                      />
                    </FormGroup>
                  </Col>
                  {sessionBatch?.value ? <Col md='2'>
                    <FormGroup>
                      <Label>Number Of Sessions</Label>
                      <Input
                        type="number"
                        value={noOfSessions}
                        onChange={(e) => handleNoOfSessions(e)}
                        className="form-control"
                      />
                    </FormGroup>
                  </Col> : null}
                  <Col md='2'>
                    <FormGroup>
                      <Label>Total Fee</Label>
                      <Input
                        type="number"
                        value={totalFee}
                        onChange={(e) => setTotalFee(e.target.value)}
                        className="form-control"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <Label for="sections">Select attributes</Label>
                      <Select
                        // styles={styles}
                        disabled={sectionLoading}
                        options={sectionOptions}
                        isClearable={false}
                        isMulti
                        value={attributes}
                        onChange={handleSectionToggle}
                      />
                      {/* </FormGroup> */}
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <Label>Summary</Label>
                      <AvField
                        name="summary"
                        placeholder="Enter Summary ..."
                        type="text"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Summary is required'
                          }
                        }}
                        defaultValue={batchInfo?.summary}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <Label>Detail</Label>
                      <AvField
                        name="detail"
                        placeholder="Enter Detail ..."
                        type="textarea"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Detail is required'
                          }
                        }}
                        defaultValue={batchInfo?.detail}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormUpload title="Images" setImages={setImages} />
                  </Col>
                </Row>
                <ModalFooter>
                  <Button
                    disabled={batchUpdateRequestLoading}
                    type="button"
                    color="light"
                    onClick={closeModalHandler}
                  >
                    Close
                  </Button>
                  <Button type="submit" color="success">
                    {batchUpdateRequestLoading
                      ? 'Updating ...'
                      : 'Update Batch'}
                  </Button>
                </ModalFooter>
              </AvForm>
            </React.Fragment>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(UpdateBatchModal);
