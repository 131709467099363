/*jshint esversion: 11 */
import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Table,
  Label,
  FormGroup,
  Button,
  Badge
} from 'reactstrap';
import { connect } from 'react-redux';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import PageDataLoader from '../Utility/PageDataLoader';
import Paginator from '../Utility/Paginator';
import { getParentInfo, getParentList } from '../../api/parent';
import moment from 'moment';
import CreateParentModal from './CreateParentModal';
import ViewParentModal from './ViewParentModal';
import UpdateParentModal from './UpdateParentModal';
import { validatePermissionKey } from '../../helpers/permission';
import {
  CREATE_PARENT,
  DELETE_PARENT,
  UPDATE_PARENT
} from '../Utility/PermissionKeys';
import { stringify as QStringify, parse as QParse } from 'qs';
import { useHistory } from 'react-router-dom';
import validator from 'validator';
import ParentFilterModal from './ParentFilterModal';
import DeleteParentModal from './DeleteParentModal';

const ParentListPage = ({ authUser }) => {
  const history = useHistory();
  const [filterError, setFilterError] = useState(false);
  const [breadcrumbItems] = useState([
    { title: 'Parents', link: '#' },
    { title: 'View List', link: '#' }
  ]);
  const [parentListLoading, setParentListLoading] = useState(true);
  const [parentList, setParentList] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageLimit: 10,
    totalEntries: 0,
    totalPages: 0
  });
  const [addParentModalOpen, setAddParentModalOpen] = useState(false);
  const [parentInfo, setParentInfo] = useState({});
  const [parentInfoLoading, setParentInfoLoading] = useState(false);
  const [parentInfoModalOpen, setParentInfoModalOpen] = useState(false);
  const [updateParentModalOpen, setUpdateParentModalOpen] = useState(false);
  const [sortingRule, setSortingRule] = useState('created_at$desc');
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [currentFilterCount, setCurrentFilterCount] = useState(0);
  const [dobFilter, setDobFilter] = useState({
    startDate: null,
    endDate: null
  });
  const [filterParentName, setFilterParentName] = useState('');
  const [filterPhone, setFilterPhone] = useState('');
  const [filterGender, setFilterGender] = useState('');
  const [filterLocation, setFilterLocation] = useState('');
  const [deleteParentModalOpen, setDeleteParentModalOpen] = useState(false);
  const [parentId, setParentId] = useState('');
  const [isDeleted, setIsDeleted] = useState(false);

  let listElem = document.getElementById('parentList');
  let linkElem = document.getElementById('parentLink');

  useEffect(() => {
    if (listElem) listElem.classList.add('mm-active');
    if (linkElem) linkElem.classList.add('active');
    if (authUser) {
      populateSearchOptions(history?.location?.search);
    }
    // eslint-disable-next-line
  }, [authUser, listElem, linkElem]);

  const handlePagination = (page) => {
    fetchData(page, pagination.pageLimit, createQueryOptions(), sortingRule);
  };

  const fetchData = async (
    current_page = 1,
    page_limit = 10,
    queryOptions = {},
    sortingOption = sortingRule
  ) => {
    setParentListLoading(true);
    const response = await getParentList(
      current_page,
      page_limit,
      queryOptions,
      sortingOption
    );

    if (response) {
      setPagination({
        currentPage: response.metadata.page,
        pageLimit: response.metadata.limit,
        totalPages: response.metadata.total_pages,
        totalEntries: response.metadata.total_results
      });
      setParentList(response.data);
      setParentListLoading(false);
    }
  };

  /**
   *
   * @param {string} str
   */
  function getValidDateInput(str) {
    return validator.isDate(str) ? new Date(str).toISOString() : '';
  }

  function createQueryOptions(
    startDate = dobFilter.startDate,
    endDate = dobFilter.endDate,
    name = filterParentName,
    phone_number = filterPhone,
    gender = filterGender,
    address_location = filterLocation,
    deletedStatus = isDeleted
  ) {
    if (
      (startDate && !endDate) ||
      (!startDate && endDate) ||
      new Date(startDate) > new Date(endDate)
    ) {
      setFilterError('Enter valid date range');
      return;
    }
    const startDateISOStringDob = getValidDateInput(startDate);
    const endDateISOStringDob = getValidDateInput(endDate);
    const queryOptions = {
      filter_dob_start_date: startDateISOStringDob,
      filter_dob_end_date: endDateISOStringDob,
      filter_name: name,
      filter_phone_number: phone_number,
      filter_gender: gender,
      filter_address_location: address_location,
      include_deleted: deletedStatus,
      sorting_option: sortingRule
    };
    updateUrlPath(queryOptions);
    return queryOptions;
  }

  function updateFilterCount(option) {
    let count = 0;
    Object.keys(option).forEach((i) => {
      if (option[i]?.toString()?.trim()?.length > 0 && option[i] !== false) {
        count += 1;
      }
    });
    setCurrentFilterCount(count - 1);
  }

  /**
   *
   * @param {string} searchQuery
   */
  function populateSearchOptions(searchQuery) {
    const parsedQuery = QParse(searchQuery.substring(1));
    if (parsedQuery.filter_dob_start_date && parsedQuery.filter_dob_end_date) {
      updateDateFilterInput(
        parsedQuery.filter_dob_start_date,
        parsedQuery.filter_dob_end_date,
        setDobFilter
      );
    }
    setFilterParentName(parsedQuery.filter_name);
    setFilterPhone(parsedQuery.filter_phone_number);
    setFilterGender(parsedQuery.filter_gender);
    setFilterLocation(parsedQuery.filter_address_location);
    const sortingOption = parsedQuery.sorting_option || sortingRule;
    setSortingRule(sortingOption);
    updateFilterCount(parsedQuery);
    fetchData(1, null, parsedQuery, sortingOption);
  }

  /**
   *
   * @param {string} str
   * @param {function} setHook
   * @returns
   */
  function updateDateFilterInput(startStr, endStr, setHook) {
    setHook({
      startDate: new Date(startStr),
      endDate: new Date(endStr)
    });
  }

  function updateUrlPath(queryOptions) {
    const queryString = QStringify(queryOptions);
    history.replace(`/parents?${queryString}`);
  }

  async function fetchSortedList(sortingOption) {
    setSortingRule(sortingOption);
    const queryOptions = createQueryOptions();
    queryOptions.sorting_option = sortingOption;
    updateUrlPath(queryOptions);
    fetchData(1, null, queryOptions, sortingOption);
  }

  function openFilterModal() {
    setFilterModalOpen(true);
  }

  function closeFilterModal() {
    setFilterModalOpen(false);
  }

  function openAddParentModal() {
    setAddParentModalOpen(true);
  }

  function closeAddParentModal() {
    setAddParentModalOpen(false);
  }

  function openParentInfoModal(roleId) {
    fetchParentInfo(roleId);
    setParentInfoModalOpen(true);
  }

  function closeParentInfoModal() {
    setParentInfoModalOpen(false);
  }

  async function openUpdateParentModal(parentId) {
    fetchParentInfo(parentId);
    setUpdateParentModalOpen(true);
  }

  function closeUpdateParentModal() {
    setUpdateParentModalOpen(false);
  }

  async function openDeleteParentModal(parentId, requestType) {
    setParentId({
      parentId,
      requestType
    });
    setDeleteParentModalOpen(true);
  }

  function closeDeleteParentModal() {
    setDeleteParentModalOpen(false);
  }

  async function fetchParentInfo(parentId) {
    setParentInfoLoading(true);
    const response = await getParentInfo(parentId);
    if (response) {
      setParentInfo(response);
      setParentInfoLoading(false);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <ParentFilterModal
          filterError={filterError}
          setFilterError={setFilterError}
          modalOpenState={filterModalOpen}
          closeModalHandler={closeFilterModal}
          fetchList={fetchData}
          updateFilterCount={updateFilterCount}
          updateUrlPath={updateUrlPath}
          parentName={filterParentName}
          setParentName={setFilterParentName}
          parentPhone={filterPhone}
          setParentPhone={setFilterPhone}
          parentDob={dobFilter}
          setParentDob={setDobFilter}
          createQueryOptions={createQueryOptions}
          parentGender={filterGender}
          setParentGender={setFilterGender}
          parentLocation={filterLocation}
          setParentLocation={setFilterLocation}
          authUser={authUser}
          isDeleted={isDeleted}
          setIsDeleted={setIsDeleted}
        />
        <CreateParentModal
          modalOpenState={addParentModalOpen}
          closeModalHandler={closeAddParentModal}
          fetchNewParentList={async () => {
            let options = await createQueryOptions();
            if (options) {
              await fetchData(1, null, options);
            }
          }}
        />
        <ViewParentModal
          modalOpenState={parentInfoModalOpen}
          closeModalHandler={closeParentInfoModal}
          parentInfo={parentInfo}
          parentInfoLoading={parentInfoLoading}
        />
        <UpdateParentModal
          modalOpenState={updateParentModalOpen}
          closeModalHandler={closeUpdateParentModal}
          parentInfo={parentInfo}
          parentInfoLoading={parentInfoLoading}
          fetchNewParentList={async () => {
            let options = await createQueryOptions();
            if (options) {
              await fetchData(1, null, options);
            }
          }}
        />
        {parentId && (
          <DeleteParentModal
            modalOpenState={deleteParentModalOpen}
            closeModalHandler={closeDeleteParentModal}
            parentId={parentId.parentId}
            type={parentId.requestType}
            fetchNewData={async () => {
              let options = await createQueryOptions();
              if (options) {
                await fetchData(1, null, options);
              }
            }}
          />
        )}
        <Container fluid>
          <Breadcrumbs title="View Parents" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <>
                    <Row className="mt-2">
                      <Col md="2">
                        <FormGroup>
                          <Col md="12">
                            <Label className="col-form-label">
                              Show Entries
                            </Label>
                            <select
                              className="form-control"
                              onChange={(e) => {
                                fetchData(
                                  1,
                                  e.target.value,
                                  createQueryOptions(),
                                  sortingRule
                                );
                                setPagination({
                                  ...pagination,
                                  pageLimit: e.target.value
                                });
                              }}
                              value={pagination.pageLimit}
                              disabled={parentListLoading}
                            >
                              <option value={10}>10</option>
                              <option value={25}>25</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <Label className="col-form-label">Sort Parent</Label>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              fetchSortedList(e.target.value);
                            }}
                            value={sortingRule}
                            disabled={parentListLoading}
                          >
                            <option value="created_at$desc">
                              Create Time (Newest First)
                            </option>
                            <option value="created_at$asc">
                              Create Time (Oldest First)
                            </option>
                            <option value="location$asc">Location (A-Z)</option>
                            <option value="location$desc">
                              Location (Z-A)
                            </option>
                            <option value="gender$asc">Gender (A-Z)</option>
                            <option value="gender$desc">Gender (Z-A)</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col className="d-flex justify-content-end align-items-center">
                        <Button
                          size="sm"
                          color="primary"
                          className=" waves-effect waves-light mr-3"
                          onClick={openFilterModal}
                        >
                          <i className="ri-filter-fill align-middle mr-2"></i>
                          <span>Filter Parent</span>
                          {currentFilterCount > 0 && (
                            <Badge color="light" pill className="ml-2">
                              {currentFilterCount}
                            </Badge>
                          )}
                        </Button>
                        {validatePermissionKey(authUser, CREATE_PARENT) && (
                          <Button
                            size="sm"
                            color="success"
                            className=" waves-effect waves-light"
                            onClick={openAddParentModal}
                          >
                            <i className="ri-add-line align-middle mr-2"></i>
                            Add Parent
                          </Button>
                        )}
                      </Col>
                    </Row>
                    {parentListLoading ? (
                      <div className="text-center">
                        <PageDataLoader />
                      </div>
                    ) : (
                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <Table
                            id="tech-companies-1"
                            striped
                            bordered
                            responsive
                          >
                            <thead>
                              <tr>
                                <th>SL. No</th>
                                <th>Name</th>
                                <th>Phone Number</th>
                                <th>Gender</th>
                                <th>DOB</th>
                                <th>Address Location</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {parentList?.map((item, index) => (
                                <tr key={index}>
                                  <th>{index + 1}</th>
                                  <td>{item.name}</td>
                                  <td>{item.phone_number}</td>
                                  <td>{item.gender}</td>
                                  <td>
                                    {moment(new Date(item.dob)).format(
                                      'DD-MM-YYYY'
                                    )}
                                  </td>
                                  <td>{item.address_location}</td>
                                  <td>
                                    <Button
                                      size="sm"
                                      color="primary"
                                      className=" waves-effect waves-light mr-2 mb-2"
                                      onClick={() => {
                                        openParentInfoModal(item._id);
                                      }}
                                    >
                                      <i className="fas fa-eye mr-2"></i>
                                      View
                                    </Button>
                                    {validatePermissionKey(
                                      authUser,
                                      UPDATE_PARENT
                                    ) && (
                                      <Button
                                        size="sm"
                                        color="warning"
                                        className=" waves-effect waves-light mr-2 mb-2"
                                        onClick={() => {
                                          openUpdateParentModal(item._id);
                                        }}
                                      >
                                        <i className="fas fa-edit mr-2"></i>
                                        Edit
                                      </Button>
                                    )}
                                    {validatePermissionKey(
                                      authUser,
                                      DELETE_PARENT
                                    ) && (
                                      <Button
                                        size="sm"
                                        color="danger"
                                        className=" waves-effect waves-light mb-2"
                                        onClick={() => {
                                          openDeleteParentModal(
                                            item._id,
                                            item.isDeleted
                                              ? 'restore'
                                              : 'delete'
                                          );
                                        }}
                                      >
                                        {item.isDeleted ? (
                                          <i className="fas fa-trash-restore mr-2"></i>
                                        ) : (
                                          <i className="fas fa-trash mr-2"></i>
                                        )}
                                        {item.isDeleted ? 'Restore' : 'Delete'}
                                      </Button>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    )}
                    <Row className="mt-2">
                      <Col md="5">
                        <p>
                          <strong>
                            {' '}
                            Showing{' '}
                            {(pagination.currentPage - 1) *
                              pagination.pageLimit +
                              1}{' '}
                            to{' '}
                            {Math.min(
                              pagination.currentPage * pagination.pageLimit,
                              pagination.totalEntries
                            )}{' '}
                            of {pagination.totalEntries} entries
                          </strong>
                        </p>
                      </Col>
                      {!parentListLoading && parentList.length > 0 && (
                        <Col md="7">
                          <Paginator
                            totalPages={pagination.totalPages}
                            currentPage={pagination.currentPage}
                            changePage={handlePagination}
                          />
                        </Col>
                      )}
                    </Row>
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(ParentListPage);
