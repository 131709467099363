/*jshint esversion: 11 */
import React, { useState, useEffect, useMemo } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Table,
  Label,
  FormGroup,
  Button,
  Badge
} from 'reactstrap';
import { connect } from 'react-redux';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import PageDataLoader from '../Utility/PageDataLoader';
import Paginator from '../Utility/Paginator';
import { getActivityInfo, getActivityList } from '../../api/activity';
import moment from 'moment';
import CreateActivityModal from './CreateActivityModal';
import ViewActivityModal from './ViewActivityModal';
import UpdateActivityModal from './UpdateActivityModal';
import { validatePermissionKey } from '../../helpers/permission';
import {
  CREATE_ACTIVITY,
  DELETE_ACTIVITY,
  UPDATE_ACTIVITY
} from '../Utility/PermissionKeys';
import { stringify as QStringify, parse as QParse } from 'qs';
import { useHistory } from 'react-router-dom';
import validator from 'validator';
import ActivityFilterModal from './ActivityFilterModal';
import DeleteActivityModal from './DeleteActivityModal';
import { getTagList } from '../../api/tag';

const ActivityListPage = ({ authUser }) => {
  const history = useHistory();

  const [breadcrumbItems] = useState([
    { title: 'Activities', link: '#' },
    { title: 'View List', link: '#' }
  ]);
  const [activityListLoading, setActivityListLoading] = useState(true);
  const [activityList, setActivityList] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageLimit: 10,
    totalEntries: 0,
    totalPages: 0
  });
  const [addActivityModalOpen, setAddActivityModalOpen] = useState(false);
  const [activityInfo, setActivityInfo] = useState({});
  const [activityInfoLoading, setActivityInfoLoading] = useState(false);
  const [activityInfoModalOpen, setActivityInfoModalOpen] = useState(false);
  const [updateActivityModalOpen, setUpdateActivityModalOpen] = useState(false);
  const [sortingRule, setSortingRule] = useState('created_at$desc');
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [currentFilterCount, setCurrentFilterCount] = useState(0);
  const [createdAtFilter, setCreatedAtFilter] = useState({
    startDate: null,
    endDate: null
  });
  const [updatedAtFilter, setUpdatedAtFilter] = useState({
    startDate: null,
    endDate: null
  });
  const [filterName, setFilterName] = useState('');
  const [filterDuration, setFilterDuration] = useState({
    min: '',
    max: ''
  });

  const [filterError, setFilterError] = useState(false);
  const [deleteActivityModalOpen, setDeleteActivityModalOpen] = useState(false);
  const [activityId, setActivityId] = useState('');
  const [isDeleted, setIsDeleted] = useState(false);
  const [tagQueryId, setTagQueryId] = useState([]);
  const [filterTagList, setFilterTagList] = useState([]);
  const [selectedFilterTagOption, setSelectedFilterTagOption] = useState([]);

  const filterTagOptions = useMemo(
    () => buildTagOptions(filterTagList),
    // eslint-disable-next-line
    [filterTagList]
  );

  let listElem = document.getElementById('activityList');
  let linkElem = document.getElementById('activityLink');

  useEffect(() => {
    if (listElem) listElem.classList.add('mm-active');
    if (linkElem) linkElem.classList.add('active');
    if (authUser) {
      fetchTagOptions();
      populateSearchOptions(history?.location?.search);
    }
    // eslint-disable-next-line
  }, [authUser, linkElem, listElem]);

  const handlePagination = (page) => {
    fetchData(page, pagination.pageLimit, createQueryOptions(), sortingRule);
  };

  const fetchData = async (
    current_page = 1,
    page_limit = 10,
    queryOptions = {},
    sortingOption = sortingRule
  ) => {
    setActivityListLoading(true);
    const response = await getActivityList(
      current_page,
      page_limit,
      queryOptions,
      sortingOption
    );

    if (response) {
      setPagination({
        currentPage: response.metadata.page,
        pageLimit: response.metadata.limit,
        totalPages: response.metadata.total_pages,
        totalEntries: response.metadata.total_results
      });
      setActivityList(response.data);
      setActivityListLoading(false);
    }
  };

  /**
   *
   * @param {string} str
   */
  function getValidDateInput(str) {
    return validator.isDate(str) ? new Date(str).toISOString() : '';
  }

  async function fetchTagOptions() {
    const response = await getTagList(1, 100, {});
    if (response) {
      setFilterTagList(response?.data);
    }
  }

  /**
   *
   * @param {array<object>} tagArray
   * @returns
   */
  function buildTagOptions(tagArray) {
    const tagOptions = tagArray.map((i) => {
      return {
        label: i.name,
        value: i
      };
    });
    const selectedTagOption = tagOptions.filter((i) =>
      tagQueryId.includes(i.value._id)
    );
    setSelectedFilterTagOption(selectedTagOption);
    return tagOptions;
  }

  function createQueryOptions(
    createdStart = createdAtFilter.startDate,
    createdEnd = createdAtFilter.endDate,
    updatedStart = updatedAtFilter.startDate,
    updatedEnd = updatedAtFilter.endDate,
    name = filterName,
    minDuration = filterDuration.min,
    maxDuration = filterDuration.max,
    deletedStatus = isDeleted,
    tags = selectedFilterTagOption
  ) {
    setFilterError(false);
    if (
      (!createdStart && createdEnd) ||
      (createdStart && !createdEnd) ||
      (!updatedStart && updatedEnd) ||
      (updatedStart && !updatedEnd)
    ) {
      setFilterError('Enter valid date range');
      return;
    }

    if (
      (!minDuration && maxDuration) ||
      (minDuration && !maxDuration) ||
      parseInt(minDuration) > parseInt(maxDuration)
    ) {
      setFilterError('Enter valid duration range');
      return;
    }
    const selectedTag = Array.isArray(tags)
      ? tags.map((i) => i.value._id).join(',')
      : '';
    const startDateISOStringCreatedAt = getValidDateInput(createdStart);
    const endDateISOStringCreatedAt = getValidDateInput(createdEnd);
    const startDateISOStringUpdatedAt = getValidDateInput(updatedStart);
    const endDateISOStringUpdatedAt = getValidDateInput(updatedEnd);
    const queryOptions = {
      filter_created_at_start: startDateISOStringCreatedAt,
      filter_created_at_end: endDateISOStringCreatedAt,
      filter_updated_at_start: startDateISOStringUpdatedAt,
      filter_updated_at_end: endDateISOStringUpdatedAt,
      filter_name: name,
      filter_tag_id: selectedTag,
      filter_duration_min: minDuration,
      filter_duration_max: maxDuration,
      include_deleted: deletedStatus,
      sorting_option: sortingRule
    };
    updateUrlPath(queryOptions);
    return queryOptions;
  }

  function updateFilterCount(option) {
    let count = 0;
    Object.keys(option).forEach((i) => {
      if (option[i]?.toString()?.trim()?.length > 0 && option[i] !== false) {
        count += 1;
      }
    });
    setCurrentFilterCount(count - 1);
  }

  /**
   *
   * @param {string} searchQuery
   */
  function populateSearchOptions(searchQuery) {
    const parsedQuery = QParse(searchQuery.substring(1));
    if (parsedQuery.filter_tag_id) {
      const tagId = parsedQuery.filter_tag_id.split(',').map((i) => i.trim());
      setTagQueryId(tagId);
    }
    if (
      parsedQuery.filter_created_at_start &&
      parsedQuery.filter_created_at_end
    ) {
      updateDateFilterInput(
        parsedQuery.filter_created_at_start,
        parsedQuery.filter_created_at_end,
        setCreatedAtFilter
      );
    }
    if (
      parsedQuery.filter_updated_at_start &&
      parsedQuery.filter_updated_at_end
    ) {
      updateDateFilterInput(
        parsedQuery.filter_updated_at_start,
        parsedQuery.filter_updated_at_end,
        setUpdatedAtFilter
      );
    }
    setFilterName(parsedQuery.filter_name);
    setFilterDuration({
      min: parsedQuery.filter_duration_min,
      max: parsedQuery.filter_duration_max
    });
    const sortingOption = parsedQuery.sorting_option || sortingRule;
    setSortingRule(sortingOption);
    updateFilterCount(parsedQuery);
    fetchData(1, null, parsedQuery, sortingOption);
  }

  /**
   *
   * @param {string} str
   * @param {function} setHook
   * @returns
   */
  function updateDateFilterInput(startStr, endStr, setHook) {
    setHook({
      startDate: new Date(startStr),
      endDate: new Date(endStr)
    });
  }

  function updateUrlPath(queryOptions) {
    const queryString = QStringify(queryOptions);
    history.replace(`/activities?${queryString}`);
  }

  async function fetchSortedList(sortingOption) {
    setSortingRule(sortingOption);
    const queryOptions = createQueryOptions();
    queryOptions.sorting_option = sortingOption;
    updateUrlPath(queryOptions);
    fetchData(1, null, queryOptions, sortingOption);
  }

  function openFilterModal() {
    setFilterModalOpen(true);
  }

  function closeFilterModal() {
    setFilterModalOpen(false);
  }

  function openAddActivityModal() {
    setAddActivityModalOpen(true);
  }

  function closeAddActivityModal() {
    setAddActivityModalOpen(false);
  }

  function openActivityInfoModal(roleId) {
    fetchActivityInfo(roleId);
    setActivityInfoModalOpen(true);
  }

  function closeActivityInfoModal() {
    setActivityInfoModalOpen(false);
  }

  async function openUpdateActivityModal(activityId) {
    fetchActivityInfo(activityId);
    setUpdateActivityModalOpen(true);
  }

  function closeUpdateActivityModal() {
    setUpdateActivityModalOpen(false);
  }

  async function fetchActivityInfo(activityId) {
    setActivityInfoLoading(true);
    const response = await getActivityInfo(activityId);
    if (response) {
      setActivityInfo(response);
      setActivityInfoLoading(false);
    }
  }

  async function openDeleteActivityModal(activityId, requestType) {
    setActivityId({
      activityId,
      requestType
    });
    setDeleteActivityModalOpen(true);
  }

  function closeDeleteActivityModal() {
    setDeleteActivityModalOpen(false);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <ActivityFilterModal
          filterError={filterError}
          setFilterError={setFilterError}
          modalOpenState={filterModalOpen}
          closeModalHandler={closeFilterModal}
          fetchList={fetchData}
          updateFilterCount={updateFilterCount}
          updateUrlPath={updateUrlPath}
          name={filterName}
          setName={setFilterName}
          createdAtFilter={createdAtFilter}
          setCreatedAtFilter={setCreatedAtFilter}
          updatedAtFilter={updatedAtFilter}
          setUpdatedAtFilter={setUpdatedAtFilter}
          createQueryOptions={createQueryOptions}
          duration={filterDuration}
          setDuration={setFilterDuration}
          authUser={authUser}
          isDeleted={isDeleted}
          setIsDeleted={setIsDeleted}
          tagOptions={filterTagOptions}
          selectedTagOption={selectedFilterTagOption}
          setSelectedTagOption={setSelectedFilterTagOption}
        />
        <CreateActivityModal
          modalOpenState={addActivityModalOpen}
          closeModalHandler={closeAddActivityModal}
          fetchNewActivityList={async () => {
            let options = await createQueryOptions();
            if (options) {
              await fetchData(1, null, options);
            }
          }}
        />
        <ViewActivityModal
          modalOpenState={activityInfoModalOpen}
          closeModalHandler={closeActivityInfoModal}
          activityInfo={activityInfo}
          activityInfoLoading={activityInfoLoading}
        />
        <UpdateActivityModal
          modalOpenState={updateActivityModalOpen}
          closeModalHandler={closeUpdateActivityModal}
          activityInfo={activityInfo}
          activityInfoLoading={activityInfoLoading}
          fetchNewActivityList={async () => {
            let options = await createQueryOptions();
            if (options) {
              await fetchData(1, null, options);
            }
          }}
        />
        {activityId && (
          <DeleteActivityModal
            modalOpenState={deleteActivityModalOpen}
            closeModalHandler={closeDeleteActivityModal}
            activityId={activityId.activityId}
            type={activityId.requestType}
            fetchNewData={async () => {
              let options = await createQueryOptions();
              if (options) {
                await fetchData(1, null, options);
              }
            }}
          />
        )}

        <Container fluid>
          <Breadcrumbs
            title="View Activities"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <>
                    <Row className="mt-2">
                      <Col md="2">
                        <FormGroup>
                          <Col md="12">
                            <Label className="col-form-label">
                              Show Entries
                            </Label>
                            <select
                              className="form-control"
                              onChange={(e) => {
                                fetchData(1, e.target.value);
                                setPagination({
                                  ...pagination,
                                  pageLimit: e.target.value
                                });
                              }}
                              value={pagination.pageLimit}
                              disabled={activityListLoading}
                            >
                              <option value={10}>10</option>
                              <option value={25}>25</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <Label className="col-form-label">
                            Sort Activity
                          </Label>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              fetchSortedList(e.target.value);
                            }}
                            value={sortingRule}
                            disabled={activityListLoading}
                          >
                            <option value="created_at$desc">
                              Create Time (Newest First)
                            </option>
                            <option value="created_at$asc">
                              Create Time (Oldest First)
                            </option>
                            <option value="updated_at$desc">
                              Update Time (Newest First)
                            </option>
                            <option value="updated_at$asc">
                              Update Time (Oldest First)
                            </option>
                            <option value="duration$asc">
                              Duration (MIN-MAX)
                            </option>
                            <option value="duration$desc">
                              Duration (MAX-MIN)
                            </option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col className="d-flex justify-content-end align-items-center">
                        <Button
                          size="sm"
                          color="primary"
                          className=" waves-effect waves-light mr-3"
                          onClick={openFilterModal}
                        >
                          <i className="ri-filter-fill align-middle mr-2"></i>
                          <span>Filter Activity</span>
                          {currentFilterCount > 0 && (
                            <Badge color="light" pill className="ml-2">
                              {currentFilterCount}
                            </Badge>
                          )}
                        </Button>
                        {validatePermissionKey(authUser, CREATE_ACTIVITY) && (
                          <Button
                            size="sm"
                            color="success"
                            className=" waves-effect waves-light"
                            onClick={openAddActivityModal}
                          >
                            <i className="ri-add-line align-middle mr-2"></i>
                            Add Activity
                          </Button>
                        )}
                      </Col>
                    </Row>
                    {activityListLoading ? (
                      <div className="text-center">
                        <PageDataLoader />
                      </div>
                    ) : (
                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <Table
                            id="tech-companies-1"
                            striped
                            bordered
                            responsive
                          >
                            <thead>
                              <tr>
                                <th>SL. No</th>
                                <th>Name</th>
                                <th>Summary</th>
                                <th>Details</th>
                                <th>Tags</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {activityList?.map((item, index) => (
                                <tr key={index}>
                                  <th>{index + 1}</th>
                                  <td>{item.name}</td>
                                  <td>{item.summary}</td>
                                  <td>
                                    {/* {moment(new Date(item.createdAt)).format(
                                      'DD-MM-YYYY'
                                    )} */}

                                    {item.detail}
                                  </td>
                                  <td>
                                    {' '}
                                    {item.tags &&
                                      item.tags.map((t) => (
                                        <Badge
                                          color="primary"
                                          className="mr-2 mb-2"
                                          style={{
                                            padding: '8px 15px',
                                            fontSize: '12px'
                                          }}
                                          pill
                                        >
                                          {t.name}
                                        </Badge>
                                      ))}
                                  </td>
                                  <td>
                                    <Button
                                      size="sm"
                                      color="primary"
                                      className=" waves-effect waves-light mr-2 mb-2"
                                      onClick={() => {
                                        openActivityInfoModal(item._id);
                                      }}
                                    >
                                      <i className="fas fa-eye mr-2"></i>
                                      View
                                    </Button>
                                    {validatePermissionKey(
                                      authUser,
                                      UPDATE_ACTIVITY
                                    ) && (
                                        <Button
                                          size="sm"
                                          color="warning"
                                          className=" waves-effect waves-light mr-2 mb-2"
                                          onClick={() => {
                                            openUpdateActivityModal(item._id);
                                          }}
                                        >
                                          <i className="fas fa-edit mr-2"></i>
                                          Edit
                                        </Button>
                                      )}
                                    {validatePermissionKey(
                                      authUser,
                                      DELETE_ACTIVITY
                                    ) && (
                                        <Button
                                          size="sm"
                                          color="danger"
                                          className=" waves-effect waves-light mb-2"
                                          onClick={() => {
                                            openDeleteActivityModal(
                                              item._id,
                                              item.isDeleted
                                                ? 'restore'
                                                : 'delete'
                                            );
                                          }}
                                        >
                                          {item.isDeleted ? (
                                            <i className="fas fa-trash-restore mr-2"></i>
                                          ) : (
                                            <i className="fas fa-trash mr-2"></i>
                                          )}
                                          {item.isDeleted ? 'Restore' : 'Delete'}
                                        </Button>
                                      )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    )}
                    <Row className="mt-2">
                      <Col md="5">
                        <p>
                          <strong>
                            {' '}
                            Showing{' '}
                            {(pagination.currentPage - 1) *
                              pagination.pageLimit +
                              1}{' '}
                            to{' '}
                            {Math.min(
                              pagination.currentPage * pagination.pageLimit,
                              pagination.totalEntries
                            )}{' '}
                            of {pagination.totalEntries} entries
                          </strong>
                        </p>
                      </Col>
                      {!activityListLoading && activityList.length > 0 && (
                        <Col md="7">
                          <Paginator
                            totalPages={pagination.totalPages}
                            currentPage={pagination.currentPage}
                            changePage={handlePagination}
                          />
                        </Col>
                      )}
                    </Row>
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(ActivityListPage);
