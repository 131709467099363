import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input, Col } from 'reactstrap';
import DatePicker from 'react-datepicker';
import Select from 'react-select';

const FilterTransactionModal = ({
    modalOpenState,
    closeModalHandler,
    authUser,
    childId,
    childinfo,
    refresh,
    setCurrentFilterCount
}) => {
    const [filters, setFilters] = useState({
        child_id: childId
    });

    const buildOptions = (list) => {
        return list.map((item) => ({ value: item._id, label: item.name }));
    };

    const [batchOptions, setBatchOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState({});

    useEffect(() => {
        setBatchOptions(buildOptions(childinfo?.batches || []));
        setFilters((prevFilters) => ({
            ...prevFilters,
            child_id: childId
        }));
    }, [childId, childinfo]);

    const handleInputChange = (value) => {
        setSelectedOption(value);
        setFilters((prevFilters) => ({ ...prevFilters, batch_id: value.value }));
    };

    const dateRangeHandler = (dates) => {
        const [start, end] = dates;
        setFilters((prevFilters) => ({ ...prevFilters, start_date: start, end_date: end }));
    };

    const handleSortChange = (event) => {
        const { name, value } = event.target;
        setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
    };

    const applyFilters = () => {
        let len = Object.keys(filters).length
        setCurrentFilterCount(len > 0 ? len - 1 : len)
        refresh(filters);
        closeModalHandler();
    };

    const resetFilters = () => {
        setFilters({ child_id: childId });
        setCurrentFilterCount(0)
        setSelectedOption({});
        refresh({ child_id: childId });
        closeModalHandler()
    };

    return (
        <Modal isOpen={modalOpenState} toggle={closeModalHandler} size="md">
            <ModalHeader toggle={closeModalHandler}>Filter Transactions</ModalHeader>
            <ModalBody>
                <Col md="12">
                    <FormGroup>
                        <Label>Batch</Label>
                        <Select
                            options={batchOptions}
                            onChange={handleInputChange}
                            value={selectedOption}
                        />
                    </FormGroup>
                </Col>
                <Col md="12">
                    <FormGroup>
                        <Label>Date</Label>
                        <div className="d-flex justify-content-center">
                            <DatePicker
                                selected={filters.start_date}
                                selectsStart
                                className="form-control"
                                startDate={filters.start_date}
                                endDate={filters.end_date}
                                selectsRange={true}
                                dateFormat="dd-MM-yyyy"
                                onChange={dateRangeHandler}
                                isClearable={true}
                            />
                        </div>
                    </FormGroup>
                </Col>
                <Col md="12">
                    <FormGroup>
                        <Label for="sorting_option">Sort Transaction</Label>
                        <Input
                            type="select"
                            id="sorting_option"
                            name="sorting_option"
                            value={filters.sorting_option || ''}
                            onChange={handleSortChange}
                        >
                            <option value="date$desc">
                                Date (Newest First)
                            </option>
                            <option value="date$asc">
                                Date (Oldest First)
                            </option>
                            {/* Add more sorting options as needed */}
                        </Input>
                    </FormGroup>
                </Col>
            </ModalBody>
            <ModalFooter>
                <Button size="sm" onClick={applyFilters} color="primary">
                    Apply Filter
                </Button>
                <Button size="sm" type="button" color="success" onClick={resetFilters}>
                    Reset
                </Button>
                <Button size="sm" type="button" color="light" onClick={closeModalHandler}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default FilterTransactionModal;
