/*jshint esversion: 11 */
import sendApiRequest from '../helpers/axios-setup';

/**
 *
 * @returns {Promise}
 */
export async function getToyList(
  page,
  limit,
  {
    filter_name = '',
    filter_tag_id = '',
    filter_start_age = '',
    filter_end_age = '',
    filter_is_available = '',
    include_deleted = ''
  } = {},
  sorting_option = ''
) {
  try {
    const response = await sendApiRequest.get('/toy', {
      params: {
        page: page,
        limit: limit,
        sorting_option: sorting_option,
        filter_name,
        filter_tag_id,
        filter_start_age,
        filter_end_age,
        filter_is_available,
        include_deleted
      }
    });
    return response.data?.data[0];
  } catch (e) {
    return null;
  }
}

/**
 *
 * @param {string} name
 * @param {string} overview
 * @param {string} summary
 * @param {number} age
 * @param {Array} tags
 * @returns {Promise<Object>}
 */
export async function addNewToy(data) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.post('/toy', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @param {String} toyId
 * @returns
 */
export async function getToyInfo(toyId) {
  try {
    const response = await sendApiRequest.get(`/toy/${toyId}`);
    return response.data?.data;
  } catch (e) {
    return null;
  }
}

/**
 *
 * @param {string} name
 * @param {string} overview
 * @param {string} summary
 * @param {number} age
 * @param {Array} tags
 * @param {Boolean} isAvailable
 * @returns {Promise<Object>}
 */
export async function updateToy(toyId, data) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.put(`/toy/${toyId}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @param {String} toyId
 * @returns
 */
export async function deleteRestoreToy(toyId, type) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.delete(`/toy/${toyId}`, {
      params: {
        type: type
      }
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}
