/*jshint esversion: 11 */
import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Table,
  Label,
  FormGroup,
  Button,
  Badge
} from 'reactstrap';
import { connect } from 'react-redux';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import PageDataLoader from '../Utility/PageDataLoader';
import Paginator from '../Utility/Paginator';
import { getStaffInfo, getStaffList } from '../../api/staff';
import moment from 'moment';
import CreateStaffModal from './CreateStaffModal';
import ViewStaffModal from './ViewStaffModal';
import UpdateStaffModal from './UpdateStaffModal';
import { validatePermissionKey } from '../../helpers/permission';
import {
  CREATE_STAFF,
  DELETE_STAFF,
  EDIT_STAFF
} from '../Utility/PermissionKeys';
import { stringify as QStringify, parse as QParse } from 'qs';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import validator from 'validator';
import StaffFilterModal from './StaffFilterModal';
import { getRoleList } from '../../api/role';
import DeleteStaffModal from './DeleteStaffModal';

const StaffListPage = ({ authUser }) => {
  const history = useHistory();

  const [breadcrumbItems] = useState([
    { title: 'Staffs', link: '#' },
    { title: 'View List', link: '#' }
  ]);
  const [staffListLoading, setStaffListLoading] = useState(true);
  const [staffList, setStaffList] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageLimit: 10,
    totalEntries: 0,
    totalPages: 0
  });
  const [isDeleted, setIsDeleted] = useState(false);
  const [deleteStaffModalOpen, setDeleteStaffModalOpen] = useState(false);
  const [staffId, setStaffId] = useState('');
  const [addStaffModalOpen, setAddStaffModalOpen] = useState(false);
  const [staffInfo, setStaffInfo] = useState({});
  const [staffInfoLoading, setStaffInfoLoading] = useState(false);
  const [staffInfoModalOpen, setStaffInfoModalOpen] = useState(false);
  const [updateStaffModalOpen, setUpdateStaffModalOpen] = useState(false);
  const [sortingRule, setSortingRule] = useState('created_at$desc');
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [currentFilterCount, setCurrentFilterCount] = useState(0);
  const [createdAtFilter, setCreatedAtFilter] = useState({
    startDate: null,
    endDate: null
  });
  const [filterStaffName, setFilterStaffName] = useState('');
  const [filterEmail, setFilterEmail] = useState('');
  const [filterPhone, setFilterPhone] = useState('');
  const [filterGender, setFilterGender] = useState('');
  const [filterAge, setFilterAge] = useState({
    upper: '',
    lower: ''
  });
  const [filterError, setFilterError] = useState(false);
  const [roleQueryId, setRoleQueryId] = useState([]);
  const [filterRoleList, setFilterRoleList] = useState([]);
  const [selectedFilterRoleOption, setSelectedFilterRoleOption] = useState([]);
  const filterRoleOptions = useMemo(
    () => buildRoleOptions(filterRoleList),
    // eslint-disable-next-line
    [filterRoleList]
  );
  let listElem = document.getElementById('staffList');
  let linkElem = document.getElementById('staffLink');

  useEffect(() => {
    if (listElem) listElem.classList.add('mm-active');
    if (linkElem) linkElem.classList.add('active');
    if (authUser) {
      fetchRoleOptions();
      populateSearchOptions(history?.location?.search);
    }
    // eslint-disable-next-line
  }, [authUser, listElem, linkElem]);

  const handlePagination = (page) => {
    fetchData(page, pagination.pageLimit, createQueryOptions(), sortingRule);
  };

  const fetchData = async (
    current_page = 1,
    page_limit = 10,
    queryOptions = {},
    sortingOption = sortingRule
  ) => {
    setStaffListLoading(true);
    const response = await getStaffList(
      current_page,
      page_limit,
      queryOptions,
      sortingOption
    );

    if (response) {
      setPagination({
        currentPage: response.metadata.page,
        pageLimit: response.metadata.limit,
        totalPages: response.metadata.total_pages,
        totalEntries: response.metadata.total_results
      });
      setStaffList(response.data);
      setStaffListLoading(false);
    }
  };

  async function fetchRoleOptions() {
    const response = await getRoleList(1, 100, {});
    if (response) {
      setFilterRoleList(response?.data);
    }
  }

  /**
   *
   * @param {array<object>} roleArray
   * @returns
   */
  function buildRoleOptions(roleArray) {
    const roleOptions = roleArray.map((i) => {
      return {
        label: i.role_name,
        value: i
      };
    });
    const selectedRoleOption = roleOptions.filter((i) =>
      roleQueryId.includes(i.value._id)
    );
    setSelectedFilterRoleOption(selectedRoleOption);
    return roleOptions;
  }

  /**
   *
   * @param {string} str
   */
  function getValidDateInput(str) {
    return validator.isDate(str) ? new Date(str).toISOString() : '';
  }

  function createQueryOptions(
    createStart = createdAtFilter.startDate,
    createEnd = createdAtFilter.endDate,
    name = filterStaffName,
    email_address = filterEmail,
    phone_number = filterPhone,
    role = selectedFilterRoleOption,
    gender = filterGender,
    startAge = filterAge.lower,
    endAge = filterAge.upper,
    deletedStatus = isDeleted
  ) {
    setFilterError(false);
    if ((createStart && !createEnd) || (!createStart && createEnd)) {
      setFilterError('Select valid date range');
      return;
    }

    if (
      (startAge && !endAge) ||
      (endAge && !startAge) ||
      parseInt(startAge) > parseInt(endAge)
    ) {
      setFilterError('Select valid age range');
      return;
    }

    const startDateISOStringCreatedAt = getValidDateInput(createStart);
    const endDateISOStringCreatedAt = getValidDateInput(createEnd);
    const selectedRole = Array.isArray(role)
      ? role.map((i) => i.value._id).join(',')
      : '';
    const queryOptions = {
      start_created_at: startDateISOStringCreatedAt,
      end_created_at: endDateISOStringCreatedAt,
      name,
      email_address,
      phone_number,
      role: selectedRole,
      gender,
      start_age: startAge,
      end_age: endAge,
      include_deleted: deletedStatus,
      sorting_option: sortingRule
    };
    updateUrlPath(queryOptions);
    return queryOptions;
  }

  function updateFilterCount(option) {
    let count = 0;
    Object.keys(option).forEach((i) => {
      if (option[i]?.toString()?.trim()?.length > 0 && option[i] !== false) {
        count += 1;
      }
    });
    setCurrentFilterCount(count - 1);
  }

  /**
   *
   * @param {string} searchQuery
   */
  function populateSearchOptions(searchQuery) {
    const parsedQuery = QParse(searchQuery.substring(1));
    if (parsedQuery.start_created_at && parsedQuery.end_created_at) {
      updateDateFilterInput(
        parsedQuery.start_created_at,
        parsedQuery.end_created_at,
        setCreatedAtFilter
      );
    }
    if (parsedQuery.role) {
      const roleId = parsedQuery.role.split(',').map((i) => i.trim());
      setRoleQueryId(roleId);
    }
    setFilterStaffName(parsedQuery.name);
    setFilterEmail(parsedQuery.email_address);
    setFilterPhone(parsedQuery.phone_number);
    setFilterGender(parsedQuery.gender);
    setFilterAge({
      lower: parsedQuery.start_age,
      upper: parsedQuery.end_age
    });
    const sortingOption = parsedQuery.sorting_option || sortingRule;
    setSortingRule(sortingOption);
    updateFilterCount(parsedQuery);
    fetchData(1, null, parsedQuery, sortingOption);
  }

  /**
   *
   * @param {string} str
   * @param {function} setHook
   * @returns
   */
  function updateDateFilterInput(startStr, endStr, setHook) {
    setHook({
      startDate: new Date(startStr),
      endDate: new Date(endStr)
    });
  }

  function updateUrlPath(queryOptions) {
    const queryString = QStringify(queryOptions);
    history.replace(`/staff?${queryString}`);
  }

  async function fetchSortedList(sortingOption) {
    setSortingRule(sortingOption);
    const queryOptions = createQueryOptions();
    queryOptions.sorting_option = sortingOption;
    updateUrlPath(queryOptions);
    fetchData(1, null, queryOptions, sortingOption);
  }

  function openFilterModal() {
    setFilterModalOpen(true);
  }

  function closeFilterModal() {
    setFilterModalOpen(false);
  }

  function openAddStaffModal() {
    setAddStaffModalOpen(true);
  }

  function closeAddStaffModal() {
    setAddStaffModalOpen(false);
  }

  function openStaffInfoModal(roleId) {
    fetchStaffInfo(roleId);
    setStaffInfoModalOpen(true);
  }

  function closeStaffInfoModal() {
    setStaffInfoModalOpen(false);
  }

  async function openUpdateStaffModal(roleId) {
    fetchStaffInfo(roleId);
    setUpdateStaffModalOpen(true);
  }

  function closeUpdateRoleModal() {
    setUpdateStaffModalOpen(false);
  }

  async function fetchStaffInfo(staffId) {
    setStaffInfoLoading(true);
    const response = await getStaffInfo(staffId);
    if (response) {
      setStaffInfo(response);
      setStaffInfoLoading(false);
    }
  }

  async function openDeleteStaffModal(staffId, requestType) {
    setStaffId({
      staffId,
      requestType
    });
    setDeleteStaffModalOpen(true);
  }

  function closeDeleteStaffModal() {
    setDeleteStaffModalOpen(false);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <StaffFilterModal
          filterError={filterError}
          setFilterError={setFilterError}
          modalOpenState={filterModalOpen}
          closeModalHandler={closeFilterModal}
          fetchList={fetchData}
          updateFilterCount={updateFilterCount}
          updateUrlPath={updateUrlPath}
          staffName={filterStaffName}
          setStaffName={setFilterStaffName}
          staffEmail={filterEmail}
          setStaffEmail={setFilterEmail}
          staffPhone={filterPhone}
          setStaffPhone={setFilterPhone}
          createdAtFilter={createdAtFilter}
          setCreatedAtFilter={setCreatedAtFilter}
          roleOptions={filterRoleOptions}
          selectedRoleOption={selectedFilterRoleOption}
          setSelectedRoleOption={setSelectedFilterRoleOption}
          createQueryOptions={createQueryOptions}
          staffGender={filterGender}
          setStaffGender={setFilterGender}
          staffAge={filterAge}
          setStaffAge={setFilterAge}
          authUser={authUser}
          isDeleted={isDeleted}
          setIsDeleted={setIsDeleted}
        />
        <CreateStaffModal
          modalOpenState={addStaffModalOpen}
          closeModalHandler={closeAddStaffModal}
          fetchNewStaffList={async () => {
            let options = await createQueryOptions();
            if (options) {
              await fetchData(1, null, options);
            }
          }}
        />
        <ViewStaffModal
          modalOpenState={staffInfoModalOpen}
          closeModalHandler={closeStaffInfoModal}
          staffInfo={staffInfo}
          staffInfoLoading={staffInfoLoading}
        />
        <UpdateStaffModal
          modalOpenState={updateStaffModalOpen}
          closeModalHandler={closeUpdateRoleModal}
          staffInfo={staffInfo}
          staffInfoLoading={staffInfoLoading}
          fetchNewStaffList={async () => {
            let options = await createQueryOptions();
            if (options) {
              await fetchData(1, null, options);
            }
          }}
        />
        {staffId && (
          <DeleteStaffModal
            modalOpenState={deleteStaffModalOpen}
            closeModalHandler={closeDeleteStaffModal}
            staffId={staffId.staffId}
            type={staffId.requestType}
            setStaffId={setStaffId}
            fetchNewData={async () => {
              let options = await createQueryOptions();
              if (options) {
                await fetchData(1, null, options);
              }
            }}
          />
        )}
        <Container fluid>
          <Breadcrumbs title="View Staff" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <>
                    <Row className="mt-2">
                      <Col md="2">
                        <FormGroup>
                          <Col md="12">
                            <Label className="col-form-label">
                              Show Entries
                            </Label>
                            <select
                              className="form-control"
                              onChange={(e) => {
                                fetchData(
                                  1,
                                  e.target.value,
                                  createQueryOptions(),
                                  sortingRule
                                );
                                setPagination({
                                  ...pagination,
                                  pageLimit: e.target.value
                                });
                              }}
                              value={pagination.pageLimit}
                              disabled={staffListLoading}
                            >
                              <option value={10}>10</option>
                              <option value={25}>25</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <Label className="col-form-label">Sort Staff</Label>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              fetchSortedList(e.target.value);
                            }}
                            value={sortingRule}
                            disabled={staffListLoading}
                          >
                            <option value="created_at$desc">
                              Create Time (Newest First)
                            </option>
                            <option value="created_at$asc">
                              Create Time (Oldest First)
                            </option>
                            <option value="role_name$asc">
                              Role Name (A-Z)
                            </option>
                            <option value="role_name$desc">
                              Role Name (Z-A)
                            </option>
                            <option value="age$desc">
                              Age (Youngest First)
                            </option>
                            <option value="age$asc">Age (Eldest First)</option>
                            <option value="updated_at$desc">
                              Update Time (Newest First)
                            </option>
                            <option value="updated_at$asc">
                              Update Time (Oldest First)
                            </option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col className="d-flex justify-content-end align-items-center">
                        <Button
                          size="sm"
                          color="primary"
                          className=" waves-effect waves-light mr-3"
                          onClick={openFilterModal}
                        >
                          <i className="ri-filter-fill align-middle mr-2"></i>
                          <span>Filter Staff</span>
                          {currentFilterCount > 0 && (
                            <Badge color="light" pill className="ml-2">
                              {currentFilterCount}
                            </Badge>
                          )}
                        </Button>
                        {validatePermissionKey(authUser, CREATE_STAFF) && (
                          <Button
                            size="sm"
                            color="success"
                            className=" waves-effect waves-light"
                            onClick={openAddStaffModal}
                          >
                            <i className="ri-add-line align-middle mr-2"></i>
                            Add Staff
                          </Button>
                        )}
                      </Col>
                    </Row>
                    {staffListLoading ? (
                      <div className="text-center">
                        <PageDataLoader />
                      </div>
                    ) : (
                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <Table
                            id="tech-companies-1"
                            striped
                            bordered
                            responsive
                          >
                            <thead>
                              <tr>
                                <th>SL. No</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                                <th>Role</th>
                                <th>Age</th>
                                <th>Gender</th>
                                <th>Created At</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {staffList?.map((item, index) => (
                                <tr key={index}>
                                  <th>{index + 1}</th>
                                  <td>{item.name}</td>
                                  <td>{item.email_address}</td>
                                  <td>{item.phone_number}</td>
                                  <td>{item.role?.role_name}</td>
                                  <td>
                                    {moment(new Date()).diff(
                                      new Date(item.dob),
                                      'years'
                                    )}
                                  </td>
                                  <td>{item.gender}</td>
                                  <td>
                                    {moment(new Date(item.createdAt)).format(
                                      'DD-MM-YYYY'
                                    )}
                                  </td>
                                  <td>
                                    <Button
                                      size="sm"
                                      color="primary"
                                      className=" waves-effect waves-light mr-2 mb-2"
                                      onClick={() => {
                                        openStaffInfoModal(item._id);
                                      }}
                                    >
                                      <i className="fas fa-eye mr-2"></i>
                                      View
                                    </Button>
                                    {validatePermissionKey(
                                      authUser,
                                      EDIT_STAFF
                                    ) && (
                                      <Button
                                        size="sm"
                                        color="warning"
                                        className=" waves-effect waves-light mr-2 mb-2"
                                        onClick={() => {
                                          openUpdateStaffModal(item._id);
                                        }}
                                      >
                                        <i className="fas fa-edit mr-2"></i>
                                        Edit
                                      </Button>
                                    )}
                                    {validatePermissionKey(
                                      authUser,
                                      DELETE_STAFF
                                    ) &&
                                      authUser._id !== item._id && (
                                        <>
                                          <Button
                                            size="sm"
                                            color="danger"
                                            className=" waves-effect waves-light mb-2 mr-2"
                                            onClick={() => {
                                              openDeleteStaffModal(
                                                item._id,
                                                item.isDeleted
                                                  ? 'restore'
                                                  : 'delete'
                                              );
                                            }}
                                          >
                                            {item.isDeleted ? (
                                              <i className="fas fa-trash-restore mr-2"></i>
                                            ) : (
                                              <i className="fas fa-trash mr-2"></i>
                                            )}
                                            {item.isDeleted
                                              ? 'Restore'
                                              : 'Delete'}
                                          </Button>
                                          {!item.isTerminated && (
                                            <Button
                                              size="sm"
                                              color="success"
                                              className=" waves-effect waves-light mb-2"
                                              onClick={() => {
                                                openDeleteStaffModal(
                                                  item._id,
                                                  'terminate'
                                                );
                                              }}
                                            >
                                              <i className="fas fa-times mr-2"></i>
                                              Terminate
                                            </Button>
                                          )}
                                        </>
                                      )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    )}
                    <Row className="mt-2">
                      <Col md="5">
                        <p>
                          <strong>
                            {' '}
                            Showing{' '}
                            {(pagination.currentPage - 1) *
                              pagination.pageLimit +
                              1}{' '}
                            to{' '}
                            {Math.min(
                              pagination.currentPage * pagination.pageLimit,
                              pagination.totalEntries
                            )}{' '}
                            of {pagination.totalEntries} entries
                          </strong>
                        </p>
                      </Col>
                      {!staffListLoading && staffList.length > 0 && (
                        <Col md="7">
                          <Paginator
                            totalPages={pagination.totalPages}
                            currentPage={pagination.currentPage}
                            changePage={handlePagination}
                          />
                        </Col>
                      )}
                    </Row>
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(StaffListPage);
