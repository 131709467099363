import { AvField, AvForm, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import React, { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
  Alert,
  Button,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import { addNewQuestion } from '../../api/question'; // Ensure this is pointing to your questionController method

const AddQuestionModal = ({
  modalOpenState,
  closeModalHandler,
  fetchNewQuestionList
}) => {
  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });

  const [questionRequestLoading, setQuestionRequestLoading] = useState(false);
  const [questionArray, setQuestionArray] = useState([{ option_name: '', is_correct: null }]);
  const [successAlert, setSuccessAlert] = useState({
    message: '',
    show_alert: false
  });

  const styles = {
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
        : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: 'none' } : base;
    }
  };


  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }

  function showSuccessAlert(message) {
    setSuccessAlert({ message: message, show_alert: true });
  }

  function resetSuccessAlert() {
    setSuccessAlert({ message: '', show_alert: false });
  }

  function resetState() {
    resetSuccessAlert();
    resetErrorAlert();
  }

  function hasDuplicateOptions(arr) {
    // Create a Set to store unique names
    const nameSet = new Set();

    // Iterate through the array
    for (const obj of arr) {
      // If the name already exists in the set, there's a duplicate
      if (nameSet.has(obj.option_name)) {
        return true;
      }
      // Add the name to the set
      nameSet.add(obj.option_name);
    }

    // No duplicates found
    return false;
  }

  async function handleSubmit(event, values) {
    resetErrorAlert();

    if (questionArray.length === 1 && questionArray[0].option_name === '') {
      showErrorAlert('At least one option should be added');
      return;
    }
    let correct = questionArray.filter((opt) => opt.is_correct === true)
    if (correct.length === 0) {
      //check for no correct option
      showErrorAlert('At least one option should marked correct');
      return;

    }
    console.log(questionArray, 'question array')
    let duplicate = hasDuplicateOptions(questionArray)
    if (duplicate) {
      showErrorAlert('Duplicate options are not allowed')
      return
    }

    setQuestionRequestLoading(true);
    const questionData = {
      title: values.name,
      option_list: questionArray
    };

    const { response, error } = await addNewQuestion(questionData);
    setQuestionRequestLoading(false);
    if (error) {
      showErrorAlert(error || 'Error adding question');
      return;
    }
    setQuestionArray([{ option_name: '', is_correct: null }]);
    fetchNewQuestionList();
    closeModalHandler();
    showSuccessAlert(response.message);
  }

  function handleQuestion(index, selectedItem) {
    resetErrorAlert();
    let value = selectedItem.target.value;
    let newQuestionArray = [...questionArray];
    newQuestionArray[index].option_name = value;
    setQuestionArray(newQuestionArray);
  }

  function handleWeightage(index, selectedItem) {
    resetErrorAlert();
    let value = selectedItem.target.value;
    if (value < 0) {
      showErrorAlert("Weightage cannot be negative")
      return
    }
    let newQuestionArray = [...questionArray];
    newQuestionArray[index].weightage = value;
    setQuestionArray(newQuestionArray);
  }

  function handleIsCorrect(index, selectedItem) {
    let newQuestionArray = [...questionArray];
    newQuestionArray.forEach((item, i) => {
      if (i === index) {
        item.is_correct = true;
      } else {
        item.is_correct = false;
      }
    });
    setQuestionArray(newQuestionArray);
  }

  const addQuestionFields = () => {
    resetErrorAlert();
    setQuestionArray([...questionArray, { option_name: '', is_correct: null }]);
  };

  const removeQuestionFields = (i) => {
    let newQuestionArray = [...questionArray];
    newQuestionArray.splice(i, 1);
    setQuestionArray(newQuestionArray);
  };

  useEffect(() => {
    resetErrorAlert()
    setQuestionArray([{ option_name: '', is_correct: null }]);
    // eslint-disable-next-line
  }, [modalOpenState]);

  return (
    <React.Fragment>
      {successAlert.show_alert && (
        <SweetAlert success title="Created" onConfirm={resetState}>
          {successAlert.message}
        </SweetAlert>
      )}
      <Modal isOpen={modalOpenState} backdrop="static" size="xl">
        <ModalHeader toggle={closeModalHandler}>Add Question</ModalHeader>
        <ModalBody>
          <React.Fragment>
            {requestError.show_alert && (
              <Alert
                color="danger"
                role="alert"
                isOpen={requestError.show_alert}
                toggle={resetErrorAlert}
              >
                <i className="mdi mdi-block-helper mr-2"></i>
                {requestError.message}
              </Alert>
            )}
            <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
              <ModalBody>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label>Question Title</Label>
                      <AvField
                        name="name"
                        placeholder="Enter title ..."
                        type="text"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'title is required'
                          }
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col md="5">
                    <CardTitle tag="h5">Options</CardTitle>
                  </Col>

                </Row>
                {questionArray.map((element, index) => (
                  <Row key={index}>
                    <Col md="4">
                      <FormGroup>
                        <AvField
                          name={`question_${index}`}
                          placeholder="Enter Options ..."
                          type="text"
                          className="form-control"
                          value={element.option_name}
                          onChange={(e) => handleQuestion(index, e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <AvField
                          name={`weightage_${index}`}
                          placeholder="Enter Weightage"
                          type="number"
                          min={0}
                          className="form-control"
                          value={element.weightage}
                          onChange={(e) => handleWeightage(index, e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3" className="d-flex align-items-center" >
                      <AvRadioGroup inline name={`is_correct_${index}`} value={element.is_correct} onChange={(e) => handleIsCorrect(index, e)}>
                        <AvRadio label="Is Correct Option" value={true} />
                      </AvRadioGroup>
                    </Col>
                    <Col md="1" className="d-flex align-items-center mb-1">
                      <Button
                        size="sm"
                        color="danger"
                        disabled={index === 0}
                        className="waves-effect waves-light"
                        onClick={() => removeQuestionFields(index)}
                      >
                        <i className="ri-delete-bin-6-line align-middle"></i>
                      </Button>
                    </Col>
                  </Row>
                ))}
                <Row>
                  <Col className="d-flex justify-content align-items-center mt-2">
                    <Button
                      size="sm"
                      color="primary"
                      className="waves-effect waves-light"
                      disabled={!questionArray[questionArray.length - 1].option_name}
                      onClick={() => addQuestionFields()}
                    >
                      <i className="ri-add-line align-middle mr-2"></i>
                      Add
                    </Button>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button
                  disabled={questionRequestLoading}
                  type="button"
                  color="light"
                  onClick={closeModalHandler}
                >
                  Close
                </Button>
                <Button type="submit" color="success">
                  {questionRequestLoading ? 'Adding ...' : 'Add Question'}
                </Button>
              </ModalFooter>
            </AvForm>
          </React.Fragment>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default AddQuestionModal;
