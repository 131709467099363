// all apis for forms

/*jshint esversion: 11 */
import sendApiRequest from '../helpers/axios-setup';


/**
 *
 * @param {number} page
 * @param {number} limit
 * @param {string} [filter_title]
 * @param {string} [filter_start_date]
 * @param {string} [filter_end_date]
 * @param {string} [include_deleted]
 * @returns {Promise<Object>}
 */
export async function getFormList(
    page,
    limit,
    {
        filter_name = '',
        filter_start_date = '',
        filter_end_date = '',
        include_deleted = ''
    } = {},
    sorting_option = ''
) {
    try {
        const response = await sendApiRequest.get('/form', {
            params: {
                page: page,
                limit: limit,
                sortOrder: sorting_option,
                filter_name,
                filter_start_date,
                filter_end_date,
                include_deleted
            }
        });
        return response.data?.data[0];
    } catch (e) {
        return null;
    }
}


/**
 *
 * @param {String} formId
 * @returns
 */
export async function getFormInfo(formId) {
    try {
        const response = await sendApiRequest.get(`/form/${formId}`);
        return response.data;
    } catch (e) {
        return null;
    }
}

/**
 *
 * @param {String}
 * @returns
 */
export async function getFormResponse(child_id,batch_id) {
    try {
        const response = await sendApiRequest.get(`/form_response/${child_id}/${batch_id}`);
        return response.data;
    } catch (e) {
        return null;
    }
}
/**
 *
 * @param {String}
 * @returns
 */
export async function submitFormResponse(data) {
    try {
        const response = await sendApiRequest.post(`/form_response`,data);
        return response.data;
    } catch (e) {
        return null;
    }
}
/**
 *
 * @param {String}
 * @returns
 */
export async function updateFormResponse(response_id,data) {
    try {
        const response = await sendApiRequest.put(`/form_response/${response_id}`,data);
        return response.data;
    } catch (e) {
        return null;
    }
}


/**
 *
 * @param {string} formId
 * @param {string} title
 * @param {array} batches,sections 
 * @returns {Promise<Object>}
 */
export async function updateForm(formId, name, sections, batches) {
    const responseMap = {
        response: null,
        error: null
    };
    try {
        const response = await sendApiRequest.put(`/form/${formId}`, {
            name,
            sections,
            batches
        });
        responseMap.response = response.data;
    } catch (e) {
        responseMap.error = e?.response?.data?.error;
    }
    return responseMap;
}

/**
 *
 * @param {string} title
 * @param {array} batches,sections 
 * @returns {Promise<Object>}
 */
export async function createForm(name, sections, batches) {
    const responseMap = {
        response: null,
        error: null
    };
    try {
        const response = await sendApiRequest.post(`/form`, {
            name,
            sections,
            batches
        });
        responseMap.response = response.data;
    } catch (e) {
        responseMap.error = e?.response?.data?.error;
    }
    return responseMap;
}


/**
 *
 * @param {string} formId
 * @returns {Promise<Object>}
 */
export async function deleteRestoreForm(formId) {
    const responseMap = {
      response: null,
      error: null
    };
    try {
      const response = await sendApiRequest.delete(`/form/${formId}`);
      responseMap.response = response.data;
    } catch (e) {
      responseMap.error = e?.response?.data?.error;
    }
    return responseMap;
  }
