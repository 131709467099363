/*jshint esversion: 11 */
import sendApiRequest from '../helpers/axios-setup';

/**
 *
 * @returns {Promise}
 */
export async function getParentList(
  page,
  limit,
  {
    filter_dob_start_date = '',
    filter_dob_end_date = '',
    filter_name = '',
    filter_phone_number = '',
    filter_gender = '',
    filter_address_location = '',
    include_deleted = ''
  } = {},
  sorting_option = ''
) {
  try {
    const response = await sendApiRequest.get('/parent', {
      params: {
        page: page,
        limit: limit,
        sorting_option: sorting_option,
        filter_dob_start_date,
        filter_dob_end_date,
        filter_name,
        filter_phone_number,
        filter_gender,
        filter_address_location,
        include_deleted
      }
    });
    return response.data?.data[0];
  } catch (e) {
    return null;
  }
}

/**
 *
 * @param {String} name
 * @param {String} phone_number
 * @param {Date} dob
 * @param {String} gender
 * @param {String} address
 * @param {String} address_location
 * @returns {Promise<Object>}
 */
export async function addNewParent(data) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.post('/parent', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @param {String} parentId
 * @returns
 */
export async function getParentInfo(parentId) {
  try {
    const response = await sendApiRequest.get(`/parent/${parentId}`);
    return response.data?.data;
  } catch (e) {
    return null;
  }
}

/**
 *
 * @param {String} parentId
 * @param {String} name
 * @param {String} phone_number
 * @param {Date} dob
 * @param {String} gender
 * @param {String} address
 * @param {String} address_location
 * @returns {Promise<Object>}
 */
export async function updateParent(parentId, data) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.put(`/parent/${parentId}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @param {String} parentId
 * @returns
 */
export async function deleteRestoreParent(parentId, type) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.delete(`/parent/${parentId}`, {
      params: {
        type: type
      }
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}
