/*jshint esversion: 11 */
import React, { useState } from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Input,
  Alert
} from 'reactstrap';
import { upperCase } from 'lodash';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { getAvailablePermissions, updateRole } from '../../api/role';
import PageDataLoader from '../Utility/PageDataLoader';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import SweetAlert from 'react-bootstrap-sweetalert';

const UpdateRoleModal = ({
  modalOpenState,
  closeModalHandler,
  authUser,
  roleInfoLoading,
  roleInfo,
  fetchNewRoleList
}) => {
  const [availablePermissions, setAvailablePermissions] = useState({});
  const [permissionListLoading, setPermissionListLoading] = useState(false);
  const [permissionSelectionInput, setPermissionSelectionInput] = useState({});
  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });
  const [roleUpdateRequestLoading, setRoleUpdateRequestLoading] =
    useState(false);
  const [successAlert, setSuccessAlert] = useState({
    message: '',
    show_alert: false
  });

  useEffect(() => {
    if (authUser) {
      fetchAvailablePermissions();
    }
    // eslint-disable-next-line
  }, [authUser]);

  useEffect(
    () => {
      setPermissionSelectionInput(
        buildSelectionInput(availablePermissions, roleInfo.permissions)
      );
    }, // eslint-disable-next-line
    [availablePermissions, roleInfo.permissions]
  );

  const fetchAvailablePermissions = async () => {
    setPermissionListLoading(true);
    const response = await getAvailablePermissions();
    if (response) {
      setAvailablePermissions(response);
      setPermissionListLoading(false);
    }
  };

  function buildSelectionInput(permissionMap, selectedPermissionList = []) {
    const permissionInputMap = new Map();
    Object.keys(permissionMap)
      .flatMap((k) => permissionMap[k])
      .forEach((t) => {
        if (selectedPermissionList.includes(t)) {
          permissionInputMap.set(t, true);
        } else {
          permissionInputMap.set(t, false);
        }
      });
    return Object.fromEntries(permissionInputMap);
  }

  async function handleRoleUpdate(event, values) {
    resetErrorAlert();
    const selectedPermissionKeys = Object.keys(permissionSelectionInput).filter(
      (k) => permissionSelectionInput[k]
    );
    if (selectedPermissionKeys.length === 0) {
      showErrorAlert('Please select at least one permission');
      return;
    }
    setRoleUpdateRequestLoading(true);
    const { response, error } = await updateRole(
      roleInfo._id,
      values.role_name,
      selectedPermissionKeys
    );
    setRoleUpdateRequestLoading(false);
    if (error) {
      showErrorAlert(error);
      return;
    }
    closeModalHandler();
    showSuccessAlert(response.message);
    fetchNewRoleList();
  }

  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }

  function showSuccessAlert(message) {
    setSuccessAlert({ message: message, show_alert: true });
  }

  function resetSuccessAlert() {
    setSuccessAlert({ message: '', show_alert: false });
  }

  function resetState() {
    resetSuccessAlert();
    resetErrorAlert();
  }

  return (
    <React.Fragment>
      {successAlert.show_alert && (
        <SweetAlert success title="Updated" onConfirm={resetState}>
          {successAlert.message}
        </SweetAlert>
      )}
      <Modal isOpen={modalOpenState} backdrop="static" size="lg">
        <ModalHeader toggle={closeModalHandler}>
          {roleInfoLoading ? 'Loading ...' : roleInfo.role_name}
        </ModalHeader>
        <ModalBody>
          {roleInfoLoading || permissionListLoading ? (
            <div className="d-flex justify-content-center">
              <PageDataLoader />
            </div>
          ) : (
            <React.Fragment>
              {requestError ? (
                <Alert
                  color="danger"
                  role="alert"
                  isOpen={requestError.show_alert}
                  toggle={resetErrorAlert}
                >
                  <i className="mdi mdi-block-helper mr-2"></i>
                  {requestError.message}
                </Alert>
              ) : null}
              <AvForm
                className="needs-validation"
                onValidSubmit={handleRoleUpdate}
              >
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label>Role Name</Label>
                      <AvField
                        name="role_name"
                        placeholder="Enter Role Name ..."
                        type="text"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Role name is required'
                          }
                        }}
                        defaultValue={roleInfo.role_name}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr className="my-2" />
                <Row className="d-flex px-3" style={{ height: '100%' }}>
                  <React.Fragment>
                    {Object.keys(availablePermissions).map((k, idx) => (
                      <React.Fragment key={idx}>
                        <Col
                          sm={4}
                          className="border p-2"
                          style={{ height: '100%' }}
                        >
                          <h5 className="font-size-14 mb-4">{upperCase(k)}</h5>
                          {availablePermissions[k].map((pmKey) => (
                            <div
                              className="form-check mb-3"
                              key={pmKey}
                              style={{ cursor: 'pointer' }}
                            >
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id={pmKey}
                                checked={permissionSelectionInput[pmKey]}
                                readOnly
                                name={pmKey}
                                style={{ cursor: 'pointer' }}
                                onChange={async (e) => {
                                  setPermissionSelectionInput({
                                    ...permissionSelectionInput,
                                    [pmKey]: !permissionSelectionInput[pmKey]
                                  });
                                }}
                              />
                              <Label
                                style={{ cursor: 'pointer' }}
                                className="form-check-label"
                                htmlFor={pmKey}
                              >
                                {upperCase(pmKey)}
                              </Label>
                            </div>
                          ))}
                        </Col>
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                </Row>
                <ModalFooter>
                  <Button
                    type="button"
                    color="light"
                    onClick={closeModalHandler}
                  >
                    Close
                  </Button>
                  <Button type="submit" color="success">
                    {roleUpdateRequestLoading ? 'Updating ...' : 'Update Role'}
                  </Button>
                </ModalFooter>
              </AvForm>
            </React.Fragment>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(UpdateRoleModal);
