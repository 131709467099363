import React, { useEffect, useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Button
} from 'reactstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

// Carousel images
import img1 from '../../assets/images/execution_absence.jpeg';
import { fetchImage } from '../../api/image';
import PageDataLoader from '../Utility/PageDataLoader';
import DeleteImageModal from './DeleteImageModal';
import { extractYouTubeVideoId, isValidYouTubeLink } from '../../helpers/expression-checker';
import YouTube from 'react-youtube';
import ReactPlayer from 'react-player';

const ExecutionCarousel = ({ images, canDelete, childId, fetchChildInfo, showLightbox }) => {
  const [deleteImageModal, setDeleteImageModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isGalleryZoom, setIsGalleryZoom] = useState(false);
  const [imagesLoading, setImageLoading] = useState(true);
  const [items, setItems] = useState([
    {
      src: img1,
      altText: 'Slide 1',
      caption: 'Slide 1'
    }
  ]);

  const onExiting = () => setAnimating(true);
  const onExited = () => setAnimating(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const fetchUrls = async () => {
    setImageLoading(true);
    if (images.length > 0) {
      let data = images.map(async (i) => {
        if (!i?.is_video) {
          let imgUrl = await fetchImage(i.documentKey);
          if (imgUrl === null) {
            return {}
          }
          if (imgUrl !== null) {
            return {
              src: URL.createObjectURL(imgUrl),
              altText: 'Execution img',
              caption: 'Execution img',
              is_video: false
            };
          }

        }
        else if (i?.is_video && i?.ETag) {

          let imgUrl = await fetchImage(i.documentKey);
          return {
            src: URL.createObjectURL(imgUrl),
            altText: 'Execution vid',
            caption: 'Execution vid',
            is_video: true
          };
        }
        else {
          let imgUrl = ''
          if (isValidYouTubeLink(i.documentKey)) {
            imgUrl = extractYouTubeVideoId(i?.documentKey)
          }
          else {
            imgUrl = i.documentKey;
          }
          return {
            src: imgUrl,
            altText: 'Execution vid',
            caption: 'Execution vid',
            is_video: true,
            is_yt: isValidYouTubeLink(i.documentKey) ? true : false
          };
        }
      });

      let resp = await Promise.all(data);
      var newRes = resp.filter(value => value !== undefined);
      setItems(newRes);

    }
    setImageLoading(false);
  };

  function openDeleteImageModal() {
    setDeleteImageModal(true);
  }

  function closeDeleteImageModal() {
    setDeleteImageModal(false);
  }

  useEffect(() => {
    fetchUrls();
    // eslint-disable-next-line
  }, [images]);

  const ImagesLightbox = () => {
    return (
      <>
        {isGalleryZoom && (
          <Lightbox
            mainSrc={items[photoIndex].src}
            nextSrc={items[(photoIndex + 1) % items.length].src}
            prevSrc={items[(photoIndex + items.length - 1) % items.length].src}
            onCloseRequest={() => setIsGalleryZoom(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + items.length - 1) % items.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % items.length)
            }
          />
        )}
      </>
    );
  };

  const slides = items.map((item, index) => {
    return (
      <CarouselItem onExiting={onExiting} onExited={onExited} key={item.src}>
        <div className="zoom-gallery" style={{ textAlign: 'center' }}>
          {!item?.is_video ? <img
            key={index}
            src={item.src}
            alt={item.altText}
            style={{
              objectFit: 'fill',
              width: '40rem',
              height: '30rem',
              // maxHeight: '45rem',
              verticalAlign: 'middle'
            }}
            onClick={() => {
              setIsGalleryZoom(true);
              setPhotoIndex(index);
            }}
          /> :
            item?.is_video && !item?.is_yt ?
              <>
                <video
                  key={index}
                  src={item.src}
                  autoPlay={true}
                  loop
                  onError={(e) => console.log("playback error: ", e)}
                  muted
                  style={{
                    objectFit: 'fill',
                    width: '40rem',
                    height: '30rem',
                    // maxHeight: '45rem',
                    verticalAlign: 'middle'
                  }}
                /></>

              : <div
                key={index}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'red',
                  // overflow: 'hidden'
                }}
              >
                <YouTube
                  key={index}
                  videoId={item?.src}
                  style={{
                    objectFit: 'fill',
                    width: '100%',
                    height: '100%',
                    // maxHeight: '45rem',
                    verticalAlign: 'middle',
                  }}
                />
              </div>
          }
        </div>
      </CarouselItem>
    );
  });

  return (
    <React.Fragment>
      {imagesLoading ? (
        <div className="text-center">
          <PageDataLoader />
        </div>
      ) : (
        <>
          <DeleteImageModal
            images={images}
            closeModalHandler={closeDeleteImageModal}
            modalOpenState={deleteImageModal}
            childId={childId}
            fetchChildInfo={fetchChildInfo}
            activeIndex={activeIndex}
          />
          {showLightbox !== false && (
            <ImagesLightbox />
          )}
          <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            interval={null}
          >
            <CarouselIndicators
              items={items}
              activeIndex={activeIndex}
              onClickHandler={goToIndex}
            />
            {slides}
            <CarouselControl
              direction="prev"
              directionText="Previous"
              onClickHandler={previous}
            />
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={next}
            />
          </Carousel>
          {canDelete && (
            <Button
              size="sm"
              color="danger"
              className="mt-1 waves-effect waves-light"
              onClick={() => openDeleteImageModal()}
            >
              <i className="ri-delete-bin-6-line align-middle"></i>
            </Button>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default ExecutionCarousel;
