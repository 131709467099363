/*jshint esversion: 11 */
import React, { useState } from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Alert
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { updateNotice } from '../../api/notice';
import PageDataLoader from '../Utility/PageDataLoader';
import moment from 'moment';

const UpdateNoticeModal = ({
  modalOpenState,
  closeModalHandler,
  fetchNewNoticeList,
  noticeInfoLoading,
  noticeInfo,
  batch
}) => {
  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });
  const [noticeUpdateRequestLoading, setNoticeUpdateRequestLoading] =
    useState(false);
  const [successAlert, setSuccessAlert] = useState({
    message: '',
    show_alert: false
  });

  async function handleNoticeUpdate(event, values) {
    resetErrorAlert();
    setNoticeUpdateRequestLoading(true);
    const { response, error } = await updateNotice(noticeInfo._id, {
      date: values.date,
      description: values.description,
      batch,
      subject: values.subject
    });
    setNoticeUpdateRequestLoading(false);
    if (error) {
      showErrorAlert(error);
      return;
    }
    closeModalHandler();
    showSuccessAlert(response.message);
    fetchNewNoticeList();
  }

  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }

  function showSuccessAlert(message) {
    setSuccessAlert({ message: message, show_alert: true });
  }

  function resetSuccessAlert() {
    setSuccessAlert({ message: '', show_alert: false });
  }

  function resetState() {
    resetSuccessAlert();
    resetErrorAlert();
  }

  return (
    <React.Fragment>
      {successAlert.show_alert && (
        <SweetAlert success title="Updated" onConfirm={resetState}>
          {successAlert.message}
        </SweetAlert>
      )}
      <Modal isOpen={modalOpenState} backdrop="static" size="xl">
        <ModalHeader toggle={closeModalHandler}>
          {noticeInfoLoading ? 'Loading ...' : 'Edit Notice'}
        </ModalHeader>
        <ModalBody>
          {noticeInfoLoading ? (
            <div className="d-flex justify-content-center">
              <PageDataLoader />
            </div>
          ) : (
            <React.Fragment>
              {requestError ? (
                <Alert
                  color="danger"
                  role="alert"
                  isOpen={requestError.show_alert}
                  toggle={resetErrorAlert}
                >
                  <i className="mdi mdi-block-helper mr-2"></i>
                  {requestError.message}
                </Alert>
              ) : null}
              <AvForm
                className="needs-validation"
                onValidSubmit={handleNoticeUpdate}
              >
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label>Date</Label>
                      <AvField
                        name="date"
                        placeholder="Enter Date ..."
                        type="date"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Date is required'
                          }
                        }}
                        defaultValue={
                          noticeInfo &&
                          moment(new Date(noticeInfo.date)).format('YYYY-MM-DD')
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <Label>Subject</Label>
                      <AvField
                        name="subject"
                        placeholder="Enter Subject"
                        type="text"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Notice subject is required'
                          }
                        }}
                        defaultValue={noticeInfo?.subject}
                      />
                    </FormGroup>
                  </Col>

                  <Col md="12">
                    <FormGroup>
                      <Label>Description</Label>
                      <AvField
                        name="description"
                        placeholder="Enter Description"
                        type="textarea"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Description is required'
                          }
                        }}
                        defaultValue={noticeInfo?.description}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <ModalFooter>
                  <Button
                    disabled={noticeUpdateRequestLoading}
                    type="button"
                    color="light"
                    onClick={closeModalHandler}
                  >
                    Close
                  </Button>
                  <Button type="submit" color="success">
                    {noticeUpdateRequestLoading
                      ? 'Updating ...'
                      : 'Update Notice'}
                  </Button>
                </ModalFooter>
              </AvForm>
            </React.Fragment>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(UpdateNoticeModal);
