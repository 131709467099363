/*jshint esversion: 11 */
import React from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Alert,
  Input
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const RoleListFilterModal = ({
  filterError,
  setFilterError,
  modalOpenState,
  closeModalHandler,
  fetchNewRoleList,
  updateFilterCount,
  updateUrlPath,
  roleName,
  setRoleName,
  createdAtFilter,
  setCreatedAtFilter,
  updatedAtFilter,
  setUpdatedFilter,
  selectedCreatedByOption,
  setSelectedCreatedByOption,
  selectedUpdatedByOption,
  setSelectedUpdatedByOption,
  staffOptions,
  createQueryOptions,
  isDeleted,
  setIsDeleted,
  authUser
}) => {
  function dateRangeHandler(setHook) {
    return function (dates) {
      const [start, end] = dates;
      setHook({
        startDate: start,
        endDate: end
      });
    };
  }

  function clearFilter() {
    setCreatedAtFilter({
      startDate: null,
      endDate: null
    });
    setUpdatedFilter({
      startDate: null,
      endDate: null
    });
    setSelectedCreatedByOption(null);
    setSelectedUpdatedByOption(null);
    setRoleName('');
    setIsDeleted(false);

    const queryOptions = createQueryOptions(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    if (queryOptions) {
      updateFilterCount(queryOptions);
      fetchNewRoleList(1, null, queryOptions);
      closeModalHandler();
    }
  }
  function applyFilter() {
    const queryOptions = createQueryOptions();
    if (queryOptions) {
      updateFilterCount(queryOptions);
      fetchNewRoleList(1, null, queryOptions);
      closeModalHandler();
    }
  }

  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100
    })
  };

  return (
    <React.Fragment>
      <Modal isOpen={modalOpenState} backdrop="static" size="md">
        <ModalHeader toggle={closeModalHandler}>Filter Role</ModalHeader>
        <ModalBody>
          {filterError ? (
            <Alert
              color="danger"
              role="alert"
              isOpen={filterError ? true : false}
              toggle={() => setFilterError(false)}
            >
              <i className="mdi mdi-block-helper mr-2"></i>
              {filterError}
            </Alert>
          ) : null}
          <AvForm className="needs-validation" onValidSubmit={applyFilter}>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label>Role Name</Label>
                  <AvField
                    name="role_name"
                    placeholder="Enter Role Name ..."
                    type="text"
                    className="form-control"
                    onChange={(e) => setRoleName(e.target?.value)}
                    value={roleName}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label>Created By</Label>
                  <Select
                    options={staffOptions}
                    onChange={(e) => setSelectedCreatedByOption(e)}
                    value={selectedCreatedByOption}
                    components={animatedComponents}
                    styles={selectStyles}
                    isMulti
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label>Last Updated By</Label>
                  <Select
                    options={staffOptions}
                    onChange={(e) => setSelectedUpdatedByOption(e)}
                    value={selectedUpdatedByOption}
                    components={animatedComponents}
                    styles={selectStyles}
                    isMulti
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label>Created At</Label>
                  <DatePicker
                    selected={createdAtFilter.startDate}
                    selectsStart
                    className="form-control"
                    startDate={createdAtFilter.startDate}
                    endDate={createdAtFilter.endDate}
                    selectsRange={true}
                    dateFormat="dd-MM-yyyy"
                    onChange={dateRangeHandler(setCreatedAtFilter)}
                    isClearable={true}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label>Last Updated At</Label>
                  <DatePicker
                    selected={updatedAtFilter.startDate}
                    selectsStart
                    className="form-control"
                    startDate={updatedAtFilter.startDate}
                    endDate={updatedAtFilter.endDate}
                    selectsRange={true}
                    dateFormat="dd-MM-yyyy"
                    onChange={dateRangeHandler(setUpdatedFilter)}
                    isClearable={true}
                  />
                </FormGroup>
              </Col>
              {authUser && authUser.user_type === 'super_admin' && (
                <Col md="12">
                  <FormGroup>
                    <Label>Is Deleted</Label>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr"
                    >
                      <Input
                        id="customSwitch"
                        name="isComplted"
                        type="checkbox"
                        className="custom-control-input p-3"
                        defaultChecked={isDeleted}
                      />
                      <Label
                        htmlFor="customSwitch"
                        className="custom-control-label"
                        onClick={() => setIsDeleted(!isDeleted)}
                      >
                        Deleted Roles
                      </Label>
                    </div>
                  </FormGroup>
                </Col>
              )}
            </Row>
            <Row className="d-flex px-3" style={{ height: '100%' }}></Row>
            <ModalFooter>
              <Button size="sm" type="submit" color="primary">
                Apply Filter
              </Button>
              <Button
                size="sm"
                type="button"
                color="success"
                onClick={() => clearFilter()}
              >
                Reset
              </Button>
              <Button
                size="sm"
                type="button"
                color="light"
                onClick={closeModalHandler}
              >
                Close
              </Button>
            </ModalFooter>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(RoleListFilterModal);
