/*jshint esversion: 11 */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import {
  Row,
  Col,
  Container,
  Button,
  FormGroup,
  Label,
  Alert,
  Card,
  CardBody,
  CardTitle
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { addNewChild } from '../../api/child';
import {
  bloodGroup,
  genderEnum,
  languages,
  locationEnum,
  relationEnum
} from '../Utility/Enumerator';
import {
  validateEmailAddress,
  validatePhoneNumber
} from '../../helpers/input-validator';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { getParentList } from '../../api/parent';
import { useHistory } from 'react-router-dom';
import FormUpload from '../../components/FormUpload';

const CreateChildPage = ({ authUser }) => {
  const history = useHistory();
  const [image, setImage] = useState('');
  const [breadcrumbItems] = useState([
    { title: 'Children', link: '/children' },
    { title: 'Create Child', link: '#' }
  ]);
  const [togglePassword, setTogglePassword] = useState(false);
  const [parentArray, setParentArray] = useState([
    {
      parent: '',
      relation: ''
    }
  ]);

  const [parentOptions, setParentOptions] = useState([]);
  const [parentOptionsLoading, setParentOptionsLoading] = useState(false);

  const [bloodGroupOptions, setBloodGroupOptions] = useState([]);
  const [selectedBloodGroup, setSelectedBloodGroup] = useState(null);

  const [languageOptions, setLanguageOptions] = useState([]);
  const [selectedMotherTongue, setSelectedMotherTongue] = useState(null);
  const [selectedLangSpoken, setSelectedLangSpoken] = useState([]);

  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });
  const [childCreateRequestLoading, setChildCreateRequestLoading] =
    useState(false);
  const [successAlert, setSuccessAlert] = useState({
    message: '',
    show_alert: false
  });

  const locationOptions = locationEnum;
  const [selectedLocation, setSelectedLocation] = useState(null);

  const genderOptions = genderEnum;
  const [selectedGender, setSelectedGender] = useState({
    label: 'Male',
    value: 'Male'
  });

  const relationOptions = relationEnum;

  async function handleChildCreation(event, values) {
    let parents = [];
    let languages_spoken = [];
    resetErrorAlert();
    if (
      parentArray.length > 0 &&
      !(
        parentArray.length === 1 &&
        !parentArray[0].parent.value &&
        !parentArray[0].relation.value
      )
    ) {
      parents = parentArray.map((p) => ({
        parent: p.parent.value,
        relation: p.relation.value
      }));
    }
    if (selectedLangSpoken.length > 0) {
      languages_spoken = selectedLangSpoken.map((l) => l.value);
    }
    if (!selectedGender) {
      showErrorAlert('Please select gender');
      return;
    }
    if (!selectedLocation) {
      showErrorAlert('Please select address location');
      return;
    }

    if (!selectedBloodGroup) {
      showErrorAlert('Please select blood group');
      return;
    }
    if (!selectedMotherTongue) {
      showErrorAlert('Please select mother tongue');
      return;
    }
    if (languages_spoken.length === 0) {
      showErrorAlert('Atleast one language should be associated');
      return;
    }

    // if (parents.length === 1 && (!parents[0].parent || !parents[0].relation)) {
    //   showErrorAlert('Atleast one parent should be associated');
    //   return;
    // }

    // if (!image) {
    //   showErrorAlert('Select a profile picture');
    //   return;
    // }
    let form = new FormData();
    form.set('name', values.name);
    form.set('email_address', values.email_address);
    form.set('password', values.password);
    form.set('phone_number', values.phone_number);
    form.set('dob', values.dob);
    form.set('gender', selectedGender.value);
    form.set('address', values.address);
    form.set('address_location', selectedLocation.value);
    if (parents.length > 0) {
      parents.forEach((g, index) => {
        form.append(`parents[${index}][parent]`, g.parent);
        form.append(`parents[${index}][relation]`, g.relation);
      });
    }
    form.set('mother_tongue', selectedMotherTongue.value);
    languages_spoken.map((g, index) =>
      form.set(`languages_spoken[${index}]`, g)
    );
    form.set('blood_group', selectedBloodGroup.value);
    form.set('allergies', values.allergies);
    form.set('good_habits', values.good_habits);
    form.set('bad_habits', values.bad_habits);
    form.set('sleeping_pattern', values.sleeping_pattern);
    if (image) form.set('profile_picture', image);

    setChildCreateRequestLoading(true);
    const { response, error } = await addNewChild(form);
    setChildCreateRequestLoading(false);
    if (error) {
      showErrorAlert(error);
      return;
    }

    setSelectedBloodGroup(null);
    setSelectedGender({ label: 'Male', value: 'Male' });
    setSelectedLocation(null);
    showSuccessAlert(response.message);
    history.goBack();
  }

  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }

  function showSuccessAlert(message) {
    setSuccessAlert({ message: message, show_alert: true });
  }

  function resetSuccessAlert() {
    setSuccessAlert({ message: '', show_alert: false });
  }

  function resetState() {
    resetSuccessAlert();
    resetErrorAlert();
  }

  function handleGenderSelection(selectedItem) {
    setSelectedGender(selectedItem);
  }

  function handleLocationSelection(selectedItem) {
    setSelectedLocation(selectedItem);
  }

  function handleBloodGroupSelection(selectedItem) {
    setSelectedBloodGroup(selectedItem);
  }

  function handleMotherTongueSelection(selectedItem) {
    setSelectedMotherTongue(selectedItem);
  }

  function handleLangSpokenSelection(selectedItem) {
    setSelectedLangSpoken(selectedItem);
  }

  function handleParentSelection(index, selectedItem) {
    let newParentArray = [...parentArray];
    newParentArray[index]['parent'] = selectedItem;
    setParentArray(newParentArray);
  }

  function handleRelationSelection(index, selectedItem) {
    let newParentArray = [...parentArray];
    newParentArray[index]['relation'] = selectedItem;
    setParentArray(newParentArray);
  }
  let listElem = document.getElementById('childList');
  let linkElem = document.getElementById('childLink');
  useEffect(() => {
    if (listElem) listElem.classList.add('mm-active');
    if (linkElem) linkElem.classList.add('active');
    if (authUser) {
      fetchParentOptions();
      setBloodGroupOptions(buildListOptions(bloodGroup));
      setLanguageOptions(buildListOptions(languages));
    }
    // eslint-disable-next-line
  }, [authUser, listElem, linkElem]);

  const fetchParentOptions = async () => {
    setParentOptionsLoading(true);
    const response = await getParentList(1, 1000);
    if (response) {
      setParentOptions(buildOptions(response?.data || []));
      setParentOptionsLoading(false);
    }
  };

  function buildOptions(list) {
    return list.map((item) => ({
      value: item._id,
      label: item.name
    }));
  }

  function buildListOptions(list) {
    return list.map((item) => ({
      value: item,
      label: item
    }));
  }

  const addParentFields = () => {
    resetErrorAlert();
    setParentArray([...parentArray, { parent: '', relation: '' }]);
  };

  let removeParentFields = (i) => {
    let newParentArray = [...parentArray];
    newParentArray.splice(i, 1);
    setParentArray(newParentArray);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Create Child" breadcrumbItems={breadcrumbItems} />
          {successAlert.show_alert && (
            <SweetAlert success title="Created" onConfirm={resetState}>
              {successAlert.message}
            </SweetAlert>
          )}
          {requestError ? (
            <Alert
              color="danger"
              role="alert"
              isOpen={requestError.show_alert}
              toggle={resetErrorAlert}
            >
              <i className="mdi mdi-block-helper mr-2"></i>
              {requestError.message}
            </Alert>
          ) : null}
          <AvForm
            className="needs-validation"
            onValidSubmit={handleChildCreation}
          >
            <Row>
              <Col md={6} xs={12}>
                <Card>
                  <CardBody>
                    <CardTitle tag="h5">Basic Details</CardTitle>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <Label>Child Name</Label>
                          <AvField
                            name="name"
                            placeholder="Enter Child Name ..."
                            type="text"
                            className="form-control"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: 'Child name is required'
                              }
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Email Address</Label>
                          <AvField
                            name="email_address"
                            placeholder="Enter Email Address ..."
                            type="text"
                            className="form-control"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: 'Email Address is required'
                              },
                              async: validateEmailAddress
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup
                          style={{
                            position: 'relative',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden'
                          }}
                        >
                          <Label
                            style={{ display: 'flex', alignItems: 'middle' }}
                          >
                            Password{' '}
                            <i
                              className={
                                togglePassword
                                  ? 'ri-eye-line ml-2'
                                  : 'ri-eye-off-line ml-2'
                              }
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setTogglePassword(!togglePassword);
                              }}
                            ></i>
                          </Label>
                          <AvField
                            name="password"
                            placeholder="Password"
                            type={togglePassword ? 'text' : 'password'}
                            className="form-control"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: 'Please provide a strong password'
                              },
                              minLength: {
                                value: 6,
                                errorMessage:
                                  'Password must be at least 6 characters'
                              }
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Phone Number</Label>
                          <AvField
                            name="phone_number"
                            placeholder="Enter Phone Number ..."
                            type="text"
                            className="form-control"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: 'Phone Number is required'
                              },
                              minLength: {
                                value: 10,
                                errorMessage: 'Phone Number must be 10 digits'
                              },
                              maxLength: {
                                value: 10,
                                errorMessage: 'Phone Number must be 10 digits'
                              },
                              async: validatePhoneNumber
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>DOB</Label>
                          <AvField
                            name="dob"
                            placeholder="Enter DOB ..."
                            type="date"
                            className="form-control"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: 'DOB is required'
                              }
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Gender</Label>
                          <Select
                            options={genderOptions}
                            onChange={handleGenderSelection}
                            value={selectedGender}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Address Location</Label>
                          <Select
                            options={locationOptions}
                            onChange={handleLocationSelection}
                            value={selectedLocation}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <Label>Address</Label>
                          <AvField
                            name="address"
                            placeholder="Enter Address ..."
                            type="textarea"
                            className="form-control"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: 'Address is required'
                              }
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormUpload
                          setImage={setImage}
                          title="Profile Picture"
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={6} xs={12}>
                <Card>
                  <CardBody>
                    <CardTitle tag="h5">Additional Details</CardTitle>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label>Blood Group</Label>
                          <Select
                            options={bloodGroupOptions}
                            onChange={handleBloodGroupSelection}
                            value={selectedBloodGroup}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Allergies</Label>
                          <AvField
                            name="allergies"
                            placeholder="Enter Allergies ..."
                            type="text"
                            className="form-control"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Good Habits</Label>
                          <AvField
                            name="good_habits"
                            placeholder="Enter Good Habits ..."
                            type="text"
                            className="form-control"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Bad Habits</Label>
                          <AvField
                            name="bad_habits"
                            placeholder="Enter Bad Habits ..."
                            type="text"
                            className="form-control"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Sleeping Pattern</Label>
                          <AvField
                            name="sleeping_pattern"
                            placeholder="Enter Sleeping Pattern ..."
                            type="text"
                            className="form-control"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Mother Tongue</Label>
                          <Select
                            options={languageOptions}
                            onChange={handleMotherTongueSelection}
                            value={selectedMotherTongue}
                          />
                        </FormGroup>
                      </Col>

                      <Col md="12">
                        <FormGroup>
                          <Label>Languages Spoken</Label>
                          <Select
                            isMulti
                            options={languageOptions}
                            onChange={handleLangSpokenSelection}
                            value={selectedLangSpoken}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row className="mt-2 mb-2">
                      <Col md="5">
                        <CardTitle tag="h5">Parent Details</CardTitle>
                      </Col>
                      <Col className="d-flex justify-content-end align-items-center">
                        <Button
                          size="sm"
                          color="success"
                          className=" waves-effect waves-light"
                          disabled={
                            !parentArray[parentArray.length - 1].parent ||
                            !parentArray[parentArray.length - 1].relation
                          }
                          onClick={() => addParentFields()}
                        >
                          <i className="ri-add-line align-middle mr-2"></i>
                          Parent
                        </Button>
                      </Col>
                    </Row>

                    {parentArray.map((element, index) => (
                      <Row>
                        <Col md="5">
                          <FormGroup>
                            <Label>Parent</Label>
                            <Select
                              options={parentOptions}
                              disabled={parentOptionsLoading}
                              onChange={(e) => handleParentSelection(index, e)}
                              value={element.parent}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="5">
                          <FormGroup>
                            <Label>Relation</Label>
                            <Select
                              options={relationOptions}
                              onChange={(e) =>
                                handleRelationSelection(index, e)
                              }
                              value={element.relation}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="2" className="d-flex mt-1 align-items-center">
                          <Button
                            size="sm"
                            color="danger"
                            disabled={index === 0}
                            className=" waves-effect waves-light"
                            onClick={() => removeParentFields(index)}
                          >
                            <i className="ri-delete-bin-6-line align-middle"></i>
                          </Button>
                        </Col>
                      </Row>
                    ))}
                  </CardBody>
                </Card>

                <Button type="submit" color="success">
                  {childCreateRequestLoading ? 'Creating ...' : 'Add Child'}
                </Button>
              </Col>
            </Row>
          </AvForm>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(CreateChildPage);
