/*jshint esversion: 11 */
import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Table,
  CardHeader,
  Card,
  CardBody,
  Badge
} from 'reactstrap';
import { connect } from 'react-redux';
import PageDataLoader from '../Utility/PageDataLoader';
import moment from 'moment';
import ExecutionCarousel from '../Child/ExecutionCarousel';

const ViewActivityModal = ({
  modalOpenState,
  closeModalHandler,
  activityInfoLoading,
  activityInfo
}) => {
  console.log(activityInfo, 'activity info')
  let { date, batch, activities, tags } = activityInfo;

  const [toggle, setToggle] = useState(999)
  return (
    <React.Fragment>
      <Modal isOpen={modalOpenState} backdrop="static" size="xl">
        <ModalHeader toggle={closeModalHandler}>
          {activityInfoLoading
            ? 'Loading...'
            : batch + ' | ' + moment(new Date(date)).format('DD-MM-YYYY')}
        </ModalHeader>
        <ModalBody>
          {activityInfoLoading ? (
            <div className="d-flex justify-content-center">
              <PageDataLoader />
            </div>
          ) : (
            <>
              {activities && activities.length > 0 ? (
                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <Table id="tech-companies-1" striped bordered responsive>
                      <thead>
                        <tr>
                          <th>SL. No</th>
                          <th>Name</th>
                          <th>Description</th>
                          <th></th>
                          {/* <th>Duration</th>
                          <th>Start Time</th>
                          <th>Summary</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {activities?.map((item, index) => (
                          <>
                            <tr key={index}>
                              <th>{index + 1}</th>
                              <td>{item.activity.name}</td>
                              {/* <td>{item.activity.duration}</td>
                            <td>{item.start_time}</td> */}
                              <td>
                                {item.activity.detail.substring(0, 250)}
                                {item.activity.detail.length > 250 && '...'}
                              </td>
                              <td><Button size='sm' color='primary' onClick={(e) => { setToggle(index) }}>Show details</Button></td>
                            </tr>
                            {toggle === index && (
                              <tr>
                                <td>
                                </td>
                                <td>{item.activity.tags.map((tag) => {
                                  {
                                    return (
                                      <Badge
                                        color="primary"
                                        className="mr-2 mb-2"
                                        style={{
                                          padding: '10px 20px',
                                          // fontSize: '14px'
                                        }}
                                        pill
                                      >
                                        {tag.name}
                                      </Badge>
                                    )
                                  }
                                })}</td>
                                <td>
                                  {item.activity.pictures && item.activity.pictures.length !== 0 ? <ExecutionCarousel images={item.activity.pictures} showLightbox={false} /> : (
                                    <p>no images</p>
                                  )}

                                </td>
                              </tr>
                            )}
                          </>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              ) : (
                <CardHeader>No activity assigned</CardHeader>
              )}
            </>
          )}

          <ModalFooter>
            <Button type="button" color="light" onClick={closeModalHandler}>
              Close
            </Button>
          </ModalFooter>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(ViewActivityModal);
