import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Card, CardBody, CardTitle, CardText, Row, Col, Label, FormGroup, Input, UncontrolledAccordion, CardHeader, Collapse, Table } from 'reactstrap';
// import Accordion from 'react-bootstrap/Accordion';

const ViewSectionModal = ({ isOpen, toggle, section }) => {

  const [toggleQuestion, setToggequestion] = useState(1);


  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>View Attribute</ModalHeader>
      <ModalBody>
        {section && (
          <React.Fragment>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label>Name</Label>
                  <Input
                    name="title"
                    type="text"
                    className="form-control"
                    defaultValue={section.name}
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Badge</Label>
                  <Input
                    name="title"
                    type="text"
                    className="form-control"
                    defaultValue={section?.badge?.name}
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>No of Questions</Label>
                  <Input
                    name="title"
                    type="text"
                    className="form-control"
                    defaultValue={section.questions.length}
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label>Questions</Label>
                </FormGroup>
              </Col>
              {section.questions.map((question, i) => (
                <Col md="12">
                  <Card>
                    <CardHeader onClick={() => setToggequestion(i)}>
                      <span className="font-weight-bold">{question.title}</span>
                    </CardHeader>
                    <Collapse isOpen={toggleQuestion === i ? true : false}>
                      <CardBody>
                        <Table>
                          <tr>
                            <th>Option Name</th>
                            <th>Weightage</th>
                          </tr>
                          <tbody>
                            {question.option_list.map((option) => (
                              <tr key={option._id} style={{ backgroundColor: option.is_correct && 'pink' }}>
                                <td >
                                  {option.option_name}
                                </td>
                                <td>
                                  {option.weightage || 1}
                                </td>
                              </tr>

                            ))}
                          </tbody>

                        </Table>
                      </CardBody>
                    </Collapse>
                  </Card>

                  {/* <UncontrolledAccordion defaultOpen="1">
                  </UncontrolledAccordion> */}

                  {/* <Card key={question._id} className="my-3">
                    <CardBody>
                      <CardTitle tag="h6">{question.title}</CardTitle>
                      <ul>
                        {question.option_list.map((option) => (
                          <li key={option._id} style={{ backgroundColor: option.isCorrect ? 'lightgreen' : 'transparent' }}>
                            {option.option_name}
                          </li>
                        ))}
                      </ul>
                    </CardBody>
                  </Card> */}
                </Col>
              ))}
            </Row>


          </React.Fragment>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggle}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

export default ViewSectionModal;