/*jshint esversion: 11 */
import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Alert,
  Input
} from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import PageDataLoader from '../Utility/PageDataLoader';
import {
  getAssignmentChildrenInfo,
  updateAssignmentStatus
} from '../../api/assignment';
import moment from 'moment';

const UpdateAssignmentStatusModal = ({
  modalOpenState,
  closeModalHandler,
  assignmentId,
  fetchNewData,
  authUser
}) => {
  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });

  const [updateAssignmentRequestLoading, setUpdateAssignmentRequestLoading] =
    useState(false);
  const [assignmentInfo, setAssignmentInfo] = useState({});
  const [assignmentInfoLoading, setAssignmentInfoLoading] = useState(true);
  const [successAlert, setSuccessAlert] = useState({
    message: '',
    show_alert: false
  });
  const [isCompleted, setIsCompleted] = useState(false);

  async function handleUpdateAssignment(event, values) {
    resetErrorAlert();
    setUpdateAssignmentRequestLoading(true);
    const { response, error } = await updateAssignmentStatus(assignmentId, {
      isCompleted
    });
    setUpdateAssignmentRequestLoading(false);
    if (error) {
      showErrorAlert(error);
      return;
    }
    fetchNewData();
    closeModalHandler();
    showSuccessAlert(response.message);
  }

  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }

  function showSuccessAlert(message) {
    setSuccessAlert({ message: message, show_alert: true });
  }

  function resetSuccessAlert() {
    setSuccessAlert({ message: '', show_alert: false });
  }

  function resetState() {
    resetSuccessAlert();
    resetErrorAlert();
  }

  useEffect(() => {
    if (authUser && assignmentId) {
      fetchAssignmentInfo();
    }
    // eslint-disable-next-line
  }, [authUser, modalOpenState]);

  useEffect(() => {
    if (assignmentInfo) {
      setIsCompleted(assignmentInfo.isCompleted);
    }
  }, [authUser, assignmentInfo, modalOpenState]);

  const fetchAssignmentInfo = async () => {
    setAssignmentInfoLoading(true);
    const response = await getAssignmentChildrenInfo(assignmentId);

    if (response) {
      setAssignmentInfo(response);
      setAssignmentInfoLoading(false);
    } else {
      setAssignmentInfoLoading(false);
      showErrorAlert('Assignment with given ID not found');
      return;
    }
  };

  return (
    <React.Fragment>
      {successAlert.show_alert && (
        <SweetAlert success title="Updated" onConfirm={resetState}>
          {successAlert.message}
        </SweetAlert>
      )}
      <Modal isOpen={modalOpenState} backdrop="static" size="xl">
        <ModalHeader toggle={closeModalHandler}>
          {'Update Assignment Status'}
        </ModalHeader>
        <ModalBody>
          <React.Fragment>
            {requestError ? (
              <Alert
                color="danger"
                role="alert"
                isOpen={requestError.show_alert}
                toggle={resetErrorAlert}
              >
                <i className="mdi mdi-block-helper mr-2"></i>
                {requestError.message}
              </Alert>
            ) : null}
            {assignmentInfoLoading ? (
              <div className="d-flex justify-content-center">
                <PageDataLoader />
              </div>
            ) : (
              <AvForm
                className="needs-validation"
                onValidSubmit={handleUpdateAssignment}
              >
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label>Name</Label>
                      <AvField
                        name="name"
                        placeholder="Enter Name ..."
                        type="text"
                        className="form-control"
                        defaultValue={assignmentInfo.assignment?.name}
                        readOnly
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <Label>Summary</Label>
                      <AvField
                        name="summary"
                        placeholder="Enter Summary ..."
                        type="textarea"
                        className="form-control"
                        defaultValue={assignmentInfo.assignment?.summary}
                        readOnly
                      />
                    </FormGroup>
                  </Col>
                  <Col md="8">
                    <FormGroup>
                      <Label>Date</Label>
                      <AvField
                        name="date"
                        placeholder="Enter Date ..."
                        type="date"
                        className="form-control"
                        defaultValue={
                          assignmentInfo.assignment &&
                          moment(
                            new Date(assignmentInfo.assignment.date)
                          ).format('YYYY-MM-DD')
                        }
                        readOnly
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label>Completed</Label>
                      <div
                        className="custom-control custom-switch mb-2"
                        dir="ltr"
                      >
                        <Input
                          id="customSwitchAssignment"
                          name="isComplted"
                          type="checkbox"
                          className="custom-control-input p-3"
                          defaultChecked={assignmentInfo?.isCompleted}
                        />
                        <Label
                          htmlFor="customSwitchAssignment"
                          className="custom-control-label"
                          onClick={() => setIsCompleted(!isCompleted)}
                        >
                          Completed Status
                        </Label>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <Label>Video Url</Label>
                      <AvField
                        name="video_url"
                        type="text"
                        className="form-control"
                        defaultValue={assignmentInfo.assignment?.video_url}
                        readOnly
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <ModalFooter>
                  <Button
                    disabled={updateAssignmentRequestLoading}
                    type="button"
                    color="light"
                    onClick={closeModalHandler}
                  >
                    Close
                  </Button>
                  <Button type="submit" color="success">
                    {updateAssignmentRequestLoading
                      ? 'Updating ...'
                      : 'Update Status'}
                  </Button>
                </ModalFooter>
              </AvForm>
            )}
          </React.Fragment>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(UpdateAssignmentStatusModal);
