/*jshint esversion: 11 */
import React from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const BatchActivityFilterModal = ({
  modalOpenState,
  closeModalHandler,
  fetchNewData,
  name,
  setName,
  date,
  setDate,
  startDate,
  endDate,
  updateFilterCount
}) => {
  function applyFilter() {
    updateFilterCount(name, date);
    if (date) {
      fetchNewData(moment(date).format('YYYY-MM-DD'), name, 1);
    } else {
      fetchNewData(null, name, 1);
    }

    closeModalHandler();
  }

  function clearFilter() {
    updateFilterCount(null, '');
    setDate(null);
    setName('');
    fetchNewData(null, null, 1);
    closeModalHandler();
  }

  return (
    <React.Fragment>
      <Modal isOpen={modalOpenState} backdrop="static" size="md">
        <ModalHeader toggle={closeModalHandler}>Filter Activity</ModalHeader>
        <ModalBody>
          <AvForm className="needs-validation" onValidSubmit={applyFilter}>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label>Activity Name</Label>
                  <AvField
                    name="name"
                    placeholder="Enter Activity Name ..."
                    type="text"
                    className="form-control"
                    onChange={(e) => setName(e.target?.value)}
                    value={name}
                  />
                </FormGroup>
              </Col>

              <Col md="12" className="mb-2">
                <FormGroup>
                  <Label>Date</Label>
                  <DatePicker
                    selected={date}
                    className="form-control"
                    minDate={new Date(startDate)}
                    maxDate={new Date(endDate)}
                    dateFormat="yyyy-MM-dd"
                    onChange={(e) => {
                      if (e) setDate(e);
                      else setDate(null);
                    }}
                    isClearable={true}
                    placeholderText={'Select Date'}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="d-flex px-3" style={{ height: '100%' }}></Row>
            <ModalFooter>
              <Button size="sm" type="submit" color="primary">
                Apply Filter
              </Button>
              <Button
                size="sm"
                type="button"
                color="success"
                onClick={() => clearFilter()}
              >
                Reset
              </Button>
              <Button
                size="sm"
                type="button"
                color="light"
                onClick={closeModalHandler}
              >
                Close
              </Button>
            </ModalFooter>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(BatchActivityFilterModal);
