/*jshint esversion: 11 */
export function validatePermissionKey(authUser, permissionKey) {
  if (authUser) {
    if (authUser?.user_type === 'super_admin') {
      return true;
    } else if (
      Array.isArray(authUser?.role?.permissions) &&
      authUser?.role?.permissions.includes(permissionKey)
    ) {
      return true;
    }
  }
  return false;
}
