import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import { fetchImage } from '../../api/image';
import PageDataLoader from '../Utility/PageDataLoader';
import DeleteImageModal from './DeleteImageModal';
import { validatePermissionKey } from '../../helpers/permission';
import { DELETE_BATCH_IMAGE } from '../Utility/PermissionKeys';

const ThumbnailGallery = ({
  images,
  canDelete,
  batch,
  date,
  fetchNewData,
  authUser
}) => {
  const [deleteImageModal, setDeleteImageModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isGalleryZoom, setIsGalleryZoom] = useState(false);
  const [imagesLoading, setImageLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [loadMore, setLoadMore] = useState(false)

  // const fetchUrls = async () => {
  //   setImageLoading(true);
  //   if (images.length > 0) {
  //     let data = images.map(async (i) => {
  //       let imgUrl = await fetchImage(i.documentKey);
  //       return {
  //         src: URL.createObjectURL(imgUrl),
  //         altText: 'Batch img',
  //         caption: 'Batch img'
  //       };
  //     });
  //     let resp = await Promise.all(data);
  //     setItems(resp);
  //   }
  //   setImageLoading(false);
  // };


  const fetchUrls = async () => {
    setImageLoading(true);

    // Function to process each batch
    const processBatch = async (batch) => {
      const data = batch.map(async (i) => {
        const imgUrl = await fetchImage(i.documentKey);
        if (imgUrl === null) {
          return {}
        }
        else {
          return {
            src: URL.createObjectURL(imgUrl),
            altText: 'Batch img',
            caption: 'Batch img'
          };
        }
      });
      return Promise.all(data);
    };

    // Split array into chunks of 3
    const chunkSize = 2;
    const chunks = [];
    for (let i = 0; i < images.length; i += chunkSize) {
      chunks.push(images.slice(i, i + chunkSize));
    }

    // Process each chunk and update state incrementally
    let allItems = [];
    for (const chunk of chunks) {

      const resp = await processBatch(chunk);
      var newRes = resp.filter(value => Object.keys(value).length !== 0);

      allItems = [...allItems, ...newRes];
      setItems([...allItems]); // Update state after each batch
      setImageLoading(false);
      setLoadMore(true)
    }
    setLoadMore(false)

  };




  function openDeleteImageModal() {
    setDeleteImageModal(true);
  }

  function closeDeleteImageModal() {
    setDeleteImageModal(false);
  }

  useEffect(() => {
    fetchUrls();
    // eslint-disable-next-line
  }, [images]);

  const ImagesLightbox = () => {
    return (
      <>
        {isGalleryZoom && (
          <Lightbox
            mainSrc={items[photoIndex].src}
            nextSrc={items[(photoIndex + 1) % items.length].src}
            prevSrc={items[(photoIndex + items.length - 1) % items.length].src}
            onCloseRequest={() => setIsGalleryZoom(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + items.length - 1) % items.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % items.length)
            }
          />
        )}
      </>
    );
  };

  const slides = items.map((item, index) => {
    return (
      <>
        <Col md="3" className="mb-2">
          <div className="zoom-gallery thumbnail">
            <img
              src={item.src}
              alt={item.altText}
              style={{
                width: '100%',
                height: "auto"
              }}
              onClick={() => {
                setIsGalleryZoom(true);
                setPhotoIndex(index);
              }}
            />
            {validatePermissionKey(authUser, DELETE_BATCH_IMAGE) && canDelete && (
              <Button
                size="sm"
                color="danger"
                className="mt-1 waves-effect waves-light deleteBtn"
                onClick={() => {
                  setActiveIndex(index);
                  openDeleteImageModal();
                }}
              >
                <i className="ri-delete-bin-6-line align-middle"></i>
              </Button>
            )}
          </div>
        </Col>

      </>
    );
  });

  return (
    <React.Fragment>
      {imagesLoading ? (
        <div className="text-center">
          <PageDataLoader />
        </div>
      ) : (
        <>
          <DeleteImageModal
            images={images}
            closeModalHandler={closeDeleteImageModal}
            modalOpenState={deleteImageModal}
            batch={batch}
            date={date}
            fetchNewData={fetchNewData}
            activeIndex={activeIndex}
          />
          <ImagesLightbox />
          <Row>{slides}</Row>
          {loadMore && <div className="text-center">
            <PageDataLoader />
          </div>
          }
        </>
      )}
    </React.Fragment>
  );
};

export default ThumbnailGallery;
