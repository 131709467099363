/*jshint esversion: 11 */
import sendApiRequest from '../helpers/axios-setup';

/**
 *
 * @returns {Promise}
 */
export async function getChildList(
  page,
  limit,
  {
    filter_dob_start_date = '',
    filter_dob_end_date = '',
    filter_name = '',
    filter_phone_number = '',
    filter_email_address = '',
    filter_roll_no = '',
    filter_gender = '',
    filter_address_location = '',
    include_deleted = ''
  } = {},
  sorting_option = ''
) {
  try {
    const response = await sendApiRequest.get('/child', {
      params: {
        page: page,
        limit: limit,
        sorting_option: sorting_option,
        filter_dob_start_date,
        filter_dob_end_date,
        filter_name,
        filter_phone_number,
        filter_email_address,
        filter_roll_no,
        filter_gender,
        filter_address_location,
        include_deleted
      }
    });
    return response.data?.data[0];
  } catch (e) {
    return null;
  }
}

/**
 *
 * @param {String} name
 * @param {String} email_address
 * @param {String} password
 * @param {String} phone_number
 * @param {Date} dob
 * @param {String} gender
 * @param {String} address
 * @param {String} address_location
 * @param {Array} parents
 * @param {String} mother_tongue
 * @param {Array} languages_spoken
 * @param {String} blood_group
 * @param {String} allergies
 * @param {String} good_habits
 * @param {String} bad_habits
 * @param {String} sleeping_pattern
 * @returns {Promise<Object>}
 */
export async function addNewChild(data) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.post('/child', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @param {String} childId
 * @returns
 */
export async function getChildInfo(childId) {
  try {
    const response = await sendApiRequest.get(`/child/${childId}`);
    return response.data?.data;
  } catch (e) {
    return null;
  }
}

/**
 *
 * @param {String} childId
 * @param {String} name
 * @param {String} email_address
 * @param {String} password
 * @param {String} phone_number
 * @param {Date} dob
 * @param {String} gender
 * @param {String} address
 * @param {String} address_location
 * @param {Array} parents
 * @param {String} mother_tongue
 * @param {Array} languages_spoken
 * @param {String} blood_group
 * @param {String} allergies
 * @param {String} good_habits
 * @param {String} bad_habits
 * @param {String} sleeping_pattern
 * @returns {Promise<Object>}
 */
export async function updateChild(childId, data) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.put(`/child/${childId}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @param {String} childId
 * @returns
 */
export async function getGalleryImages(childId) {
  try {
    const response = await sendApiRequest.get(`/child/gallery/${childId}`);
    return response.data?.data;
  } catch (e) {
    return null;
  }
}

/**
 *
 * @param {String} childId
 * @returns
 */
export async function deleteRestoreChild(childId, type) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.delete(`/child/${childId}`, {
      params: {
        type: type
      }
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}
