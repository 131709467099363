/*jshint esversion: 11 */
import moment from 'moment';
import validator from 'validator';

export const validatePhoneNumber = async (value, ctx, input, cb) => {
  if (!validator.isMobilePhone(value, 'en-IN')) {
    return cb('Please enter a valid Indian Mobile Number');
  }
  return cb(true);
};

export const validateEmailAddress = async (value, ctx, input, cb) => {
  if (value.trim().length > 0 && !validator.isEmail(value)) {
    return cb('Please enter a valid Email Address');
  }
  return cb(true);
};

export const validateDOB = async (value, ctx, input, cb) => {
  let age = moment(new Date()).diff(new Date(value), 'years');
  if (age < 22) {
    return cb('Minimum age for DOB should be 22');
  }
  return cb(true);
};
