import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Container, Table, Label, FormGroup, Button, Modal, Badge } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import AddSectionModal from './AddSectionModal';
import EditSectionModal from './EditSectionModal';
import DeleteSectionModal from './DeleteSectionModal';
import ViewSectionModal from './ViewSectionModal';
import FilterSectionModal from './FilterSectionModal';
import { viewSections, updateSection, deleteSection } from '../../api/section';
import moment from 'moment';
import { validatePermissionKey } from '../../helpers/permission';
import { CREATE_ATTRIBUTE, DELETE_ATTRIBUTE, UPDATE_ATTRIBUTE } from '../Utility/PermissionKeys';
import { connect, useSelector } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import Paginator from '../Utility/Paginator';
import PageDataLoader from '../Utility/PageDataLoader';
import validator from 'validator';
import { stringify as QStringify, parse as QParse } from 'qs';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


const SectionManager = ({ authUser }) => {
  const history = useHistory()
  /**
   *
   * @param {string} str
   */
  function getValidDateInput(str) {
    return validator.isDate(str) ? new Date(str).toISOString() : '';
  }


  const [breadcrumbItems] = useState([
    { title: 'Sections', link: '#' },
    { title: 'View List', link: '#' }
  ]);

  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageLimit: 10,
    totalEntries: 0,
    totalPages: 0
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [sectionLoading, setSectionLoading] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [filterError, setFilterError] = useState(false);
  const [sections, setSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState(null);
  const [sortOrder, setSortOrder] = useState('updated_at$desc');
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [deleteOrRestore, setDeleteOrRestore] = useState({});
  const [filterCount, setFilterCount] = useState(0);
  const [createdAtFilter, setCreatedAtFilter] = useState({
    startDate: null,
    endDate: null
  });
  const [expectedDateFilter, setExpectedDateFilter] = useState({
    startDate: null,
    endDate: null
  });

  const [filterSectionName, setFilterSectionName] = useState('');
  const [isDeleted, setIsDeleted] = useState(false);
  const [currentFilterCount, setCurrentFilterCount] = useState(0);
  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });
  const [successAlert, setSuccessAlert] = useState({
    title: '',
    message: '',
    show_alert: false
  });

  function showErrorAlert(message, title) {
    setRequestError({ message: message, show_alert: true, title: title });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false, title: '' });
  }

  function showSuccessAlert(message, title) {
    setSuccessAlert({ message: message, show_alert: true, title: title });
  }

  function resetSuccessAlert() {
    setSuccessAlert({ message: '', show_alert: false, title: '' });
  }

  function resetState() {
    resetSuccessAlert();
    resetErrorAlert();
  }

  const toggleModal = () => setModalOpen(!modalOpen);
  const toggleEditModal = () => setEditModalOpen(!editModalOpen);
  const toggleDeleteModal = () => setDeleteModalOpen(!deleteModalOpen);
  const toggleViewModal = () => setViewModalOpen(!viewModalOpen);
  const toggleFilterModal = () => setFilterModalOpen(!filterModalOpen);

  const handlePagination = (page) => {
    fetchSections(page, pagination.pageLimit, createQueryOptions());
  };

  const fetchSections = async (
    current_page = 1,
    page_limit = 10,
    queryOptions = {},
    sortingOption = sortOrder
  ) => {
    setSectionLoading(true)
    const response = await viewSections(current_page, page_limit, queryOptions, sortingOption);
    if (response) {
      setPagination({
        currentPage: response.metadata.page,
        pageLimit: response.metadata.limit,
        totalPages: response.metadata.total_pages,
        totalEntries: response.metadata.total_results
      });
      setSections(response?.data);
      setSectionLoading(false)
    }
  };

  async function fetchSortedList(sortingOption) {
    setSortOrder(sortingOption);
    const queryOptions = createQueryOptions();
    queryOptions.sorting_option = sortingOption;
    updateUrlPath(queryOptions);
    fetchSections(1, null, queryOptions, sortingOption);
  }


  let listElem = document.getElementById('sectionList');
  let linkElem = document.getElementById('sectionLink');

  useEffect(() => {
    if (listElem) listElem.classList.add('mm-active');
    if (linkElem) linkElem.classList.add('active');
    if (authUser) {
      fetchSections();
      populateSearchOptions(history?.location?.search)
    }
    // eslint-disable-next-line
  }, [authUser, listElem, linkElem]);



  const handleDeleteSection = async () => {
    resetState();
    const { response, error } = await deleteSection(selectedSection._id);
    if (error) {
      showErrorAlert(error || 'Error Deleting Section', 'Error');
    } else {
      showSuccessAlert(!selectedSection?.isDeleted ? 'Attribute Deleted Successfully' : 'Attribute Restored Successfully', !selectedSection?.isDeleted ? 'Deleted' : 'Restored');
    }
    fetchSections();
    toggleDeleteModal();
  };

  const applyFilters = async () => {
    await fetchSections(sortOrder, entriesPerPage);
    toggleFilterModal();
  };



  function createQueryOptions(
    startDate = createdAtFilter.startDate,
    endDate = createdAtFilter.endDate,
    filterName = filterSectionName,
    deletedStatus = isDeleted
  ) {
    setFilterError(false);
    if ((startDate && !endDate) || (!startDate && endDate)) {
      setFilterError('Select valid date range');
      return;
    }
    const startDateISOStringCreatedAt = getValidDateInput(startDate);
    const endDateISOStringCreatedAt = getValidDateInput(endDate);

    const queryOptions = {
      filter_name: filterName,
      filter_start_date: startDateISOStringCreatedAt,
      filter_end_date: endDateISOStringCreatedAt,
      include_deleted: deletedStatus
    };
    updateUrlPath(queryOptions);
    // updateFilterCount(queryOptions);
    // fetchSections(1, null, queryOptions);
    return queryOptions;
  }

  function updateUrlPath(queryOptions) {
    const queryString = QStringify(queryOptions);
    history.replace(`/section?${queryString}`);
  }

  /**
 *
 * @param {string} searchQuery
 */
  function populateSearchOptions(searchQuery) {
    const parsedQuery = QParse(searchQuery.substring(1));
    if (parsedQuery.filter_start_date) {
      setExpectedDateFilter((pv) => {
        return {
          ...pv,
          startDate: new Date(parsedQuery.filter_start_date)
        };
      });
    }
    if (parsedQuery.filter_end_date) {
      setExpectedDateFilter((pv) => {
        return {
          ...pv,
          endDate: new Date(parsedQuery.filter_end_date)
        };
      });
    }
    setFilterSectionName(parsedQuery.filter_name);
    fetchSections(1, null, parsedQuery);
    updateFilterCount(parsedQuery);
  }


  function updateFilterCount(option) {
    let count = 0;
    Object.keys(option).forEach((i) => {
      if (option[i]?.toString()?.trim()?.length > 0 && option[i] !== false) {
        count += 1;
      }
    });
    setCurrentFilterCount(count);
  }


  // useEffect(() => {
  //   setFilterCount(Object.keys(filters).filter((key) => key !== 'page').length);
  // }, [filters]);

  return (
    <div className="page-content">
      {successAlert.show_alert && (
        <SweetAlert success title={successAlert.title} onConfirm={resetState}>
          {successAlert.message}
        </SweetAlert>
      )}
      <AddSectionModal isOpen={modalOpen} toggle={toggleModal} fetchSections={fetchSections} />
      <EditSectionModal isOpen={editModalOpen} toggle={toggleEditModal} section={selectedSection} fetchSections={fetchSections} />
      <DeleteSectionModal isOpen={deleteModalOpen} toggle={toggleDeleteModal} deleteSection={handleDeleteSection} section={deleteOrRestore} />
      <ViewSectionModal isOpen={viewModalOpen} toggle={toggleViewModal} section={selectedSection} />
      <FilterSectionModal
        isOpen={filterModalOpen}
        closeFilterModal={toggleFilterModal}
        filterError={filterError}
        setFilterError={setFilterError}
        sectionName={filterSectionName}
        setSectionName={setFilterSectionName}
        updateFilterCount={updateFilterCount}
        createdAtFilter={createdAtFilter}
        setCreatedAtFilter={setCreatedAtFilter}
        isDeleted={isDeleted}
        setIsDeleted={setIsDeleted}
        // filters={filters}
        // setFilters={setFilters}
        // applyFilters={applyFilters}
        // fetchSectionsReset={fetchSectionsReset}
        createQueryOptions={createQueryOptions}
        fetchSections={fetchSections}
        authUser={authUser}
      />


      <Container fluid>
        <Breadcrumbs title="View Attributes" breadcrumbItems={breadcrumbItems} />
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                <Row className="mt-2">
                  <Col md="2">
                    <FormGroup>
                      <Col md="12">
                        <Label className="col-form-label">Show Entries</Label>
                        <select
                          className="form-control"
                          onChange={(e) => {
                            fetchSections(1, e.target.value, createQueryOptions(), sortOrder)
                            setPagination({ ...pagination, pageLimit: e.target.value })
                          }}
                          value={pagination.pageLimit}
                          disabled={sectionLoading}
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label className="col-form-label">Sort Attributes</Label>
                      <select
                        className="form-control"
                        onChange={(e) => fetchSortedList(e.target.value)}
                        value={sortOrder}
                        disabled={sectionLoading}
                      >
                        <option value="name$asc">Attribute Name (A-Z)</option>
                        <option value="name$desc">Attribute Name (Z-A)</option>
                        <option value="updated_at$desc">Update Time (Newest First)</option>
                        <option value="updated_at$asc">Update Time (Oldest First)</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col className="d-flex justify-content-end align-items-center">
                    <Button size="sm" color="primary" className="waves-effect waves-light mr-3" onClick={toggleFilterModal}>
                      <i className="ri-filter-fill align-middle mr-2"></i>
                      <span>Filter Attribute</span>
                      {currentFilterCount > 0 && (
                        <Badge color="light" pill className="ml-2">
                          {currentFilterCount}
                        </Badge>
                      )}
                    </Button>
                    {validatePermissionKey(authUser, CREATE_ATTRIBUTE) && (
                      <Button size="sm" color="success" className="waves-effect waves-light" onClick={toggleModal}>
                        <i className="ri-add-line align-middle mr-2"></i>
                        Add Attribute
                      </Button>
                    )}
                  </Col>
                </Row>
                {sectionLoading ? (
                  <div className="text-center">
                    <PageDataLoader />
                  </div>
                ) : (
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table id="tech-companies-1" striped bordered responsive>
                        <thead>
                          <tr>
                            <th>SL. No</th>
                            <th>Name</th>
                            <th>Created At</th>
                            <th>Last Updated At</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {sections.map((section, index) => (
                            <tr key={section._id}>
                              <th>{index + 1}</th>
                              <td>{section.name}</td>
                              <td>{moment(new Date(section.createdAt)).format('DD-MM-YYYY')}</td>
                              <td>{moment(new Date(section.updatedAt)).format('DD-MM-YYYY')}</td>
                              <td>
                                <Button size="sm" color="primary" className="waves-effect waves-light mr-2 mb-2" onClick={() => { setSelectedSection(section); toggleViewModal(); }}>
                                  <i className="fas fa-eye mr-2"></i>
                                  View
                                </Button>
                                {validatePermissionKey(authUser, UPDATE_ATTRIBUTE) && (
                                  <Button size="sm" color="warning" className="waves-effect waves-light mr-2 mb-2" onClick={() => { setSelectedSection(section); toggleEditModal(); }}>
                                    <i className="fas fa-edit mr-2"></i>
                                    Edit
                                  </Button>
                                )}
                                {validatePermissionKey(authUser, DELETE_ATTRIBUTE) && (
                                  <Button size="sm" color="danger" className="waves-effect waves-light mb-2" onClick={() => { setSelectedSection(section); toggleDeleteModal(); setDeleteOrRestore(section) }}>
                                    {!section.isDeleted ? <i className="fas fa-trash mr-2"></i> : <i className="fas fa-trash-restore mr-2"></i>}
                                    {!section.isDeleted ? 'Delete' : 'Restore'}
                                  </Button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>)}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md="5">
            <p>
              <strong>
                {' '}
                Showing{' '}
                {(pagination.currentPage - 1) * pagination.pageLimit + 1}{' '}
                to{' '}
                {Math.min(
                  pagination.currentPage * pagination.pageLimit,
                  pagination.totalEntries
                )}{' '}
                of {pagination.totalEntries} entries
              </strong>
            </p>
          </Col>
          {!sectionLoading && sections.length > 0 && (
            <Col md="7">
              <Paginator
                totalPages={pagination.totalPages}
                currentPage={pagination.currentPage}
                changePage={handlePagination}
              />
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(SectionManager);
