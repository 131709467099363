/*jshint esversion: 11 */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Alert
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { updateParent } from '../../api/parent';
import PageDataLoader from '../Utility/PageDataLoader';
import { genderEnum, locationEnum } from '../Utility/Enumerator';
import { validatePhoneNumber } from '../../helpers/input-validator';
import FormUpload from '../../components/FormUpload';
import moment from 'moment';

const UpdateParentModal = ({
  modalOpenState,
  closeModalHandler,
  authUser,
  fetchNewParentList,
  parentInfoLoading,
  parentInfo
}) => {
  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });
  const [parentUpdateRequestLoading, setParentUpdateRequestLoading] =
    useState(false);
  const [successAlert, setSuccessAlert] = useState({
    message: '',
    show_alert: false
  });
  const [image, setImage] = useState('');
  const locationOptions = locationEnum;
  const [selectedLocation, setSelectedLocation] = useState(null);

  const genderOptions = genderEnum;
  const [selectedGender, setSelectedGender] = useState(null);

  async function handleParentUpdate(event, values) {
    resetErrorAlert();
    if (!selectedGender) {
      showErrorAlert('Please select gender');
      return;
    }
    if (!selectedLocation) {
      showErrorAlert('Please select address location');
      return;
    }
    let form = new FormData();
    form.set('name', values.name);
    form.set('phone_number', values.phone_number);
    form.set('dob', values.dob);
    form.set('gender', selectedGender.value);
    form.set('address', values.address);
    form.set('address_location', selectedLocation.value);
    if (image) {
      form.set('profile_picture', image);
    }

    setParentUpdateRequestLoading(true);
    const { response, error } = await updateParent(parentInfo._id, form);
    setParentUpdateRequestLoading(false);
    if (error) {
      showErrorAlert(error);
      return;
    }
    closeModalHandler();
    showSuccessAlert(response.message);
    fetchNewParentList();
  }

  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }

  function showSuccessAlert(message) {
    setSuccessAlert({ message: message, show_alert: true });
  }

  function resetSuccessAlert() {
    setSuccessAlert({ message: '', show_alert: false });
  }

  function resetState() {
    resetSuccessAlert();
    resetErrorAlert();
  }

  function handleGenderSelection(selectedItem) {
    setSelectedGender(selectedItem);
  }

  function handleLocationSelection(selectedItem) {
    setSelectedLocation(selectedItem);
  }

  useEffect(() => {
    if (parentInfo) {
      setSelectedGender(
        genderOptions.find((k) => k.value === parentInfo?.gender)
      );

      setSelectedLocation(
        locationOptions.find((k) => k.value === parentInfo?.address_location)
      );
    }
    // eslint-disable-next-line
  }, [parentInfo]);

  return (
    <React.Fragment>
      {successAlert.show_alert && (
        <SweetAlert success title="Updated" onConfirm={resetState}>
          {successAlert.message}
        </SweetAlert>
      )}
      <Modal isOpen={modalOpenState} backdrop="static" size="xl">
        <ModalHeader toggle={closeModalHandler}>
          {parentInfoLoading ? 'Loading ...' : 'Edit [' + parentInfo.name + ']'}
        </ModalHeader>
        <ModalBody>
          {parentInfoLoading ? (
            <div className="d-flex justify-content-center">
              <PageDataLoader />
            </div>
          ) : (
            <React.Fragment>
              {requestError ? (
                <Alert
                  color="danger"
                  role="alert"
                  isOpen={requestError.show_alert}
                  toggle={resetErrorAlert}
                >
                  <i className="mdi mdi-block-helper mr-2"></i>
                  {requestError.message}
                </Alert>
              ) : null}
              <AvForm
                className="needs-validation"
                onValidSubmit={handleParentUpdate}
              >
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>Parent Name</Label>
                      <AvField
                        name="name"
                        placeholder="Enter Parent Name ..."
                        type="text"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Parent name is required'
                          }
                        }}
                        defaultValue={parentInfo?.name}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Phone Number</Label>
                      <AvField
                        name="phone_number"
                        placeholder="Enter Phone Number ..."
                        type="text"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Phone Number is required'
                          },
                          minLength: {
                            value: 10,
                            errorMessage: 'Phone Number must be 10 digits'
                          },
                          maxLength: {
                            value: 10,
                            errorMessage: 'Phone Number must be 10 digits'
                          },
                          async: validatePhoneNumber
                        }}
                        defaultValue={parentInfo?.phone_number}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>DOB</Label>
                      <AvField
                        name="dob"
                        placeholder="Enter DOB ..."
                        type="date"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'DOB is required'
                          }
                        }}
                        defaultValue={
                          parentInfo &&
                          moment(new Date(parentInfo.dob)).format('YYYY-MM-DD')
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Gender</Label>
                      <Select
                        options={genderOptions}
                        onChange={handleGenderSelection}
                        value={selectedGender}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <Label>Address</Label>
                      <AvField
                        name="address"
                        placeholder="Enter Address ..."
                        type="textarea"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Address is required'
                          }
                        }}
                        defaultValue={parentInfo?.address}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <Label>Address Location</Label>
                      <Select
                        options={locationOptions}
                        onChange={handleLocationSelection}
                        value={selectedLocation}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormUpload title="Profile Picture" setImage={setImage} />
                  </Col>
                </Row>
                <ModalFooter>
                  <Button
                    disabled={parentUpdateRequestLoading}
                    type="button"
                    color="light"
                    onClick={closeModalHandler}
                  >
                    Close
                  </Button>
                  <Button type="submit" color="success">
                    {parentUpdateRequestLoading
                      ? 'Updating ...'
                      : 'Update Parent'}
                  </Button>
                </ModalFooter>
              </AvForm>
            </React.Fragment>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(UpdateParentModal);
