import React, { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import { removeActivities } from '../../api/batch';

const DeleteBatchActivitiesModal = ({
  modalOpenState,
  closeModalHandler,
  batchId,
  fetchNewData
}) => {
  const [successAlert, setSuccessAlert] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });

  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }
  const deleteActivities = async () => {
    resetErrorAlert();
    setRequestLoading(true);

    const { response, error } = await removeActivities(batchId);
    if (response) {
      setRequestLoading(false);
      setSuccessAlert(`Batch activities deleted successfully`);
      closeModalHandler();
      fetchNewData();
    }
    if (error) {
      setRequestLoading(false);
      showErrorAlert(error);
      return;
    }
  };

  useEffect(() => {
    resetErrorAlert();
  }, [modalOpenState]);
  return (
    <React.Fragment>
      {successAlert && (
        <SweetAlert
          success
          title="Deleted"
          onConfirm={() => setSuccessAlert(false)}
        >
          {successAlert}
        </SweetAlert>
      )}

      <Modal isOpen={modalOpenState} backdrop="static" size="md">
        <ModalHeader toggle={closeModalHandler}></ModalHeader>
        <ModalBody>
          {requestError ? (
            <Alert
              color="danger"
              role="alert"
              isOpen={requestError.show_alert}
              toggle={resetErrorAlert}
            >
              <i className="mdi mdi-block-helper mr-2"></i>
              {requestError.message}
            </Alert>
          ) : null}
          <h4>{`Are you sure you want to delete all activities associated to this batch?`}</h4>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={requestLoading}
            type="button"
            color="light"
            onClick={closeModalHandler}
          >
            Close
          </Button>
          <Button
            type="submit"
            color="danger"
            onClick={() => deleteActivities()}
          >
            {requestLoading ? 'Deleting...' : 'Delete'}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default DeleteBatchActivitiesModal;
