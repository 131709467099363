/*jshint esversion: 11 */
import sendApiRequest from '../helpers/axios-setup';

/**
 *
 * @returns {Promise}
 */
export async function getActivityList(
  page,
  limit,
  {
    filter_created_at_start = '',
    filter_created_at_end = '',
    filter_updated_at_start = '',
    filter_updated_at_end = '',
    filter_name = '',
    filter_duration_min = '',
    filter_duration_max = '',
    filter_tag_id = '',
    include_deleted = ''
  } = {},
  sorting_option = '',
  group_by_batch = '',
  group_by_date = ''
) {
  try {
    const response = await sendApiRequest.get('/activity', {
      params: {
        page: page,
        limit: limit,
        filter_created_at_start,
        filter_created_at_end,
        filter_updated_at_start,
        filter_updated_at_end,
        filter_name,
        filter_duration_min,
        filter_duration_max,
        filter_tag_id,
        include_deleted,
        sorting_option,
        group_by_batch,
        group_by_date
      }
    });
    return response.data?.data[0];
  } catch (e) {
    return null;
  }
}

/**
 *
 * @param {string} name
 * @param {string} summary
 * @param {string} detail
 * @param {number} duration
 * @param {string} tags
 * @returns {Promise<Object>}
 */
export async function addNewActivity(data) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.post('/activity', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @param {String} activityId
 * @returns
 */
export async function getActivityInfo(activityId) {
  try {
    const response = await sendApiRequest.get(`/activity/${activityId}`);
    return response.data?.data;
  } catch (e) {
    return null;
  }
}

/**
 *
 * @param {string} name
 * @param {string} summary
 * @param {string} detail
 * @param {number} duration
 * @returns {Promise<Object>}
 */
export async function updateActivity(activityId, data) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.put(`/activity/${activityId}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @param {String} activityId
 * @returns
 */
export async function deleteRestoreActivity(activityId, type) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.delete(`/activity/${activityId}`, {
      params: {
        type: type
      }
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}
