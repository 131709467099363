import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, ListGroup, ListGroupItem, Col, Row, Alert } from 'reactstrap';
import Select from 'react-select';
import { getQuestionList } from '../../api/question';
import { getBadgeList } from '../../api/badge';
import SweetAlert from 'react-bootstrap-sweetalert';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { createSection } from '../../api/section';

const AddSectionModal = ({ isOpen, toggle, fetchSections }) => {
  const [name, setName] = useState('');
  const [selectedBadge, setSelectedBadge] = useState('');
  const [badges, setBadges] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [question, setQuestion] = useState([])
  const [successAlert, setSuccessAlert] = useState({
    message: '',
    show_alert: false
  });
  const [sectionLoading, setSectionLoading] = useState(false)

  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });

  const styles = {
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
        : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: 'none' } : base;
    }
  };

  function buildOptions(list) {
    return list.map((item) => ({
      value: item._id,
      label: item.title || item.name
    }));
  }

  useEffect(() => {
    async function fetchData() {
      const badgeList = await getBadgeList(1, 100);
      const questionList = await getQuestionList(1, 100);
      setBadges(buildOptions(badgeList.data || []));
      setQuestions(buildOptions(questionList?.data || []));

    }
    fetchData();
  }, []);



  async function handleSubmit(e, values) {
    resetErrorAlert();
    if (question.length === 0) {
      showErrorAlert("Please select atleast one question")
      return
    }
    if (selectedBadge === "" || selectedBadge === undefined) {
      showErrorAlert("Please select Badge")
      return
    }
    setSectionLoading(true)
    let sectionData = {
      name: values.name,
      badge: selectedBadge.value,
      questions: question.map((q) => q.value)
    }
    const { response, error } = await createSection(sectionData)
    if (error) {
      showErrorAlert(error.message || "Error in creating section")
      return
    }
    setSelectedBadge("")
    setQuestion([])
    fetchSections()
    toggle()
    showSuccessAlert(response.message)
    setSectionLoading(false)

  }


  const handleQuestionToggle = (selectedItem) => {
    setQuestion(selectedItem);
    // if (selectedItem.length > 0) {
    //   setErrors(prevErrors => ({ ...prevErrors, questions: undefined }));
    // }
  };

  function handleBadgeSelection(selectedItem) {
    setSelectedBadge(selectedItem);
  }


  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }

  function showSuccessAlert(message) {
    setSuccessAlert({ message: message, show_alert: true });
  }

  function resetSuccessAlert() {
    setSuccessAlert({ message: '', show_alert: false });
  }

  function resetState() {
    resetSuccessAlert();
    resetErrorAlert();
  }


  return (
    <React.Fragment>
      {successAlert.show_alert && (
        <SweetAlert success title="Created" onConfirm={resetState}>
          {successAlert.message}
        </SweetAlert>
      )}
      <Modal isOpen={isOpen} backdrop="static" size='md'>
        <ModalHeader toggle={toggle}>Add Attribute</ModalHeader>
        <ModalBody>
          {requestError.show_alert && (
            <Alert
              color="danger"
              role="alert"
              isOpen={requestError.show_alert}
              toggle={resetErrorAlert}
            >
              <i className="mdi mdi-block-helper mr-2"></i>
              {requestError.message}
            </Alert>
          )}
          <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
            <ModalBody>
              <Row>
                <Col md="12">

                  <FormGroup>
                    <Label for="name">Name</Label>

                    <AvField
                      name="name"
                      placeholder="Enter attribute name ..."
                      type="text"
                      className="form-control"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Name is required'
                        }
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label>Badge</Label>
                    <Select
                      options={badges}
                      styles={styles}
                      onChange={handleBadgeSelection}
                      value={selectedBadge}
                    />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label for="questions">Select Questions</Label>
                    <Select
                      styles={styles}
                      options={questions}
                      isClearable={false}
                      isMulti
                      value={question}
                      onChange={handleQuestionToggle}
                    />
                    {/* </FormGroup> */}
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                disabled={sectionLoading}
                type="button"
                color="light"
                onClick={toggle}
              >
                Close
              </Button>
              <Button type="submit" color="success">
                {sectionLoading ? 'Adding ...' : 'Add Attribute'}
              </Button>
            </ModalFooter>
          </AvForm>
        </ModalBody>

      </Modal>
    </React.Fragment>
  );
};

export default AddSectionModal;
