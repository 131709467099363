import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Col,
  Alert,
  Row,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import SweetAlert from 'react-bootstrap-sweetalert';
import { viewSections } from '../../api/section';
import Select from 'react-select';
import { getBadgeList } from '../../api/badge';
import { createForm, updateForm } from '../../api/form';
import { getBatchList } from '../../api/batch';

const AddFormModal = ({ modalOpenState, closeModalHandler, formInfoLoading, fetchNewFormList }) => {
  const [title, setTitle] = useState('');
  const [formRequestLoading, setFormRequestLoading] = useState(false);
  const [batchList, setBatchList] = useState([])
  const [sectionList, setSectionList] = useState([])
  const [selectedSection, setSelectedSection] = useState([])
  const [selectedBatch, setSelectedBatch] = useState([])

  const styles = {
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
        : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: 'none' } : base;
    }
  };



  const [successAlert, setSuccessAlert] = useState({
    message: '',
    show_alert: false
  });
  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });

  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }

  function showSuccessAlert(message) {
    setSuccessAlert({ message: message, show_alert: true });
  }

  function resetSuccessAlert() {
    setSuccessAlert({ message: '', show_alert: false });
  }

  function resetState() {
    resetSuccessAlert();
    resetErrorAlert();
  }

  function buildOptions(list) {
    return list.map((item) => ({
      value: item._id,
      label: item.name
    }));
  }

  useEffect(() => {
    async function fetchData() {
      const section = await viewSections("", false, "", "", 1, "", 100, "", "");
      const batch = await getBatchList(1, 100)
      // const questionList = await getQuestionList(1, 100);
      // console.log("aayush:: ",section?.data,"pandey:: ",batch?.data)
      setSectionList(buildOptions(section?.data));
      setBatchList(buildOptions(batch?.data));
    }
    fetchData();
  }, []);

 


  const handleSubmit = async () => {
    resetErrorAlert()
    if (selectedSection.length === 0) {
      showErrorAlert("Please select atleast one section")
    }
    let sections = selectedSection.map((p) => p.value)
    let batches = selectedBatch.map((p) => p.value)


    setFormRequestLoading(true);
    const { response, error } = await createForm( title, sections, batches);
    if (error) {
      showErrorAlert(error.message || 'Error in updating Form');
      return;
    }
    setSelectedBatch([])
    setSelectedSection([])
    setTitle('')
    fetchNewFormList()
    closeModalHandler()
    showSuccessAlert(response.message)
    setFormRequestLoading(false)

  };

  const handleSectionToggle = (selectedData) => {
    setSelectedSection(selectedData)

  };

  const handleBatchToggle = (selectedData) => {
    setSelectedBatch(selectedData)

  };



  return (
    <React.Fragment>
    {successAlert.show_alert && (
      <SweetAlert success title="Updated" onConfirm={resetState}>
        {successAlert.message}
      </SweetAlert>
    )}
    <Modal isOpen={modalOpenState} backdrop="static" size='xl'>
      <ModalHeader toggle={closeModalHandler}>Create Form</ModalHeader>
      <ModalBody>
        <React.Fragment>
          {requestError ? (
            <Alert
              color="danger"
              role="alert"
              isOpen={requestError.show_alert}
              toggle={resetErrorAlert}
            >
              <i className="mdi mdi-block-helper mr-2"></i>
              {requestError.message}
            </Alert>
          ) : null}
          <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
            <ModalBody>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label for="title">Name</Label>
                    <AvField
                      name="title"
                      id="title"
                      type="text"
                      value={title}
                      className="form-control"

                      onChange={(e) => setTitle(e.target.value)}
                      validate={{
                        required: { value: true, errorMessage: 'Please enter a title' },
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label for="sections">Select Sections</Label>
                    <Select
                      styles={styles}
                      options={sectionList}
                      isClearable={false}
                      isMulti
                      value={selectedSection}
                      onChange={handleSectionToggle}
                    />
                    {/* </FormGroup> */}
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label for="sections">Select Batches</Label>
                    <Select
                      styles={styles}
                      options={batchList}
                      isClearable={false}
                      isMulti
                      value={selectedBatch}
                      onChange={handleBatchToggle}
                    />
                    {/* </FormGroup> */}
                  </FormGroup>
                </Col>
              </Row>


            </ModalBody>

            <ModalFooter>
              <Button
                disabled={formRequestLoading}
                type="button"
                color="light"
                onClick={closeModalHandler}
              >
                Close
              </Button>
              <Button type="submit" color="success">
                {formRequestLoading ? 'Adding ...' : 'Add Form'}
              </Button>
            </ModalFooter>

          </AvForm>
        </React.Fragment>
      </ModalBody>

    </Modal>
  </React.Fragment>
  );
};

export default AddFormModal;
