/*jshint esversion: 11 */
import sendApiRequest from '../helpers/axios-setup';

/**
 *
 * @param {string} title
 * @param {array} option_list
 * @returns {Promise<Object>}
 */
export async function addNewQuestion(questionData) {
  const responseMap = {
    response: null,
    error: null
  };
  const { title, option_list } = questionData;

  try {
    const response = await sendApiRequest.post('/questions', {
      title,
      option_list,
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @param {String} questionId
 * @returns
 */
export async function getQuestionInfo(questionId) {
  try {
    const response = await sendApiRequest.get(`/questions/${questionId}`);
    return response.data;
  } catch (e) {
    return null;
  }
}

/**
 *
 * @param {string} questionId
 * @param {string} title
 * @param {array} option_list [{option_name, is_correct}]
 * @returns {Promise<Object>}
 */
export async function updateQuestion(questionId, title, option_list) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.put(`/questions/${questionId}`, {
      title,
      option_list,
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}


/**
 *
 * @param {number} page
 * @param {number} limit
 * @param {string} [filter_title]
 * @param {string} [filter_start_date]
 * @param {string} [filter_end_date]
 * @param {string} [include_deleted]
 * @returns {Promise<Object>}
 */
export async function getQuestionList(
  page,
  limit,
  {
    filter_name = '',
    filter_start_date = '',
    filter_end_date = '',
    include_deleted = ''
  } = {},
  sorting_option = ''
) {
  try {
    const response = await sendApiRequest.get('/questions', {
      params: {
        page: page,
        limit: limit,
        sorting_option: sorting_option,
        filter_name,
        filter_start_date,
        filter_end_date,
        include_deleted
      }
    });
    return response.data?.data[0];
  } catch (e) {
    return null;
  }
}

/**
 *
 * @param {string} type
 * @returns {Promise}
 */
export async function getReferenceList(type) {
  try {
    const response = await sendApiRequest.get('/references', {
      params: {
        type
      }
    });
    return response.data;
  } catch (e) {
    return null;
  }
}

/**
 *
 * @param {string} questionId
 * @returns {Promise<Object>}
 */
export async function deleteRestoreQuestion(questionId) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.delete(`/questions/${questionId}`);
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}
