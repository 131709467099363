/*jshint esversion: 11 */
import React from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Alert,
  Input
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const ParentFilterModal = ({
  filterError,
  setFilterError,
  modalOpenState,
  closeModalHandler,
  fetchList,
  updateFilterCount,
  parentName,
  setParentName,
  parentDob,
  setParentDob,
  parentGender,
  setParentGender,
  parentLocation,
  setParentLocation,
  createQueryOptions,
  setParentPhone,
  parentPhone,
  authUser,
  isDeleted,
  setIsDeleted
}) => {
  function dateRangeHandler(setHook) {
    return function (dates) {
      const [start, end] = dates;
      setHook({
        startDate: start,
        endDate: end
      });
    };
  }

  function applyFilter() {
    const queryOptions = createQueryOptions();
    if (queryOptions) {
      updateFilterCount(queryOptions);
      fetchList(1, null, queryOptions);
      closeModalHandler();
    }
  }

  function clearFilter() {
    setParentDob({
      startDate: null,
      endDate: null
    });
    setParentGender(null);
    setParentLocation(null);
    setParentName('');
    setParentPhone('');
    setIsDeleted(false);
    const queryOptions = createQueryOptions(
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    if (queryOptions) {
      updateFilterCount(queryOptions);
      fetchList(1, null, queryOptions);
      closeModalHandler();
    }
  }

  return (
    <React.Fragment>
      <Modal isOpen={modalOpenState} backdrop="static" size="md">
        <ModalHeader toggle={closeModalHandler}>Filter Parent</ModalHeader>
        <ModalBody>
          {filterError ? (
            <Alert
              color="danger"
              role="alert"
              isOpen={filterError ? true : false}
              toggle={() => setFilterError(false)}
            >
              <i className="mdi mdi-block-helper mr-2"></i>
              {filterError}
            </Alert>
          ) : null}
          <AvForm className="needs-validation" onValidSubmit={applyFilter}>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label>Parent Name</Label>
                  <AvField
                    name="parent_name"
                    placeholder="Enter Parent Name ..."
                    type="text"
                    className="form-control"
                    onChange={(e) => setParentName(e.target?.value)}
                    value={parentName}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label>Phone Number</Label>
                  <AvField
                    name="phone_number"
                    placeholder="Enter Phone Number ..."
                    type="text"
                    className="form-control"
                    onChange={(e) => setParentPhone(e.target?.value)}
                    value={parentPhone}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label>DOB Range</Label>
                  <DatePicker
                    selected={parentDob.startDate}
                    selectsStart
                    className="form-control"
                    startDate={parentDob.startDate}
                    endDate={parentDob.endDate}
                    selectsRange={true}
                    dateFormat="dd-MM-yyyy"
                    onChange={dateRangeHandler(setParentDob)}
                    isClearable={true}
                    showMonthYearPicker={true}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Gender</Label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                      setParentGender(e.target?.value);
                    }}
                    value={parentGender}
                  >
                    <option value="">None</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Location</Label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                      setParentLocation(e.target?.value);
                    }}
                    value={parentLocation}
                  >
                    <option value="">All</option>
                    <option value="Kandivali">Kandivali</option>
                    <option value="Malad">Malad</option>
                    <option value="Borivali">Borivali</option>
                    <option value="Other">Other</option>
                  </select>
                </FormGroup>
              </Col>
              {authUser && authUser.user_type === 'super_admin' && (
                <Col md="12">
                  <FormGroup>
                    <Label>Is Deleted</Label>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr"
                    >
                      <Input
                        id="customSwitch"
                        name="isComplted"
                        type="checkbox"
                        className="custom-control-input p-3"
                        defaultChecked={isDeleted}
                      />
                      <Label
                        htmlFor="customSwitch"
                        className="custom-control-label"
                        onClick={() => setIsDeleted(!isDeleted)}
                      >
                        Deleted Parents
                      </Label>
                    </div>
                  </FormGroup>
                </Col>
              )}
            </Row>
            <Row className="d-flex px-3" style={{ height: '100%' }}></Row>
            <ModalFooter>
              <Button size="sm" type="submit" color="primary">
                Apply Filter
              </Button>
              <Button
                size="sm"
                type="button"
                color="success"
                onClick={() => clearFilter()}
              >
                Reset
              </Button>
              <Button
                size="sm"
                type="button"
                color="light"
                onClick={closeModalHandler}
              >
                Close
              </Button>
            </ModalFooter>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(ParentFilterModal);
