/*jshint esversion: 11 */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Alert,
  Input
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { updateToy } from '../../api/toy';
import PageDataLoader from '../Utility/PageDataLoader';
import { getTagList } from '../../api/tag';
import FormUpload from '../../components/FormUpload';

const UpdateToyModal = ({
  modalOpenState,
  closeModalHandler,
  authUser,
  fetchNewToyList,
  toyInfoLoading,
  toyInfo
}) => {
  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });
  const [toyUpdateRequestLoading, setToyUpdateRequestLoading] = useState(false);
  const [successAlert, setSuccessAlert] = useState({
    message: '',
    show_alert: false
  });

  const [tagOptions, setTagOptions] = useState([]);
  const [tagOptionsLoading, setTagOptionsLoading] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [images, setImages] = useState([]);
  const [isAvailable, setIsAvailable] = useState(false);

  async function handleToyUpdate(event, values) {
    resetErrorAlert();
    if (!selectedTag || selectedTag.length === 0) {
      showErrorAlert('Select atleast one tag');
      return;
    }
    let selectedTagArray = selectedTag && selectedTag.map((c) => c.value);
    let form = new FormData();
    form.set('name', values.toy_name);
    form.set('overview', values.overview);
    form.set('summary', values.summary);
    form.set('age', values.age);
    form.set('isAvailable', isAvailable);
    selectedTagArray.map((g, index) => form.set(`tags[${index}]`, g));
    if (images && images.length > 0) {
      images.map((g, index) => form.append(`pictures`, g));
    }
    setToyUpdateRequestLoading(true);
    const { response, error } = await updateToy(toyInfo._id, form);
    setToyUpdateRequestLoading(false);
    if (error) {
      showErrorAlert(error);
      return;
    }
    setImages([]);
    closeModalHandler();
    showSuccessAlert(response.message);
    fetchNewToyList();
  }

  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }

  function showSuccessAlert(message) {
    setSuccessAlert({ message: message, show_alert: true });
  }

  function resetSuccessAlert() {
    setSuccessAlert({ message: '', show_alert: false });
  }

  function resetState() {
    resetSuccessAlert();
    resetErrorAlert();
  }

  useEffect(() => {
    if (authUser && toyInfo) {
      fetchTagOptions();
      if (toyInfo.tags && toyInfo.tags.length > 0) {
        console.log(toyInfo.tags)
        setSelectedTag(buildOptions(toyInfo.tags));
      }
      setIsAvailable(toyInfo.isAvailable);
    }
    // eslint-disable-next-line
  }, [authUser, toyInfo, modalOpenState]);

  const fetchTagOptions = async () => {
    setTagOptionsLoading(true);
    const response = await getTagList(1, 100);
    if (response) {
      setTagOptions(buildOptions(response?.data || []));
      setTagOptionsLoading(false);
    }
  };

  function buildOptions(list) {
    return list.map((item) => ({
      value: item._id,
      label: item.name
    }));
  }

  function handleTagSelection(selectedItem) {
    setSelectedTag(selectedItem);
  }

  return (
    <React.Fragment>
      {successAlert.show_alert && (
        <SweetAlert success title="Updated" onConfirm={resetState}>
          {successAlert.message}
        </SweetAlert>
      )}
      <Modal isOpen={modalOpenState} backdrop="static" size="xl">
        <ModalHeader toggle={closeModalHandler}>
          {toyInfoLoading ? 'Loading ...' : 'Edit [' + toyInfo.name + ']'}
        </ModalHeader>
        <ModalBody>
          {toyInfoLoading ? (
            <div className="d-flex justify-content-center">
              <PageDataLoader />
            </div>
          ) : (
            <React.Fragment>
              {requestError ? (
                <Alert
                  color="danger"
                  role="alert"
                  isOpen={requestError.show_alert}
                  toggle={resetErrorAlert}
                >
                  <i className="mdi mdi-block-helper mr-2"></i>
                  {requestError.message}
                </Alert>
              ) : null}
              <AvForm
                className="needs-validation"
                onValidSubmit={handleToyUpdate}
              >
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>Toy Name</Label>
                      <AvField
                        name="toy_name"
                        placeholder="Enter Toy Name ..."
                        type="text"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Toy name is required'
                          }
                        }}
                        defaultValue={toyInfo?.name}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Age</Label>
                      <AvField
                        name="age"
                        placeholder="Enter Age ..."
                        type="number"
                        min="1"
                        max="15"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Age is required'
                          }
                        }}
                        defaultValue={toyInfo?.age}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <Label>Summary</Label>
                      <AvField
                        name="summary"
                        placeholder="Enter Summary ..."
                        type="text"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Summary is required'
                          }
                        }}
                        defaultValue={toyInfo?.summary}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <Label>Overview</Label>
                      <AvField
                        name="overview"
                        placeholder="Enter Overview ..."
                        type="textarea"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Overview is required'
                          }
                        }}
                        defaultValue={toyInfo?.overview}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="8">
                    <FormGroup>
                      <Label>Tags</Label>
                      <Select
                        options={tagOptions}
                        disabled={tagOptionsLoading}
                        isMulti
                        defaultValue={selectedTag}
                        onChange={handleTagSelection}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label>Available</Label>
                      <div
                        className="custom-control custom-switch mb-2"
                        dir="ltr"
                      >
                        <Input
                          id="customSwitch"
                          name="isAvailable"
                          type="checkbox"
                          className="custom-control-input p-3"
                          defaultChecked={toyInfo?.isAvailable}
                        />
                        <Label
                          htmlFor="customSwitch"
                          className="custom-control-label"
                          onClick={() => setIsAvailable(!isAvailable)}
                        >
                          Availability Status
                        </Label>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormUpload title="Toy Images" setImages={setImages} />
                  </Col>
                </Row>
                <ModalFooter>
                  <Button
                    disabled={toyUpdateRequestLoading}
                    type="button"
                    color="light"
                    onClick={closeModalHandler}
                  >
                    Close
                  </Button>
                  <Button type="submit" color="success">
                    {toyUpdateRequestLoading ? 'Updating ...' : 'Update Toy'}
                  </Button>
                </ModalFooter>
              </AvForm>
            </React.Fragment>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(UpdateToyModal);
