/*jshint esversion: 11 */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Alert
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { addNewBook } from '../../api/book';
import { getTagList } from '../../api/tag';
import FormUpload from '../../components/FormUpload';

const CreateBookModal = ({
  modalOpenState,
  closeModalHandler,
  fetchNewBookList,
  authUser
}) => {
  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });
  const [bookCreateRequestLoading, setBookCreateRequestLoading] =
    useState(false);
  const [successAlert, setSuccessAlert] = useState({
    message: '',
    show_alert: false
  });
  const [images, setImages] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [tagOptionsLoading, setTagOptionsLoading] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);

  async function handleBookCreation(event, values) {
    resetErrorAlert();
    if (!selectedTag || selectedTag.length === 0) {
      showErrorAlert('Select atleast one tag');
      return;
    }
    let selectedTagArray = selectedTag && selectedTag.map((c) => c.value);
    let form = new FormData();
    form.set('name', values.book_name);
    form.set('overview', values.overview);
    form.set('summary', values.summary);
    form.set('age', values.age);
    selectedTagArray.map((g, index) => form.set(`tags[${index}]`, g));
    if (images && images.length > 0) {
      images.map((g, index) => form.append(`pictures`, g));
    }
    setBookCreateRequestLoading(true);
    const { response, error } = await addNewBook(form);
    setBookCreateRequestLoading(false);
    if (error) {
      showErrorAlert(error);
      return;
    }
    setImages([]);
    setSelectedTag(null);
    closeModalHandler();
    showSuccessAlert(response.message);
    fetchNewBookList();
  }

  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }

  function showSuccessAlert(message) {
    setSuccessAlert({ message: message, show_alert: true });
  }

  function resetSuccessAlert() {
    setSuccessAlert({ message: '', show_alert: false });
  }

  function resetState() {
    resetSuccessAlert();
    resetErrorAlert();
  }

  useEffect(() => {
    if (authUser) {
      fetchTagOptions();
    }
    // eslint-disable-next-line
  }, [authUser, modalOpenState]);

  const fetchTagOptions = async () => {
    setTagOptionsLoading(true);
    const response = await getTagList(1, 100);
    if (response) {
      setTagOptions(buildOptions(response?.data || []));
      setTagOptionsLoading(false);
    }
  };

  function buildOptions(list) {
    return list.map((item) => ({
      value: item._id,
      label: item.name
    }));
  }

  function handleTagSelection(selectedItem) {
    setSelectedTag(selectedItem);
  }

  return (
    <React.Fragment>
      {successAlert.show_alert && (
        <SweetAlert success title="Created" onConfirm={resetState}>
          {successAlert.message}
        </SweetAlert>
      )}
      <Modal isOpen={modalOpenState} backdrop="static" size="xl">
        <ModalHeader toggle={closeModalHandler}>Add New Book</ModalHeader>
        <ModalBody>
          {requestError ? (
            <Alert
              color="danger"
              role="alert"
              isOpen={requestError.show_alert}
              toggle={resetErrorAlert}
            >
              <i className="mdi mdi-block-helper mr-2"></i>
              {requestError.message}
            </Alert>
          ) : null}
          <AvForm
            className="needs-validation"
            onValidSubmit={handleBookCreation}
          >
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>Book Name</Label>
                  <AvField
                    name="book_name"
                    placeholder="Enter Book Name ..."
                    type="text"
                    className="form-control"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Book name is required'
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Age</Label>
                  <AvField
                    name="age"
                    placeholder="Enter Age ..."
                    type="number"
                    min="1"
                    max="15"
                    className="form-control"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Age is required'
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label>Summary</Label>
                  <AvField
                    name="summary"
                    placeholder="Enter Summary ..."
                    type="text"
                    className="form-control"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Summary is required'
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label>Overview</Label>
                  <AvField
                    name="overview"
                    placeholder="Enter Overview ..."
                    type="textarea"
                    className="form-control"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Overview is required'
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label>Tags</Label>
                  <Select
                    options={tagOptions}
                    disabled={tagOptionsLoading}
                    isMulti
                    defaultValue={selectedTag}
                    onChange={handleTagSelection}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormUpload title="Book Images" setImages={setImages} />
              </Col>
            </Row>
            <ModalFooter>
              <Button
                disabled={bookCreateRequestLoading}
                type="button"
                color="light"
                onClick={closeModalHandler}
              >
                Close
              </Button>
              <Button type="submit" color="success">
                {bookCreateRequestLoading ? 'Creating ...' : 'Add Book'}
              </Button>
            </ModalFooter>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(CreateBookModal);
