/*jshint esversion: 11 */
import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Table,
  Label,
  FormGroup,
  Button,
  Badge
} from 'reactstrap';
import { connect } from 'react-redux';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import PageDataLoader from '../Utility/PageDataLoader';
import Paginator from '../Utility/Paginator';
import { getTagInfo, getTagList } from '../../api/tag';
import moment from 'moment';
import CreateTagModal from './CreateTagModal';
import ViewTagModal from './ViewTagModal';
import UpdateTagModal from './UpdateTagModal';
import { validatePermissionKey } from '../../helpers/permission';
import { CREATE_TAG, DELETE_TAG, UPDATE_TAG } from '../Utility/PermissionKeys';
import validator from 'validator';
import { stringify as QStringify, parse as QParse } from 'qs';
import { useHistory } from 'react-router-dom';
import TagFilterModal from './TagFilterModal';
import DeleteTagModal from './DeleteTagModal';

const TagListPage = ({ authUser }) => {
  const history = useHistory();
  const [filterError, setFilterError] = useState(false);
  const [breadcrumbItems] = useState([
    { title: 'Tags', link: '#' },
    { title: 'View List', link: '#' }
  ]);
  const [tagListLoading, setTagListLoading] = useState(true);
  const [tagList, setTagList] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageLimit: 10,
    totalEntries: 0,
    totalPages: 0
  });
  const [addTagModalOpen, setAddTagModalOpen] = useState(false);
  const [tagInfo, setTagInfo] = useState({});
  const [tagInfoLoading, setTagInfoLoading] = useState(false);
  const [tagInfoModalOpen, setTagInfoModalOpen] = useState(false);
  const [updateTagModalOpen, setUpdateTagModalOpen] = useState(false);
  const [sortingRule, setSortingRule] = useState('name$asc');
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [currentFilterCount, setCurrentFilterCount] = useState(0);
  const [createdAtFilter, setCreatedAtFilter] = useState({
    startDate: null,
    endDate: null
  });
  const [updatedAtFilter, setUpdatedAtFilter] = useState({
    startDate: null,
    endDate: null
  });
  const [filterTagName, setFilterTagName] = useState('');
  const [deleteTagModalOpen, setDeleteTagModalOpen] = useState(false);
  const [tagId, setTagId] = useState('');
  const [isDeleted, setIsDeleted] = useState(false);

  let listElem = document.getElementById('tagList');
  let linkElem = document.getElementById('tagLink');

  useEffect(() => {
    if (listElem) listElem.classList.add('mm-active');
    if (linkElem) linkElem.classList.add('active');
    if (authUser) {
      populateSearchOptions(history?.location?.search);
    }
    // eslint-disable-next-line
  }, [authUser, listElem, linkElem]);

  const handlePagination = (page) => {
    fetchData(page, pagination.pageLimit, createQueryOptions(), sortingRule);
  };

  const fetchData = async (
    current_page = 1,
    page_limit = 10,
    queryOptions = {},
    sortingOption = sortingRule
  ) => {
    setTagListLoading(true);
    const response = await getTagList(
      current_page,
      page_limit,
      queryOptions,
      sortingOption
    );

    if (response) {
      setPagination({
        currentPage: response.metadata.page,
        pageLimit: response.metadata.limit,
        totalPages: response.metadata.total_pages,
        totalEntries: response.metadata.total_results
      });
      setTagList(response.data);
      setTagListLoading(false);
    }
  };

  /**
   *
   * @param {string} str
   */
  function getValidDateInput(str) {
    return validator.isDate(str) ? new Date(str).toISOString() : '';
  }

  function createQueryOptions(
    createdStart = createdAtFilter.startDate,
    createdEnd = createdAtFilter.endDate,
    updatedStart = updatedAtFilter.startDate,
    updatedEnd = updatedAtFilter.endDate,
    name = filterTagName,
    deletedStatus = isDeleted
  ) {
    setFilterError(false);
    if (
      (!createdStart && createdEnd) ||
      (createdStart && !createdEnd) ||
      (!updatedStart && updatedEnd) ||
      (updatedStart && !updatedEnd)
    ) {
      setFilterError('Enter valid date range');
      return;
    }
    const startDateISOStringCreatedAt = getValidDateInput(createdStart);
    const endDateISOStringCreatedAt = getValidDateInput(createdEnd);
    const startDateISOStringUpdatedAt = getValidDateInput(updatedStart);
    const endDateISOStringUpdatedAt = getValidDateInput(updatedEnd);
    const queryOptions = {
      filter_start_created_at: startDateISOStringCreatedAt,
      filter_end_created_at: endDateISOStringCreatedAt,
      filter_start_last_updated_at: startDateISOStringUpdatedAt,
      filter_end_last_updated_at: endDateISOStringUpdatedAt,
      name,
      include_deleted: deletedStatus,
      sorting_option: sortingRule
    };
    updateUrlPath(queryOptions);
    return queryOptions;
  }

  function updateFilterCount(option) {
    let count = 0;
    Object.keys(option).forEach((i) => {
      if (option[i]?.toString()?.trim()?.length > 0 && option[i] !== false) {
        count += 1;
      }
    });
    setCurrentFilterCount(count - 1);
  }

  /**
   *
   * @param {string} searchQuery
   */
  function populateSearchOptions(searchQuery) {
    const parsedQuery = QParse(searchQuery.substring(1));
    if (
      parsedQuery.filter_start_created_at &&
      parsedQuery.filter_end_created_at
    ) {
      updateDateFilterInput(
        parsedQuery.filter_start_created_at,
        parsedQuery.filter_end_created_at,
        setCreatedAtFilter
      );
    }
    if (
      parsedQuery.filter_start_last_updated_at &&
      parsedQuery.filter_end_last_updated_at
    ) {
      updateDateFilterInput(
        parsedQuery.filter_start_last_updated_at,
        parsedQuery.filter_end_last_updated_at,
        setUpdatedAtFilter
      );
    }
    setFilterTagName(parsedQuery.name);
    const sortingOption = parsedQuery.sorting_option || sortingRule;
    setSortingRule(sortingOption);
    updateFilterCount(parsedQuery);
    fetchData(1, null, parsedQuery, sortingOption);
  }

  /**
   *
   * @param {string} str
   * @param {function} setHook
   * @returns
   */
  function updateDateFilterInput(startStr, endStr, setHook) {
    setHook({
      startDate: new Date(startStr),
      endDate: new Date(endStr)
    });
  }

  function updateUrlPath(queryOptions) {
    const queryString = QStringify(queryOptions);
    history.replace(`/tags?${queryString}`);
  }

  async function fetchSortedList(sortingOption) {
    setSortingRule(sortingOption);
    const queryOptions = createQueryOptions();
    queryOptions.sorting_option = sortingOption;
    updateUrlPath(queryOptions);
    fetchData(1, null, queryOptions, sortingOption);
  }

  function openFilterModal() {
    setFilterModalOpen(true);
  }

  function closeFilterModal() {
    setFilterModalOpen(false);
  }

  function openAddTagModal() {
    setAddTagModalOpen(true);
  }

  function closeAddTagModal() {
    setAddTagModalOpen(false);
  }

  function openTagInfoModal(roleId) {
    fetchTagInfo(roleId);
    setTagInfoModalOpen(true);
  }

  function closeTagInfoModal() {
    setTagInfoModalOpen(false);
  }

  async function openUpdateTagModal(tagId) {
    fetchTagInfo(tagId);
    setUpdateTagModalOpen(true);
  }

  function closeUpdateTagModal() {
    setUpdateTagModalOpen(false);
  }

  async function openDeleteTagModal(tagId, requestType) {
    setTagId({
      tagId,
      requestType
    });
    setDeleteTagModalOpen(true);
  }

  function closeDeleteTagModal() {
    setDeleteTagModalOpen(false);
  }

  async function fetchTagInfo(tagId) {
    setTagInfoLoading(true);
    const response = await getTagInfo(tagId);
    if (response) {
      setTagInfo(response);
      setTagInfoLoading(false);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <TagFilterModal
          filterError={filterError}
          setFilterError={setFilterError}
          modalOpenState={filterModalOpen}
          closeModalHandler={closeFilterModal}
          fetchList={fetchData}
          updateFilterCount={updateFilterCount}
          updateUrlPath={updateUrlPath}
          tagName={filterTagName}
          setTagName={setFilterTagName}
          createdAtFilter={createdAtFilter}
          setCreatedAtFilter={setCreatedAtFilter}
          updatedAtFilter={updatedAtFilter}
          setUpdatedAtFilter={setUpdatedAtFilter}
          createQueryOptions={createQueryOptions}
          authUser={authUser}
          isDeleted={isDeleted}
          setIsDeleted={setIsDeleted}
        />
        <CreateTagModal
          modalOpenState={addTagModalOpen}
          closeModalHandler={closeAddTagModal}
          fetchNewTagList={async () => {
            let options = await createQueryOptions();
            if (options) {
              await fetchData(1, null, options);
            }
          }}
        />
        <ViewTagModal
          modalOpenState={tagInfoModalOpen}
          closeModalHandler={closeTagInfoModal}
          tagInfo={tagInfo}
          tagInfoLoading={tagInfoLoading}
        />
        <UpdateTagModal
          modalOpenState={updateTagModalOpen}
          closeModalHandler={closeUpdateTagModal}
          tagInfo={tagInfo}
          tagInfoLoading={tagInfoLoading}
          fetchNewTagList={async () => {
            let options = await createQueryOptions();
            if (options) {
              await fetchData(1, null, options);
            }
          }}
        />
        {tagId && (
          <DeleteTagModal
            modalOpenState={deleteTagModalOpen}
            closeModalHandler={closeDeleteTagModal}
            tagId={tagId.tagId}
            type={tagId.requestType}
            fetchNewData={async () => {
              let options = await createQueryOptions();
              if (options) {
                await fetchData(1, null, options);
              }
            }}
          />
        )}
        <Container fluid>
          <Breadcrumbs title="View tags" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <>
                    <Row className="mt-2">
                      <Col md="2">
                        <FormGroup>
                          <Col md="12">
                            <Label className="col-form-label">
                              Show Entries
                            </Label>
                            <select
                              className="form-control"
                              onChange={(e) => {
                                fetchData(
                                  1,
                                  e.target.value,
                                  createQueryOptions(),
                                  sortingRule
                                );
                                setPagination({
                                  ...pagination,
                                  pageLimit: e.target.value
                                });
                              }}
                              value={pagination.pageLimit}
                              disabled={tagListLoading}
                            >
                              <option value={10}>10</option>
                              <option value={25}>25</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <Label className="col-form-label">Sort Tags</Label>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              fetchSortedList(e.target.value);
                            }}
                            value={sortingRule}
                            disabled={tagListLoading}
                          >
                            <option value="name$asc">Tag Name (A-Z)</option>
                            <option value="name$desc">Tag Name (Z-A)</option>
                            <option value="created_at$desc">
                              Create Time (Newest First)
                            </option>
                            <option value="created_at$asc">
                              Create Time (Oldest First)
                            </option>
                            <option value="updated_at$desc">
                              Update Time (Newest First)
                            </option>
                            <option value="updated_at$asc">
                              Update Time (Oldest First)
                            </option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col className="d-flex justify-content-end align-items-center">
                        <Button
                          size="sm"
                          color="primary"
                          className=" waves-effect waves-light mr-3"
                          onClick={openFilterModal}
                        >
                          <i className="ri-filter-fill align-middle mr-2"></i>
                          <span>Filter Tag</span>
                          {currentFilterCount > 0 && (
                            <Badge color="light" pill className="ml-2">
                              {currentFilterCount}
                            </Badge>
                          )}
                        </Button>
                        {validatePermissionKey(authUser, CREATE_TAG) && (
                          <Button
                            size="sm"
                            color="success"
                            className=" waves-effect waves-light"
                            onClick={openAddTagModal}
                          >
                            <i className="ri-add-line align-middle mr-2"></i>
                            Add Tag
                          </Button>
                        )}
                      </Col>
                    </Row>
                    {tagListLoading ? (
                      <div className="text-center">
                        <PageDataLoader />
                      </div>
                    ) : (
                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <Table
                            id="tech-companies-1"
                            striped
                            bordered
                            responsive
                          >
                            <thead>
                              <tr>
                                <th>SL. No</th>
                                <th>Name</th>
                                <th>Created At</th>
                                <th>Last Updated At</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {tagList?.map((item, index) => (
                                <tr key={index}>
                                  <th>{index + 1}</th>
                                  <td>{item.name}</td>
                                  <td>
                                    {moment(new Date(item.createdAt)).format(
                                      'DD-MM-YYYY'
                                    )}
                                  </td>
                                  <td>
                                    {moment(new Date(item.updatedAt)).format(
                                      'DD-MM-YYYY'
                                    )}
                                  </td>
                                  <td>
                                    <Button
                                      size="sm"
                                      color="primary"
                                      className=" waves-effect waves-light mr-2 mb-2"
                                      onClick={() => {
                                        openTagInfoModal(item._id);
                                      }}
                                    >
                                      <i className="fas fa-eye mr-2"></i>
                                      View
                                    </Button>
                                    {validatePermissionKey(
                                      authUser,
                                      UPDATE_TAG
                                    ) && (
                                      <Button
                                        size="sm"
                                        color="warning"
                                        className=" waves-effect waves-light mr-2 mb-2"
                                        onClick={() => {
                                          openUpdateTagModal(item._id);
                                        }}
                                      >
                                        <i className="fas fa-edit mr-2"></i>
                                        Edit
                                      </Button>
                                    )}
                                    {validatePermissionKey(
                                      authUser,
                                      DELETE_TAG
                                    ) && (
                                      <Button
                                        size="sm"
                                        color="danger"
                                        className=" waves-effect waves-light mb-2"
                                        onClick={() => {
                                          openDeleteTagModal(
                                            item._id,
                                            item.isDeleted
                                              ? 'restore'
                                              : 'delete'
                                          );
                                        }}
                                      >
                                        {item.isDeleted ? (
                                          <i className="fas fa-trash-restore mr-2"></i>
                                        ) : (
                                          <i className="fas fa-trash mr-2"></i>
                                        )}
                                        {item.isDeleted ? 'Restore' : 'Delete'}
                                      </Button>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    )}
                    <Row className="mt-2">
                      <Col md="5">
                        <p>
                          <strong>
                            {' '}
                            Showing{' '}
                            {(pagination.currentPage - 1) *
                              pagination.pageLimit +
                              1}{' '}
                            to{' '}
                            {Math.min(
                              pagination.currentPage * pagination.pageLimit,
                              pagination.totalEntries
                            )}{' '}
                            of {pagination.totalEntries} entries
                          </strong>
                        </p>
                      </Col>
                      {!tagListLoading && tagList.length > 0 && (
                        <Col md="7">
                          <Paginator
                            totalPages={pagination.totalPages}
                            currentPage={pagination.currentPage}
                            changePage={handlePagination}
                          />
                        </Col>
                      )}
                    </Row>
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(TagListPage);
