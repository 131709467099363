/*jshint esversion: 11 */
import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Input
} from 'reactstrap';
import Select from 'react-select';
import { connect } from 'react-redux';
import PageDataLoader from '../Utility/PageDataLoader';

const ViewFormModal = ({
  modalOpenState,
  closeModalHandler,
  formInfo,
  formInfoLoading
}) => {
  const [selectedReference, setSelectedReference] = useState(null);
  const [selectedSections, setSelectedSections] = useState(null)

  useEffect(() => {
    if (formInfo) {
      setSelectedReference(buildOptions(formInfo?.batches || []));
      setSelectedSections(buildOptions(formInfo?.sections || []))
    }
  }, [formInfo]);

  function buildOptions(list) {
    return list.map((item) => ({
      value: item._id,
      label: item.name
    }));
  }


  return (
    <React.Fragment>
      <Modal isOpen={modalOpenState} backdrop="static" size="lg">
        <ModalHeader toggle={closeModalHandler}>
          {formInfoLoading ? 'Loading ...' : formInfo.name}
        </ModalHeader>
        <ModalBody>
          {formInfoLoading ? (
            <div className="d-flex justify-content-center">
              <PageDataLoader />
            </div>
          ) : (
            <React.Fragment>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label>Name</Label>
                    <Input
                      name="title"
                      type="text"
                      className="form-control"
                      defaultValue={formInfo?.name}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label>Batches</Label>

                    <Select
                      isDisabled={true}
                      isMulti
                      defaultValue={selectedReference}
                    />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label>Sections</Label>

                    <Select
                      isDisabled={true}
                      isMulti
                      defaultValue={selectedSections}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <ModalFooter>
                <Button type="button" color="light" onClick={closeModalHandler}>
                  Close
                </Button>
              </ModalFooter>
            </React.Fragment>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(ViewFormModal);
