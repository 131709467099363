/*jshint esversion: 11 */
import React from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Alert,
  Input
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const StaffFilterModal = ({
  setFilterError,
  filterError,
  modalOpenState,
  closeModalHandler,
  fetchList,
  updateFilterCount,
  staffName,
  setStaffName,
  staffEmail,
  setStaffEmail,
  staffPhone,
  setStaffPhone,
  createdAtFilter,
  setCreatedAtFilter,
  createQueryOptions,
  roleOptions,
  selectedRoleOption,
  setSelectedRoleOption,
  staffGender,
  setStaffGender,
  staffAge,
  setStaffAge,
  authUser,
  isDeleted,
  setIsDeleted
}) => {
  function dateRangeHandler(setHook) {
    return function (dates) {
      const [start, end] = dates;
      setHook({
        startDate: start,
        endDate: end
      });
    };
  }

  function applyFilter() {
    const queryOptions = createQueryOptions();
    if (queryOptions) {
      updateFilterCount(queryOptions);
      fetchList(1, null, queryOptions);
      closeModalHandler();
    }
  }

  function clearFilter() {
    setCreatedAtFilter({
      startDate: null,
      endDate: null
    });
    setSelectedRoleOption(null);
    setStaffAge({
      lower: '',
      upper: ''
    });
    setStaffEmail('');
    setStaffGender('');
    setStaffName('');
    setStaffPhone('');
    setIsDeleted(false);
    const queryOptions = createQueryOptions(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    if (queryOptions) {
      updateFilterCount(queryOptions);
      fetchList(1, null, queryOptions);
      closeModalHandler();
    }
  }

  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100
    })
  };

  return (
    <React.Fragment>
      <Modal isOpen={modalOpenState} backdrop="static" size="md">
        <ModalHeader toggle={closeModalHandler}>Filter Staff</ModalHeader>
        <ModalBody>
          {filterError ? (
            <Alert
              color="danger"
              role="alert"
              isOpen={filterError ? true : false}
              toggle={() => setFilterError(false)}
            >
              <i className="mdi mdi-block-helper mr-2"></i>
              {filterError}
            </Alert>
          ) : null}
          <AvForm className="needs-validation" onValidSubmit={applyFilter}>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label>Staff Name</Label>
                  <AvField
                    name="staff_name"
                    placeholder="Enter Staff Name ..."
                    type="text"
                    className="form-control"
                    onChange={(e) => setStaffName(e.target?.value)}
                    value={staffName}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label>Email Address</Label>
                  <AvField
                    name="staff_email"
                    placeholder="Enter Email Address ..."
                    type="text"
                    className="form-control"
                    onChange={(e) => setStaffEmail(e.target?.value)}
                    value={staffEmail}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label>Phone Number</Label>
                  <AvField
                    name="staff_phone_number"
                    placeholder="Enter Phone Number ..."
                    type="text"
                    className="form-control"
                    onChange={(e) => setStaffPhone(e.target?.value)}
                    value={staffPhone}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label>Staff Role</Label>
                  <Select
                    options={roleOptions}
                    onChange={(e) => setSelectedRoleOption(e)}
                    value={selectedRoleOption}
                    components={animatedComponents}
                    styles={selectStyles}
                    isMulti
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>Gender</Label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                      setStaffGender(e.target?.value);
                    }}
                    value={staffGender}
                  >
                    <option value="">None</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>Lower Age</Label>
                  <AvField
                    name="staff_lower_age"
                    placeholder="Lower Limit ..."
                    type="number"
                    className="form-control"
                    onChange={(e) =>
                      setStaffAge((pv) => {
                        return {
                          ...pv,
                          lower: e.target?.value
                        };
                      })
                    }
                    value={staffAge.lower}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>Upper Age</Label>
                  <AvField
                    name="staff_upper_age"
                    placeholder="Upper Limit ..."
                    type="number"
                    className="form-control"
                    onChange={(e) =>
                      setStaffAge((pv) => {
                        return {
                          ...pv,
                          upper: e.target?.value
                        };
                      })
                    }
                    value={staffAge.upper}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label>Created At</Label>
                  <DatePicker
                    selected={createdAtFilter.startDate}
                    selectsStart
                    className="form-control"
                    startDate={createdAtFilter.startDate}
                    endDate={createdAtFilter.endDate}
                    selectsRange={true}
                    dateFormat="dd-MM-yyyy"
                    onChange={dateRangeHandler(setCreatedAtFilter)}
                    isClearable={true}
                  />
                </FormGroup>
              </Col>
              {authUser && authUser.user_type === 'super_admin' && (
                <Col md="12">
                  <FormGroup>
                    <Label>Is Deleted</Label>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr"
                    >
                      <Input
                        id="customSwitch"
                        name="isComplted"
                        type="checkbox"
                        className="custom-control-input p-3"
                        defaultChecked={isDeleted}
                      />
                      <Label
                        htmlFor="customSwitch"
                        className="custom-control-label"
                        onClick={() => setIsDeleted(!isDeleted)}
                      >
                        Deleted Users
                      </Label>
                    </div>
                  </FormGroup>
                </Col>
              )}
            </Row>
            <Row className="d-flex px-3" style={{ height: '100%' }}></Row>
            <ModalFooter>
              <Button size="sm" type="submit" color="primary">
                Apply Filter
              </Button>
              <Button
                size="sm"
                type="button"
                color="success"
                onClick={() => clearFilter()}
              >
                Reset
              </Button>
              <Button
                size="sm"
                type="button"
                color="light"
                onClick={closeModalHandler}
              >
                Close
              </Button>
            </ModalFooter>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(StaffFilterModal);
