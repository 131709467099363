export const scheduleEnum = [
  { value: 'Daily', label: 'Daily' },
  { value: 'Weekday', label: 'Weekday' },
  { value: 'Weekend', label: 'Weekend' },
  { value: 'Random', label: 'Random' }
];

export const locationEnum = [
  { value: 'Kandivali', label: 'Kandivali' },
  { value: 'Borivali', label: 'Borivali' },
  { value: 'Malad', label: 'Malad' },
  { value: 'Other', label: 'Other' }
];

export const genderEnum = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' }
];

export const relationEnum = [
  { value: 'Mother', label: 'Mother' },
  { value: 'Father', label: 'Father' },
  { value: 'Grandmother', label: 'Grandmother' },
  { value: 'Grandfather', label: 'Grandfather' },
  { value: 'Uncle', label: 'Uncle' },
  { value: 'Aunt', label: 'Aunt' },
  { value: 'Other', label: 'Other' }
];

export const languages = [
  'Asami',
  'Bengali',
  'Gujarati',
  'Hindi',
  'Kannada',
  'Malayalam',
  'Marathi',
  'Odia',
  'Punjabi',
  'Tamil',
  'Telugu',
  'Urdu',
  'Sanskrit',
  'English',
  'Konkani',
  'Nepali',
  'Bodo',
  'Kashmiri',
  'Maithili',
  'Santali',
  'Sindhi'
];

export const bloodGroup = ['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-'];

export const taskStatus = ['New', 'In Progress', 'Blocked', 'Completed'];
