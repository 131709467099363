/*jshint esversion: 11 */
import sendApiRequest from '../helpers/axios-setup';


/**
 * Schema for invoice details within a transaction.
 * @typedef {Object} InvoiceDetailSchema
 * @property {string} description - Description of the item.
 * @property {number} rate - Rate per unit of the item.
 * @property {number} quantity - Quantity of the item.
 * @property {number} price - Total price calculated from rate and quantity.
 */

/**
 * Schema for transactions.
 * @typedef {Object} TransactionSchema
 * @property {ObjectId} childrenId - ID referencing the child associated with the transaction.
 * @property {ObjectId} batchId - ID referencing the batch associated with the transaction.
 * @property {number} totalAmountToPay - Total amount to be paid for the transaction.
 * @property {number} paidAmount - Amount already paid for the transaction.
 * @property {number} dueAmount - Remaining amount to be paid for the transaction.
 * @property {boolean} isSessionBatch - Indicates if the batch is a session-based batch.
 * @property {number} perSessionFee - Fee per session if applicable.
 * @property {number} noOfSessions - Number of sessions if applicable.
 * @property {Date} date - Date of the transaction.
 * @property {InvoiceDetailSchema[]} invoiceDetail - Details of items included in the invoice.
 */

export async function addNewTransaction(data) {

  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.post('/transaction', data);
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 * Schema for transactions.
 * @typedef {Object} TransactionSchema
 * @property {number} totalAmountToPay - Total amount to be paid for the transaction.
 * @property {number} paidAmount - Amount already paid for the transaction.
 * @property {number} dueAmount - Remaining amount to be paid for the transaction.
 * @property {InvoiceDetailSchema[]} invoiceDetail - Details of items included in the invoice.
 */

export async function editTransaction(data,id) {

  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.put(`/transaction/${id}`, data);
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}


/**
 *
 * @returns {Promise}
 */
export async function getLatestTransaction({batch_id, child_id}) {
  try {
    const response = await sendApiRequest.get(`/transaction/latest?child_id=${child_id}&batch_id=${batch_id}`);
    return response.data
  } catch (e) {
    return null;
  }
}


export async function getAllTransactionsOfChild({ child_id, batch_id, start_date, end_date, sorting_option }) {
  try {
    // Construct query parameters
    let queryParams = new URLSearchParams();

    if (child_id) queryParams.append('child_id', child_id);
    if (batch_id) queryParams.append('batch_id', batch_id);
    if (start_date) queryParams.append('start_date', start_date);
    if (end_date) queryParams.append('end_date', end_date);
    if (sorting_option) queryParams.append('sorting_option', sorting_option);

    // Construct the URL with query parameters
    const queryString = queryParams.toString();
    const url = `/transaction?${queryString}`;
    const response = await sendApiRequest.get(url);
    return response.data;
  } catch (e) {
    console.error(e);
    return null;
  }
}


export async function getHistoryOfTransactions({child_id,batch_id}){
  try {
    const response=await sendApiRequest.get(`/transaction/history?child_id=${child_id}&batch_id=${batch_id}`);
      return response?.data
  } catch (error) {
    return null;
  }
}