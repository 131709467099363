/*jshint esversion: 11 */
import React from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Input
} from 'reactstrap';
import { connect } from 'react-redux';
import _ from 'lodash';
import PageDataLoader from '../Utility/PageDataLoader';
import moment from 'moment';

const ViewTodoModal = ({
  modalOpenState,
  closeModalHandler,
  todoInfoLoading,
  todoInfo
}) => {
  return (
    <React.Fragment>
      <Modal isOpen={modalOpenState} backdrop="static" size="lg">
        <ModalHeader toggle={closeModalHandler}>
          {todoInfoLoading ? 'Loading ...' : todoInfo.title}
        </ModalHeader>
        <ModalBody>
          {todoInfoLoading ? (
            <div className="d-flex justify-content-center">
              <PageDataLoader />
            </div>
          ) : (
            <React.Fragment>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label>Title</Label>
                    <Input
                      name="title"
                      type="text"
                      className="form-control"
                      defaultValue={todoInfo?.title}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>Assigned By</Label>
                    <Input
                      name="assigned_by"
                      type="text"
                      className="form-control"
                      defaultValue={
                        todoInfo.assigned_by?.name +
                        ' [' +
                        _.upperCase(todoInfo.assigned_by_role) +
                        ']'
                      }
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>Assigned To</Label>
                    <Input
                      name="assigned_by"
                      type="text"
                      className="form-control"
                      defaultValue={
                        todoInfo.assigned_to?.name +
                        ' [' +
                        _.upperCase(todoInfo.assigned_to_role) +
                        ']'
                      }
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label>Details</Label>
                    <Input
                      name="detail"
                      type="textarea"
                      className="form-control"
                      defaultValue={todoInfo?.detail}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>Expected Date</Label>
                    <Input
                      name="expected_date"
                      type="text"
                      className="form-control"
                      defaultValue={
                        todoInfo &&
                        moment(new Date(todoInfo.expected_date)).format(
                          'DD-MM-YYYY'
                        )
                      }
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>Status</Label>
                    <Input
                      name="status"
                      type="text"
                      className="form-control"
                      defaultValue={todoInfo?.status}
                      readOnly
                    />
                  </FormGroup>
                </Col>
              </Row>
              <ModalFooter>
                <Button type="button" color="light" onClick={closeModalHandler}>
                  Close
                </Button>
              </ModalFooter>
            </React.Fragment>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(ViewTodoModal);
