import React, { useEffect, useRef, useState } from 'react';
import { Container, Card, CardBody, Row, Col } from 'reactstrap';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import BootstrapTheme from '@fullcalendar/bootstrap';

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/bootstrap/main.css';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import { getBatchActivityList, getCalendarDetails } from '../../api/batch';
import ViewActivityModal from './ViewActivityModal';
import { connect } from 'react-redux';

const Dashboard = ({ authUser }) => {
  let calendar = useRef();
  const [calendarEvents, setCalendarEvents] = useState([]);
  let breadcrumbItems = [
    { title: 'Dashboard', link: '#' },
    { title: 'Calendar', link: '#' }
  ];
  const [activityInfoModalOpen, setActivityInfoModalOpen] = useState(false);
  const [activityInfo, setActivityInfo] = useState([]);
  const [activityInfoLoading, setActivityInfoLoading] = useState(true);

  function openActivityInfoModal(date, batchId, batchName) {
    fetchActivityInfo(date, batchId, batchName);
    setActivityInfoModalOpen(true);
  }

  function closeActivityInfoModal() {
    setActivityInfoModalOpen(false);
  }

  const fetchActivityInfo = async (
    date,
    batchId,
    batchName,
    current_page = 1,
    page_limit = 100
  ) => {
    setActivityInfoLoading(true);
    const activityArray = await getBatchActivityList(
      batchId,
      date,
      null,
      current_page,
      page_limit
    );

    if (activityArray) {
      setActivityInfo({
        date,
        batch: batchName,
        activities: activityArray.data
      });
      setActivityInfoLoading(false);
    }
  };

  const fetchCalendarDetails = async (fetchInfo) => {
    let response = await getCalendarDetails(fetchInfo.start, fetchInfo.end);
    if (response) {
      setCalendarEvents(response.data);
    }
  };

  let listElem = document.getElementById('homeList');
  let linkElem = document.getElementById('homeLink');

  useEffect(() => {
    if (listElem) listElem.classList.add('mm-active');
    if (linkElem) linkElem.classList.add('active');
    let calendarApi = calendar.current.getApi();
    fetchCalendarDetails(calendarApi.state.dateProfile.activeRange);
  }, [listElem, linkElem]);

  return (
    <React.Fragment>
      <ViewActivityModal
        modalOpenState={activityInfoModalOpen}
        closeModalHandler={closeActivityInfoModal}
        activityInfo={activityInfo}
        activityInfoLoading={activityInfoLoading}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Calendar" breadcrumbItems={breadcrumbItems} />

          <Row>
            <Col xs={1}></Col>
            <Col xs={10}>
              <Card>
                <CardBody>
                  <FullCalendar
                    ref={calendar}
                    defaultView="dayGridMonth"
                    displayEventTime={false}
                    plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
                    handleWindowResize={true}
                    themeSystem="bootstrap"
                    header={{
                      right: 'customprev, customnext'
                    }}
                    customButtons={{
                      customprev: {
                        text: 'Prev',
                        click: function () {
                          let calendarApi = calendar.current.getApi();
                          calendarApi.prev();
                          fetchCalendarDetails(
                            calendarApi.state.dateProfile.activeRange
                          );
                        }
                      },
                      customnext: {
                        text: 'Next',
                        click: function () {
                          let calendarApi = calendar.current.getApi();
                          calendarApi.next();
                          fetchCalendarDetails(
                            calendarApi.state.dateProfile.activeRange
                          );
                        }
                      }
                    }}

                    validRange={authUser?.user_type === "staff" && {

                      start: new Date(new Date().setDate(new Date().getDate() - 15)), // 7 days in the past
                      end: new Date(new Date().setDate(new Date().getDate() + 15)) // today
                    }}
                    defaultDate={new Date(new Date().setDate(new Date().getDate() - 15))}
                    lazyFetching={true}
                    editable={true}
                    droppable={true}
                    eventLimit={true}
                    selectable={true}
                    events={calendarEvents}
                    eventClick={(e) => {
                      e.jsEvent.preventDefault();
                      openActivityInfoModal(
                        e.event.start,
                        e.event.id,
                        e.event.title
                      );
                    }}
                  />

                  <div style={{ clear: 'both' }}></div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(Dashboard);