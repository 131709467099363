/*jshint esversion: 11 */
import React from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Alert,
  Input
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const BookFilterModal = ({
  filterError,
  setFilterError,
  modalOpenState,
  closeModalHandler,
  fetchList,
  updateFilterCount,
  bookName,
  setBookName,
  createQueryOptions,
  tagOptions,
  selectedTagOption,
  setSelectedTagOption,
  bookAge,
  setBookAge,
  availableStatus,
  setAvailableStatus,
  authUser,
  isDeleted,
  setIsDeleted
}) => {
  function applyFilter() {
    const queryOptions = createQueryOptions();
    if (queryOptions) {
      updateFilterCount(queryOptions);
      fetchList(1, null, queryOptions);
      closeModalHandler();
    }
  }

  function clearFilter() {
    setAvailableStatus(null);
    setBookAge({
      lower: null,
      upper: null
    });
    setBookName('');
    setSelectedTagOption(null);
    setIsDeleted(false);
    const queryOptions = createQueryOptions(null, null, null, null, null, null);
    if (queryOptions) {
      updateFilterCount(queryOptions);
      fetchList(1, null, queryOptions);
      closeModalHandler();
    }
  }

  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100
    })
  };

  return (
    <React.Fragment>
      <Modal isOpen={modalOpenState} backdrop="static" size="md">
        <ModalHeader toggle={closeModalHandler}>Filter Books</ModalHeader>
        <ModalBody>
          {filterError ? (
            <Alert
              color="danger"
              role="alert"
              isOpen={filterError ? true : false}
              toggle={() => setFilterError(false)}
            >
              <i className="mdi mdi-block-helper mr-2"></i>
              {filterError}
            </Alert>
          ) : null}
          <AvForm className="needs-validation" onValidSubmit={applyFilter}>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label>Book Name</Label>
                  <AvField
                    name="book_name"
                    placeholder="Enter Book Name ..."
                    type="text"
                    className="form-control"
                    onChange={(e) => setBookName(e.target?.value)}
                    value={bookName}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label>Tags</Label>
                  <Select
                    options={tagOptions}
                    onChange={(e) => setSelectedTagOption(e)}
                    value={selectedTagOption}
                    components={animatedComponents}
                    styles={selectStyles}
                    isMulti
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>Availability Status</Label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                      setAvailableStatus(e.target?.value);
                    }}
                    value={availableStatus}
                  >
                    <option value="">ALL</option>
                    <option value="YES">Available</option>
                    <option value="NO">Not Available</option>
                  </select>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>Lower Age</Label>
                  <AvField
                    name="toy_lower_age"
                    placeholder="Lower Limit ..."
                    type="number"
                    className="form-control"
                    onChange={(e) =>
                      setBookAge((pv) => {
                        return {
                          ...pv,
                          lower: e.target?.value
                        };
                      })
                    }
                    value={bookAge.lower}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>Upper Age</Label>
                  <AvField
                    name="toy_upper_age"
                    placeholder="Upper Limit ..."
                    type="number"
                    className="form-control"
                    onChange={(e) =>
                      setBookAge((pv) => {
                        return {
                          ...pv,
                          upper: e.target?.value
                        };
                      })
                    }
                    value={bookAge.upper}
                  />
                </FormGroup>
              </Col>
              {authUser && authUser.user_type === 'super_admin' && (
                <Col md="12">
                  <FormGroup>
                    <Label>Is Deleted</Label>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr"
                    >
                      <Input
                        id="customSwitch"
                        name="isComplted"
                        type="checkbox"
                        className="custom-control-input p-3"
                        defaultChecked={isDeleted}
                      />
                      <Label
                        htmlFor="customSwitch"
                        className="custom-control-label"
                        onClick={() => setIsDeleted(!isDeleted)}
                      >
                        Deleted Books
                      </Label>
                    </div>
                  </FormGroup>
                </Col>
              )}
            </Row>
            <Row className="d-flex px-3" style={{ height: '100%' }}></Row>
            <ModalFooter>
              <Button size="sm" type="submit" color="primary">
                Apply Filter
              </Button>
              <Button
                size="sm"
                type="button"
                color="success"
                onClick={() => clearFilter()}
              >
                Reset
              </Button>
              <Button
                size="sm"
                type="button"
                color="light"
                onClick={closeModalHandler}
              >
                Close
              </Button>
            </ModalFooter>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(BookFilterModal);
