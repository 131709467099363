/*jshint esversion: 11 */
import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Alert
} from 'reactstrap';
import Select from 'react-select';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { addNewTodo } from '../../api/todo';
import { getUsersList } from '../../api/staff';

const CreateTodoModal = ({
  modalOpenState,
  closeModalHandler,
  fetchNewTodoList,
  authUser
}) => {
  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });
  const [todoCreateRequestLoading, setTodoCreateRequestLoading] =
    useState(false);
  const [successAlert, setSuccessAlert] = useState({
    message: '',
    show_alert: false
  });
  const [userOptions, setUserOptions] = useState([]);
  const [userOptionsLoading, setUserOptionsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  async function handleTodoCreation(event, values) {
    resetErrorAlert();
    if (!selectedUser) {
      showErrorAlert('Please select user to assign task');
      return;
    }
    setTodoCreateRequestLoading(true);
    const { response, error } = await addNewTodo(
      values.title,
      values.detail,
      authUser._id,
      authUser.user_type === 'super_admin' ? 'super_admin' : 'Staff',
      selectedUser.value,
      selectedUser.role,
      values.expected_date
    );
    setTodoCreateRequestLoading(false);
    if (error) {
      showErrorAlert(error);
      return;
    }
    setSelectedUser(null);
    closeModalHandler();
    showSuccessAlert(response.message);
    fetchNewTodoList();
  }

  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }

  function showSuccessAlert(message) {
    setSuccessAlert({ message: message, show_alert: true });
  }

  function resetSuccessAlert() {
    setSuccessAlert({ message: '', show_alert: false });
  }

  function resetState() {
    resetSuccessAlert();
    resetErrorAlert();
  }

  const handleUserSelection = (selectedItem) => {
    setSelectedUser(selectedItem);
  };

  useEffect(() => {
    if (authUser) {
      fetchUserOptions();
    }
    // eslint-disable-next-line
  }, [authUser, modalOpenState]);

  const fetchUserOptions = async () => {
    setUserOptionsLoading(true);
    const response = await getUsersList();
    if (response) {
      setUserOptions(buildOptions(response || []));
      setUserOptionsLoading(false);
    }
  };

  function buildOptions(list) {
    return list.map((item) => {
      let role_name = item.role ? item.role.role_name : 'SuperAdmin';
      let user_type = item.role ? 'Staff' : 'super_admin';
      return {
        value: item._id,
        label: item.name + ' (' + role_name + ')',
        role: user_type
      };
    });
  }

  return (
    <React.Fragment>
      {successAlert.show_alert && (
        <SweetAlert success title="Created" onConfirm={resetState}>
          {successAlert.message}
        </SweetAlert>
      )}
      <Modal isOpen={modalOpenState} backdrop="static" size="xl">
        <ModalHeader toggle={closeModalHandler}>Add New Task</ModalHeader>
        <ModalBody>
          {requestError ? (
            <Alert
              color="danger"
              role="alert"
              isOpen={requestError.show_alert}
              toggle={resetErrorAlert}
            >
              <i className="mdi mdi-block-helper mr-2"></i>
              {requestError.message}
            </Alert>
          ) : null}
          <AvForm
            className="needs-validation"
            onValidSubmit={handleTodoCreation}
          >
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label>Title</Label>
                  <AvField
                    name="title"
                    placeholder="Enter Title ..."
                    type="text"
                    className="form-control"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Title is required'
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label>Detail</Label>
                  <AvField
                    name="detail"
                    placeholder="Enter Details"
                    type="textarea"
                    className="form-control"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Details is required'
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Expected Date</Label>
                  <AvField
                    name="expected_date"
                    placeholder="Enter Expected Date ..."
                    type="date"
                    className="form-control"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Expected date is required'
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Assign To</Label>
                  <Select
                    options={userOptions}
                    disabled={userOptionsLoading}
                    onChange={handleUserSelection}
                    value={selectedUser}
                  />
                </FormGroup>
              </Col>
            </Row>
            <ModalFooter>
              <Button
                disabled={todoCreateRequestLoading}
                type="button"
                color="light"
                onClick={closeModalHandler}
              >
                Close
              </Button>
              <Button type="submit" color="success">
                {todoCreateRequestLoading ? 'Creating ...' : 'Add Task'}
              </Button>
            </ModalFooter>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(CreateTodoModal);
