/*jshint esversion: 11 */
import sendApiRequest from '../helpers/axios-setup';

/**
 *
 * @returns {Promise}
 */
export async function getAssignmentList(
  page,
  limit,
  {
    filter_start_date = '',
    filter_end_date = '',
    name = '',
    include_deleted = ''
  } = {},
  sorting_option = ''
) {
  try {
    const response = await sendApiRequest.get('/assignment', {
      params: {
        page: page,
        limit: limit,
        filter_start_date,
        filter_end_date,
        filter_name: name,
        include_deleted,
        sorting_option
      }
    });
    return response.data?.data[0];
  } catch (e) {
    return null;
  }
}

/**
 *
 * @returns {Promise}
 */
export async function getAssignmentChildrenList(data) {
  try {
    const response = await sendApiRequest.get('/assignment/children', {
      params: data
    });
    return response.data?.data[0];
  } catch (e) {
    return null;
  }
}

/**
 *
 * @param {string} child
 * @param {string} name
 * @param {string} summary
 * @param {date} date
 * @returns {Promise<Object>}
 */
export async function addNewAssignment(data) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.post('/assignment', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @param {String} assignmentId
 * @returns
 */
export async function getAssignmentInfo(assignmentId) {
  try {
    const response = await sendApiRequest.get(`/assignment/${assignmentId}`);
    return response.data?.data;
  } catch (e) {
    return null;
  }
}

/**
 *
 * @param {string} name
 * @param {string} summary
 * @param {string} date
 * @returns {Promise<Object>}
 */
export async function updateAssignment(assignmentId, data) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.put(
      `/assignment/${assignmentId}`,
      data,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    );
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @param {String} assignmentId
 * @returns
 */
export async function getAssignmentChildrenInfo(assignmentId) {
  try {
    const response = await sendApiRequest.get(
      `/assignment/children/${assignmentId}`
    );
    return response.data?.data;
  } catch (e) {
    return null;
  }
}

export async function updateAssignmentStatus(assignmentId, data) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.put(
      `/assignment/children/${assignmentId}`,
      data
    );
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @param {String} assignmentId
 * @returns
 */
export async function deleteRestoreAssignment(assignmentId, type) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.delete(
      `/assignment/${assignmentId}`,
      {
        params: {
          type: type
        }
      }
    );
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}
