/*jshint esversion: 11 */
import React from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Input,
  Badge
} from 'reactstrap';
import { connect } from 'react-redux';
import PageDataLoader from '../Utility/PageDataLoader';
import moment from 'moment';
import ExecutionCarousel from '../Child/ExecutionCarousel';

const ViewAssignmentModal = ({
  modalOpenState,
  closeModalHandler,
  assignmentInfoLoading,
  assignmentInfo
}) => {
  return (
    <React.Fragment>
      <Modal isOpen={modalOpenState} backdrop="static" size="lg">
        <ModalHeader toggle={closeModalHandler}>
          {assignmentInfoLoading ? 'Loading ...' : assignmentInfo.name}
        </ModalHeader>
        <ModalBody>
          {assignmentInfoLoading ? (
            <div className="d-flex justify-content-center">
              <PageDataLoader />
            </div>
          ) : (
            <React.Fragment>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label>Name</Label>
                    <Input
                      name="name"
                      type="text"
                      className="form-control"
                      defaultValue={assignmentInfo?.name}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>Date</Label>
                    <Input
                      name="date"
                      type="date"
                      className="form-control"
                      defaultValue={
                        assignmentInfo &&
                        moment(new Date(assignmentInfo.date)).format(
                          'YYYY-MM-DD'
                        )
                      }
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label>Summary</Label>
                    <Input
                      name="summary"
                      type="textarea"
                      className="form-control"
                      defaultValue={assignmentInfo?.summary}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label>Video URL</Label>
                    <Input
                      name="video_url"
                      type="text"
                      className="form-control"
                      defaultValue={assignmentInfo?.video_url}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label>Tags</Label>
                    <div>
                      {assignmentInfo.tags &&
                        assignmentInfo.tags.map((t) => (
                          <Badge
                            color="primary"
                            className="mr-2 mb-2"
                            style={{
                              padding: '10px 20px',
                              fontSize: '14px'
                            }}
                            pill
                          >
                            {t.name}
                          </Badge>
                        ))}
                    </div>
                  </FormGroup>
                </Col>
                {assignmentInfo.images && assignmentInfo.images.length > 0 && (
                  <Col md="12">
                    <ExecutionCarousel images={assignmentInfo.images} />
                  </Col>
                )}
              </Row>
              <ModalFooter>
                <Button type="button" color="light" onClick={closeModalHandler}>
                  Close
                </Button>
              </ModalFooter>
            </React.Fragment>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(ViewAssignmentModal);
