/*jshint esversion: 11 */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Alert
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { addNewActivity } from '../../api/activity';
import { getTagList } from '../../api/tag';
import FormUpload from '../../components/FormUpload';

const CreateActivityModal = ({
  modalOpenState,
  closeModalHandler,
  fetchNewActivityList
}) => {
  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });
  const [activityCreateRequestLoading, setActivityCreateRequestLoading] =
    useState(false);
  const [successAlert, setSuccessAlert] = useState({
    message: '',
    show_alert: false
  });
  const [images, setImages] = useState([]);

  const [tagOptions, setTagOptions] = useState([]);
  const [tagOptionsLoading, setTagOptionsLoading] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [form, setForm] = useState(new FormData());

  async function handleActivityCreation(event, values) {
    resetErrorAlert();
    if (!selectedTag || selectedTag.length === 0) {
      showErrorAlert('Select atleast one tag');
      return;
    }
    let selectedTagArray = selectedTag && selectedTag.map((c) => c.value);
    setActivityCreateRequestLoading(true);
    if (images && images.length > 0) {
      images.map((g, index) => form.append(`images`, g));
    }
    form.set('name', values.activity_name);
    form.set('summary', values.summary);
    form.set('duration', values.duration);
    selectedTagArray.map((g, index) => form.set(`tags[${index}]`, g));
    form.set('detail', values.detail);
    const { response, error } = await addNewActivity(
      // values.activity_name,
      // values.summary,
      // values.detail,
      // values.duration,
      // selectedTagArray
      form
    );
    setActivityCreateRequestLoading(false);
    if (error) {
      showErrorAlert(error);
      return;
    }
    setSelectedTag(null);
    setImages([]);
    setForm(new FormData());
    closeModalHandler();
    showSuccessAlert(response.message);
    fetchNewActivityList();
  }

  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }

  function showSuccessAlert(message) {
    setSuccessAlert({ message: message, show_alert: true });
  }

  function resetSuccessAlert() {
    setSuccessAlert({ message: '', show_alert: false });
  }

  function resetState() {
    resetSuccessAlert();
    resetErrorAlert();
  }

  useEffect(() => {
    fetchTagOptions();
    // eslint-disable-next-line
  }, [modalOpenState]);

  const fetchTagOptions = async () => {
    setTagOptionsLoading(true);
    const response = await getTagList(1, 100);
    if (response) {
      setTagOptions(buildOptions(response?.data || []));
      setTagOptionsLoading(false);
    }
  };

  function buildOptions(list) {
    return list.map((item) => ({
      value: item._id,
      label: item.name
    }));
  }

  function handleTagSelection(selectedItem) {
    setSelectedTag(selectedItem);
  }

  return (
    <React.Fragment>
      {successAlert.show_alert && (
        <SweetAlert success title="Created" onConfirm={resetState}>
          {successAlert.message}
        </SweetAlert>
      )}
      <Modal isOpen={modalOpenState} backdrop="static" size="xl">
        <ModalHeader toggle={closeModalHandler}>Add New Activity</ModalHeader>
        <ModalBody>
          {requestError ? (
            <Alert
              color="danger"
              role="alert"
              isOpen={requestError.show_alert}
              toggle={resetErrorAlert}
            >
              <i className="mdi mdi-block-helper mr-2"></i>
              {requestError.message}
            </Alert>
          ) : null}
          <AvForm
            className="needs-validation"
            onValidSubmit={handleActivityCreation}
          >
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>Activity Name</Label>
                  <AvField
                    name="activity_name"
                    placeholder="Enter Activity Name ..."
                    type="text"
                    className="form-control"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Activity name is required'
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Duration (minutes)</Label>
                  <AvField
                    name="duration"
                    placeholder="Enter Duration ..."
                    type="number"
                    min="5"
                    className="form-control"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Duration is required'
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label>Summary</Label>
                  <AvField
                    name="summary"
                    placeholder="Enter Summary"
                    type="text"
                    className="form-control"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Activity summary is required'
                      }
                    }}
                  />
                </FormGroup>
              </Col>

              <Col md="12">
                <FormGroup>
                  <Label>Detail</Label>
                  <AvField
                    name="detail"
                    placeholder="Enter Details"
                    type="textarea"
                    className="form-control"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Details is required'
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label>Tags</Label>
                  <Select
                    options={tagOptions}
                    disabled={tagOptionsLoading}
                    isMulti
                    defaultValue={selectedTag}
                    onChange={handleTagSelection}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormUpload title="Activity Images" setImages={setImages} />
              </Col>
            </Row>
            <ModalFooter>
              <Button
                disabled={activityCreateRequestLoading}
                type="button"
                color="light"
                onClick={closeModalHandler}
              >
                Close
              </Button>
              <Button type="submit" color="success">
                {activityCreateRequestLoading ? 'Creating ...' : 'Add Activity'}
              </Button>
            </ModalFooter>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(CreateActivityModal);
