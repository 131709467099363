import sendApiRequest from '../helpers/axios-setup';

// Create a new section
export async function createSection(sectionData) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.post('/sections', sectionData);
    responseMap.response = response.data
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap

};

/**
 *
 * @param {number} page
 * @param {number} limit
 * @param {string} [filter_title]
 * @param {string} [filter_start_date]
 * @param {string} [filter_end_date]
 * @param {string} [include_deleted]
 * @returns {Promise<Object>}
 */

export const viewSections = async (page, limit, { filter_name, include_deleted, filter_start_date, filter_end_date } = {}, sorting_option = '') => {
  try {

    const data = await sendApiRequest.get(`/sections`, {
      params: {
        page: page,
        limit: limit,
        sorting_option: sorting_option,
        filter_start_date,
        filter_end_date,
        name: filter_name,
        include_deleted
      }
    });
    return data.data?.data[0];
  } catch (error) {
    return null
  }
};

// View a section by ID
export const viewSectionByID = async (sectionId) => {
  const response = await sendApiRequest.get(`/sections/${sectionId}`);
  return response.data;
};

// Update a section
export async function updateSection(sectionId, sectionData) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.put(`/sections/${sectionId}`, sectionData);
    responseMap.response = response.data
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap
};

// Delete a section
export const deleteSection = async (sectionId) => {
  const responseMap = {
    response: null,
    error: null
  };
  const response = await sendApiRequest.delete(`/sections/${sectionId}`);
  return response.data;
};

export async function getSectionResponse(child_id, batch_id, section_id) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.get(`/section_response/${batch_id}/${section_id}/${child_id}`);
    responseMap.response = response.data
  } catch (e) {
    responseMap.error = e?.response?.data?.error;

  }
  return responseMap
}

export async function crudSectionResponse(child_id, batch_id, section_id, question_response) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.post(`/section_response/${batch_id}/${section_id}/${child_id}`, { question_response: question_response });
    responseMap.response = response.data
  } catch (e) {
    responseMap.error = e?.response?.data?.error;

  }
  return responseMap
}