const todoStatusEnum = ['New', 'In Progress', 'Blocked', 'Completed'];

const todoStatusOptions = todoStatusEnum.map((i) => {
  return {
    label: i,
    value: i
  };
});

export { todoStatusEnum, todoStatusOptions };
