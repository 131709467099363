/*jshint esversion: 11 */
import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
  LOGOUT_USER,
  LOAD_USER,
  CHECK_ADMIN_LOGIN,
  CHECK_STAFF_LOGIN
} from './actionTypes';
import { apiError, loginUserSuccessful, loadUserSuccessful } from './actions';
import {
  loginRequest,
  getAuthenticatedUser
} from '../../helpers/auth-requests';

//If user is login then dispatch redux action's are directly from here.
const globalLoginUser = (user_type) =>
  function* loginUser({ payload: { user, history } }) {
    try {
      const data = yield call(loginRequest, `${user_type}/auth/login`, {
        phone_number: user.phone_number,
        password: user.password
      });

      localStorage.setItem('ACCESS_TOKEN', data.data.access_token);
      localStorage.setItem(
        'user_details',
        JSON.stringify(data.data.user_details)
      );
      yield put(loginUserSuccessful(data.data));
      history.push('/dashboard');
      window.location.reload();
    } catch (e) {
      yield put(apiError(e.error));
    }
  };

function* loadUser() {
  try {
    const response_data = yield call(getAuthenticatedUser, '/auth/verify');
    if (response_data) {
      localStorage.setItem('user_details', JSON.stringify(response_data.data));
      yield put(loadUserSuccessful(response_data?.data));
    } else yield put(apiError(''));
  } catch (e) {
    localStorage.clear();
    yield put(apiError(e.error));
    // yield put(apiError(e.error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.clear();
    history.push('/login');
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchAdminUserLogin() {
  yield takeEvery(CHECK_ADMIN_LOGIN, globalLoginUser('super_admin'));
}

export function* watchStaffUserLogin() {
  yield takeEvery(CHECK_STAFF_LOGIN, globalLoginUser('staff'));
}

export function* watchLoggedInUser() {
  yield takeEvery(LOAD_USER, loadUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

function* loginSaga() {
  yield all([
    fork(watchAdminUserLogin),
    fork(watchStaffUserLogin),
    fork(watchUserLogout),
    fork(watchLoggedInUser)
  ]);
}

export default loginSaga;
