/*jshint esversion: 11 */
import React from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Alert,
  Input
} from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const ExecutionFilterModal = ({
  filterError,
  setFilterError,
  modalOpenState,
  closeModalHandler,
  fetchList,
  updateFilterCount,
  createdAtFilter,
  setCreatedAtFilter,
  authUser,
  isDeleted,
  setIsDeleted,
  batchOptions,
  selectedBatchOption,
  setSelectedBatchOption
}) => {
  function dateRangeHandler(setHook) {
    return function (dates) {
      const [start, end] = dates;
      setHook({
        startDate: start,
        endDate: end
      });
    };
  }

  function applyFilter() {
    setFilterError(false);
    if (
      (createdAtFilter.startDate && !createdAtFilter.endDate) ||
      (!createdAtFilter.startDate && createdAtFilter.endDate)
    ) {
      setFilterError('Enter valid date range');
      return;
    }

    fetchList(
      1,
      3,
      isDeleted,
      null,
      selectedBatchOption,
      createdAtFilter.startDate,
      createdAtFilter.endDate
    );
    closeModalHandler();
  }

  function clearFilter() {
    setCreatedAtFilter({
      startDate: null,
      endDate: null
    });

    setSelectedBatchOption(null);
    setIsDeleted(false);
    fetchList(1, 3, false, null, null, null, null);
    closeModalHandler();
  }

  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100
    })
  };

  return (
    <React.Fragment>
      <Modal isOpen={modalOpenState} backdrop="static" size="md">
        <ModalHeader toggle={closeModalHandler}>Filter Execution</ModalHeader>
        <ModalBody>
          {filterError ? (
            <Alert
              color="danger"
              role="alert"
              isOpen={filterError ? true : false}
              toggle={() => setFilterError(false)}
            >
              <i className="mdi mdi-block-helper mr-2"></i>
              {filterError}
            </Alert>
          ) : null}
          <AvForm className="needs-validation" onValidSubmit={applyFilter}>
            <Row>
              {authUser && authUser.user_type === 'super_admin' && (
                <Col md="12">
                  <FormGroup>
                    <Label>Is Deleted</Label>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr"
                    >
                      <Input
                        id="customSwitch"
                        name="isComplted"
                        type="checkbox"
                        className="custom-control-input p-3"
                        defaultChecked={isDeleted}
                      />
                      <Label
                        htmlFor="customSwitch"
                        className="custom-control-label"
                        onClick={() => setIsDeleted(!isDeleted)}
                      >
                        Deleted Executions
                      </Label>
                    </div>
                  </FormGroup>
                </Col>
              )}
              <Col md="12">
                <FormGroup>
                  <Label>Batches</Label>
                  <Select
                    options={batchOptions}
                    onChange={(e) => setSelectedBatchOption(e)}
                    value={selectedBatchOption}
                    components={animatedComponents}
                    styles={selectStyles}
                    isMulti
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label>Date</Label>
                  <DatePicker
                    selected={createdAtFilter.startDate}
                    selectsStart
                    className="form-control"
                    startDate={createdAtFilter.startDate}
                    endDate={createdAtFilter.endDate}
                    selectsRange={true}
                    dateFormat="dd-MM-yyyy"
                    onChange={dateRangeHandler(setCreatedAtFilter)}
                    isClearable={true}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="d-flex px-3" style={{ height: '100%' }}></Row>
            <ModalFooter>
              <Button size="sm" type="submit" color="primary">
                Apply Filter
              </Button>
              <Button
                size="sm"
                type="button"
                color="success"
                onClick={() => clearFilter()}
              >
                Reset
              </Button>
              <Button
                size="sm"
                type="button"
                color="light"
                onClick={closeModalHandler}
              >
                Close
              </Button>
            </ModalFooter>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(ExecutionFilterModal);
