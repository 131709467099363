/*jshint esversion: 11 */
import sendApiRequest from '../helpers/axios-setup';

/**
 *
 * @returns {Promise}
 */
export async function getRoleList(
  page,
  limit,
  {
    role_name = '',
    filter_created_by = '',
    filter_last_updated_by = '',
    filter_start_created_at = '',
    filter_end_created_at = '',
    filter_start_last_updated_at = '',
    filter_end_last_updated_at = '',
    include_deleted = ''
  },
  sorting_option = ''
) {
  try {
    const response = await sendApiRequest.get('/role', {
      params: {
        page: page,
        limit: limit,
        role_name,
        filter_created_by,
        filter_last_updated_by,
        filter_start_created_at,
        filter_end_created_at,
        filter_start_last_updated_at,
        filter_end_last_updated_at,
        include_deleted,
        sorting_option
      }
    });
    return response.data?.data;
  } catch (e) {
    return null;
  }
}

/**
 *
 * @returns {Promise<Array>}
 */
export async function getAvailablePermissions() {
  try {
    const response = await sendApiRequest.get('/role/permissions');
    return response.data?.data;
  } catch (e) {
    return null;
  }
}

/**
 *
 * @returns {Promise<Array>}
 */
export async function getRequiredPermissions(key) {
  try {
    const response = await sendApiRequest.get('/role/prerequisite', {
      params: {
        key
      }
    });
    return response.data?.data;
  } catch (e) {
    return null;
  }
}

/**
 *
 * @param {String} roleName
 * @param {Arrays} permissions
 * @returns {Promise<Object>}
 */
export async function addNewRole(roleName, permissions) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.post('/role', {
      role_name: roleName,
      permissions: permissions
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @param {String} roleId
 * @returns
 */
export async function getRoleInfo(roleId) {
  try {
    const response = await sendApiRequest.get(`/role/${roleId}/permissions`);
    return response.data?.data;
  } catch (e) {
    return null;
  }
}

/**
 *
 * @param {String} roleId
 * @param {String} roleName
 * @param {Array} permissions
 * @returns {Promise<Object>}
 */
export async function updateRole(roleId, roleName, permissions) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.put(`/role/${roleId}`, {
      role_name: roleName,
      permissions: permissions
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}

/**
 *
 * @param {String} roleId
 * @returns
 */
export async function deleteRestoreRole(roleId, type) {
  const responseMap = {
    response: null,
    error: null
  };
  try {
    const response = await sendApiRequest.delete(`/role/${roleId}`, {
      params: {
        type: type
      }
    });
    responseMap.response = response.data;
  } catch (e) {
    responseMap.error = e?.response?.data?.error;
  }
  return responseMap;
}
