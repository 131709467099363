/*jshint esversion: 11 */
import React, { useState } from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Alert
} from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import FormUpload from '../../components/FormUpload';
import { updateChild } from '../../api/child';

const ChildGalleryModal = ({
  modalOpenState,
  closeModalHandler,
  child,
  fetchNewData,
  authUser
}) => {
  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });
  const [images, setImages] = useState([]);
  const [form, setForm] = useState(new FormData());
  const [addGalleryRequestLoading, setAddGalleryRequestLoading] =
    useState(false);
  const [successAlert, setSuccessAlert] = useState({
    message: '',
    show_alert: false
  });

  async function handleAddGallery(event, values) {
    resetErrorAlert();
    if (images && images.length > 0) {
      images.map((g, index) => form.append(`pictures`, g));
    }
    setAddGalleryRequestLoading(true);
    const { response, error } = await updateChild(child, form);
    setAddGalleryRequestLoading(false);
    if (error) {
      showErrorAlert(error);
      return;
    }
    setForm(new FormData());
    setImages([]);
    fetchNewData();
    closeModalHandler();
    showSuccessAlert(response.message);
  }

  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }

  function showSuccessAlert(message) {
    setSuccessAlert({ message: message, show_alert: true });
  }

  function resetSuccessAlert() {
    setSuccessAlert({ message: '', show_alert: false });
  }

  function resetState() {
    resetSuccessAlert();
    resetErrorAlert();
  }

  return (
    <React.Fragment>
      {successAlert.show_alert && (
        <SweetAlert success title="Created" onConfirm={resetState}>
          {successAlert.message}
        </SweetAlert>
      )}
      <Modal isOpen={modalOpenState} backdrop="static" size="xl">
        <ModalHeader toggle={closeModalHandler}>{'Add Images'}</ModalHeader>
        <ModalBody>
          <React.Fragment>
            {requestError ? (
              <Alert
                color="danger"
                role="alert"
                isOpen={requestError.show_alert}
                toggle={resetErrorAlert}
              >
                <i className="mdi mdi-block-helper mr-2"></i>
                {requestError.message}
              </Alert>
            ) : null}

            <AvForm
              className="needs-validation"
              onValidSubmit={handleAddGallery}
            >
              <Row>
                <Col md="12">
                  <FormUpload title="Gallery Images" setImages={setImages} />
                </Col>
              </Row>
              <ModalFooter>
                <Button
                  disabled={addGalleryRequestLoading}
                  type="button"
                  color="light"
                  onClick={closeModalHandler}
                >
                  Close
                </Button>
                <Button type="submit" color="success">
                  {addGalleryRequestLoading ? 'Adding ...' : 'Add Images'}
                </Button>
              </ModalFooter>
            </AvForm>
          </React.Fragment>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(ChildGalleryModal);
