import React, { useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { updateChild } from '../../api/child';

const DeleteImageModal = ({
  modalOpenState,
  closeModalHandler,
  childId,
  images,
  fetchChildInfo,
  activeIndex
}) => {
  const [successAlert, setSuccessAlert] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const deleteImage = async () => {
    setRequestLoading(true);
    let form = new FormData();
    images.splice(activeIndex, 1);
    if (images.length > 0) {
      images.forEach((g, index) => {
        form.append(`images[${index}][ETag]`, g.ETag);
        form.append(`images[${index}][documentKey]`, g.documentKey);
      });
    } else {
      form.append('images', null);
    }

    const response = await updateChild(childId, form);
    if (response) {
      setRequestLoading(false);
      setSuccessAlert('Image deleted successfully');
      closeModalHandler();
      fetchChildInfo();
    }
  };
  return (
    <React.Fragment>
      {successAlert && (
        <SweetAlert
          success
          title="Deleted"
          onConfirm={() => setSuccessAlert(false)}
        >
          {successAlert}
        </SweetAlert>
      )}

      <Modal isOpen={modalOpenState} backdrop="static" size="md">
        <ModalHeader toggle={closeModalHandler}></ModalHeader>
        <ModalBody>
          <h4>Are you sure you want to delete this image?</h4>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={requestLoading}
            type="button"
            color="light"
            onClick={closeModalHandler}
          >
            Close
          </Button>
          <Button type="submit" color="danger" onClick={() => deleteImage()}>
            {requestLoading ? 'Deleting ...' : 'Delete'}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default DeleteImageModal;
