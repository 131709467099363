/*jshint esversion: 11 */
import React from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Alert,
  Input
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const QuestionFilterModal = ({
  filterError,
  setFilterError,
  modalOpenState,
  closeModalHandler,
  fetchList,
  updateFilterCount,
  questionName,
  setQuestionName,
  createdAtFilter,
  setCreatedAtFilter,
  updatedAtFilter,
  setUpdatedAtFilter,
  createQueryOptions,
  authUser,
  isDeleted,
  setIsDeleted
}) => {
  function dateRangeHandler(setHook) {
    return function (dates) {
      const [start, end] = dates;
      setHook({
        startDate: start,
        endDate: end
      });
    };
  }

  function applyFilter() {
    const queryOptions = createQueryOptions();
    if (queryOptions) {
      updateFilterCount(queryOptions);
      fetchList(1, null, queryOptions);
      closeModalHandler();
    }
  }

  function clearFilter() {
    setCreatedAtFilter({
      startDate: null,
      endDate: null
    });

    setQuestionName('');
    setIsDeleted(false);
    const queryOptions = createQueryOptions(null, null, null, null, null, null);
    if (queryOptions) {
      updateFilterCount(queryOptions);
      fetchList(1, null, queryOptions);
      closeModalHandler();
    }
  }

  return (
    <React.Fragment>
      <Modal isOpen={modalOpenState} backdrop="static" size="md">
        <ModalHeader toggle={closeModalHandler}>Filter Question</ModalHeader>
        <AvForm
          className="needs-validation"
          onValidSubmit={applyFilter}

        >
          <ModalBody>
            {filterError ? (
              <Alert
                color="danger"
                role="alert"
                isOpen={filterError ? true : false}
                toggle={() => setFilterError(false)}
              >
                <i className="mdi mdi-block-helper mr-2"></i>
                {filterError}
              </Alert>
            ) : null}
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label>Question Name</Label>
                  <AvField
                    name="name"
                    placeholder="Enter Name ..."
                    type="text"
                    className="form-control"
                    onChange={(e) => setQuestionName(e.target?.value)}
                    value={questionName}
                  />
                </FormGroup>
              </Col>

              <Col md="12">
                <FormGroup className="mb-0">
                  <Label>Created At</Label>
                  <div className="d-flex justify-content-center">
                    <DatePicker
                      selected={createdAtFilter.startDate}
                      selectsStart
                      className="form-control"
                      startDate={createdAtFilter.startDate}
                      endDate={createdAtFilter.endDate}
                      selectsRange={true}
                      dateFormat="dd-MM-yyyy"
                      onChange={dateRangeHandler(setCreatedAtFilter)}
                      isClearable={true}
                    />
                  </div>
                </FormGroup>
              </Col>
              {authUser && authUser.user_type === 'super_admin' && (
                <Col md="12">
                  <FormGroup>
                    <Label>Is Deleted</Label>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr"
                    >
                      <Input
                        id="customSwitch"
                        name="isComplted"
                        type="checkbox"
                        className="custom-control-input p-3"
                        defaultChecked={isDeleted}
                      />
                      <Label
                        htmlFor="customSwitch"
                        className="custom-control-label"
                        onClick={() => setIsDeleted(!isDeleted)}
                      >
                        Deleted Questions
                      </Label>
                    </div>
                  </FormGroup>
                </Col>
              )}
            </Row>
            {/* <Row className="d-flex px-3" style={{ height: '100%' }}></Row> */}
          </ModalBody>
          <ModalFooter>
            <Button size="sm" type="submit" color="primary">
              Apply Filter
            </Button>
            <Button
              size="sm"
              type="button"
              color="success"
              onClick={() => clearFilter()}

            >
              Reset
            </Button>
            <Button
              size="sm"
              type="button"
              color="light"
              onClick={closeModalHandler}
            >
              Close
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(QuestionFilterModal);
