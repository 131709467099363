/*jshint esversion: 11 */
import React from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Input,
  Badge
} from 'reactstrap';
import { connect } from 'react-redux';
import PageDataLoader from '../Utility/PageDataLoader';
import ExecutionCarousel from '../Child/ExecutionCarousel';

const ViewActivityModal = ({
  modalOpenState,
  closeModalHandler,
  activityInfoLoading,
  activityInfo
}) => {
  return (
    <React.Fragment>
      <Modal isOpen={modalOpenState} backdrop="static" size="lg">
        <ModalHeader toggle={closeModalHandler}>
          {activityInfoLoading ? 'Loading ...' : activityInfo.name}
        </ModalHeader>
        <ModalBody>
          {activityInfoLoading ? (
            <div className="d-flex justify-content-center">
              <PageDataLoader />
            </div>
          ) : (
            <React.Fragment>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label>Name</Label>
                    <Input
                      name="name"
                      type="text"
                      className="form-control"
                      defaultValue={activityInfo?.name}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>Duration (minutes)</Label>
                    <Input
                      name="duration"
                      type="number"
                      className="form-control"
                      defaultValue={activityInfo?.duration}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label>Summary</Label>
                    <Input
                      name="summary"
                      type="text"
                      className="form-control"
                      defaultValue={activityInfo?.summary}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label>Details</Label>
                    <Input
                      name="detail"
                      type="textarea"
                      className="form-control"
                      defaultValue={activityInfo?.detail}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col>
                  {activityInfo.tags &&
                    activityInfo.tags.map((t) => (
                      <Badge
                        color="primary"
                        className="mr-2 mb-2"
                        style={{
                          padding: '10px 20px',
                          fontSize: '14px'
                        }}
                        pill
                      >
                        {t.name}
                      </Badge>
                    ))}
                </Col>
                {activityInfo.pictures && activityInfo.pictures.length > 0 && (
                  <Col md="12">
                    <ExecutionCarousel images={activityInfo.pictures} />
                  </Col>
                )}
              </Row>
              <ModalFooter>
                <Button type="button" color="light" onClick={closeModalHandler}>
                  Close
                </Button>
              </ModalFooter>
            </React.Fragment>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(ViewActivityModal);
