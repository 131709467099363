/*jshint esversion: 11 */
import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Table,
  Label,
  FormGroup,
  Button,
  Badge
} from 'reactstrap';
import { connect } from 'react-redux';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import PageDataLoader from '../Utility/PageDataLoader';
import Paginator from '../Utility/Paginator';
import { getChildList } from '../../api/child';
import moment from 'moment';
import { validatePermissionKey } from '../../helpers/permission';
import {
  CREATE_CHILD,
  DELETE_CHILD,
  UPDATE_CHILD
} from '../Utility/PermissionKeys';
import { useHistory } from 'react-router-dom';
import { stringify as QStringify, parse as QParse } from 'qs';
import validator from 'validator';
import ChildFilterModal from './ChildFilterModal';
import DeleteChildModal from './DeleteChildModal';

const ChildListPage = ({ authUser }) => {
  const history = useHistory();
  const [breadcrumbItems] = useState([
    { title: 'Children', link: '#' },
    { title: 'View List', link: '#' }
  ]);
  const [childListLoading, setChildListLoading] = useState(true);
  const [childList, setChildList] = useState([]);
  const [sortingRule, setSortingRule] = useState('created_at$desc');
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [currentFilterCount, setCurrentFilterCount] = useState(0);
  const [dobFilter, setDobFilter] = useState({
    startDate: null,
    endDate: null
  });
  const [filterChildName, setFilterChildName] = useState('');
  const [filterPhone, setFilterPhone] = useState('');
  const [filterEmail, setFilterEmail] = useState('');
  const [filterRollNo, setFilterRollNo] = useState('');
  const [filterGender, setFilterGender] = useState('');
  const [filterLocation, setFilterLocation] = useState('');
  const [filterError, setFilterError] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageLimit: 10,
    totalEntries: 0,
    totalPages: 0
  });

  const [deleteChildModalOpen, setDeleteChildModalOpen] = useState(false);
  const [childId, setChildId] = useState('');
  const [isDeleted, setIsDeleted] = useState(false);
  let listElem = document.getElementById('childList');
  let linkElem = document.getElementById('childLink');
  useEffect(() => {
    if (listElem) listElem.classList.add('mm-active');
    if (linkElem) linkElem.classList.add('active');
    if (authUser) {
      populateSearchOptions(history?.location?.search);
    }
    // eslint-disable-next-line
  }, [authUser, listElem, linkElem]);

  const handlePagination = (page) => {
    fetchData(page, pagination.pageLimit, createQueryOptions(), sortingRule);
  };

  const fetchData = async (
    current_page = 1,
    page_limit = 10,
    queryOptions = {},
    sortingOption = sortingRule
  ) => {
    setChildListLoading(true);
    const response = await getChildList(
      current_page,
      page_limit,
      queryOptions,
      sortingOption
    );

    if (response) {
      setPagination({
        currentPage: response.metadata.page,
        pageLimit: response.metadata.limit,
        totalPages: response.metadata.total_pages,
        totalEntries: response.metadata.total_results
      });
      setChildList(response.data);
      setChildListLoading(false);
    }
  };

  /**
   *
   * @param {string} str
   */
  function getValidDateInput(str) {
    return validator.isDate(str) ? new Date(str).toISOString() : '';
  }

  function createQueryOptions(
    startDate = dobFilter.startDate,
    endDate = dobFilter.endDate,
    name = filterChildName,
    phone_number = filterPhone,
    email_address = filterEmail,
    roll_no = filterRollNo,
    gender = filterGender,
    address_location = filterLocation,
    deletedStatus = isDeleted
  ) {
    if (
      (startDate && !endDate) ||
      (!startDate && endDate) ||
      new Date(startDate) > new Date(endDate)
    ) {
      setFilterError('Enter valid date range');
      return;
    }
    const startDateISOStringDob = getValidDateInput(startDate);
    const endDateISOStringDob = getValidDateInput(endDate);
    const queryOptions = {
      filter_dob_start_date: startDateISOStringDob,
      filter_dob_end_date: endDateISOStringDob,
      filter_name: name,
      filter_phone_number: phone_number,
      filter_email_address: email_address,
      filter_roll_no: roll_no,
      filter_gender: gender,
      filter_address_location: address_location,
      include_deleted: deletedStatus,
      sorting_option: sortingRule
    };
    updateUrlPath(queryOptions);
    return queryOptions;
  }

  function updateFilterCount(option) {
    let count = 0;
    Object.keys(option).forEach((i) => {
      if (option[i]?.toString()?.trim()?.length > 0 && option[i] !== false) {
        count += 1;
      }
    });
    setCurrentFilterCount(count - 1);
  }

  /**
   *
   * @param {string} searchQuery
   */
  function populateSearchOptions(searchQuery) {
    const parsedQuery = QParse(searchQuery.substring(1));
    if (parsedQuery.filter_dob_start_date && parsedQuery.filter_dob_end_date) {
      updateDateFilterInput(
        parsedQuery.filter_dob_start_date,
        parsedQuery.filter_dob_end_date,
        setDobFilter
      );
    }
    setFilterChildName(parsedQuery.filter_name);
    setFilterPhone(parsedQuery.filter_phone_number);
    setFilterEmail(parsedQuery.filter_email_address);
    setFilterRollNo(parsedQuery.filter_roll_no);
    setFilterGender(parsedQuery.filter_gender);
    setFilterLocation(parsedQuery.filter_address_location);
    const sortingOption = parsedQuery.sorting_option || sortingRule;
    setSortingRule(sortingOption);
    updateFilterCount(parsedQuery);
    fetchData(1, null, parsedQuery, sortingOption);
  }

  /**
   *
   * @param {string} str
   * @param {function} setHook
   * @returns
   */
  function updateDateFilterInput(startStr, endStr, setHook) {
    setHook({
      startDate: new Date(startStr),
      endDate: new Date(endStr)
    });
  }

  function updateUrlPath(queryOptions) {
    const queryString = QStringify(queryOptions);
    history.replace(`/children?${queryString}`);
  }

  async function fetchSortedList(sortingOption) {
    setSortingRule(sortingOption);
    const queryOptions = createQueryOptions();
    queryOptions.sorting_option = sortingOption;
    updateUrlPath(queryOptions);
    fetchData(1, null, queryOptions, sortingOption);
  }

  function openFilterModal() {
    setFilterModalOpen(true);
  }

  function closeFilterModal() {
    setFilterModalOpen(false);
  }

  async function openDeleteChildModal(childId, requestType) {
    setChildId({
      childId,
      requestType
    });
    setDeleteChildModalOpen(true);
  }

  function closeDeleteChildModal() {
    setDeleteChildModalOpen(false);
  }

  return (
    <React.Fragment>
      <ChildFilterModal
        filterError={filterError}
        setFilterError={setFilterError}
        modalOpenState={filterModalOpen}
        closeModalHandler={closeFilterModal}
        fetchList={fetchData}
        updateFilterCount={updateFilterCount}
        updateUrlPath={updateUrlPath}
        childName={filterChildName}
        setChildName={setFilterChildName}
        childPhone={filterPhone}
        setChildPhone={setFilterPhone}
        childEmail={filterEmail}
        setChildEmail={setFilterEmail}
        rollNo={filterRollNo}
        setRollNo={setFilterRollNo}
        childDob={dobFilter}
        setChildDob={setDobFilter}
        createQueryOptions={createQueryOptions}
        childGender={filterGender}
        setChildGender={setFilterGender}
        addressLocation={filterLocation}
        setAddressLocation={setFilterLocation}
        authUser={authUser}
        isDeleted={isDeleted}
        setIsDeleted={setIsDeleted}
      />

      {childId && (
        <DeleteChildModal
          modalOpenState={deleteChildModalOpen}
          closeModalHandler={closeDeleteChildModal}
          childId={childId.childId}
          type={childId.requestType}
          fetchNewData={async () => {
            let options = await createQueryOptions();
            if (options) {
              await fetchData(1, null, options);
            }
          }}
        />
      )}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="View Children"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <>
                    <Row className="mt-2">
                      <Col md="2">
                        <FormGroup>
                          <Col md="12">
                            <Label className="col-form-label">
                              Show Entries
                            </Label>
                            <select
                              className="form-control"
                              onChange={(e) => {
                                fetchData(
                                  1,
                                  e.target.value,
                                  createQueryOptions(),
                                  sortingRule
                                );
                                setPagination({
                                  ...pagination,
                                  pageLimit: e.target.value
                                });
                              }}
                              value={pagination.pageLimit}
                              disabled={childListLoading}
                            >
                              <option value={10}>10</option>
                              <option value={25}>25</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <Label className="col-form-label">
                            Sort Children
                          </Label>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              fetchSortedList(e.target.value);
                            }}
                            value={sortingRule}
                            disabled={childListLoading}
                          >
                            <option value="created_at$desc">
                              Create Time (Newest First)
                            </option>
                            <option value="created_at$asc">
                              Create Time (Oldest First)
                            </option>
                            <option value="roll_no$asc">Roll No (A-Z)</option>
                            <option value="roll_no$desc">Roll No (Z-A)</option>
                            <option value="location$asc">Location (A-Z)</option>
                            <option value="location$desc">
                              Location (Z-A)
                            </option>
                            <option value="gender$asc">Gender (A-Z)</option>
                            <option value="gender$desc">Gender (Z-A)</option>
                            <option value="updated_at$desc">
                              Update Time (Newest First)
                            </option>
                            <option value="updated_at$asc">
                              Update Time (Oldest First)
                            </option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col className="d-flex justify-content-end align-items-center">
                        <Button
                          size="sm"
                          color="primary"
                          className=" waves-effect waves-light mr-3"
                          onClick={openFilterModal}
                        >
                          <i className="ri-filter-fill align-middle mr-2"></i>
                          <span>Filter Children</span>
                          {currentFilterCount > 0 && (
                            <Badge color="light" pill className="ml-2">
                              {currentFilterCount}
                            </Badge>
                          )}
                        </Button>
                        {validatePermissionKey(authUser, CREATE_CHILD) && (
                          <Button
                            size="sm"
                            color="success"
                            className=" waves-effect waves-light"
                            onClick={() => history.push('/children/create')}
                          >
                            <i className="ri-add-line align-middle mr-2"></i>
                            Add Child
                          </Button>
                        )}
                      </Col>
                    </Row>
                    {childListLoading ? (
                      <div className="text-center">
                        <PageDataLoader />
                      </div>
                    ) : (
                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <Table
                            id="tech-companies-1"
                            striped
                            bordered
                            responsive
                          >
                            <thead>
                              <tr>
                                <th>SL. No</th>
                                <th>Roll No</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                                <th>Gender</th>
                                <th>DOB</th>
                                <th>Address</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {childList?.map((item, index) => (
                                <tr key={index}>
                                  <th>{index + 1}</th>
                                  <th>{item.roll_no}</th>
                                  <td>{item.name}</td>
                                  <td>{item.email_address}</td>
                                  <td>{item.phone_number}</td>
                                  <td>{item.gender}</td>
                                  <td>
                                    {moment(new Date(item.dob)).format(
                                      'DD-MM-YYYY'
                                    )}
                                  </td>
                                  <td>{item.address_location}</td>
                                  <td>
                                    <Button
                                      size="sm"
                                      color="primary"
                                      className=" waves-effect waves-light mr-2 mb-2"
                                      onClick={() => {
                                        history.push({
                                          pathname: `/children/view/${item._id}`
                                        });
                                      }}
                                    >
                                      <i className="fas fa-eye mr-2"></i>
                                      View
                                    </Button>
                                    {validatePermissionKey(
                                      authUser,
                                      UPDATE_CHILD
                                    ) && (
                                        <Button
                                          size="sm"
                                          color="warning"
                                          className=" waves-effect waves-light mr-2 mb-2"
                                          onClick={() =>
                                            history.push({
                                              pathname: `/children/update/${item._id}`
                                            })
                                          }
                                        >
                                          <i className="fas fa-edit mr-2"></i>
                                          Edit
                                        </Button>
                                      )}
                                    {validatePermissionKey(
                                      authUser,
                                      DELETE_CHILD
                                    ) && (
                                        <Button
                                          size="sm"
                                          color="danger"
                                          className=" waves-effect waves-light mb-2"
                                          onClick={() => {
                                            openDeleteChildModal(
                                              item._id,
                                              item.isDeleted
                                                ? 'restore'
                                                : 'terminate'
                                            );
                                          }}
                                        >
                                          {item.isDeleted ? (
                                            <i className="fas fa-trash-restore mr-2"></i>
                                          ) : (
                                            <i className="fas fa-times mr-2"></i>
                                          )}
                                          {item.isDeleted
                                            ? 'Restore'
                                            : 'Terminate'}
                                        </Button>
                                      )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    )}
                    <Row className="mt-2">
                      <Col md="5">
                        <p>
                          <strong>
                            {' '}
                            Showing{' '}
                            {(pagination.currentPage - 1) *
                              pagination.pageLimit +
                              1}{' '}
                            to{' '}
                            {Math.min(
                              pagination.currentPage * pagination.pageLimit,
                              pagination.totalEntries
                            )}{' '}
                            of {pagination.totalEntries} entries
                          </strong>
                        </p>
                      </Col>
                      {!childListLoading && childList.length > 0 && (
                        <Col md="7">
                          <Paginator
                            totalPages={pagination.totalPages}
                            currentPage={pagination.currentPage}
                            changePage={handlePagination}
                          />
                        </Col>
                      )}
                    </Row>
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(ChildListPage);
