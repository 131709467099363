/*jshint esversion: 11 */
import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Input,
  CardImg
} from 'reactstrap';
import { connect } from 'react-redux';
import PageDataLoader from '../Utility/PageDataLoader';
import moment from 'moment';
import { fetchImage } from '../../api/image';

const ViewParentModal = ({
  modalOpenState,
  closeModalHandler,
  parentInfoLoading,
  parentInfo
}) => {
  const [imgUrl, setImgUrl] = useState('');
  const [imageLoading, setImageLoading] = useState(false);
  const getImage = async (key) => {
    setImageLoading(true);
    let response = await fetchImage(key);
    setImgUrl(URL.createObjectURL(response));
    setImageLoading(false);
  };

  useEffect(() => {
    let key;
    if (parentInfo.profile_picture && parentInfo.profile_picture.documentKey) {
      key = parentInfo.profile_picture.documentKey;
    } else {
      key = process.env.REACT_APP_PROFILE_IMG;
    }
    getImage(key);
  }, [parentInfo]);
  return (
    <React.Fragment>
      <Modal isOpen={modalOpenState} backdrop="static" size="lg">
        <ModalHeader toggle={closeModalHandler}>
          {parentInfoLoading ? 'Loading ...' : parentInfo.name}
        </ModalHeader>
        <ModalBody>
          {parentInfoLoading ? (
            <div className="d-flex justify-content-center">
              <PageDataLoader />
            </div>
          ) : (
            <React.Fragment>
              <Row>
                <Col
                  md="12"
                  className="d-flex justify-content-center align-items-center"
                >
                  {imageLoading ? (
                    <div className="text-center">
                      <PageDataLoader />
                    </div>
                  ) : (
                    <CardImg
                      src={imgUrl}
                      alt={parentInfo.name}
                      style={{ borderRadius: '50%', height: 200, width: 200 }}
                      onClick={() => {}}
                    />
                  )}
                </Col>
              </Row>
              <hr className="my-3" />
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label>Name</Label>
                    <Input
                      name="name"
                      type="text"
                      className="form-control"
                      defaultValue={parentInfo?.name}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>Phone Number</Label>
                    <Input
                      name="name"
                      type="text"
                      className="form-control"
                      defaultValue={parentInfo?.phone_number}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>DOB</Label>
                    <Input
                      name="dob"
                      type="date"
                      className="form-control"
                      defaultValue={
                        parentInfo &&
                        moment(new Date(parentInfo.dob)).format('YYYY-MM-DD')
                      }
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>Gender</Label>
                    <Input
                      name="gender"
                      type="text"
                      className="form-control"
                      defaultValue={parentInfo?.gender}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label>Address</Label>
                    <Input
                      name="address"
                      type="textarea"
                      className="form-control"
                      defaultValue={parentInfo?.address}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label>Address Location</Label>
                    <Input
                      name="address_location"
                      type="text"
                      className="form-control"
                      defaultValue={parentInfo?.address_location}
                      readOnly
                    />
                  </FormGroup>
                </Col>
              </Row>
              <ModalFooter>
                <Button type="button" color="light" onClick={closeModalHandler}>
                  Close
                </Button>
              </ModalFooter>
            </React.Fragment>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(ViewParentModal);
