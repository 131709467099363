/*jshint esversion: 11 */
import React, { useState } from 'react';
import Select from 'react-select';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Alert
} from 'reactstrap';
import FormUpload from '../../components/FormUpload';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { addNewParent } from '../../api/parent';
import { genderEnum, locationEnum } from '../Utility/Enumerator';
import { validatePhoneNumber } from '../../helpers/input-validator';

const CreateParentModal = ({
  modalOpenState,
  closeModalHandler,
  fetchNewParentList
}) => {
  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });
  const [parentCreateRequestLoading, setParentCreateRequestLoading] =
    useState(false);
  const [successAlert, setSuccessAlert] = useState({
    message: '',
    show_alert: false
  });
  const [image, setImage] = useState('');
  const locationOptions = locationEnum;
  const [selectedLocation, setSelectedLocation] = useState(null);

  const genderOptions = genderEnum;
  const [selectedGender, setSelectedGender] = useState({
    label: 'Male',
    value: 'Male'
  });

  async function handleParentCreation(event, values) {
    resetErrorAlert();
    if (!selectedGender) {
      showErrorAlert('Please select gender');
      return;
    }
    if (!selectedLocation) {
      showErrorAlert('Please select address location');
      return;
    }
    // if (!image) {
    //   showErrorAlert('Select a profile picture');
    //   return;
    // }
    let form = new FormData();
    form.set('name', values.name);
    form.set('phone_number', values.phone_number);
    form.set('dob', values.dob);
    form.set('gender', selectedGender.value);
    form.set('address', values.address);
    form.set('address_location', selectedLocation.value);
    if (image) form.set('profile_picture', image);
    setParentCreateRequestLoading(true);
    const { response, error } = await addNewParent(form);
    setParentCreateRequestLoading(false);
    if (error) {
      showErrorAlert(error);
      return;
    }
    setSelectedGender({
      label: 'Male',
      value: 'Male'
    });
    setSelectedLocation(null);
    closeModalHandler();
    showSuccessAlert(response.message);
    fetchNewParentList();
  }

  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }

  function showSuccessAlert(message) {
    setSuccessAlert({ message: message, show_alert: true });
  }

  function resetSuccessAlert() {
    setSuccessAlert({ message: '', show_alert: false });
  }

  function resetState() {
    resetSuccessAlert();
    resetErrorAlert();
  }

  function handleGenderSelection(selectedItem) {
    setSelectedGender(selectedItem);
  }

  function handleLocationSelection(selectedItem) {
    setSelectedLocation(selectedItem);
  }

  return (
    <React.Fragment>
      {successAlert.show_alert && (
        <SweetAlert success title="Created" onConfirm={resetState}>
          {successAlert.message}
        </SweetAlert>
      )}
      <Modal isOpen={modalOpenState} backdrop="static" size="xl">
        <ModalHeader toggle={closeModalHandler}>Add New Parent</ModalHeader>
        <ModalBody>
          {requestError ? (
            <Alert
              color="danger"
              role="alert"
              isOpen={requestError.show_alert}
              toggle={resetErrorAlert}
            >
              <i className="mdi mdi-block-helper mr-2"></i>
              {requestError.message}
            </Alert>
          ) : null}
          <AvForm
            className="needs-validation"
            onValidSubmit={handleParentCreation}
          >
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>Parent Name</Label>
                  <AvField
                    name="name"
                    placeholder="Enter Parent Name ..."
                    type="text"
                    className="form-control"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Parent name is required'
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Phone Number</Label>
                  <AvField
                    name="phone_number"
                    placeholder="Enter Phone Number ..."
                    type="text"
                    className="form-control"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Phone Number is required'
                      },
                      minLength: {
                        value: 10,
                        errorMessage: 'Phone Number must be 10 digits'
                      },
                      maxLength: {
                        value: 10,
                        errorMessage: 'Phone Number must be 10 digits'
                      },
                      async: validatePhoneNumber
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>DOB</Label>
                  <AvField
                    name="dob"
                    placeholder="Enter DOB ..."
                    type="date"
                    className="form-control"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'DOB is required'
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Gender</Label>
                  <Select
                    options={genderOptions}
                    onChange={handleGenderSelection}
                    value={selectedGender}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label>Address</Label>
                  <AvField
                    name="address"
                    placeholder="Enter Address ..."
                    type="textarea"
                    className="form-control"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Address is required'
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label>Address Location</Label>
                  <Select
                    options={locationOptions}
                    onChange={handleLocationSelection}
                    value={selectedLocation}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormUpload title="Profile Picture" setImage={setImage} />
              </Col>
            </Row>
            <ModalFooter>
              <Button
                disabled={parentCreateRequestLoading}
                type="button"
                color="light"
                onClick={closeModalHandler}
              >
                Close
              </Button>
              <Button type="submit" color="success">
                {parentCreateRequestLoading ? 'Creating ...' : 'Add Parent'}
              </Button>
            </ModalFooter>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(CreateParentModal);
