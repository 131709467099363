/*jshint esversion: 11 */
import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Alert
} from 'reactstrap';
import Select from 'react-select';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import PageDataLoader from '../Utility/PageDataLoader';
import {
  getAssignmentChildrenList,
  updateAssignment
} from '../../api/assignment';
import moment from 'moment';
import FormUpload from '../../components/FormUpload';
import { getChildList } from '../../api/child';
import { getTagList } from '../../api/tag';

const UpdateAssignmentModal = ({
  modalOpenState,
  closeModalHandler,
  assignmentInfo,
  assignmentInfoLoading,
  fetchNewAssignmentList,
  authUser
}) => {
  const styles = {
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
        : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: 'none' } : base;
    }
  };
  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });
  const [form, setForm] = useState(new FormData());
  const [images, setImages] = useState([]);
  const [updateAssignmentRequestLoading, setUpdateAssignmentRequestLoading] =
    useState(false);
  const [successAlert, setSuccessAlert] = useState({
    message: '',
    show_alert: false
  });
  const [childOptions, setChildOptions] = useState([]);
  const [childOptionsLoading, setChildOptionsLoading] = useState(false);
  const [children, setChildren] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [tagOptionsLoading, setTagOptionsLoading] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);

  async function handleUpdateAssignment(event, values) {
    resetErrorAlert();
    if (!children || children.length === 0) {
      showErrorAlert('Select atleast one child');
      return;
    }
    if (!selectedTag || selectedTag.length === 0) {
      showErrorAlert('Select atleast one tag');
      return;
    }
    let selectedTagArray = selectedTag && selectedTag.map((c) => c.value);
    form.set('name', values.name);
    form.set('summary', values.summary);
    form.set('date', values.date);
    form.set('video_url', values.video_url);
    selectedTagArray.map((g, index) => form.set(`tags[${index}]`, g));
    if (images && images.length > 0) {
      images.map((g, index) => form.append(`images`, g));
    }
    children.map((g, index) => form.set(`children[${index}]`, g.value));
    setUpdateAssignmentRequestLoading(true);
    const { response, error } = await updateAssignment(
      assignmentInfo._id,
      form
    );
    setUpdateAssignmentRequestLoading(false);
    if (error) {
      showErrorAlert(error);
      return;
    }
    setForm(new FormData());
    setImages([]);
    fetchNewAssignmentList();
    closeModalHandler();
    showSuccessAlert(response.message);
  }

  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }

  function showSuccessAlert(message) {
    setSuccessAlert({ message: message, show_alert: true });
  }

  function resetSuccessAlert() {
    setSuccessAlert({ message: '', show_alert: false });
  }

  function resetState() {
    resetSuccessAlert();
    resetErrorAlert();
  }

  useEffect(() => {
    setSelectedTag(null);
    setChildren(null);
    if (authUser) {
      fetchChildOptions();
      fetchChildInfo();
      fetchTagOptions();
      if (assignmentInfo.tags && assignmentInfo.tags.length > 0) {
        setSelectedTag(buildOptions(assignmentInfo.tags));
      }
    }
    // eslint-disable-next-line
  }, [authUser, modalOpenState, assignmentInfo]);

  const fetchChildOptions = async () => {
    setChildOptionsLoading(true);
    const response = await getChildList(1, 100);
    if (response) {
      setChildOptions(buildOptions(response?.data || []));
      setChildOptionsLoading(false);
    }
  };
  const fetchTagOptions = async () => {
    setTagOptionsLoading(true);
    const response = await getTagList(1, 100);
    if (response) {
      setTagOptions(buildOptions(response?.data || []));
      setTagOptionsLoading(false);
    }
  };

  function handleTagSelection(selectedItem) {
    setSelectedTag(selectedItem);
  }

  const fetchChildInfo = async () => {
    const response = await getAssignmentChildrenList({
      page: 1,
      limit: null,
      assignment: assignmentInfo._id
    });
    if (response) {
      let options = buildChildOptions(response?.data || []);
      if (options.length > 0) {
        options = options
          .filter((v) => v.isFixed)
          .concat(options.filter((v) => !v.isFixed));
      }

      setChildren(options);
    }
  };

  function buildChildOptions(list) {
    return list.map((item) => ({
      value: item.child?._id,
      label: item.child?.name,
      isFixed: item.isCompleted
    }));
  }

  function buildOptions(list) {
    return list.map((item) => ({
      value: item._id,
      label: item.name
    }));
  }

  function handleChildSelection(selectedItem) {
    setChildren(selectedItem);
  }

  return (
    <React.Fragment>
      {successAlert.show_alert && (
        <SweetAlert success title="Updated" onConfirm={resetState}>
          {successAlert.message}
        </SweetAlert>
      )}
      <Modal isOpen={modalOpenState} backdrop="static" size="xl">
        <ModalHeader toggle={closeModalHandler}>
          {'Update Assignment'}
        </ModalHeader>
        <ModalBody>
          <React.Fragment>
            {requestError ? (
              <Alert
                color="danger"
                role="alert"
                isOpen={requestError.show_alert}
                toggle={resetErrorAlert}
              >
                <i className="mdi mdi-block-helper mr-2"></i>
                {requestError.message}
              </Alert>
            ) : null}
            {assignmentInfoLoading ? (
              <div className="d-flex justify-content-center">
                <PageDataLoader />
              </div>
            ) : (
              <AvForm
                className="needs-validation"
                onValidSubmit={handleUpdateAssignment}
              >
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>Name</Label>
                      <AvField
                        name="name"
                        placeholder="Enter Name ..."
                        type="text"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Name is required'
                          }
                        }}
                        defaultValue={assignmentInfo?.name}
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <Label>Date</Label>
                      <AvField
                        name="date"
                        placeholder="Enter Date ..."
                        type="date"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Date is required'
                          }
                        }}
                        defaultValue={
                          assignmentInfo &&
                          moment(new Date(assignmentInfo.date)).format(
                            'YYYY-MM-DD'
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <Label>Children</Label>
                      <Select
                        styles={styles}
                        options={childOptions}
                        disabled={childOptionsLoading}
                        isClearable={false}
                        isMulti
                        value={children}
                        onChange={handleChildSelection}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <Label>Summary</Label>
                      <AvField
                        name="summary"
                        placeholder="Enter Summary ..."
                        type="textarea"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Summary is required'
                          }
                        }}
                        defaultValue={assignmentInfo?.summary}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <Label>Tags</Label>
                      <Select
                        options={tagOptions}
                        disabled={tagOptionsLoading}
                        isMulti
                        defaultValue={selectedTag}
                        onChange={handleTagSelection}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <Label>Video Url</Label>
                      <AvField
                        name="video_url"
                        placeholder="Enter Video Url ..."
                        type="text"
                        className="form-control"
                        defaultValue={assignmentInfo?.video_url}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormUpload
                      title="Assignment Images"
                      setImages={setImages}
                    />
                  </Col>
                </Row>
                <ModalFooter>
                  <Button
                    disabled={updateAssignmentRequestLoading}
                    type="button"
                    color="light"
                    onClick={closeModalHandler}
                  >
                    Close
                  </Button>
                  <Button type="submit" color="success">
                    {updateAssignmentRequestLoading
                      ? 'Updating ...'
                      : 'Update Assignment'}
                  </Button>
                </ModalFooter>
              </AvForm>
            )}
          </React.Fragment>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(UpdateAssignmentModal);
