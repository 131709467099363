/*jshint esversion: 11 */
import React, { useState } from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Alert
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { addNewOffering } from '../../api/offering';

const CreateOfferingModal = ({
  modalOpenState,
  closeModalHandler,
  fetchNewOfferingList
}) => {
  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });
  const [offeringCreateRequestLoading, setOfferingCreateRequestLoading] =
    useState(false);
  const [successAlert, setSuccessAlert] = useState({
    message: '',
    show_alert: false
  });

  async function handleOfferingCreation(event, values) {
    resetErrorAlert();
    setOfferingCreateRequestLoading(true);
    const { response, error } = await addNewOffering(
      values.offering_name,
      values.summary,
      values.detail,
      values.color
    );
    setOfferingCreateRequestLoading(false);
    if (error) {
      showErrorAlert(error);
      return;
    }
    closeModalHandler();
    showSuccessAlert(response.message);
    fetchNewOfferingList();
  }

  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }

  function showSuccessAlert(message) {
    setSuccessAlert({ message: message, show_alert: true });
  }

  function resetSuccessAlert() {
    setSuccessAlert({ message: '', show_alert: false });
  }

  function resetState() {
    resetSuccessAlert();
    resetErrorAlert();
  }

  return (
    <React.Fragment>
      {successAlert.show_alert && (
        <SweetAlert success title="Created" onConfirm={resetState}>
          {successAlert.message}
        </SweetAlert>
      )}
      <Modal isOpen={modalOpenState} backdrop="static" size="xl">
        <ModalHeader toggle={closeModalHandler}>Add New Offering</ModalHeader>
        <ModalBody>
          {requestError ? (
            <Alert
              color="danger"
              role="alert"
              isOpen={requestError.show_alert}
              toggle={resetErrorAlert}
            >
              <i className="mdi mdi-block-helper mr-2"></i>
              {requestError.message}
            </Alert>
          ) : null}
          <AvForm
            className="needs-validation"
            onValidSubmit={handleOfferingCreation}
          >
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>Offering Name</Label>
                  <AvField
                    name="offering_name"
                    placeholder="Enter Offering Name ..."
                    type="text"
                    className="form-control"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Offering name is required'
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Color</Label>
                  <AvField
                    name="color"
                    placeholder="Enter Color ..."
                    type="color"
                    className="form-control"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Color is required'
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label>Summary</Label>
                  <AvField
                    name="summary"
                    placeholder="Enter Summary"
                    type="text"
                    className="form-control"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Offering summary is required'
                      }
                    }}
                  />
                </FormGroup>
              </Col>

              <Col md="12">
                <FormGroup>
                  <Label>Detail</Label>
                  <AvField
                    name="detail"
                    placeholder="Enter Details"
                    type="textarea"
                    className="form-control"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Details is required'
                      }
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <ModalFooter>
              <Button
                disabled={offeringCreateRequestLoading}
                type="button"
                color="light"
                onClick={closeModalHandler}
              >
                Close
              </Button>
              <Button type="submit" color="success">
                {offeringCreateRequestLoading ? 'Creating ...' : 'Add Offering'}
              </Button>
            </ModalFooter>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(CreateOfferingModal);
