// @flow
import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  TOGGLE_RIGHT_SIDEBAR,
  SHOW_RIGHT_SIDEBAR,
  CHANGE_PRELOADER,
  HIDE_RIGHT_SIDEBAR,
  CHANGE_NOTIFICATION_PERMISSION,
  CHANGE_DOM_LOADING_STATE,
  CHANGE_SOCKET_SOUND_STATE
} from './actionTypes';

const INIT_STATE = {
  layoutType: 'vertical',
  layoutWidth: 'fluid',
  leftSideBarTheme: 'dark',
  leftSideBarType: 'default',
  topbarTheme: 'light',
  isPreloader: false,
  showRightSidebar: false,
  isMobile: false,
  notificationEnabled: false,
  kd_window_loaded: false,
  socket_sound_mute_status: false
};

const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LAYOUT:
      return {
        ...state,
        layoutType: action.payload
      };
    case CHANGE_PRELOADER:
      return {
        ...state,
        isPreloader: action.payload
      };

    case CHANGE_LAYOUT_WIDTH:
      return {
        ...state,
        layoutWidth: action.payload.width
      };
    case CHANGE_SIDEBAR_THEME:
      return {
        ...state,
        leftSideBarTheme: action.payload.theme
      };
    case CHANGE_SIDEBAR_TYPE:
      return {
        ...state,
        leftSideBarType: action.payload.sidebarType
      };
    case CHANGE_TOPBAR_THEME:
      return {
        ...state,
        topbarTheme: action.payload
      };
    case TOGGLE_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: !state.showRightSidebar
      };
    case SHOW_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: true
      };
    case HIDE_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: false
      };
    case CHANGE_NOTIFICATION_PERMISSION:
      return {
        ...state,
        notificationEnabled: true
      };
    case CHANGE_SOCKET_SOUND_STATE:
      return {
        ...state,
        socket_sound_mute_status: action.payload
      };
    case CHANGE_DOM_LOADING_STATE:
      return {
        ...state,
        kd_window_loaded: true
      };
    default:
      return state;
  }
};

export default Layout;
