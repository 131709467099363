/*jshint esversion: 11 */
import React, { useEffect, useState } from 'react';
import {
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Modal,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Alert,
    Input
} from 'reactstrap';
import Select from 'react-select';
import { AvForm } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getBatchInfo, getBatchList } from '../../api/batch';
import { getOfferingList } from '../../api/offering';
import { addNewTransaction, getLatestTransaction } from '../../api/transaction';

const AddTransactionModal = ({
    modalOpenState,
    closeModalHandler,
    authUser,
    childId,
    refresh,
    childinfo
}) => {
    const [requestError, setRequestError] = useState({ show_alert: false, message: '' });
    const [offeringOptions, setOfferingOptions] = useState([]);
    const [offeringOptionsLoading, setOfferingOptionsLoading] = useState(false);
    const [selectedOffering, setSelectedOffering] = useState(null);
    const [batchOptions, setBatchOptions] = useState([]);
    const [selectedBatch, setSelectedBatch] = useState(null);
    const [totalFee, setTotalFee] = useState(0);
    const [paidFee, setPaidFee] = useState(0);
    const [discount, setDiscount] = useState(0)
    const [discountType, setDiscountType] = useState()
    const [dueFee, setDueFee] = useState(0);
    const [sessionFee, setSessionFee] = useState(0);
    const [batchDetail, setBatchDetail] = useState(null);
    const [noOfSessions, setNumberOfSessions] = useState(1);
    const [noOfRegSession, setNumberOfRegSessions] = useState(0);
    const [rows, setRows] = useState([{ description: '', rate: '', quantity: '', price: '' }]);
    const [batchCreateRequestLoading, setBatchCreateRequestLoading] = useState(false);
    const [successAlert, setSuccessAlert] = useState({ message: '', show_alert: false });
    const [lastTransaction, setLastTransaction] = useState(null);
    const [paid, setPaid] = useState(false)
    const [paidTillNow, setPaidTillNow] = useState(0)
    const [madeBy, setMadeby] = useState({})
    const [address, setAddress] = useState()

    const description_list = [{ value: "Admission Fee", label: "Admission Fee" }, { value: "Tution Fee", label: "Tution Fee" }, { value: "Library", label: "Library" }, { value: "Newspaper", label: "Newspaper" }, { value: "Uniform", label: "Uniform" }, { value: "", label: "Others" }]
    const address_list = [{ value: "5, Mahatma Gandhi Rd, opp. Rajubhai Dhoklawala, Amrut Nagar, Kamala Nagar, Dahanukar Wadi", label: "5, Mahatma Gandhi Rd, opp. Rajubhai Dhoklawala, Amrut Nagar, Kamala Nagar, Dahanukar Wadi, Kandivali West, Mumbai, Maharashtra 400067" }, { value: "Shop 02, Off Link Road, Extension, Mahavir Nagar", label: "Shop 02, Off Link Road, Extension, Mahavir Nagar, Kandivali West, Mumbai, Maharashtra 400067" }]


    useEffect(() => {
        setBatchOptions(buildOptions(childinfo?.batches || []))
        if (authUser) {
            setMadeby({
                name: authUser?.name,
                email: authUser?.email_address,
                user_type: authUser?.user_type
            })
            // fetchOfferingOptions();
        }
    }, [authUser, modalOpenState]);

    const fetchBatchOptions = async (offeringId) => {
        try {
            const response = await getBatchList(1, 100, { offering: offeringId });
            setBatchOptions(buildOptions(response?.data || []));
        } catch (error) {
            showErrorAlert('Error fetching batch options');
        }
    };

    const handleTransactionCreation = async () => {

        resetErrorAlert();
        if (!selectedBatch) {
            showErrorAlert('Please select a batch');
            return;
        }
        if (discount < 0) {
            showErrorAlert("Discount cannot be negative")
            return
        }
        if (discount && !discountType) {
            showErrorAlert("Please select discount type")
            return
        }
        if (!address) {
            showErrorAlert("Please select one address")
            return
        }
        if (parseInt(paidFee) - parseInt(discount) < 0) {
            showErrorAlert("Discount cannot be more than paid amount")
            return
        }


        let form = {
            batchId: selectedBatch.value,
            invoiceDetail: rows,
            childrenId: childId,
            totalAmountToPay: parseInt(batchDetail?.totalFee),
            noOfSessions: parseInt(batchDetail?.totalSessions),
            isSessionBatch: batchDetail?.isSessionBatch,
            paidAmount: parseInt(paidFee),
            dueAmount: parseInt(dueFee),
            perSessionFee: batchDetail?.sessionFee,
            rows: JSON.stringify(rows),
            madeBy: madeBy,
            InvoiceID: '0',
            rollNo: childinfo?.roll_no,
            discountAmount: parseInt(discount),
            address: address.value,
            discountType: discountType?.value ? discountType.value : ""

        };

        let error_row = false

        rows.map((dt) => {
            if (dt.description === "" || dt.price === "" || dt.rate === "" || dt.quantity === "") {
                error_row = true
            }
            else {
                dt.description = dt.description.value
            }
        })

        if (error_row) {
            showErrorAlert("Please enter valid description, rate and quantity")
            return
        }


        if (batchDetail?.isSessionBatch) {
            if (lastTransaction?.dueAmount === 0 && lastTransaction?.registeredSessions) {
                form = { ...form, registeredSessions: noOfRegSession > 0 ? parseInt(parseInt(noOfRegSession) + parseInt(lastTransaction?.registeredSessions)) : 1 + lastTransaction?.registeredSessions }
            }
            else {
                form = { ...form, registeredSessions: noOfRegSession > 0 ? parseInt(noOfRegSession) : 1 }
            }
        }

        setBatchCreateRequestLoading(true);
        try {
            const { response, error } = await addNewTransaction(form);
            if (error) {
                showErrorAlert(error);
                return;
            }
            resetForm();
            refresh()
            closeModalHandler();
            showSuccessAlert(response.message);
        } catch (error) {
            showErrorAlert('Error creating transaction');
        } finally {
            setBatchCreateRequestLoading(false);
        }
    };

    const handleModalClose = () => {
        resetForm()
        closeModalHandler()
    }

    const handleBatchSelection = async (selectedItem) => {
        setPaid(false)
        setSelectedBatch(selectedItem);
        try {
            const response = await getBatchInfo(selectedItem?.value);
            setBatchDetail(response);
            const transactionDetail = await getLatestTransaction({ batch_id: selectedItem?.value, child_id: childId });
            setLastTransaction(transactionDetail?.data);

            if (!response.isSessionBatch) {
                if (transactionDetail?.data?.dueAmount === 0) {
                    setPaid(true)
                }
                setTotalFee(response?.totalFee);
                setPaidTillNow(response?.totalFee - transactionDetail?.data?.dueAmount || 0)
                setDueFee(transactionDetail?.data?.dueAmount || response?.totalFee);
            } else {

                if (transactionDetail?.data?.registeredSessions >= response?.totalSessions && transactionDetail?.data?.dueAmount == 0) {
                    setPaid(true)
                }
                else {
                    const dueAmount = transactionDetail?.data?.dueAmount >= 0 ? transactionDetail?.data?.dueAmount : noOfRegSession * response?.sessionFee;
                    setNumberOfSessions(response?.totalSessions)
                    if (transactionDetail?.data?.dueAmount === 0) {
                        setNumberOfSessions(response?.totalSessions - transactionDetail?.data?.registeredSessions)
                        setNumberOfRegSessions(transactionDetail?.data?.registeredSessions)
                    }
                    if (transactionDetail?.data) {

                        setNumberOfRegSessions(transactionDetail?.data?.registeredSessions)
                        setTotalFee(transactionDetail?.data?.registeredSessions * response?.sessionFee)
                    }
                    setDueFee(dueAmount);
                    setPaidTillNow((transactionDetail?.data?.registeredSessions * response?.sessionFee) - dueAmount)

                }
            }
        } catch (error) {
            showErrorAlert('Error fetching batch information');
        }
    };

    const buildOptions = (list) => {
        return list.map((item) => ({ value: item._id, label: item.name }));
    };

    const handleRowChange = (index, field, value) => {
        const updatedRows = [...rows];
        updatedRows[index][field] = value;
        if (field === 'rate' || field === 'quantity') {
            updatedRows[index].price = updatedRows[index].rate * updatedRows[index].quantity;
        }
        setRows(updatedRows);
        updatePaidAmount(updatedRows);
    };

    const addRow = () => {
        const newRows = [...rows, { description: '', rate: '', quantity: '', price: '' }];
        setRows(newRows);
        updatePaidAmount(newRows);
    };

    const removeRow = (index) => {
        const updatedRows = rows.filter((_, rowIndex) => rowIndex !== index);
        setRows(updatedRows);
        updatePaidAmount(updatedRows);
    };

    const updatePaidAmount = (updatedRows) => {
        const totalPaid = updatedRows.reduce((sum, row) => sum + (row.price || 0), 0);
        // setPaidFee(totalPaid);
        setDueFee(batchDetail?.totalFee - totalPaid);
        if (!batchDetail?.isSessionBatch) {
            setDueFee(lastTransaction?.dueAmount ? lastTransaction?.dueAmount - totalPaid : batchDetail?.totalFee - totalPaid);
        }
        else if (batchDetail?.isSessionBatch) {
            setDueFee(lastTransaction?.dueAmount ? lastTransaction?.dueAmount - totalPaid : totalFee - totalPaid);
        }
        setPaidFee(totalPaid);
    };

    const showErrorAlert = (message) => {
        setRequestError({ message, show_alert: true });
    };

    const resetErrorAlert = () => {
        setRequestError({ message: '', show_alert: false });
    };

    const showSuccessAlert = (message) => {
        setSuccessAlert({ message, show_alert: true });
    };

    const resetSuccessAlert = () => {
        setSuccessAlert({ message: '', show_alert: false });
    };

    const resetForm = () => {
        setRequestError({ show_alert: false, message: '' });
        setOfferingOptions([]);
        setOfferingOptionsLoading(false);
        setSelectedOffering(null);
        setBatchOptions([]);
        setSelectedBatch(null);
        setTotalFee(0);
        setPaidFee(0);
        setDueFee(0);
        setSessionFee(0);
        setBatchDetail(null);
        setNumberOfSessions(1);
        setNumberOfRegSessions(0);
        setRows([{ description: '', rate: '', quantity: '', price: '' }]);
        setBatchCreateRequestLoading(false);
        setSuccessAlert({ message: '', show_alert: false });
        setLastTransaction(null);
        setPaid(false);
        setDiscount(0)
        setDiscountType({})
    };

    const handleSessionFee = (e) => {
        const fee = e.target.value;
        setSessionFee(fee);
        const value = parseInt(fee) * parseInt(noOfSessions);
        setTotalFee(value);
    };

    const handleNoOfSessions = (e) => {
        const value = e.target.value;
        setNumberOfSessions(value);
        setTotalFee(value * batchDetail?.sessionFee);
        setDueFee(value * batchDetail?.sessionFee);
        setPaidFee(0);
    };

    const handleRegisterSessionSelection = (e) => {
        setNumberOfRegSessions(e.target.value);
        setTotalFee(e.target.value * batchDetail?.sessionFee);
        setDueFee(e.target.value * batchDetail?.sessionFee);
        setPaidFee(0);
    };

    return (
        <React.Fragment>
            {successAlert.show_alert && (
                <SweetAlert success title="Created" onConfirm={resetSuccessAlert}>
                    {successAlert.message}
                </SweetAlert>
            )}
            <Modal isOpen={modalOpenState} backdrop="static" size="xl">
                <ModalHeader toggle={handleModalClose}>Add New Transaction</ModalHeader>
                <ModalBody>
                    {requestError.show_alert && (
                        <Alert color="danger" role="alert" isOpen={requestError.show_alert} toggle={resetErrorAlert}>
                            <i className="mdi mdi-block-helper mr-2"></i>
                            {requestError.message}
                        </Alert>
                    )}
                    <AvForm className="needs-validation" onValidSubmit={handleTransactionCreation}>
                        <Row>

                            <Col md="4">
                                <FormGroup>
                                    <Label>Batch</Label>
                                    <Select
                                        options={batchOptions}
                                        onChange={handleBatchSelection}
                                        value={selectedBatch}
                                    />
                                </FormGroup>
                            </Col>
                            {!paid ? <>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>Batch Type</Label>
                                        <Input
                                            value={batchDetail ? batchDetail?.isSessionBatch ? "Session Batch" : "Normal Batch" : ""}
                                            disabled
                                            className="form-control"
                                        />
                                    </FormGroup>
                                </Col></> : null}

                            <hr style={{ borderTop: '1px solid lightgray' }} />

                            {!paid ? <>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>{batchDetail ? batchDetail?.isSessionBatch ? "Session Fee" : "Batch Fee" : "Batch Fee"}</Label>
                                        <Input
                                            type="number"
                                            value={batchDetail ? batchDetail?.sessionFee : ""}
                                            onChange={handleSessionFee}
                                            disabled
                                            className="form-control"
                                        />
                                    </FormGroup>
                                </Col>
                                {batchDetail?.isSessionBatch && (
                                    <>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label>Number Of Sessions</Label>
                                                <Input
                                                    type="number"
                                                    value={noOfSessions}
                                                    disabled
                                                    onChange={handleNoOfSessions}
                                                    className="form-control"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label>Registered Sessions </Label>
                                                <Input
                                                    type="number"
                                                    value={noOfRegSession}
                                                    disabled={lastTransaction?.dueAmount ? true : false}
                                                    onChange={handleRegisterSessionSelection}
                                                    className="form-control"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </>
                                )}
                                <Col md="4">
                                    <FormGroup>
                                        <Label>Total Fee</Label>
                                        <Input
                                            type="number"
                                            value={batchDetail ? batchDetail?.isSessionBatch ? totalFee : batchDetail?.totalFee : ""}
                                            disabled={batchDetail ? batchDetail?.totalFee ? true : false : true}
                                            className="form-control"
                                        />
                                    </FormGroup>
                                </Col>
                            </> : null}

                            <Col md="4">
                                <FormGroup>
                                    <Label>Amount Paid Till Now</Label>
                                    <Input
                                        value={paidTillNow}
                                        disabled
                                        className="form-control"
                                    />
                                </FormGroup>
                            </Col>

                            <Col md="4">
                                <FormGroup>
                                    <Label>Address</Label>
                                    <Select
                                        value={address}
                                        options={address_list}
                                        onChange={(e) => { setAddress({ value: e.value, label: e.label }) }}
                                    />
                                </FormGroup>
                            </Col>


                        </Row>
                        <hr style={{ borderTop: '1px solid lightgray' }} />
                        {!paid ? (
                            <>
                                {rows.map((row, index) => (
                                    <Row key={index}>
                                        <Col md="5">
                                            <FormGroup>
                                                <Label>Description</Label>
                                                <Select
                                                    value={row.description}
                                                    options={description_list}
                                                    onChange={(e) =>
                                                        handleRowChange(index, 'description', { value: e.value, label: e.label })
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="2.5">
                                            <FormGroup>
                                                <Label>Rate</Label>
                                                <Input
                                                    type="number"
                                                    value={row.rate}
                                                    onChange={(e) => handleRowChange(index, 'rate', e.target.value)}
                                                    className="form-control"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="1">
                                            <FormGroup>
                                                <Label>Quantity</Label>
                                                <Input
                                                    type="number"
                                                    value={row.quantity}
                                                    onChange={(e) => handleRowChange(index, 'quantity', e.target.value)}
                                                    className="form-control"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="2.5">
                                            <FormGroup>
                                                <Label>Price</Label>
                                                <Input
                                                    type="number"
                                                    value={row.price}
                                                    readOnly
                                                    className="form-control"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="1" className="d-flex align-items-end mb-3">
                                            <Button color="primary" onClick={addRow} className="mr-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                                    <path d="M8 7V4.5a.5.5 0 0 1 1 0V7h2.5a.5.5 0 0 1 0 1H9v2.5a.5.5 0 0 1-1 0V8H5.5a.5.5 0 0 1 0-1H8z" />
                                                </svg>
                                            </Button>
                                            {index > 0 && (
                                                <Button color="danger" onClick={() => removeRow(index)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dash" viewBox="0 0 16 16">
                                                        <path d="M3.5 8a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1h-8a.5.5 0 0 1-.5-.5z" />
                                                    </svg>
                                                </Button>
                                            )}
                                        </Col>
                                        {row.description.label === "Others" && (<Col md="5">
                                            <FormGroup>
                                                <Label>Please Enter description value</Label>
                                                <Input
                                                    value={row.description?.value}
                                                    // options={description_list}
                                                    onChange={(e) =>
                                                        handleRowChange(index, 'description', { value: e.target.value, label: "Others" })
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>)}
                                    </Row>
                                ))}


                                <Row >
                                    <Col md='5'></Col>
                                    <Col md="3" className='mx-1'>
                                        <FormGroup>
                                            <Label for="discount">Discount Type</Label>
                                            <Select
                                                value={discountType}
                                                options={[{ value: "Promotional", label: "Promotional" }, { value: "Additional", label: "Additional" }]}
                                                onChange={(e) =>
                                                    setDiscountType({ value: e.value, label: e.label })
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="2.5" className='mx-2'>
                                        <FormGroup>
                                            <Label for="discount">Discount</Label>
                                            <Input id="discount" type="number" value={discount} onChange={(e) => setDiscount(e.target.value)} min={0} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="1"></Col>
                                </Row>


                                <Row >
                                    <Col md='5'></Col>
                                    <Col md="3" className='mx-1'>
                                        <FormGroup>
                                            <Label for="due-fee">Due Amount</Label>
                                            <Input id="due-fee" type="number" value={dueFee} readOnly />
                                        </FormGroup>
                                    </Col>
                                    <Col md="2.5" className='mx-2'>
                                        <FormGroup>
                                            <Label for="paid-fee">Paid Amount</Label>
                                            <Input id="paid-fee" type="number" value={paidFee - discount} readOnly />
                                        </FormGroup>
                                    </Col>
                                    <Col md="1"></Col>
                                </Row>

                                <ModalFooter>
                                    <Button type="submit" color="primary" disabled={batchCreateRequestLoading}>
                                        {batchCreateRequestLoading ? 'Saving...' : 'Save'}
                                    </Button>
                                    <Button type="button" color="secondary" onClick={handleModalClose}>
                                        Cancel
                                    </Button>
                                </ModalFooter>
                            </>
                        ) : <Label>Fee Already Paid</Label>}
                    </AvForm>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    authUser: state.Login?.user
});

export default connect(mapStateToProps)(AddTransactionModal);
