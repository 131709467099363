/*jshint esversion: 11 */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import {
  Row,
  Col,
  Container,
  Button,
  FormGroup,
  Label,
  Alert,
  Card,
  CardBody,
  CardTitle
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getChildInfo, updateChild } from '../../api/child';
import {
  bloodGroup,
  genderEnum,
  languages,
  locationEnum,
  relationEnum
} from '../Utility/Enumerator';
import {
  validateEmailAddress,
  validatePhoneNumber
} from '../../helpers/input-validator';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { getParentList } from '../../api/parent';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import PageDataLoader from '../Utility/PageDataLoader';
import FormUpload from '../../components/FormUpload';

const UpdateChildPage = (props) => {
  const history = useHistory();
  const [breadcrumbItems] = useState([
    { title: 'Children', link: '/children' },
    { title: 'Update Child', link: '#' }
  ]);

  const [parentArray, setParentArray] = useState([
    {
      parent: '',
      relation: ''
    }
  ]);

  const [parentOptions, setParentOptions] = useState([]);
  const [parentOptionsLoading, setParentOptionsLoading] = useState(false);

  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });
  const [childUpdateRequestLoading, setChildUpdateRequestLoading] =
    useState(false);
  const [successAlert, setSuccessAlert] = useState({
    message: '',
    show_alert: false
  });

  const [errorAlert, setErrorAlert] = useState({
    message: '',
    show_alert: false
  });

  const [image, setImage] = useState('');
  const [childInfo, setChildInfo] = useState({});
  const [childInfoLoading, setChildInfoLoading] = useState(true);

  const locationOptions = locationEnum;
  const [selectedLocation, setSelectedLocation] = useState(null);

  const genderOptions = genderEnum;
  const [selectedGender, setSelectedGender] = useState(null);

  const relationOptions = relationEnum;
  const [bloodGroupOptions, setBloodGroupOptions] = useState([]);
  const [selectedBloodGroup, setSelectedBloodGroup] = useState(null);

  const [languageOptions, setLanguageOptions] = useState([]);
  const [selectedMotherTongue, setSelectedMotherTongue] = useState(null);
  const [selectedLangSpoken, setSelectedLangSpoken] = useState(null);

  async function handleChildUpdation(event, values) {
    let parents = [];
    let languages_spoken = [];
    resetErrorAlert();
    if (
      parentArray.length > 0 &&
      !(
        parentArray.length === 1 &&
        !parentArray[0].parent.value &&
        !parentArray[0].relation.value
      )
    ) {
      parents = parentArray.map((p) => ({
        parent: p.parent.value,
        relation: p.relation.value
      }));
    }
    if (selectedLangSpoken.length > 0) {
      languages_spoken = selectedLangSpoken.map((l) => l.value);
    }

    if (!selectedGender) {
      showErrorAlert('Please select gender');
      return;
    }
    if (!selectedLocation) {
      showErrorAlert('Please select address location');
      return;
    }

    if (!selectedBloodGroup) {
      showErrorAlert('Please select blood group');
      return;
    }
    if (!selectedMotherTongue || languages_spoken.length === 0) {
      showErrorAlert('Atleast one language should be associated');
    }

    // if (parents.length === 0) {
    //   showErrorAlert('Atleast one parent should be associated');
    // }
    let form = new FormData();
    form.set('name', values.name);
    form.set('email_address', values.email_address);
    form.set('phone_number', values.phone_number);
    form.set('dob', values.dob);
    form.set('gender', selectedGender.value);
    form.set('address', values.address);
    form.set('address_location', selectedLocation.value);
    if (parents.length > 0) {
      parents.forEach((g, index) => {
        form.append(`parents[${index}][parent]`, g.parent);
        form.append(`parents[${index}][relation]`, g.relation);
      });
    }
    form.set('mother_tongue', selectedMotherTongue.value);
    languages_spoken.map((g, index) =>
      form.set(`languages_spoken[${index}]`, g)
    );
    form.set('allergies', values.allergies);
    form.set('blood_group', selectedBloodGroup.value);
    form.set('good_habits', values.good_habits);
    form.set('bad_habits', values.bad_habits);
    form.set('sleeping_pattern', values.sleeping_pattern);
    if (image) form.set('profile_picture', image);
    setChildUpdateRequestLoading(true);
    const { response, error } = await updateChild(childInfo._id, form);
    setChildUpdateRequestLoading(false);
    if (error) {
      showErrorAlert(error);
      return;
    }

    setSelectedGender(null);
    setSelectedLocation(null);
    showSuccessAlert(response.message);
    history.goBack();
  }

  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }

  function showSuccessAlert(message) {
    setSuccessAlert({ message: message, show_alert: true });
  }

  function resetSuccessAlert() {
    setSuccessAlert({ message: '', show_alert: false });
  }

  function resetState() {
    resetSuccessAlert();
    resetErrorAlert();
  }

  function handleGenderSelection(selectedItem) {
    setSelectedGender(selectedItem);
  }

  function handleLocationSelection(selectedItem) {
    setSelectedLocation(selectedItem);
  }

  function handleBloodGroupSelection(selectedItem) {
    setSelectedBloodGroup(selectedItem);
  }

  function handleMotherTongueSelection(selectedItem) {
    setSelectedMotherTongue(selectedItem);
  }

  function handleLangSpokenSelection(selectedItem) {
    setSelectedLangSpoken(selectedItem);
  }

  function handleParentSelection(index, selectedItem) {
    let newParentArray = [...parentArray];
    newParentArray[index]['parent'] = selectedItem;
    setParentArray(newParentArray);
  }

  function handleRelationSelection(index, selectedItem) {
    let newParentArray = [...parentArray];
    newParentArray[index]['relation'] = selectedItem;
    setParentArray(newParentArray);
  }
  let listElem = document.getElementById('childList');
  let linkElem = document.getElementById('childLink');
  useEffect(() => {
    if (listElem) listElem.classList.add('mm-active');
    if (linkElem) linkElem.classList.add('active');
    fetchChildInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location, listElem, linkElem]);

  async function fetchChildInfo() {
    setChildInfoLoading(true);
    const response = await getChildInfo(props.match.params.childId);
    if (response) {
      setChildInfo(response);
      setChildInfoLoading(false);
    } else {
      setErrorAlert({
        message: 'Child details not found',
        show_alert: true
      });
      setChildInfoLoading(false);
    }
  }
  useEffect(() => {
    if (props.authUser) {
      fetchParentOptions();
      setBloodGroupOptions(buildListOptions(bloodGroup));
      setLanguageOptions(buildListOptions(languages));
    }
    // eslint-disable-next-line
  }, [props.authUser]);

  useEffect(() => {
    if (childInfo && childInfo.parents && parentOptions.length > 0) {
      if (childInfo.parents.length > 0) {
        let parentData = childInfo.parents.map((p) => {
          return {
            parent: { label: p.parent.name, value: p.parent._id },
            relation: { label: p.relation, value: p.relation }
          };
        });
        setParentArray(parentData);
      }

      setSelectedGender(
        genderOptions.find((k) => k.value === childInfo?.gender)
      );
      setSelectedLocation(
        locationOptions.find((k) => k.value === childInfo?.address_location)
      );

      setSelectedBloodGroup(
        bloodGroupOptions.find((k) => k.value === childInfo?.blood_group)
      );

      setSelectedMotherTongue(
        languageOptions.find((k) => k.value === childInfo?.mother_tongue)
      );
      setSelectedLangSpoken(buildListOptions(childInfo?.languages_spoken));
    }
    // eslint-disable-next-line
  }, [childInfo, parentOptions]);

  const fetchParentOptions = async () => {
    setParentOptionsLoading(true);
    const response = await getParentList(1, 100);
    if (response) {
      setParentOptions(buildOptions(response?.data || []));
      setParentOptionsLoading(false);
    }
  };

  function buildOptions(list) {
    return list.map((item) => ({
      value: item._id,
      label: item.name
    }));
  }

  function buildListOptions(list) {
    return list.map((item) => ({
      value: item,
      label: item
    }));
  }

  const addParentFields = () => {
    resetErrorAlert();
    setParentArray([...parentArray, { parent: '', relation: '' }]);
  };

  let removeParentFields = (i) => {
    let newParentArray = [...parentArray];
    newParentArray.splice(i, 1);
    setParentArray(newParentArray);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Update Child" breadcrumbItems={breadcrumbItems} />
          {successAlert.show_alert && (
            <SweetAlert success title="Updated" onConfirm={resetState}>
              {successAlert.message}
            </SweetAlert>
          )}
          {requestError ? (
            <Alert
              color="danger"
              role="alert"
              isOpen={requestError.show_alert}
              toggle={resetErrorAlert}
            >
              <i className="mdi mdi-block-helper mr-2"></i>
              {requestError.message}
            </Alert>
          ) : null}
          {errorAlert.show_alert && (
            <SweetAlert
              danger
              title=""
              onConfirm={() => history.push('/children')}
            >
              {errorAlert.message}
            </SweetAlert>
          )}
          {childInfoLoading ? (
            <div className="text-center">
              <PageDataLoader />
            </div>
          ) : (
            <AvForm
              className="needs-validation"
              onValidSubmit={handleChildUpdation}
            >
              <Row>
                <Col md={6} xs={12}>
                  <Card>
                    <CardBody>
                      <CardTitle tag="h5">Basic Details</CardTitle>
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <Label>Child Name</Label>
                            <AvField
                              name="name"
                              placeholder="Enter Child Name ..."
                              type="text"
                              className="form-control"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: 'Child name is required'
                                }
                              }}
                              defaultValue={childInfo?.name}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <Label>Email Address</Label>
                            <AvField
                              name="email_address"
                              placeholder="Enter Email Address ..."
                              type="text"
                              className="form-control"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: 'Email Address is required'
                                },
                                async: validateEmailAddress
                              }}
                              defaultValue={childInfo?.email_address}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label>Phone Number</Label>
                            <AvField
                              name="phone_number"
                              placeholder="Enter Phone Number ..."
                              type="text"
                              className="form-control"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: 'Phone Number is required'
                                },
                                minLength: {
                                  value: 10,
                                  errorMessage: 'Phone Number must be 10 digits'
                                },
                                maxLength: {
                                  value: 10,
                                  errorMessage: 'Phone Number must be 10 digits'
                                },
                                async: validatePhoneNumber
                              }}
                              defaultValue={childInfo?.phone_number}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label>DOB</Label>
                            <AvField
                              name="dob"
                              placeholder="Enter DOB ..."
                              type="date"
                              className="form-control"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: 'DOB is required'
                                }
                              }}
                              defaultValue={
                                childInfo &&
                                moment(new Date(childInfo.dob)).format(
                                  'YYYY-MM-DD'
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label>Gender</Label>
                            <Select
                              options={genderOptions}
                              onChange={handleGenderSelection}
                              value={selectedGender}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label>Address Location</Label>
                            <Select
                              options={locationOptions}
                              onChange={handleLocationSelection}
                              value={selectedLocation}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <Label>Address</Label>
                            <AvField
                              name="address"
                              placeholder="Enter Address ..."
                              type="textarea"
                              className="form-control"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: 'Address is required'
                                }
                              }}
                              defaultValue={childInfo?.address}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormUpload
                            setImage={setImage}
                            title="Profile Picture"
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6} xs={12}>
                  <Card>
                    <CardBody>
                      <CardTitle tag="h5">Additional Details</CardTitle>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label>Blood Group</Label>
                            <Select
                              options={bloodGroupOptions}
                              onChange={handleBloodGroupSelection}
                              value={selectedBloodGroup}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label>Allergies</Label>
                            <AvField
                              name="allergies"
                              placeholder="Enter Allergies ..."
                              type="text"
                              className="form-control"
                              defaultValue={childInfo?.allergies}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label>Good Habits</Label>
                            <AvField
                              name="good_habits"
                              placeholder="Enter Good Habits ..."
                              type="text"
                              className="form-control"
                              defaultValue={childInfo?.good_habits}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label>Bad Habits</Label>
                            <AvField
                              name="bad_habits"
                              placeholder="Enter Bad Habits ..."
                              type="text"
                              className="form-control"
                              defaultValue={childInfo?.bad_habits}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label>Sleeping Pattern</Label>
                            <AvField
                              name="sleeping_pattern"
                              placeholder="Enter Sleeping Pattern ..."
                              type="text"
                              className="form-control"
                              defaultValue={childInfo?.sleeping_pattern}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label>Mother Tongue</Label>
                            <Select
                              options={languageOptions}
                              onChange={handleMotherTongueSelection}
                              value={selectedMotherTongue}
                            />
                          </FormGroup>
                        </Col>

                        <Col md="12">
                          <FormGroup>
                            <Label>Languages Spoken</Label>
                            <Select
                              isMulti
                              options={languageOptions}
                              onChange={handleLangSpokenSelection}
                              value={selectedLangSpoken}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <Row className="mt-2 mb-2">
                        <Col md="5">
                          <CardTitle tag="h5">Parent Details</CardTitle>
                        </Col>
                        <Col className="d-flex justify-content-end align-items-center">
                          <Button
                            size="sm"
                            color="success"
                            className=" waves-effect waves-light"
                            disabled={
                              !parentArray[parentArray.length - 1].parent ||
                              !parentArray[parentArray.length - 1].relation
                            }
                            onClick={() => addParentFields()}
                          >
                            <i className="ri-add-line align-middle mr-2"></i>
                            Parent
                          </Button>
                        </Col>
                      </Row>

                      {parentArray.map((element, index) => (
                        <Row>
                          <Col md="5">
                            <FormGroup>
                              <Label>Parent</Label>
                              <Select
                                options={parentOptions}
                                disabled={parentOptionsLoading}
                                onChange={(e) =>
                                  handleParentSelection(index, e)
                                }
                                value={element.parent}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="5">
                            <FormGroup>
                              <Label>Relation</Label>
                              <Select
                                options={relationOptions}
                                onChange={(e) =>
                                  handleRelationSelection(index, e)
                                }
                                value={element.relation}
                              />
                            </FormGroup>
                          </Col>
                          <Col
                            md="2"
                            className="d-flex mt-1 align-items-center"
                          >
                            <Button
                              size="sm"
                              color="danger"
                              disabled={index === 0}
                              className=" waves-effect waves-light"
                              onClick={() => removeParentFields(index)}
                            >
                              <i className="ri-delete-bin-6-line align-middle"></i>
                            </Button>
                          </Col>
                        </Row>
                      ))}
                    </CardBody>
                  </Card>

                  <Button type="submit" color="success">
                    {childUpdateRequestLoading
                      ? 'Updating ...'
                      : 'Update Child'}
                  </Button>
                </Col>
              </Row>
            </AvForm>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(UpdateChildPage);
