/*jshint esversion: 11 */
import React, { useMemo, useState } from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Input
} from 'reactstrap';
import { upperCase } from 'lodash';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { getAvailablePermissions } from '../../api/role';
import PageDataLoader from '../Utility/PageDataLoader';
import moment from 'moment';

const ViewRoleModal = ({
  modalOpenState,
  closeModalHandler,
  authUser,
  roleInfoLoading,
  roleInfo
}) => {
  const [availablePermissions, setAvailablePermissions] = useState({});
  const [permissionListLoading, setPermissionListLoading] = useState(false);
  const permissionSelectionInput = useMemo(
    () => buildSelectionInput(availablePermissions, roleInfo.permissions),
    // eslint-disable-next-line
    [availablePermissions, roleInfo.permissions]
  );

  useEffect(() => {
    if (authUser) {
      fetchAvailablePermissions();
    }
    // eslint-disable-next-line
  }, [authUser]);

  const fetchAvailablePermissions = async () => {
    setPermissionListLoading(true);
    const response = await getAvailablePermissions();
    if (response) {
      setAvailablePermissions(response);
      setPermissionListLoading(false);
    }
  };

  function buildSelectionInput(permissionMap, selectedPermissionList = []) {
    const permissionInputMap = new Map();
    Object.keys(permissionMap)
      .flatMap((k) => permissionMap[k])
      .forEach((t) => {
        if (selectedPermissionList.includes(t)) {
          permissionInputMap.set(t, true);
        } else {
          permissionInputMap.set(t, false);
        }
      });
    return Object.fromEntries(permissionInputMap);
  }

  return (
    <React.Fragment>
      <Modal isOpen={modalOpenState} backdrop="static" size="lg">
        <ModalHeader toggle={closeModalHandler}>
          {roleInfoLoading ? 'Loading ...' : roleInfo.role_name}
        </ModalHeader>
        <ModalBody>
          {roleInfoLoading || permissionListLoading ? (
            <div className="d-flex justify-content-center">
              <PageDataLoader />
            </div>
          ) : (
            <React.Fragment>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <Label>Created By</Label>
                    <Input
                      name="created_by"
                      type="text"
                      className="form-control"
                      defaultValue={roleInfo?.created_by?.name}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Creator Email Address</Label>
                    <Input
                      name="created_by.email_address"
                      type="text"
                      className="form-control"
                      defaultValue={roleInfo?.created_by?.email_address}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Creator User Type</Label>
                    <Input
                      name="created_by.user_type"
                      type="text"
                      className="form-control"
                      defaultValue={upperCase(roleInfo?.created_by?.user_type)}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Last Updated By</Label>
                    <Input
                      name="last_updated_by"
                      type="text"
                      className="form-control"
                      defaultValue={roleInfo?.last_updated_by?.name}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Email Address</Label>
                    <Input
                      name="last_updated_by.email_address"
                      type="text"
                      className="form-control"
                      defaultValue={roleInfo?.last_updated_by?.email_address}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>User Type</Label>
                    <Input
                      name="last_updated_by.user_type"
                      type="text"
                      className="form-control"
                      defaultValue={upperCase(
                        roleInfo?.last_updated_by?.user_type
                      )}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>Created At</Label>
                    <Input
                      name="role_name"
                      type="text"
                      className="form-control"
                      defaultValue={moment(roleInfo?.created_at).format(
                        'DD-MM-YYYY | hh:mm A'
                      )}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>Last Updated At</Label>
                    <Input
                      name="role_name"
                      type="text"
                      className="form-control"
                      defaultValue={moment(roleInfo?.last_updated_at).format(
                        'DD-MM-YYYY | hh:mm A'
                      )}
                      readOnly
                    />
                  </FormGroup>
                </Col>
              </Row>
              <hr className="my-2" />
              <Row className="d-flex px-3" style={{ height: '100%' }}>
                <React.Fragment>
                  {Object.keys(availablePermissions).map((k, idx) => (
                    <React.Fragment key={idx}>
                      <Col
                        sm={4}
                        className="border p-2"
                        style={{ height: '100%' }}
                      >
                        <h5 className="font-size-14 mb-4">{upperCase(k)}</h5>
                        {availablePermissions[k].map((pmKey) => (
                          <div
                            className="form-check mb-3"
                            key={pmKey}
                            style={{ cursor: 'pointer' }}
                          >
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              id={pmKey}
                              checked={permissionSelectionInput[pmKey]}
                              readOnly
                              name={pmKey}
                              style={{ cursor: 'pointer' }}
                            />
                            <Label
                              style={{ cursor: 'pointer' }}
                              className="form-check-label"
                              htmlFor={pmKey}
                            >
                              {upperCase(pmKey)}
                            </Label>
                          </div>
                        ))}
                      </Col>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              </Row>
              <ModalFooter>
                <Button type="button" color="light" onClick={closeModalHandler}>
                  Close
                </Button>
              </ModalFooter>
            </React.Fragment>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(ViewRoleModal);
